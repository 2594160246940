import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Col,
  Row,
  Card,
  CardText,
  Container,
  CardTitle,
  CardSubtitle,
  CardFooter,
  UncontrolledAlert,
  Label,
  Form,
  FormGroup,
  Input,
  Alert,
} from "reactstrap";
import "../../assets/css/minha_medalha.css";
import { busca } from "../../api/api_configuracao.js";
import { busca as buscaAtleta } from "../../api/api_athlete";
import { getToken, getUrlApi } from "../../api/auth.js";
import ClipLoader from "react-spinners/ClipLoader";
import Avatar from "react-avatar";
import MenuConfiguracao from "./MenuConfiguracao.jsx";
import { Link } from "@material-ui/core";
import ProximosEventos from "../Atletas/ProximosEventos.jsx";

const VisibilidadeAtleta = () => {
  let { id } = useParams();
  const [visibilidade, setVisibilidade] = useState([]);
  let [loading, setLoading] = useState(true);
  const [showResult, setShowResult] = useState(false);
  const [showNoResult, setShowNoResult] = useState(false);
  const [atletaPerfil, setAtletaPerfil] = useState([])
  const [atualizar, setAtualizar] = useState(false);
  const [fotoPerfil, setFotoPerfil] = useState("");
  const [aniversario, setAniversario] = useState("");
  const [sexo, setSexo] = useState("");
  const [calcado, setCalcado] = useState("");
  const [camiseta, setCamiseta] = useState("");
  const [resumo, setResumo] = useState("");
  const [assessoriaEsportiva, setAssessoriaEsportiva] = useState("");
  const [historicoCorridas, setHistoricoCorridas] = useState("");
  const [proximasCorridas, setProximasCorridas] = useState("");
  const [block, setBlock] = useState(true);

  useEffect(() => {
    busca(
      `/buscaVisibilidade`,
      setVisibilidade,
      setLoading,
      setShowResult,
      setShowNoResult
    );

    buscaAtleta(
      "/meuPerfil/",
      setAtletaPerfil,
      setLoading,
      setShowResult,
      setShowNoResult
    );
  }, [atualizar, id]);

  if (visibilidade.id !== undefined && block) {
    setFotoPerfil(visibilidade.fotoPerfil);
    setAniversario(visibilidade.aniversario);
    setSexo(visibilidade.sexo);
    setCalcado(visibilidade.calcado);
    setCamiseta(visibilidade.camiseta);
    setResumo(visibilidade.resumo);
    setAssessoriaEsportiva(visibilidade.assessoriaEsportiva);
    setHistoricoCorridas(visibilidade.historicoCorridas);
    setProximasCorridas(visibilidade.proximasCorridas);
    setBlock(false);
  }
  const [messageAPI, setMessageAPI] = useState("");
  const [resultColor, setResultColor] = useState("");

  function AlterarConfiguracao(campo, valor) {
    setMessageAPI(null);
    setLoading(true);
    let valorFoto = 0;
    let novoValor = !valor;
    if (campo === 'fotoPerfil') {
      valorFoto = valor;
    }
    const token = getToken();
    const requestInfo = {
      method: "GET",
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };

    fetch(
      getUrlApi() + "/configuracao/alterarVisibilidade/" + campo + "/" + novoValor + "/" + valorFoto,
      requestInfo
    ).then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          setMessageAPI(data.message);
          setResultColor("success");
          setAtualizar(!atualizar);
        } else {
          if (response.status === 202) {
            setMessageAPI(data.message);
          } else {
            if (response.status === 400) {
              setMessageAPI("Erro Inesperado"); //data[0].message);
            }
          }
          setResultColor("warning");
        }
      });
    });
    setLoading(false);
  }

  return (
    <Container className="p-1">
      <Row>
        {showResult ? null : null}
        {showNoResult ? null : null}
        <Col sm="2" className="p-0 ">
          <MenuConfiguracao />
        </Col>
        <Col sm="10" className="p-2 pt-2">
          <div className="text-center">
            <ClipLoader color="gray" loading={loading} size={60} />
          </div>
          <Card>
            <Form>
              <Row>
                <Col xs="12" sm="4">
                  <Card className="m-2 p-2">
                    <FormGroup switch disabled >
                      <Input type="switch" role="switch" disabled checked />
                      <Label className="text_subTitulo">Configurações básicas (obrigatório)</Label>
                      <Label className="text_small">Nome, número de amigos, número de seguidores e Localização</Label>
                    </FormGroup>

                    <CardText>
                      <Label className="text_subTitulo">Foto do perfil</Label>

                      <FormGroup check>
                        <Input
                          name="fotoPerfil"
                          type="radio"
                          checked={fotoPerfil === 1 ? 'checked' : null}
                          onClick={(event) => { AlterarConfiguracao('fotoPerfil', 1); setFotoPerfil(1) }}
                        />
                        {' '}
                        <Label check className="text_form_radio">
                          Somente meus amigos</Label>
                      </FormGroup>
                      <FormGroup check>
                        <Input
                          name="fotoPerfil"
                          type="radio"
                          checked={fotoPerfil === 2 ? 'checked' : null}
                          onClick={(event) => { AlterarConfiguracao('fotoPerfil', 2); setFotoPerfil(2) }}
                        />
                        {' '}
                        <Label className="text_form_radio">Amigos e seguidores</Label>
                      </FormGroup>
                      <FormGroup check>
                        <Input
                          name="fotoPerfil"
                          type="radio"
                          checked={fotoPerfil === 0 ? 'checked' : null}
                          onClick={(event) => { AlterarConfiguracao('fotoPerfil', 0); setFotoPerfil(0) }}
                        />
                        {' '}
                        <Label className="text_form_radio">Todos usuários do Minhas medalhas</Label>
                      </FormGroup>
                    </CardText>
                    <CardText>
                      <FormGroup switch>
                        <Input type="switch" role="switch" name='aniversario' checked={aniversario}
                          onClick={(event) => { AlterarConfiguracao('aniversario', aniversario); setAniversario(!aniversario) }} />
                        <Label check className="text_subTitulo">Aniversário </Label>
                      </FormGroup>
                    </CardText>
                    <CardText>
                      <FormGroup switch>
                        <Input type="switch" role="switch" name='sexo' checked={sexo}
                          onClick={(event) => { AlterarConfiguracao('sexo', sexo); setSexo(!sexo) }} />
                        <Label check className="text_subTitulo">Sexo</Label>
                      </FormGroup>
                    </CardText>
                    <CardText>
                      <FormGroup switch>
                        <Input type="switch" role="switch" name='calcado' checked={calcado}
                          onClick={(event) => { AlterarConfiguracao('calcado', calcado); setCalcado(!calcado) }} />
                        <Label check className="text_subTitulo">Calçado</Label>
                      </FormGroup>
                    </CardText>
                    <CardText>
                      <FormGroup switch>
                        <Input type="switch" role="switch" name='camiseta' checked={camiseta}
                          onClick={(event) => { AlterarConfiguracao('camiseta', camiseta); setCamiseta(!camiseta) }} />
                        <Label check className="text_subTitulo">Camiseta</Label>
                      </FormGroup>
                    </CardText>
                    <CardText>
                      <FormGroup switch>
                        <Input type="switch" role="switch" name='resumo' checked={resumo}
                          onClick={(event) => { AlterarConfiguracao('resumo', resumo); setResumo(!resumo) }} />
                        <Label check className="text_subTitulo">Resumo</Label>
                      </FormGroup>
                    </CardText>
                    <CardText>
                      <FormGroup switch>
                        <Input type="switch" role="switch" name='assessoriaEsportiva' checked={assessoriaEsportiva}
                          onClick={(event) => { AlterarConfiguracao('assessoriaEsportiva', assessoriaEsportiva); setAssessoriaEsportiva(!assessoriaEsportiva) }} />
                        <Label check className="text_subTitulo">Assessoria Esportiva</Label>
                      </FormGroup>
                    </CardText>
                    <CardText>
                      <FormGroup switch>
                        <Input type="switch" role="switch" name='historicoCorridas' checked={historicoCorridas}
                          onClick={(event) => { AlterarConfiguracao('historicoCorridas', historicoCorridas); setHistoricoCorridas(!historicoCorridas) }} />
                        <Label check className="text_subTitulo">Histórico de Corridas</Label>
                      </FormGroup>
                    </CardText>
                    <CardText>
                      <FormGroup switch>
                        <Input type="switch" role="switch" name='proximasCorridas' checked={proximasCorridas}
                          onClick={(event) => { AlterarConfiguracao('proximasCorridas', proximasCorridas); setProximasCorridas(!proximasCorridas) }} />
                        <Label check className="text_subTitulo">Próximas Corridas</Label>
                      </FormGroup>
                    </CardText>
                    <CardFooter>
                      <Label className="text_small">
                        Os campos CPF, e-mail e telefone não são utilizados para exibição de perfil
                      </Label>
                    </CardFooter>
                  </Card>
                </Col>
                <Col xs="12" sm="8">
                  <div className="text-center">
                    <ClipLoader color="gray" loading={loading} size={60} />
                  </div>
                  {messageAPI ? (
                    <UncontrolledAlert color={resultColor} className="m-2 text-center text_alert">
                      {messageAPI}
                    </UncontrolledAlert>
                  ) : null}
                  <Row>
                    <Col xs="12" className="p-3 m-0 mt-1 text-center">
                      <Alert color="info" className="text_alert">Prévia de como o seu perfil irá aparecer para os outros Atletas!</Alert>
                    </Col>
                    <Col xs="12" sm="4" >
                      <CardText className="p-3 m-0 mt-3 text-center">
                        {atletaPerfil.foto === null ? (
                          <Avatar name={atletaPerfil.nome} size="150" round={true} maxInitials={2} />
                        ) : (
                          <img
                            src={atletaPerfil.foto} alt="Foto Atleta"
                            style={{ width: '150px', height: '150px', objectFit: 'cover', borderRadius: '50%' }}
                          />
                        )}
                      </CardText>
                    </Col>
                    <Col xs="12" sm="7" className="m-0 mt-3 p-3 pr-0 ">
                      <Card>
                        <CardTitle tag="h4" className="text_big_center">
                          {atletaPerfil.nome}
                        </CardTitle>
                        <CardSubtitle tag="h3" className="text_medium_center mb-3">
                          {atletaPerfil.apelido}
                        </CardSubtitle>
                        <Row xs="3">
                          {aniversario ? (
                            <>
                              <Col sm="5" xs="5" className="text-end text_form">
                                Aniversário
                              </Col>
                              <Col sm="7" xs="7" className="pl-0 text_banco">
                                {atletaPerfil.dataNascimento}
                              </Col>
                            </>
                          ) : null}
                          <Col sm="5" xs="5" className="text-end text_form">
                            Localidade
                          </Col>
                          <Col sm="7" xs="7" className="pl-0 text_banco">
                            {atletaPerfil.nomeCidade !== null
                              ? atletaPerfil.nomeCidade + " - " + atletaPerfil.uf
                              : "Não informado"}
                          </Col>
                          {sexo ? (
                            <>
                              <Col sm="5" xs="5" className="text-end text_form">
                                Sexo
                              </Col>
                              <Col sm="7" xs="7" className="pl-0 text_banco">
                                {atletaPerfil.sexo === "Nao_informado" ? "Não informado" : atletaPerfil.sexo}
                              </Col>
                            </>
                          ) : null}
                          {calcado ? (
                            <>
                              <Col sm="5" xs="5" className="text-end text_form">
                                Calçado
                              </Col>
                              <Col sm="7" xs="7" className="pl-0 text_banco">
                                {atletaPerfil.numeroTenis}
                              </Col>
                            </>
                          ) : null}
                          {camiseta ? (
                            <>
                              <Col sm="5" xs="5" className="text-end text_form">
                                Tamanho da camiseta
                              </Col>
                              <Col sm="7" xs="7" className="pl-0 text_banco">
                                {atletaPerfil.tamanhoCamiseta}
                              </Col>
                            </>
                          ) : null}
                        </Row>
                      </Card>
                      {resumo ? (
                        <>
                          <Card className="mt-3 p-1 text-justify text_form">
                            Informações do(a) Atleta: <br />
                            <Label className="text_banco">{atletaPerfil.resumo}</Label>
                          </Card>
                        </>
                      ) : null}
                    </Col>
                  </Row>
                  {assessoriaEsportiva ? (
                    atletaPerfil.nomeTime ? (
                      <Card className="mt-3 p-1 text-justify">
                        <Row>
                          <Col xs="4" sm="3">
                            <CardText className="p-2 m-0">
                              <Link to={`/assessoria/${atletaPerfil.idTime}`}>
                                {atletaPerfil.logoTime === null ? (
                                  <Avatar name={atletaPerfil.nomeTime} size="70" round={true} maxInitials={2} />
                                ) : (
                                  <Avatar src={atletaPerfil.logoTime} size="70" round={true} />
                                )}
                              </Link>
                            </CardText>
                          </Col>
                          <Col xs="8" sm="9" className="text-center pl-0">
                            <CardText className="text_form">
                              {atletaPerfil.nome} faz parte do time {atletaPerfil.nomeTime}
                            </CardText>
                            <CardText className="text_banco">{atletaPerfil.emailTime}</CardText>
                            <CardText className="p-0 m-0 text_banco">
                              <svg
                                width="1em"
                                height="1em"
                                viewBox="0 0 16 16"
                                class="bi bi-telephone-plus"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM12.5 1a.5.5 0 0 1 .5.5V3h1.5a.5.5 0 0 1 0 1H13v1.5a.5.5 0 0 1-1 0V4h-1.5a.5.5 0 0 1 0-1H12V1.5a.5.5 0 0 1 .5-.5z"
                                />
                              </svg>
                              {atletaPerfil.contatoTime}
                            </CardText>
                          </Col>
                        </Row>
                      </Card>
                    ) : null
                  ) : null}
                  {proximasCorridas ? <ProximosEventos /> : null}
                </Col>
              </Row>
            </Form>
          </Card>

        </Col>
      </Row>

    </Container>
  );
};

export default VisibilidadeAtleta;
