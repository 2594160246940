import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import {
  Alert,
  Button,
  CardImg,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import logo from "../../assets/img/logo_minhas_medalhas_lg.svg";
import img_login from "../../assets/img/img_signin.svg";
import { getUrlApi } from "../../api/auth";

function EsqueceiSenha() {
  let [loading, setLoading] = useState(false);
  const [usuario, setUsuario] = useState("");
  const [messageAPI, setMessageAPI] = useState("");
  const [resultColor, setResultColor] = useState("");
  const emailInput = useRef(null);

  function envia() {
    setLoading(true);
    const requestInfo = {
      method: "POST",
      body: JSON.stringify({
        usuario: `${usuario}`,
      }),
      headers: new Headers({
        "Content-type": "application/json",
      }),
    };
    fetch(
      getUrlApi() + "/novoAtleta/esqueciSenha/",
      requestInfo
    ).then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          //setMessageAPI(data.message);
          window.location.href = window.location.origin + "/emailEnviado/" + data.token + "/" + usuario;
        } else {
          if (response.status === 202) {
            setMessageAPI(data.message);
          } else {
            if (response.status === 400) {
              setMessageAPI(data[0].message);
            }
          }
          setResultColor("warning");
        }
      });
    });
    setLoading(false);
  }

  return (
    <Container >
      <Row>
        <Col sm="6">
          <Row>
            <Col sm="8" className="pt-4 pb-3">
              <Link to={`/login`}>
                <CardImg src={logo} alt="Minhas Medalhas" />
              </Link>
            </Col>
            <Col sm="4">&nbsp;</Col>
          </Row>
          <Row>
            <Col sm="8" className="pt-1 pb-1">
              <Label className="text_small">
                <center>Enviaremos um código de verificação para o e-mail informado se ele corresponder a uma conta existente do Minhas Medalhas.</center>
              </Label>
              <Form
                method="Post"
                onSubmit={(event) => {
                  event.preventDefault();
                  envia();
                }}
              >
                <FormGroup>
                  <Label className="text_form">Email:*</Label>
                  <Input
                    bsSize="sm"
                    value={usuario}
                    onChange={(event) => {
                      setUsuario(event.target.value);
                    }}
                    ref={emailInput}
                    type="email"
                    name="usuario"
                    minLength="5"
                    id="usuario"
                    required
                  />
                  <FormFeedback invalid>{messageAPI}</FormFeedback>
                </FormGroup>
                <div className="text-center">
                    <ClipLoader color="gray" loading={loading} size={60} />
                </div>
                {messageAPI ? <Alert color={resultColor} className="text_small">{messageAPI}</Alert> : null}
                <Button color="primary" className="button_form" type="submit" block>
                  Enviar
                </Button>
                <Link to={`/login`}>
                  <Button color="secondary" className="button_form" outline block >
                    Voltar
                  </Button>
                </Link>
              </Form>
            </Col>
            <Col sm="4">&nbsp;</Col>
          </Row>
        </Col>
        <Col sm="6">
          <CardImg src={img_login} className="img_lateral" />
        </Col>
      </Row>

    </Container>
  );
}

export default EsqueceiSenha;
