import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Badge,
  Card,
  CardBody,
  CardFooter,
  CardGroup,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
  UncontrolledAlert,
} from "reactstrap";
import Patrocinio from "../Home/Patrocinio";
import AssessoriaAtleta from "./AssessoriaAtleta";
import { busca } from "../../api/api_teams";
import ClipLoader from "react-spinners/ClipLoader";
import { getToken, getUrlApi } from "../../api/auth";
import Avatar from 'react-avatar';

const ListaAssessorias = () => {
  const [assessorias, setAssessorias] = useState([]);
  let [loading, setLoading] = useState(true);
  const [showResult, setShowResult] = useState(false);
  const [showNoResult, setShowNoResult] = useState(false);
  const [searchTime, setSearchTime] = useState([]);

  function buscaTime(nomeTime) {
    const token = getToken();
    const requestTime = {
      method: "POST",
      body: JSON.stringify({
        nome: `${nomeTime}`,
      }),
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };
    fetch(
      getUrlApi() + "/time/buscaAssessoria/",
      requestTime
    ).then((response) => {
      response.json().then((data) => {
        setAssessorias(data);
      });
    });
  }

  const [messageAPI, setMessageAPI] = useState("");
  const [resultColor, setResultColor] = useState("");

  function Associar(codigoTime) {
    const token = getToken();
    const requestInfo = {
      method: "GET",
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };

    fetch(
      getUrlApi() + "/atleta/solicitarTime/" + codigoTime,
      requestInfo
    ).then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          setMessageAPI(data.message);
          setResultColor("success");
        } else {
          if (response.status === 202) {
            setMessageAPI(data.message);
          } else {
            if (response.status === 400) {
              setMessageAPI("Erro Inesperado"); //data[0].message);
            }
          }
          setResultColor("warning");
        }
      });
    });
  }

  useEffect(() => {
    busca(
      "/assessorias",
      setAssessorias,
      setLoading,
      setShowResult,
      setShowNoResult
    );
  }, []);

  const Result = () => (
    <CardGroup className="mt-2">
      {assessorias.map((time) => (
        <Col sm="3" className="p-1">
          <Card className="m-0 p-0 pt-2 text-center h-100">
            <Row>
              <Col xs="6" md="12">
                <Link to={`/assessoria/${time.id}`}>
                  {time.logo === null ? (
                    <Avatar name={time.nome} size="150" round={true} maxInitials={2} />
                  ) : (
                    <Avatar src={time.logo} size="150" round={true} />
                  )}
                </Link>
              </Col>
              <Col xs="6" md="12">
                <CardBody>
                  <CardTitle className="text_big">{time.nome}</CardTitle>
                  <CardText className="m-0 text_banco">
                    {time.nomeCidade} - {time.uf}
                  </CardText>
                  <CardText className="m-0 text_banco">{time.fone}</CardText>
                </CardBody>
              </Col>
            </Row>
            <CardFooter>
              <Link >
                <Badge color="primary" className="p-2" pill onClick={() => {
                  Associar(time.id);
                }}>
                  Associar
                </Badge>
              </Link>
              &nbsp;&nbsp;
              <Link to={`/assessoria/${time.id}`}>
                <Badge color="info" className="p-2" pill>
                  Ver Atletas
                </Badge>
              </Link>
            </CardFooter>
          </Card>
        </Col>
      ))}
    </CardGroup>
  );

  const NoResult = () => (
    <CardText className="p-3 text_alert">
      Algo deu errado! Volte e selecione novamente uma assessoria!
    </CardText>
  );

  return (
    <Container>
      <Row>
        <Col sm="9" className="p-2 pt-2">
          <AssessoriaAtleta />
          <Card className="mt-2">
            <CardHeader className="text_big_center pt-1" >
              Assessorias Esportivas
            </CardHeader>
            <CardText>
              <Form>
                <Row>
                  <Col sm="12">
                    <FormGroup className="p-2 m-0">
                      <Input
                        bsSize="sm"
                        type="text"
                        name="assessoria"
                        id="assessoria"
                        placeholder="Informe o nome de uma Assessoria para filtrar"
                        onChange={(event) => {
                          setSearchTime(event.target.value);
                          buscaTime(event.target.value);
                        }}
                        value={searchTime}
                        minLength="3"
                        maxlength="80"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </CardText>
          </Card>
          <Card>
            {messageAPI ? (
              <UncontrolledAlert
                color={resultColor}
                className="m-2 text-center text_alert"
              >
                {messageAPI}
              </UncontrolledAlert>
            ) : null}
            <div className="text-center">
              <ClipLoader color="gray" loading={loading} size={60} />
            </div>
            {showResult ? <Result /> : null}
            {showNoResult ? <NoResult /> : null}
          </Card>
        </Col>
        <Col sm="3" className="p-0 pt-2">
          <Patrocinio />
        </Col>
      </Row>
    </Container>
  );
};

export default ListaAssessorias;
