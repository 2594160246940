import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Card,
  CardText,
  CardHeader,
  Button,
  Col,
  Row,
  Alert,

} from "reactstrap";
import ClipLoader from "react-spinners/ClipLoader";
import { busca } from "../../api/api_event";


const ListaParticipantes = () => {
  const { id } = useParams();
  const [participantes, setParticipantes] = useState([]);
  let [loading, setLoading] = useState(true);
  const [showResult, setShowResult] = useState(false);
  const [showNoResult, setShowNoResult] = useState(false);
  const [idModalidade, setIdModaldade] = useState(0);
  const [eventoDetalhe1, setEventoDetalhe1] = useState([]);

  function FiltraModalidade(idModalidade) {
    setLoading(true);
    setIdModaldade(idModalidade);
  }


  useEffect(() => {
    busca(
      '/listaParticipantes/' + id + '/' + idModalidade,
      setParticipantes,
      setLoading,
      setShowResult,
      setShowNoResult
    );
    busca(
      `/corrida/${id}`,
      setEventoDetalhe1,
      setLoading,
      setShowResult,
      setShowNoResult
    );
  }, [idModalidade, id]);

  const Result = () => (
    <>
      <div className="text-center">
        <ClipLoader color="gray" loading={loading} size={60} />
      </div>
      <Alert color="dark" className="m-0 p-1">
        <CardText >
          <Row >
            <Col xs="2" md="1" className="pr-0 text_banco">
              #
            </Col>
            <Col xs="10" md="5" className="p-0  text_banco">
              Nome Atleta
            </Col>
            <Col xs="3" md="1" className="pr-0  text_banco">
              Modalid.
            </Col>
            <Col xs="2" md="1" className="p-0 text_banco">
              # camisa
            </Col>
            <Col xs="4" md="2" className="p-0 text_banco">
              Tempo de prova
            </Col>
            <Col xs="3" md="2" className="p-0 text_banco">
              Pace
            </Col>
          </Row>
        </CardText>
      </Alert>


      {participantes &&
        participantes.map((atletas) => (
          <Alert color="ligth" className="m-0 p-1">
          <CardText striped>
            <Row>
              <Col xs="2" md="1" className="pr-0 text_banco">
                #{atletas.posicaoChegadaGeral}
              </Col>
              <Col xs="10" md="5" className="p-0  text_banco">
                {atletas.nomeAtleta}
              </Col>
              <Col xs="3" md="1" className="pr-0  text_banco">
                {atletas.modalidade}
              </Col>
              <Col xs="2" md="1" className="p-0 text-center text_banco">
                {atletas.numeroAtleta}
              </Col>
              <Col xs="4" md="2" className="p-0 text-center text_banco">
                {atletas.tempoProva}
              </Col>
              <Col xs="3" md="2" className="p-0 text-center text_banco">
                {atletas.pace}
              </Col>
            </Row>
          </CardText>
          </Alert>
        ))}
      {participantes && participantes.length === 0 ? (
        <Card className="pb-1 pt-1 pb-2 text_medium">Nenhum atleta inscrito </Card>
      ) : (
        ""
      )}
      <br/>
    </>
  );

  const NoResult = () => <Card>Nao foram encontrados atletas</Card>;

  return (
    <>
      <CardHeader className="p-0 m-0">
        <Row>
          <Col xs="12" md="12" className="text-end m-0">
            <Button color="dark" className="p-1 m-1 mr-4" onClick={() => {
              FiltraModalidade(0);
            }}>
              Todos
            </Button>
            {eventoDetalhe1.modalidades &&
              eventoDetalhe1.modalidades.map((categoria) => (
                <Button
                  color={categoria.cor}
                  outline
                  className="p-1 m-1 mr-2"
                  id={categoria.id}
                  onClick={() => {
                    FiltraModalidade(categoria.id);
                  }}
                >
                  {categoria.nome}
                </Button>
              ))}
          </Col>
        </Row>
      </CardHeader>
      {showResult ? <Result /> : null}
      {showNoResult ? <NoResult /> : null}
    </>
  );
};

export default ListaParticipantes;
