import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardFooter,
  CardText,
  CardTitle,
  Col,
  Container,
  Label,
  Row,

} from "reactstrap";
import Rodape from "../Home/Rodape";
import CabecalhoAjuda from "./CabecalhoAjuda";

function PoliticaDeCookies() {

  return (
    <Container className="text-center">
      <CabecalhoAjuda />
      <Card className="p-3 text-start">
        <Row>
          <Col xs="12" sm="2" >&nbsp;</Col>
          <Col xs="12" sm="8"  >
            <CardTitle className="text_big pb-4">Política de Privacidade</CardTitle>
            <CardText>
              <Label className="text_small">Em vigor desde 09/01/2023</Label>
              <Label className="text_central_ajuda">
                Esta Política de Privacidade se aplica quando você utiliza os nossos serviços. Queremos que você saiba quais informações coletamos e como as usamos. É por essa razão que recomendamos a leitura da nossa Política de Privacidade. Isso facilita o uso do nosso sistema da maneira certa para você.
              </Label>
              <Label className="text_central_ajuda">
                Na Política de Privacidade, além de explicarmos como coletamos, usamos e transferimos informações. Explicamos também quais são seus direitos. Cada seção da Política apresenta exemplos úteis em uma linguagem simples para que nossas práticas sejam fáceis de entender.
              </Label>
              <Label className="text_central_ajuda">
                É importante que você saiba como controlar sua privacidade. Iremos demonstrar onde gerenciar suas informações para personalizar sua experiência.

              </Label>
              <Label className="text_central_ajuda">
                Esta Política de Privacidade se aplica quando você utiliza os nossos serviços (descritos a seguir). Oferecemos opções aos nossos usuários em relação aos dados que coletamos, usamos e compartilhamos, conforme descrito nesta Política de Privacidade, na Política de Cookies, nas Configurações e em nossa Central de Ajuda.
              </Label>
              <Label className="text_central_ajuda">
                Leia abaixo a Política na íntegra.
              </Label>
            </CardText>
            <CardText>
              <Label className="text_central_ajuda">
                Somos uma rede social e uma plataforma online para corredores de rua. Os corredores usam os nossos Serviços para registrar suas corridas, compartilhar suas conquistas, para encontrarem corridas pelo Brasil e para se conectarem com outras pessoas. Nossa Política de Privacidade se aplica a todos os Usuários da comunidade.
              </Label>
              <Label className="text_central_ajuda">
                Os nossos usuários cadastrados (“Usuários”) compartilham suas conquistas pessoais nas corridas, interagem com suas redes, trocam conhecimentos e informações sobre corridas, publicam e visualizam conteúdos relevantes, encontram assessorias esportivas e aprendem conteúdos relevantes com corridas de rua. O conteúdo e os dados de alguns de nossos Serviços podem ser visíveis para não usuários (“Visitantes”).
              </Label>
            </CardText>
            <CardText className="text_medium">
              Quais dados que coletamos
            </CardText>
            <CardText className="text_central_ajuda">
              Dados que você fornece
            </CardText>
            <CardText className="text_central_ajuda">
              Cadastro
            </CardText>
            <CardText className="text_central_ajuda">
              Para criar uma conta, você fornece dados que incluem seu nome, endereço de e-mail, sexo, CPF, data de nascimento, número do dispositivo móvel e uma senha.
            </CardText>
            <CardText className="text_central_ajuda">
              Perfil
            </CardText>
            <CardText className="text_central_ajuda">
              Você cria seu Perfil do Minhas Medalhas (um perfil completo permite melhor aproveitar nossos Serviços).
            </CardText>
            <CardText className="text_central_ajuda">
              Você tem opções sobre as informações inseridas no seu perfil, tais como o tamanho do seu tênis, número da camiseta, Apelido ou número utilizado no peito, Localidade (cidade/estado onde mora), foto, tipo sanguíneo, fator RH e comentário sobre você além de associar os eventos que você particiou. Não é necessário fornecer informações adicionais no seu perfil, entretanto, as informações do perfil permitem que você obtenha mais benefícios dos nossos Serviços, incluindo ser encontrado por Assessorias Esportivas e identificarmos em nosso sistema eventos que você tenha corrido e não adicionou. Você pode escolher se deseja incluir informações confidenciais no seu perfil e tornar tais informações públicas. Não publique nem adicione ao perfil dados pessoais que não queira disponibilizar ao público.
            </CardText>
            <CardText className="text_central_ajuda">
              Publicações e carregamentos
            </CardText>
            <CardText className="text_central_ajuda">
              Coletamos seus dados pessoais quando você os fornece, publica ou carrega em nossos Serviços, responde a uma pesquisa ou envia solicitação para uma assessoria.
            </CardText>
            <CardText className="text_central_ajuda">
              Você não é obrigado a publicar nem carregar dados pessoais; no entanto, nesse caso, a sua capacidade de ampliar e interagir com a sua rede através dos nossos Serviços será limitada.
            </CardText>
            <CardText className="text_central_ajuda">
              Dados de outros
            </CardText>
            <CardText className="text_central_ajuda">
              Terceiros podem publicar conteúdos que incluam informações sobre você (como parte de artigos, publicações e comentários) em nossos Serviços. Também podemos coletar informações públicas sobre você, como notícias e conquistas dos eventos e disponibilizá-las como parte dos nossos Serviços, assim como, caso autorizado nas suas configurações, enviá-las a outras pessoas em notificações sobre menções na mídia.
            </CardText>
            <CardText className="text_central_ajuda">
              Recebemos dados pessoais (incluindo informações de contato) sobre você quando outros enviam mensagens utilizando nossos Serviços (incluindo convites ou solicitações de conexão).
            </CardText>
            <CardText className="text_central_ajuda">
              Empresas relacionadas e outros serviços
            </CardText>
            <CardText className="text_central_ajuda">
              Recebemos dados a seu respeito quando você utiliza alguns dos outros serviços fornecidos por nós ou por empresas parceiras. Por exemplo, se você realizar uma corrida e a organizadora do evento for nossa parceira, podemos obter dados da sua colocação no evento para preenchimento automático em nossos serviços
            </CardText>
            <CardText className="text_central_ajuda">
              Uso do serviço
            </CardText>
            <CardText className="text_central_ajuda">
              Registramos os dados de utilização quando você visita ou usa, de outra forma, os nossos Serviços, inclusive a tecnologia de sites, aplicativos e plataformas. Isto é, quando você visualiza ou clica em um evento ou em anúncios (dentro ou fora de nossos sites e aplicativos), realiza uma pesquisa, instala ou atualiza um dos aplicativos para dispositivos móveis, compartilha artigos ou se candidata a um evento. Nós usamos os acessos, os cookies, as informações do dispositivo e os endereços do protocolo IP (“IP”) para identificar você ou registrar o seu uso.
            </CardText>
            <CardText className="text_central_ajuda">
              Como descrito também em nossa Política de cookies, usamos cookies e tecnologias semelhantes (por exemplo, pixels e tags de anúncios) para coletar dados (como identificadores de dispositivos) para reconhecer você e/ou seu(s) dispositivo(s) quando você tiver interagido com nossos Serviços e dispositivos, dentro ou fora deles. Também permitimos que alguns terceiros usem os cookies conforme descrito na Política de Cookies.
            </CardText>
            <CardText className="text_central_ajuda">
              Também coletamos e contamos com terceiros que coletam informações sobre seu dispositivo quando você não usar nossos Serviços (ex., código do anúncio, endereço IP, sistema operacional e informações do navegador) para fornecer aos Usuários anúncios relevantes e conhecer melhor a eficácia deles. Você pode também desabilitar nosso uso de dados de cookies e tecnologias semelhantes que acompanham seu comportamento em sites para fins de direcionamento de anúncios ou outros fins.
            </CardText>
            <CardText className="text_central_ajuda">
              Dispositivo e localidade
            </CardText>
            <CardText className="text_central_ajuda">
              Quando você visita ou deixa os nossos Serviços, recebemos a URL do site de onde você veio e a que visitará e a hora de sua visita. Também obtemos informações sobre sua rede e dispositivo (ex., endereço de IP, servidor proxy, sistema operacional, navegador e complementos, recursos e identificador de dispositivo, identificadores de cookies e/ou provedor de Internet ou operadora de celular). Ao usar nossos Serviços a partir de um dispositivo móvel, ele nos enviará informações sobre a sua localidade com base nas configurações do seu dispositivo. Pediremos que você habilite esse recurso antes de utilizarmos o GPS ou outras ferramentas para identificar precisamente a sua localidade.
            </CardText>
            <CardText className="text_central_ajuda">
              Mensagens
            </CardText>
            <CardText className="text_central_ajuda">
              Coletamos suas informações pessoais quando você envia, recebe ou interage com mensagens que estejam conectadas aos nossos Serviços. Por exemplo, se você receber uma solicitação de conexão no Minhas Medalhas, acompanharemos a sua ação e enviaremos lembretes. Também usamos tecnologia de varredura automática em mensagens para apoiar e proteger o nosso site. Por exemplo, usamos essa tecnologia para sugerir possíveis respostas às mensagens e para gerenciar ou bloquear conteúdo.
            </CardText>
            <CardText className="text_central_ajuda">
              Sites e serviços de terceiros
            </CardText>
            <CardText className="text_central_ajuda">
              Nós obtemos dados quando você visita sites que incluem nossos anúncios, cookies ou alguns de nossos plugins ou quando entra em serviços de terceiros com a sua conta do Minhas Medalhas.
            </CardText>
            <CardText className="text_central_ajuda">
              Recebemos informações sobre as suas visitas e interações com serviços fornecidos por terceiros quando você entra com a sua conta do Minhas Medalhas ou nossos anúncios, cookies ou tecnologias semelhantes
            </CardText>
            <CardText>&nbsp;</CardText>
            <CardText className="text_medium">
              Como usamos seus dados
            </CardText>
            <CardText className="text_central_ajuda">
              O uso de seus dados depende dos Serviços utilizados, da maneira como eles são usados e das definições que você selecionou nas configurações. Utilizamos os dados que temos sobre você para fornecer e personalizar nossos Serviços, incluindo com a ajuda de sistemas automatizados e inferências que fazemos, para que nossos Serviços (incluindo anúncios) possam ser mais relevantes e úteis para você e outras pessoas.
            </CardText>
            <CardText className="text_central_ajuda">
              Usamos seus dados para autorizar o seu acesso aos nossos Serviços e para atender às suas configurações.
            </CardText>
            <CardText className="text_central_ajuda">
              Os nossos Serviços permitem que você se comunique e fique atualizado sobre colegas, parceiros, clientes e outros contatos. Para isso, você pode se conectar com os corredores que escolher e com os corredores que desejarem se conectar com você. De acordo com as configurações determinadas por você e por essas pessoas, ao se conectar, você poderá pesquisar as conexões desses corredores e vice-versa
            </CardText>
            <CardText className="text_central_ajuda">
              Usaremos os dados coletados (como seu perfil, os perfis que você visualizou ou os dados fornecidos por meio de carregamentos da lista de endereços ou de integrações com parceiros) para ajudar outros a encontrarem o seu perfil, sugerir conexões a você e a terceiros (por exemplo, Corredores que possuem contatos ou Corridas em comum) e permitir que você convide terceiros para se tornarem Usuários e se conectarem com você. Você também pode optar por permitir que utilizemos a sua localização precisa ou sua proximidade a terceiros para certas tarefas (ex.: para sugerir outros Corredores na sua área com quem você pode se conectar ou notificar conexões de que você está em um evento).
            </CardText>
            <CardText className="text_central_ajuda">
              Você decide se deseja convidar alguém para usar os nossos Serviços, enviar uma solicitação de conexão ou permitir que outro corredor se conecte a você. Quando você convida alguém para se conectar a você, seu convite inclui informações da sua rede e informações básicas do seu perfil (por exemplo, nome, foto do perfil, região). Enviaremos lembretes do convite para a pessoa que você convidou. Você pode optar por compartilhar ou não a sua própria lista de conexões com as suas conexões.
            </CardText>
            <CardText className="text_central_ajuda">
              Os nossos Serviços permitem que você fique informado sobre notícias, eventos do seu interesse e dos especialistas que você respeita. Usamos os dados que temos sobre você (por exemplo, dados que você fornece, dados que coletamos sobre a sua interação com nossos Serviços e inferências baseadas nos dados que temos sobre você), para personalizar nossos Serviços para você, por exemplo, recomendando ou classificando conteúdo e conversas relevantes em nossos Serviços. Usamos seus conteúdos, suas atividades e seus dados, como seu nome e sua foto, para notificar a sua rede e terceiros. Por exemplo, dependendo das suas configurações, podemos notificar terceiros de que você atualizou o perfil, publicou conteúdo, realizou alguma ação social, utilizou um recurso ou fez novas conexões.
            </CardText>
            <CardText className="text_central_ajuda">
              Comunicações
            </CardText>
            <CardText className="text_central_ajuda">
              Entraremos em contato por e-mail, celular, avisos publicados em nossos sites, mensagens enviadas à sua caixa de entrada do Minhas Medalhas e outros meios disponibilizados por nossos Serviços, inclusive mensagens de texto e notificações push. Poderemos enviar a você mensagens sobre a disponibilidade de Serviços, segurança ou outras questões relacionadas aos Serviços. Também enviamos mensagens sobre como usar nossos Serviços, atualizações de rede, lembretes, sugestões de eventos e mensagens promocionais enviadas por nós ou por nossos parceiros. Você pode alterar suas preferências de comunicação a qualquer momento. Saiba que você não pode desabilitar o recebimento das nossas mensagens de serviço, inclusive notificações legais e de segurança.
            </CardText>
            <CardText className="text_central_ajuda">
              Nós também permitimos comunicação entre você e outros corredores por meio dos nossos Serviços, tais como convites, inbox do Minhas Medalhas e mensagens entre conexões
            </CardText>
            <CardText className="text_central_ajuda">
              Publicidade
            </CardText>
            <CardText className="text_central_ajuda">
              Os anúncios enviados para você podem ser personalizados. Será direcionado e avaliado o desempenho dos anúncios enviados para nossos usuários, visitantes e terceiros, tanto dentro como fora de nossos Serviços, diretamente ou através de diferentes parceiros, usando os seguintes dados, de forma separada ou combinada:
            </CardText>
            <CardText className="text_central_ajuda">
              - Informações fornecidas por Usuários (por exemplo, perfil, informações de contato, cargo e setor);
            </CardText>
            <CardText className="text_central_ajuda">
              - Dados de tecnologias de publicidade, pixels, tags de anúncios, cookies e identificadores de dispositivos;
            </CardText>
            <CardText className="text_central_ajuda">
              - Dados de uso dos serviços como: Histórico de pesquisas, feed, corredores que você segue ou que seguem você, suas conexões, eventos acessados, cliques em anúncios; e
            </CardText>
            <CardText className="text_central_ajuda">
              - Informações inferidas a partir dos dados do corredor.
            </CardText>
            <CardText className="text_central_ajuda">
              O Minhas Medalhas não compartilha seus dados pessoais com outros anunciantes ou redes de anúncios, exceto: com sua permissão separada (ex.: em um formulário de geração de leads) e dados que já sejam exibidos para outros usuários dos Serviços (ex.: do perfil).
            </CardText>
            <CardText className="text_central_ajuda">
              Entretanto, se você visualizar ou clicar em um anúncio dentro ou fora dos nossos Serviços, o anunciante receberá um sinal de que alguém visitou a página que exibiu o anúncio e poderá determinar que foi você, pelo uso de mecanismos como cookies. Os parceiros de publicidade podem associar dados pessoais coletados pelo anunciante diretamente de você com identificadores com hash ou identificadores de dispositivo recebidos de nós. Em tais casos, solicitaremos contratualmente a esses anunciantes que obtenham seu consentimento explícito.
            </CardText>
            <CardText className="text_central_ajuda">
              Desenvolvimento de Serviços e pesquisa
            </CardText>
            <CardText className="text_central_ajuda">
              Nós usamos dados, inclusive o feedback público, para conduzir pesquisas e desenvolver nossos Serviços a fim de oferecer a você uma experiência melhor, mais intuitiva e personalizada, incentivar o crescimento e a interação em nossos Serviços e ajudar a conectar corredores entre eles e às oportunidades de corridas.
            </CardText>
            <CardText className="text_central_ajuda">
              Nós e terceiros conduzimos pesquisas de opinião e questionários por meio dos nossos Serviços. Não é obrigatório responder às pesquisas de opinião nem aos questionários, e você pode escolher quais informações fornecer.
            </CardText>
            <CardText className="text_central_ajuda">
              Usamos os dados (que podem incluir suas mensagens) para investigar, responder e resolver reclamações e problemas, além de questões relacionadas ao Serviço (por exemplo, bugs).
            </CardText>
            <CardText className="text_central_ajuda">
              O Minhas Medalhas utiliza dados para produzir e compartilhar informações que não identificam você. Por exemplo, podemos usar seus dados para gerar estatísticas sobre nossos usuários, os corredores ou assessorias, para calcular impressões de anúncios exibidos ou clicados ou para publicar dados demográficos de visitantes para um Serviço ou criar estatísticas demográficas.
            </CardText>
            <CardText className="text_central_ajuda">
              Utilizamos seus dados (incluindo suas comunicações) para fins de segurança ou para prevenir ou investigar possíveis fraudes ou outras violações de nosso Contrato do Usuário e/ou tentativas de prejudicar nossos Usuários, Visitantes ou terceiros
            </CardText>
            <CardText>&nbsp;</CardText>
            <CardText className="text_medium">
              Como compartilhamos informações
            </CardText>
            <CardText className="text_central_ajuda">
              Serviços
            </CardText>
            <CardText className="text_central_ajuda">
              O seu perfil fica completamente visível a todos os usuários e clientes de nossos Serviços. Conforme suas configurações, ele também pode ser exibido para outros usuários dentro e fora dos nossos Serviços. Conforme as suas configurações, o grau de conexão com outros corredores que visualizar seu perfil, e os tipos de pesquisa (como por nome ou palavra-chave) influenciam a disponibilidade do seu perfil e se determinados campos do seu perfil podem ser vistos.
            </CardText>
            <CardText className="text_central_ajuda">
              Os nossos Serviços permitem a visualização e o compartilhamento de informações, inclusive ao publicar, seguir, gostar e comentar.
            </CardText>
            <CardText className="text_central_ajuda">
              - Quando você compartilha uma corrida ou evento ou publicação (ex.: uma atualização, imagem ou artigo), eles podem ser vistos por todos e compartilhados novamente em qualquer lugar (conforme as suas configurações). Usuários, Visitantes e outros poderão encontrar e visualizar seu conteúdo compartilhado publicamente, incluindo seu nome (e sua foto, se você forneceu uma).
            </CardText>
            <CardText className="text_central_ajuda">
              - Todas as informações que você compartilhar, por meio das páginas de assessorias ou organizadoras de eventos, em nossos Serviços poderão ser visualizadas pelas assessorias, organizadoras e pessoas que as visitarem.
            </CardText>
            <CardText className="text_central_ajuda">
              - Ao seguir uma pessoa ou Assessoria, você fica visível para terceiros e para o “titular da página” como seguidor.
            </CardText>
            <CardText className="text_central_ajuda">
              -	Permitimos que os remetentes saibam quando você executa alguma ação na mensagem deles, de acordo com as suas configurações, caso aplicável.
            </CardText>
            <CardText className="text_central_ajuda">
              -	Com base nas configurações, notificamos o Usuário quando você visualiza o perfil dele.
            </CardText>
            <CardText className="text_central_ajuda">
              -	Quando você gosta, compartilha ou comenta o conteúdo de outra pessoa (inclusive eventos), outras pessoas poderão visualizar essas “ações sociais” e associá-las a você (como seu nome, perfil e foto de perfil, caso fornecida).
            </CardText>
            <CardText className="text_central_ajuda">
              O seu treinador da Assessoria pode ver como você usa os Serviços e as corridas que você se inscreveu, para auxiliar no planejamento dos treinos para as corridas eletivas. Nós não mostraremos a ele as suas pesquisas por outras assessorias ou mensagens pessoais.
            </CardText>
            <CardText className="text_central_ajuda">
              Prestadores de serviços
            </CardText>
            <CardText className="text_central_ajuda">
              Contratamos terceiros para nos ajudar a fornecer nossos Serviços (por exemplo, manutenção, análise, auditoria, pagamentos, detecção de fraudes, marketing e desenvolvimento). Eles terão acesso somente às informações necessárias para executar as tarefas em nosso nome e são obrigados a não divulgar ou utilizar elas para outros fins.
            </CardText>
            <CardText className="text_central_ajuda">
              Declaração legal
            </CardText>
            <CardText className="text_central_ajuda">
              Existe a possibilidade de precisarmos divulgar suas informações pessoais se assim exigido por lei, intimação judicial ou outro procedimento legal ou se acreditarmos de boa-fé que a revelação seja necessária para:
            </CardText>
            <CardText className="text_central_ajuda">
              - investigar, impedir ou tomar medidas relacionadas a atividades ilegais suspeitas ou reais ou para cooperar com órgãos públicos;
            </CardText>
            <CardText className="text_central_ajuda">
              - aplicar os nossos contratos;
            </CardText>
            <CardText className="text_central_ajuda">
              - investigar e nos defender contra quaisquer reivindicações ou alegações de terceiros;
            </CardText>
            <CardText className="text_central_ajuda">
              - proteger a segurança ou a integridade dos nossos Serviços (por exemplo, o compartilhamento com empresas que estão sofrendo ameaças semelhantes) ou
            </CardText>
            <CardText className="text_central_ajuda">
              - exercer ou proteger os direitos e a segurança do Minhas Medalhas, de nossos Usuários, funcionários ou terceiros.
            </CardText>
            <CardText className="text_central_ajuda">
              Se entendermos necessário, procuraremos notificar os usuários sobre as demandas legais referentes às suas informações pessoais, a menos que proibido por lei ou mandado judicial ou se a requisição for emergencial. Poderemos contestar essas demandas se julgarmos, a nosso critério, que as solicitações são excessivas ou feitas por autoridades incompetentes, mas não nos comprometeremos a impugnar todas as demandas.
            </CardText>
            <CardText className="text_central_ajuda">
              Alteração no controle ou venda
            </CardText>
            <CardText className="text_central_ajuda">
              Nós também podemos compartilhar seus dados pessoais como parte de uma venda, fusão, alteração no controle ou um preparatório para qualquer um desses eventos. Qualquer outra entidade que comprar nossa empresa, ou parte dela, terá o direito de continuar usando seus dados, mas somente da forma estabelecida nesta Política de Privacidade, salvo acordo em contrário.
            </CardText>
            <CardText>&nbsp;</CardText>
            <CardText className="text_medium">
              Suas obrigações e opções
            </CardText>
            <CardText className="text_central_ajuda">
              Geralmente, armazenamos seus dados pessoais enquanto você mantiver sua conta aberta ou conforme necessário para fornecer Serviços a você. Isso inclui os dados que você ou outras pessoas fornecerem a nós, e os dados gerados ou inferidos a partir da sua utilização dos nossos Serviços. Mesmo que você use os nossos Serviços para procurar um novo emprego raramente, armazenaremos as suas informações pessoais e manteremos o seu perfil ativo, a menos que você encerre a sua conta. Em alguns casos, optamos por armazenar certas informações (por exemplo, informações sobre o uso dos Serviços) de forma despersonalizada ou agregada.
            </CardText>
            <CardText className="text_central_ajuda">
              Direitos para acessar e controlar seus dados pessoais.
            </CardText>
            <CardText className="text_central_ajuda">
              Nós oferecemos a você diversas opções de como seus dados são coletados, usados e compartilhados, desde a exclusão ou correção dos dados incluídos no perfil, o controle da visibilidade das publicações até as opções para desabilitar publicidade e controlar comunicações. Oferecemos configurações para controlar e gerenciar os dados pessoais que temos sobre você.
            </CardText>
            <CardText className="text_central_ajuda">
              Para dados pessoais que temos sobre você, as seguintes ações são possíveis:
            </CardText>
            <CardText className="text_central_ajuda">
              - Excluir dados: você pode nos solicitar para excluir ou apagar alguns dos seus dados pessoais (por exemplo, se eles não forem mais necessários para lhe fornecer Serviços).
            </CardText>
            <CardText className="text_central_ajuda">
              - Alterar ou corrigir dados: você pode editar alguns dados pessoais na sua conta. Você também pode solicitar atualizações, alterações ou correções dos seus dados em determinados casos, principalmente se eles estiverem incorretos.
            </CardText>
            <CardText className="text_central_ajuda">
              Visitantes podem saber mais sobre como fazer essas solicitações aqui. Você também pode entrar em contato conosco através das informações de contato abaixo e iremos considerar a sua solicitação de acordo com as leis aplicáveis.
            </CardText>
            <CardText className="text_central_ajuda">
              Encerramento da conta
            </CardText>
            <CardText className="text_central_ajuda">
              Se você solicitar para encerrar sua conta do Minhas Medalhas, os seus dados pessoais não ficarão mais visíveis em nossos serviços dentro de 24 horas.
            </CardText>
            <CardText className="text_central_ajuda">
              Nós armazenamos seus dados pessoais mesmo após o encerramento da sua conta, caso seja razoavelmente necessário para o cumprimento das nossas obrigações legais (inclusive solicitações das autoridades), para resolver disputas, manter a segurança, evitar fraudes e abuso.
            </CardText>
            <CardText className="text_central_ajuda">
              As informações que você compartilhou com terceiros (por exemplo, por meio de conversa, atualizações ou publicações em grupos) continuarão visíveis mesmo após você encerrar sua conta ou excluir as informações do próprio perfil ou da caixa de entrada. Além disso, não controlamos os dados que outros Usuários copiaram de nossos Serviços. Os conteúdos de avaliação, publicados por contas encerradas, serão exibidos como criados por um usuário desconhecido. Talvez o seu perfil continue sendo exibido nos serviços de terceiros até que eles atualizem o cache.
            </CardText>
            <CardText className="text_central_ajuda">
              Segurança
            </CardText>
            <CardText className="text_central_ajuda">
              O Minhas Medalhas implementa medidas de segurança desenvolvidas para proteger seus dados, como HTTPS. Monitoramos os nossos sistemas regularmente para verificar vulnerabilidades e ataques. Porém, não podemos garantir a segurança das informações que você nos envia. Não há garantias de que as informações não poderão ser acessadas, divulgadas, alteradas ou destruídas por violação de qualquer uma de nossas proteções físicas, técnicas ou administrativas.
            </CardText>
            <CardText className="text_central_ajuda">
              Coletamos e processamos dados pessoais sobre você onde temos bases legais para isso. Bases legais incluem consentimento e contrato
            </CardText>
            <CardText className="text_central_ajuda">
              Se contarmos com seu consentimento para processar dados pessoais, você tem o direito de retirar ou negar consentimento quando desejar.
            </CardText>
            <CardText className="text_central_ajuda">
              Atualmente, não compartilhamos dados pessoais com terceiros para fins de marketing direto sem a sua permissão.
            </CardText>
            <CardText className="text_central_ajuda">
            Informações de contato
            </CardText>
            <CardText className="text_central_ajuda">
            Caso tenha dúvidas ou reclamações em relação à presente Política, você deve primeiramente entrar em contato com “Fale com o Minhas Medalhas”. Você também pode entrar em contato por e-mail.
            </CardText>
            <CardText>&nbsp;</CardText>
            <CardFooter className="text-end"><Label className="text_small">Data da última revisão: 8 de dezembro de 2023</Label></CardFooter>
          </Col>
          <Col xs="12" sm="2" >&nbsp;</Col>
        </Row>
      </Card>
      <Rodape />
    </Container>
  );
}

export default PoliticaDeCookies;
