import { React, useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Form,
  Label,
  Row,
} from "reactstrap";
import { busca } from "../../../api/api_event_admin";
import ClipLoader from "react-spinners/ClipLoader";

function EditarAnuncio() {
  const { id } = useParams();
  const [nome, setNome] = useState("");
  const [messageAPI, setMessageAPI] = useState("");
  const [resultColor, setResultColor] = useState("");
  const [block, setBlock] = useState(true);
  const [anuncio, setAnuncio] = useState([]);
  let [loading, setLoading] = useState(true);
  const [showResult, setShowResult] = useState(false);
  const [showNoResult, setShowNoResult] = useState(false);
  const [visible, setVisible] = useState(false);

  const onDismiss = () => setVisible(false);


  useEffect(() => {
    busca(
      "/anuncio/" + id,
      setAnuncio,
      setLoading,
      setShowResult,
      setShowNoResult,
      null
    );
  }, [id]);

  if (anuncio.id !== undefined && block) {
    setShowNoResult(false);
    setShowResult(true);
    setNome(anuncio.nome);
    setBlock(false);
  }


  return (
    <Card>
      <div className="text-center">
        <ClipLoader color="gray" loading={loading} size={60} />
      </div>

      <Card>
        <Row>
          <Col sm="3"></Col>
          <Col sm="6">
            <Card className="p-2">
              <Form
                method="Post"
                onSubmit={(event) => {
                  event.preventDefault();
                }}
              >
                <CardTitle className="text-center mt-2 pt-2" >
                  <Label className="text_big">Anuncio <br />
                    {anuncio.nome}</Label>
                </CardTitle>
                <CardBody>
                    <Label className="text_form">Nome do Anuncio</Label>
                    <Label className="text_banco">{nome}</Label>
                  {messageAPI ? (
                    <Alert color={resultColor} isOpen={visible} toggle={onDismiss}>{messageAPI}</Alert>
                  ) : null}
                </CardBody>
                  <CardText className="text-center">
                    <Link to={"/admin/anuncio/imagemAnuncio/" + anuncio.id}>
                      <Button color="warning" className="button_form m-2">
                        Editar Imagem
                      </Button>
                    </Link>
                  </CardText>
              </Form>
            </Card>
          </Col>
        </Row>
      </Card>

    </Card>
  );
}

export default EditarAnuncio;
