import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Badge,
  Card,
  CardHeader,
  CardImg,
  CardText,
  Col,
  Row,
  UncontrolledAlert,
} from "reactstrap";
import logo from "../../assets/img/no-logo.png";
import { busca } from "../../api/api_athlete";
import ClipLoader from "react-spinners/ClipLoader";
import { getToken, getUrlApi } from "../../api/auth";

const ListaSolicitacaoTimes = (props) => {
  const [atualizar, setAtualizar] = useState(true);
  const [historicoSolicitacao, setAssessorias] = useState([]);
  let [loading, setLoading] = useState(true);
  const [showResult, setShowResult] = useState(false);
  const [showNoResult, setShowNoResult] = useState(false);

  const [messageAPI, setMessageAPI] = useState("");
  const [resultColor, setResultColor] = useState("");

  function Aceitar(codigoTime) {
    const token = getToken();
    const requestInfo = {
      method: "GET",
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };

    fetch(
      getUrlApi()+"/atleta/aceitarTime/" + codigoTime,
      requestInfo
    ).then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          setMessageAPI(data.message);
          setResultColor("success");
        } else {
          if (response.status === 202) {
            setMessageAPI(data.message);
          } else {
            if (response.status === 400) {
              setMessageAPI("Erro Inesperado"); //data[0].message);
            }
          }
          setResultColor("warning");
        }
        setAtualizar(!atualizar);
        return this.props.setConfirmadoOk = false;

      });
    });
  }

  function Rejeitar(codigoTime) {
    const token = getToken();
    const requestInfo = {
      method: "GET",
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };

    fetch(
      getUrlApi()+"/atleta/rejeitarTime/" + codigoTime,
      requestInfo
    ).then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          setMessageAPI(data.message);
          setResultColor("success");
        } else {
          if (response.status === 202) {
            setMessageAPI(data.message);
          } else {
            if (response.status === 400) {
              setMessageAPI("Erro Inesperado"); //data[0].message);
            }
          }
          setResultColor("warning");
        }
        setAtualizar(!atualizar);
      });
    });
  }

  useEffect(() => {
    busca(
      "/listarSolicitacoesTimes",
      setAssessorias,
      setLoading,
      setShowResult,
      setShowNoResult
    );
  }, [atualizar]);

  const Result = () => (
    <>
          {messageAPI ? <UncontrolledAlert className="mt-1 text-center"  color={resultColor}>{messageAPI}</UncontrolledAlert > : null}
    { historicoSolicitacao.length > 0 ? (
    <Card className="p-2 mt-1 text-center">
      <Row>
        <Col sm="12" >
          <Card >
            <CardHeader className="text-center p-1" tag="h6">
              Solicitações de Assessorias Esportivas
            </CardHeader>
          </Card>


          {historicoSolicitacao.map((solicitacao) => (
            <Card  className="mt-1">
              <Row>
                <Col xs="3" sm="2">
                  <CardText className="p-2 m-0">
                    <Link to={`/assessoria/${solicitacao.idTeam}`}>
                      <CardImg
                        src={
                          solicitacao.logoTeam === null
                            ? logo
                            : solicitacao.logoTeam
                        }
                        alt="Logo da Assessoria"
                        className="img-fluid rounded-circle"
                        top
                      />
                    </Link>
                  </CardText>
                </Col>
                <Col xs="9" sm="7" className="text-center p-0">
                  <CardText className="pt-1 pr-3">
                    A <b>{solicitacao.nameTeam}</b> está te convidando parte o time. E aí? topa?
                  </CardText>
                  <CardText className="p-0 m-0">
                    {solicitacao.emailTeam}
                  </CardText>
                  <CardText className="p-0 m-0">
                    {solicitacao.phoneTeam}
                  </CardText>
                </Col>
                <Col xs="12" sm="3" className="text-center">
                  <Link
                    onClick={() => {
                      Aceitar(solicitacao.idTeam);
                    }}
                  >
                    <Badge color="primary" className="p-2 m-3" pill>
                      Aceitar
                    </Badge>
                  </Link>
                  <Link
                    onClick={() => {
                      Rejeitar(solicitacao.idTeam);
                    }}
                  >
                    <Badge color="secondary" className="p-2 m-3" pill>
                      Rejeitar
                    </Badge>
                  </Link>
                </Col>
              </Row>
            </Card>
          ))}
        </Col>
      </Row>
    </Card>
    ) : null }
    </>
  );

  const NoResult = () => (
    <CardText className="p-3">
      &nbsp;
    </CardText>
  );

  return (
    <>
      <div className="text-center">
        <ClipLoader color="gray" loading={loading} size={60} />
      </div>
      {showResult ? <Result /> : null}
      {showNoResult ? <NoResult /> : null}
    </>
  );
};

export default ListaSolicitacaoTimes ;
