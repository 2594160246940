import React, { useState, useRef } from "react";
import {
  Alert,
  Button,
  CardImg,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import logo from "../../assets/img/logo_minhas_medalhas_lg.svg";
import { getUrlApi } from "../../api/auth";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { FaWhatsapp } from 'react-icons/fa';
import ClipLoader from "react-spinners/ClipLoader";


function Publicidade() {
  const [email, setEmail] = useState("");
  const [nome, setNome] = useState("");
  const [fone, setFone] = useState("");
  let [loading, setLoading] = useState(false);
  const [messageAPI, setMessageAPI] = useState("");
  const [resultColor, setResultColor] = useState("");
  const emailInput = useRef(null);

  function envia() {
    setLoading(true);
    const requestInfo = {
      method: "POST",
      body: JSON.stringify({
        nome: `${nome}`,
        email: `${email}`,
        fone: `${fone}`,
      }),
      headers: new Headers({
        "Content-type": "application/json",
      }),
    };

    fetch(
      getUrlApi() + "/anuncio/enviarEmail",
      requestInfo
    ).then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          setMessageAPI(data.message);
          setResultColor("success");
        } else {
          if (response.status === 202) {
            setMessageAPI(data.message);
          } else {
            if (response.status === 400) {
              setMessageAPI(data.message);
            }
          }
          setResultColor("warning");
        }
      });
    });
    setLoading(false);
  }

  return (
    <Container >
      <Row>
        <Col sm="2" >&nbsp;</Col>
        <Col sm="3" className="pt-4 pb-1">
          <Link to="/login"><CardImg src={logo} alt="Minhas Medalhas" /></Link>
        </Col>
        <Col sm="7" >&nbsp;</Col>
      </Row>
      <Row>
        <Col sm="3">&nbsp;</Col>
        <Col sm="6" className="pt-1 pb-1">
          <Label className="text_big"><center>Publicidade Minhas Medalhas!</center></Label>
          <Label className="text_small">
            Ficamos muito contente que você quer anunciar sua marca conosco? <br />
            Preencha o formulário que um de nossos vendedores irá entrar em contato para apresentar uma proposta que seja adequada a sua necessidade, ok?<br /> <br />
            Ou se preferir entre em contato pelo nosso WhatsApp <br /><FaWhatsapp color="green" size="20px" /> (48) 99951-0832
          </Label>
          <Form
            method="Post"
            onSubmit={(event) => {
              event.preventDefault();
              envia();
            }}
          >
            <FormGroup>
              <Label className="text_form">Email:*</Label>
              <Input
                bsSize="sm"
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
                ref={emailInput}
                type="email"
                name="email"
                minLength="5"
                id="email"
                className="input_form"
                required
              />
            </FormGroup>
            <Label className="text_form">Nome:*</Label>
            <Input
              bsSize="sm"
              value={nome}
              onChange={(event) => {
                setNome(event.target.value);
              }}
              type="text"
              name="nome"
              id="nome"
              minLength="4"
              className="input_form"
              required
            />
            <Label className="text_form">Celular:*</Label>
            <Input
              bsSize="sm"
              type="text"
              name="fone"
              minLength="5"
              id="fone"
              required
              value={fone}
              onChange={(event) => {
                setFone(phoneMask(event.target.value));
              }}
              className="input_form"
            />
            {messageAPI ? <Alert color={resultColor} className="text_small">{messageAPI}</Alert> : null}
            <div className="text-center">
              <ClipLoader color="gray" loading={loading} size={60} />
            </div>
            <Button color="success" className="button_form" type="submit" block>
              Enviar
            </Button>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col sm="5" >&nbsp;</Col>
        <Col sm="2" className="pt-4 pb-1">
          <Link to="/"><CardImg src={logo} alt="Minhas Medalhas" /></Link>
        </Col>
        <Col sm="5" >&nbsp;</Col>
      </Row>
    </Container>
  );
}

function phoneMask(v) {

  let r = v.replace(/\D/g, "");
  r = r.replace(/^0/, "");
  if (r.length > 11) {
    r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
  } else if (r.length > 7) {
    r = r.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, "($1) $2-$3");
  } else if (r.length > 2) {
    r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
  } else if (v.trim() !== "") {
    r = r.replace(/^(\d*)/, "($1");
  }
  return r;
}


export default Publicidade;