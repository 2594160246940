import React from 'react';
import {
    Button,
    Card,
    CardImg,
    CardSubtitle,
    CardText,
    CardTitle,
    Col,
    Container,
    Row,
  } from "reactstrap";
  import logo from "../../assets/img/logo_minhas_medalhas.svg";
  


  function Inscricao () {
    function close(){
        
        window.close();
    }
    return (
        <Container>
            <Card className="pt-2 pb-3 mb-2">
                <Row>
                    <Col sm="4">
                        <CardImg className="p-0" src={logo} width="150" height="150" />
                    </Col>
                    <Col sm="8" className="text-center pt-3">
                        <CardTitle tag="h2">Minhas Medalhas</CardTitle>
                        <CardText>Crie sua conta, registre suas corridas, compartilhe suas fotos e conecte-se com outros atletas.</CardText>
                    </Col>
                </Row>
            </Card>
            <Card className="p-5">
                <CardTitle tag="h3">Por que pedimos determinadas informações para criar uma conta?</CardTitle>
                <CardText>Quando você cria uma conta, pedimos algumas informações para manter sua conta segura e nossos serviços mais úteis.</CardText>
                <CardSubtitle className="pt-2" tag="h4">Quais campos precisamos:</CardSubtitle>
                <CardSubtitle className="pt-4" tag="h4">Nome</CardSubtitle>
                <CardText>Informe seu nome para que nosso sistema possa encontrar você nas corridas, e para que seus colegas consigam te localizar facilmente.</CardText>
                <CardSubtitle className="pt-4" tag="h4">E-mail</CardSubtitle>
                <CardText>Informe o seu e-mail! Ele você usará para fazer login em sua Conta do Minhas Medalhas. Também será através dele que enviaremos atualizações de eventos e notificações relevantes a você.</CardText>
                <CardSubtitle className="pt-4" tag="h4">Senha</CardSubtitle>
                <CardText>Para ajudar a manter sua conta segura, escolha uma senha forte com pelo menos oito caracteres.
                <br />- Use uma mistura de letras, números e símbolos.
                <br />- Evite informações pessoais ou palavras comuns que sejam fáceis de adivinhar, como o nome de animal de estimação ou de parente.
                <br />- Não use uma senha que você já tenha usado para outras contas ou sites. Da mesma forma, não use essa senha em outro lugar.</CardText>
                <CardSubtitle className="pt-4" tag="h4">Data nascimento</CardSubtitle>
                <CardText>Informe sua data de nascimento. Nas corridas, os atletas são separados por um range de idade, e as colocações também utilizam o mesmo sistema. 
                <br /> A proposta não é mostrar sua idade para outras pessoas, mas poder classificar corretamente nas corridas e poder alertar seus colegas no dia do seu aniversário!</CardText>
                <CardSubtitle className="pt-4" tag="h4">Gênero</CardSubtitle>
                <CardText>Informe seu gênero ou informe que não quer informar. Da mesma forma que a idade, o gênero também é utilizado nas corridas para separar os atletas, tendo premiações e colocações por sexo.</CardText>
                <CardSubtitle className="pt-4" tag="h4">CPF</CardSubtitle>
                <CardText>Informe seu CPF! Este é um documento único, e como muitas organizadoras de corridas solicitam o CPF para participação de seus eventos, ter esta informação facilitará e muito o trabalho de encontrar seus dados nas corridas por meio do CPF. 
                <br />Seu CPF não ficará visível para outras pessoas, a menos que você o compartilhe publicamente ou com pessoas específicas.</CardText>
                <CardSubtitle className="pt-4" tag="h4">Mais informações </CardSubtitle>
                <CardText>Depois de criar sua conta, você pode alterar algumas destas informações e controlar quem pode vê-las, acessando o ícone <b>Meu Perfil</b>!</CardText>
                <CardText className="pt-4 text-center"><Button color="primary" onClick={close()}>Fechar</Button></CardText>
            </Card>
        </Container>

)
}

export default Inscricao
