
import React, { useEffect, useState } from 'react';
import logo from '../assets/img/logo_minhas_medalhas_small.svg'
import { useParams } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Card,
  Input,
  InputGroup,
} from 'reactstrap';
import { FaSearch } from 'react-icons/fa';

const Cabecalho = (props) => {
  let { search } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [searchTop5, setSearchTop5] = useState("");
  const [pesquisado, setPesquisado] = useState("");

  const toggle = () => setIsOpen(!isOpen);

  const handleChange = (event) => {
    if (event.target.value === "") {
      setSearchTop5("");
    } else {
      setSearchTop5(event.target.value);
    }
  };

  useEffect(() => {
    if (search !== "" && searchTop5 === ""){
      setSearchTop5(search);
      setPesquisado(search);
    }
    
    if (searchTop5 !== pesquisado && searchTop5 !== "" ) {
      const delayedApiCall = setTimeout(() => {
        // Faça sua chamada de API aqui usando o valor de inputValue
        window.location.href = `/pesquisa/${searchTop5}`;
        setPesquisado(searchTop5);
      }, 2000);
      search = "";

      // Essa função limpa o timeout anterior a cada mudança de inputValue
      return () => clearTimeout(delayedApiCall);
    }
  }, [searchTop5]);


  return (

    <Card max='container-fluid' className="sticky-top menu-color text-center">
      <Navbar color="light" light expand="lg" className="p-0">
        <NavbarBrand href="/"><img alt="Minhas Medalhas" src={logo} /></NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <InputGroup style={{ width: '250px' }}>
          <Input
            bsSize="md"
            type="text"
            name="searchTop5"
            id="searchTop5"
            placeholder="Pequisar..."
            value={searchTop5}
            style={{ width: '50px' }}
            onChange={handleChange}
          />
          <span className="input-group-text">
            <FaSearch /> {/* Ícone de busca */}
          </span>
        </InputGroup>
        <Collapse isOpen={isOpen} navbar >
          <Nav className="ml-auto" navbar  >
            <NavItem>
              <NavLink href="/medalhas/">Medalhas</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/calendario/">Calendário</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/atletas/">Atletas</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/assessorias/">Assessorias</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/perfil/">Meu Perfil</NavLink>
            </NavItem>
            <NavItem className="text-end">
              <NavLink href="/logout/" >Sair</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </Card>

  );
}

export default Cabecalho;

