import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import {
  Alert,
  Button,
  CardGroup as FormGroup,
  CardImg,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import logo from "../../assets/img/logo_minhas_medalhas_lg.svg";
import img_login from "../../assets/img/img_signin.svg";
import { getUrlApi } from "../../api/auth";

function Inscrever() {
  const [user, setEmail] = useState("");
  const [name, setName] = useState("");
  const [fone, setFone] = useState("");
  const [cpf, setCPF] = useState("");
  const [cpfValido, setCPFValido] = useState(true);
  let [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [emailExist, setEmailExist] = useState(false);
  const [messageAPI, setMessageAPI] = useState("");
  const [resultColor, setResultColor] = useState("");
  const emailInput = useRef(null);
  const [erro, setErro] = useState('');

  const handleSenhaChange = (event) => {
    const novaSenha = event.target.value;
    setPassword(novaSenha);
    setErro(validarSenha(novaSenha));
  };

  const formatarCPF = (valor) => {
    // Remove caracteres não numéricos
    const cpfLimpo = valor.replace(/\D/g, '');

    // Aplica a formatação
    const cpfFormatado =
      cpfLimpo.length === 11
        ? `${cpfLimpo.slice(0, 3)}.${cpfLimpo.slice(3, 6)}.${cpfLimpo.slice(6, 9)}-${cpfLimpo.slice(9)}`
        : cpfLimpo;

    return cpfFormatado;
  };

  const validarCPF = (valor) => {
    const cpfLimpo = valor.replace(/\D/g, '');

    // Verifica se o CPF possui 11 dígitos
    if (cpfLimpo.length !== 11) {
      return false;
    }

    // Verifica se todos os dígitos são iguais, o que torna o CPF inválido
    if (/^(\d)\1+$/.test(cpfLimpo)) {
      return false;
    }

    // Calcula os dígitos verificadores
    let soma = 0;
    for (let i = 0; i < 9; i++) {
      soma += parseInt(cpfLimpo.charAt(i)) * (10 - i);
    }
    const primeiroDigito = (soma % 11) < 2 ? 0 : 11 - (soma % 11);

    soma = 0;
    for (let i = 0; i < 10; i++) {
      soma += parseInt(cpfLimpo.charAt(i)) * (11 - i);
    }
    const segundoDigito = (soma % 11) < 2 ? 0 : 11 - (soma % 11);

    // Verifica se os dígitos verificadores são iguais aos informados no CPF
    if (
      primeiroDigito === parseInt(cpfLimpo.charAt(9)) &&
      segundoDigito === parseInt(cpfLimpo.charAt(10))
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleChange = (e) => {
    const valorDigitado = e.target.value;
    const cpfFormatado = formatarCPF(valorDigitado);
    setCPF(cpfFormatado);

    // Validação do CPF
    if (validarCPF(valorDigitado)) {
      setCPFValido(true);
    } else {
      setCPFValido(false);
    }
  };


  const validarSenha = (senha) => {
    // A expressão regular verifica se a senha contém pelo menos um número, uma letra e um caractere especial
    const regex = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[\W_]).{8,}$/;

    if (!regex.test(senha)) {
      return 'A senha deve conter pelo menos 8 caracteres, incluindo números, letras e caracteres especiais.';
    }

    return '';
  };


  function envia() {

    if (!cpfValido){
      setMessageAPI("CPF informado incorreto!");
      setResultColor("warning");
      return;
    };

    setLoading(true);
    const requestInfo = {
      method: "POST",
      body: JSON.stringify({
        usuario: `${user}`,
        nome: `${name}`,
        cpf: `${cpf}`,
        fone: `${fone}`,
        password: `${password}`
      }),
      headers: new Headers({
        "Content-type": "application/json",
      }),
    };
    fetch(
      getUrlApi() + "/novoAtleta/enviaDados/",
      requestInfo
    ).then((response) => {
      response.json().then((data) => {
        setEmailExist(false);
        if (response.status === 200) {
          //setMessageAPI(data.message); retirada a msg pois vai para a proxima etapa
          setResultColor("success");
          window.location.href = window.location.origin + "/finalizarCadastro/"+data.message;
        } else {
          if (response.status === 202) {
            if (data.field === "user") {
              setEmailExist(true);
              emailInput.current.focus();
            }
            setMessageAPI(data.message);
          } else {
            if (response.status === 400) {
              setMessageAPI(data.message);
            }
          }
          setResultColor("warning");
        }
      });
    });
  }

  return (
    <Container >
      <Row>
        <Col sm="6">
          <Row>
            <Col sm="8" className="pt-4 pb-3">
              <Link to="/login">
                <CardImg src={logo} alt="Minhas Medalhas" />
              </Link>
            </Col>
            <Col sm="4">&nbsp;</Col>
          </Row>
          <Row>
            <Col sm="8" className="pt-1 pb-1">
              <Form
                method="Post"
                onSubmit={(event) => {
                  event.preventDefault();
                  envia();
                }}
              >
                <FormGroup>
                  <Label className="text_form">Email:*</Label>
                  <Input
                    bsSize="sm"
                    value={user}
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                    ref={emailInput}
                    type="email"
                    name="user"
                    minLength="5"
                    id="user"
                    required
                    invalid={emailExist}
                  />
                  <FormFeedback invalid>{messageAPI}</FormFeedback>
                </FormGroup>
                <Label className="text_form">Nome completo:*</Label>
                <Input
                  bsSize="sm"
                  value={name}
                  onChange={(event) => {
                    setName(event.target.value);
                  }}
                  type="text"
                  name="name"
                  id="name"
                  minLength="4"
                  maxlength="80"
                  className="input_form"
                  required
                />
                <Label className="text_form">CPF:*</Label>
                <Input
                  bsSize="sm"
                  type="text"
                  name="cpf"
                  minLength="14"
                  maxLength="14"
                  id="cpf"
                  value={cpf}
                  required
                  onChange={handleChange}
                  className="input_form"
                />
                {!cpfValido && <p style={{ color: 'red' }}>CPF inválido</p>}
                <Label className="text_form">Celular:*</Label>
                <Input
                  bsSize="sm"
                  type="text"
                  name="fone"
                  minLength="5"
                  maxLength="15"
                  id="fone"
                  required
                  value={fone}
                  onChange={(event) => {
                    setFone(phoneMask(event.target.value));
                  }}
                  className="input_form"
                />
                <Label className="text_form">Senha:*</Label>
                <Input
                  bsSize="sm"
                  value={password}
                  onChange={handleSenhaChange}
                  type="password"
                  name="password"
                  id="password"
                  minLength="4"
                  maxlength="80"
                  required
                  className="input_form"
                />
                {erro && <Label className="text_small_erro">{erro}</Label>}
                <br />
                <Label className="text_small">
                  Ao se cadastrar, você concorda com nossas&nbsp;<Link to="/politicaDePrivacidade">Políticas de Privacidade</Link> e <Link to="/politicaDeCookies">Política de Cookies</Link>
                </Label>
                {messageAPI ? <Alert color={resultColor} className="text_small">{messageAPI}</Alert> : null}
                {loading ? (
                  <div className="text-center">
                    <ClipLoader color="gray" loading={loading} size={60} />
                  </div>
                ) : (
                  <>
                    <Button color="primary" className="button_form" type="submit" block>
                      Cadastrar
                    </Button>
                    <Link to="/login" className="no-underline">
                      <Button color="secondary" className="button_form" outline block >
                        Voltar
                      </Button>
                    </Link>
                  </>
                )
                }
              </Form>
            </Col>
            <Col sm="4">&nbsp;</Col>
          </Row>
        </Col>
        <Col sm="6">
          <CardImg src={img_login} className="img_lateral" />
        </Col>
      </Row>

    </Container>
  );
}


function phoneMask(v) {

  let r = v.replace(/\D/g, "");
  r = r.replace(/^0/, "");
  if (r.length > 11) {
    r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
  } else if (r.length > 7) {
    r = r.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, "($1) $2-$3");
  } else if (r.length > 2) {
    r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
  } else if (v.trim() !== "") {
    r = r.replace(/^(\d*)/, "($1");
  }
  return r;
}

export default Inscrever;
