import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardText,
  CardImg,
  Col,
  Container,
  Row,
  CardBody,
  UncontrolledAlert,
  CardLink,
  Label,
  Button,
} from "reactstrap";
import { busca } from "../../api/api_athlete";
import fotoEvento from "../../assets/img/no-photo.png";
import ClipLoader from "react-spinners/ClipLoader";
import Avatar from "react-avatar";

const ListaAtletas = () => {
  let { search } = useParams();
  const [atletas, setAtletas] = useState([]);
  let [loading, setLoading] = useState(true);
  const [showResult, setShowResult] = useState(false);
  const [showNoResult, setShowNoResult] = useState(false);
  const [corridas, setCorridas] = useState([]);
  let [loadingCorridas, setLoadingCorridas] = useState(true);
  const [showResultCorridas, setShowResultCorridas] = useState(false);
  const [showNoResultCorridas, setShowNoResultCorridas] = useState(false);
  const [assessorias, setAssessorias] = useState([]);
  let [loadingAssessorias, setLoadingAssessorias] = useState(true);
  const [showResultAssessorias, setShowResultAssessorias] = useState(false);
  const [showNoResultAssessorias, setShowNoResultAssessorias] = useState(false);

  function VerTodas(tipo) {
    if (tipo === "atletas"){
      window.location.href = `/atletas`;
    }
    if (tipo === "corridas"){
      window.location.href = `/calendario`;
    }
    if (tipo === "assessorias"){
      window.location.href = `/assessorias`;
    }
  }


  useEffect(() => {
    busca(
      "/pesquisaTop5/atletas/" + search,
      setAtletas,
      setLoading,
      setShowResult,
      setShowNoResult
    );


    busca(
      "/pesquisaTop5/corridas/" + search,
      setCorridas,
      setLoadingCorridas,
      setShowResultCorridas,
      setShowNoResultCorridas
    );

    busca(
      "/pesquisaTop5/assessorias/" + search,
      setAssessorias,
      setLoadingAssessorias,
      setShowResultAssessorias,
      setShowNoResultAssessorias
    );



  }, []);


  const Result = () => (
    <>
      {atletas.map((atleta) => (

        <CardLink target={`/atleta/${atleta.idHash}`} className="m-0 text-center h-100 no-underline ">
          <Row>
            <Col xs="3" md="3" className="p-2">
              <Link to={`/atleta/${atleta.idHash}`} className="mt-1 mb-2 p-2">
                {atleta.foto === null ? (
                  <Avatar name={atleta.nome} size="100" round={true} maxInitials={2} className="mt-1 mb-1" />
                ) : (
                  <img className="mt-1 mb-1"
                    src={atleta.foto}
                    style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '50%' }}
                  />
                )}
              </Link>
            </Col>
            <Col xs="9" md="9" >
              <CardBody className="text-start p-0 barra">
                <Label className="text_big pt-2">{atleta.apelido}</Label>
                <CardText className="text_medium pb-2">
                  {atleta.nome}<br />
                  {atleta.uf ? (atleta.nomeCidade + " - " + atleta.uf) : atleta.nomeCidade}
                </CardText>
              </CardBody>
            </Col>
          </Row>
        </CardLink>
      ))}
      <br />
      <Button className="p-2 m-2" outline onClick={() => VerTodas('atletas')} color="secondary">Ver outros(as) atletas</Button>
    </>
  );

  const NoResult = () => (
    <CardText className="p-3 text_alert">
      Não encontramos atletas com este nome!
    </CardText>
  );

  const ResultCorridas = () => (
    <>
      {corridas.map((corrida) => (

        < CardLink target={`/corridas/${corrida.id}`} className="m-0 text-center h-100 no-underline" >
          <Row>
            <Col xs="3" md="3" className="pt-1">
              <Link to={`/corridas/${corrida.id}`} className="mt-1 mb-2 p-2">
                <CardImg
                  src={corrida.foto === null ? fotoEvento : corrida.foto}
                  className="img-fluid rounded-lg m-0 p-1 "
                />
              </Link>
            </Col>
            <Col xs="9" md="9">
              <CardBody className="text-start p-0 barra">
                <Label className="text_big pt-2">{corrida.nome}</Label>
                <CardText className="text_medium pb-2">
                  {corrida.dataInicio}<br />
                  {corrida.uf ? (corrida.cidade + " - " + corrida.uf) : corrida.cidade}<br />
                  {corrida.statusEvento}
                </CardText>
              </CardBody>
            </Col>
          </Row>
        </CardLink >
      ))}
      <br />
      <Button className="p-2 m-2" outline onClick={() => VerTodas('corridas')} color="secondary">Ver outras corridas</Button>
    </>
  );

  const NoResultCorridas = () => (
    <CardText className="p-3 text_alert">
      Não encontramos corridas com este nome!
    </CardText>
  );

  const ResultAssessorias = () => (
    <>
      {assessorias.map((time) => (

        <CardLink target={`/assessoria/${time.id}`} className="m-0 text-center h-100 no-underline">
          <Row>
            <Col xs="3" md="3" className="pt-2">
              <Link to={`/assessoria/${time.id}`} className="mt-1 mb-1">
                {time.logo === null ? (
                  <Avatar name={time.nome} size="100" round={true} maxInitials={2} />
                ) : (
                  <Avatar src={time.logo} size="100" round={true} />
                )}
              </Link>
            </Col>
            <Col xs="9" md="9">
              <CardBody className="text-start p-0 barra">
                <Label className="text_big pt-2">{time.nome}</Label>
                <CardText className="text_medium pb-2">
                  {time.fone}<br />
                  {time.nomeCidade} - {time.uf}
                </CardText>
              </CardBody>
            </Col>
          </Row>
        </CardLink>
      ))}
      <br />
      <Button className="p-2 m-2" outline onClick={() => VerTodas('assessorias')} color="secondary">Ver outras assessorias esportivas</Button>
    </>
  );

  const NoResultAssessorias = () => (
    <CardText className="p-3 text_alert">
      Não encontramos Assessorias esportivas com este nome!
    </CardText>
  );

  return (
    <Container>
      <Row>
        <Col md="3" sd="12">
          <Card className="mt-0">
            <CardHeader className="text-center pb-3 m-0" >
              <Row>
                <Col className="text_medium mt-2 " md="12" sd="3" >
                  <Button href="#atletas" color="light" className="no-underline" >Atletas</Button>
                </Col>
                <Col className="text_medium mt-2" md="12" sd="3">
                  <Button href="#corridas" color="light" className="no-underline" >Corridas</Button>
                </Col>
                <Col className="text_medium mt-2" md="12" sd="3">
                  <Button href="#times" color="light" className="no-underline" >Asessorias </Button>
                </Col>
              </Row>
            </CardHeader>
          </Card>
        </Col>
        <Col md="9" sd="12">
          <Card>
            <CardText className="text-center text_big p-3">
              Resultado da Pesquisa
            </CardText>
          </Card>
          <div id='atletas'>
            <Card className="mt-2" >
              <Label className="p-2 text_big">Atletas</Label>
              <div className="text-center">
                <ClipLoader color="gray" loading={loading} size={60} />
              </div>
              {showResult ? <Result /> : null}
              {showNoResult ? <NoResult /> : null}
            </Card>
          </div>
          <div id="corridas">
            <Card className="mt-2" >
              <Label className="p-2 text_big">Corridas</Label>
              <div className="text-center">
                <ClipLoader color="gray" loading={loadingCorridas} size={60} />
              </div>
              {showResultCorridas ? <ResultCorridas /> : null}
              {showNoResultCorridas ? <NoResultCorridas /> : null}
            </Card>
          </div>
          <div id="times">
            <Card className="mt-2" >
              <Label className="p-2 text_big">Asessorias Esportivas</Label>
              <div className="text-center">
                <ClipLoader color="gray" loading={loadingAssessorias} size={60} />
              </div>
              {showResultAssessorias ? <ResultAssessorias /> : null}
              {showNoResultAssessorias ? <NoResultAssessorias /> : null}
            </Card>
          </div>
        </Col>
      </Row>
    </Container >
  );
};

export default ListaAtletas;
