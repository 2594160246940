import React from 'react';
import MenuAtleta from "./MenuAtleta"

import { Container, Col, Row } from "reactstrap";
import Patrocinio from './Patrocinio';
import Feeds from './FeedPrincipal';

function Home(){
    return (
        <Container>
            <Row>
                <Col sm="2" className="p-0 pt-2">
        	        <MenuAtleta url={'/menuAtleta'} />
                </Col>
                <Col sm="8" className="p-2 pt-2">
        	        <Feeds url={'/corridas'} />
                </Col>
                <Col sm="2" className="p-0 pt-2">
        	        <Patrocinio />
                </Col>
            </Row>
        </Container>    
    )
}


export default Home