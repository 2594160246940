import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Badge,
  Button,
  Col,
  Row,
  Card,
  CardImg,
  CardSubtitle,
  CardText,
  CardBody,
} from "reactstrap";
import "../../assets/css/minha_medalha.css";
import { busca } from "../../api/api_adm_organizadora";
import logo from "../../assets/img/no-logo.png";
import ClipLoader from "react-spinners/ClipLoader";

const MenuOrganizadora = ({ url }) => {
  const [organizadora, setOrganizadora] = useState([]);
  let [loading, setLoading] = useState(true);
  const [showResult, setShowResult] = useState(false);
  const [showNoResult, setShowNoResult] = useState(false);
  
  useEffect(() => {
    busca(url, setOrganizadora, setLoading, setShowResult, setShowNoResult);
  }, [url]);

  const Result = () => (
    <Card className="mb-3 mt-2 p-1 text-center">
      <CardBody className="p-0 text-center">
        <Row>
          <Col md="5" xs="3">
          <Link to={"/perfil/"}>
            <CardImg
              top
              width="50"
              alt="Logo da Organizadora"
              src={organizadora.logo === null ? logo : organizadora.logo}
              className="img-fluid rounded-circle "
            />
          </Link>

        </Col>
        <Col md="7" xs="9" className="pl-0 mt-1 ">
        <CardSubtitle  >
          {organizadora.nome}
        </CardSubtitle>
        <small>{organizadora.apelidoResponsavel}</small>
        </Col>
        </Row>
      </CardBody>
      <div align="center">
        <div className="p-0 pt-2 pb-2 ">
          <Link to={"/adminOrganizadora"}>
            <Badge color="primary p-2" block>
              Detalhes da Organizadora
            </Badge>
          </Link>
        </div>
        <Link to="/adminOrganizadora/eventos/oficiais">
            <Row align="left">
              <Col xs="8" className="pr-0">
                Eventos Oficiais:
              </Col>
              <Col xs="4" className="pr-4 pl-0 text-end">
                <small>
                  <b>{organizadora.eventosTotal}</b>
                </small>
              </Col>
            </Row>
        </Link>
        <Link to="/adminOrganizadora/eventos/planejados">
          <Card className="mb-1">
            <Row align="left">
              <Col xs="8" className="pr-0">
                <small>Planejados:</small>
              </Col>
              <Col xs="4" className="pr-4 pl-0 text-end">
                <small>
                  <b>{organizadora.eventosPlanejados}</b>
                </small>
              </Col>
            </Row>
          </Card>
        </Link>
        <Link to="/adminOrganizadora/eventos/realizados">
          <Card className="mb-1">
            <Row align="left">
              <Col xs="8" className="pr-0">
                <small>Realizados:</small>
              </Col>
              <Col xs="4" className="pr-4 pl-0 text-end">
                <small>
                  <b>{organizadora.eventosRealizados}</b>
                </small>
              </Col>
            </Row>
          </Card>
        </Link>
        <Link to="/adminOrganizadora/eventos/semDataDefinida">
          <Card className="mb-1">
            <Row align="left">
              <Col xs="8" className="pr-0">
                <small>Sem data definida:</small>
              </Col>
              <Col xs="4" className="pr-4 pl-0 text-end">
                <small>
                  <b>{organizadora.eventosSemData}</b>
                </small>
              </Col>
            </Row>
          </Card>
        </Link>
        <Link to="/adminOrganizadora/eventos/cancelados">
          <Card className="mb-1">
            <Row align="left">
              <Col xs="8" className="pr-0">
                <small>Cancelados:</small>
              </Col>
              <Col xs="4" className="pr-4 pl-0 text-end">
                <small>
                  <b>{organizadora.eventosCancelados}</b>
                </small>
              </Col>
            </Row>
          </Card>
        </Link>
         <Row align="left">
              <Col xs="12">
                Eventos Pendentes:
              </Col>
            </Row>
        <Link to="/adminOrganizadora/eventos/pendenteAprovacao">
          <Card className="mb-1">
            <Row align="left">
              <Col xs="8" className="pr-0">
                <small>Ag. Aprovação:</small>
              </Col>
              <Col xs="4" className="pr-4 pl-0 text-end">
                <small>
                  <b>{organizadora.eventosPendentes}</b>
                </small>
              </Col>
            </Row>
          </Card>
        </Link>
        <Link to="/adminOrganizadora/eventos/pendenteAdministracao">
          <Card className="mb-1">
            <Row align="left">
              <Col xs="8" className="pr-0">
                <small>Em análise Adm:</small>
              </Col>
              <Col xs="4" className="pr-4 pl-0 text-end">
                <small>
                  <b>{organizadora.eventosEmAnaliseAdmin}</b>
                </small>
              </Col>
            </Row>
          </Card>
        </Link>
        <div className="pt-3">
          <Link to="/adminOrganizadora/novoEvento">
            <Button className="p-1" color="success" block>
              Novo Evento
            </Button>
          </Link>
        </div>
        <div className="pt-3">
          <Link to="/">
            <Button className="p-1" color="secondary" block>
              Sair do Módulo
            </Button>
          </Link>
        </div>
      </div>
    </Card>
  );

  const NoResult = () => (
    <CardText className="p-3">Opss!!! Ocorreu algum problema</CardText>
  );

  return (
    <>
      <div className="text-center">
        <ClipLoader color="gray" loading={loading} size={60} />
      </div>
      {showResult ? <Result /> : null}
      {showNoResult ? <NoResult /> : null}
    </>
  );
};

export default MenuOrganizadora;
