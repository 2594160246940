import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardText,
  Col,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  ModalFooter,
  Row,
  UncontrolledAlert,
  Label,
} from "reactstrap";
import { Link } from "react-router-dom";
import { busca } from "../../api/api_adm_team";
import ClipLoader from "react-spinners/ClipLoader";
import { getToken, getUrlApi } from "../../api/auth";
import Avatar from 'react-avatar';

const ListaAtletas = () => {
  let { id } = useParams();
  const [atletas, setAtletas] = useState([]);
  let [loading, setLoading] = useState(true);
  const [showResult, setShowResult] = useState(false);
  const [showNoResult, setShowNoResult] = useState(false);
  const [modalRejeitarAtleta, setModalRejeitarAtleta] = useState(false);
  const [modalRetiradaConvite, setModalRetiradaConvite] = useState(false);
  const [justificativa, setJustificativa] = useState("0");
  const [atletaNegado, setAtletaNegado] = useState(null);
  const [idAtletaNegado, setIdAtletaNegado] = useState(null);
  const [atualizar, setAtualizar] = useState(false);
  const [messageAPI, setMessageAPI] = useState("");
  const [resultColor, setResultColor] = useState("");
  

  useEffect(() => {
    setLoading(true);
    setShowResult(false);
    setShowNoResult(false);
    busca(
      `listaAtletas/${id}`,
      setAtletas,
      setLoading,
      setShowResult,
      setShowNoResult
    );
  }, [atualizar, id]);

  function AceitarAtleta(idAtleta) {
    const token = getToken();
    const requestInfo = {
      method: "GET",
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };
    fetch(
      getUrlApi() + "/admin/time/aceitarAtleta/" + idAtleta,
      requestInfo
    ).then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          setAtualizar(!atualizar);
        } else {
          if (response.status === 202) {
            setMessageAPI(data.message);
          } else {
            if (response.status === 400) {
              setMessageAPI(data[0].message);
            }
          }
          setResultColor("warning");
        }
      });
    });
  }

  function NegarAtleta(idAtleta, nomeAtleta) {
    setModalRejeitarAtleta(!modalRejeitarAtleta);
    setAtletaNegado(nomeAtleta);
    setIdAtletaNegado(idAtleta);
  }

  function RetirarConvite(idAtleta, nomeAtleta) {
    setModalRetiradaConvite(!modalRetiradaConvite);
    setAtletaNegado(nomeAtleta);
    setIdAtletaNegado(idAtleta);
  }

  function ConfirmarNegarAtleta() {
    if (justificativa === "0") {
      setResultColor("warning");
      setMessageAPI("Selecione uma opção!");
      return;
    } else {
      const token = getToken();
      const requestInfo = {
        method: "GET",
        headers: new Headers({
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
      };
      fetch(
        getUrlApi() +
        "/admin/time/negarAtleta/" +
        idAtletaNegado +
        "/" +
        justificativa,
        requestInfo
      ).then((response) => {
        response.json().then((data) => {
          if (response.status === 200) {
            setAtualizar(!atualizar);
          } else {
            if (response.status === 202) {
              setMessageAPI(data.message);
            } else {
              if (response.status === 400) {
                setMessageAPI(data[0].message);
              }
            }
            setResultColor("warning");
          }
        });
      });
      setModalRejeitarAtleta(!modalRejeitarAtleta);
      setJustificativa("0");
    }
  }

  function ConfirmarRetirarConvite() {
    if (justificativa === "0") {
      setResultColor("warning");
      setMessageAPI("Selecione uma opção!");
      return;
    } else {
      const token = getToken();
      const requestInfo = {
        method: "GET",
        headers: new Headers({
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
      };
      fetch(
        getUrlApi() +
        "/admin/time/retirarConvite/" +
        idAtletaNegado +
        "/" +
        justificativa,
        requestInfo
      ).then((response) => {
        response.json().then((data) => {
          if (response.status === 200) {
            setAtualizar(!atualizar);
          } else {
            if (response.status === 202) {
              setMessageAPI(data.message);
            } else {
              if (response.status === 400) {
                setMessageAPI(data[0].message);
              }
            }
            setResultColor("warning");
          }
        });
      });
      setModalRetiradaConvite(!modalRetiradaConvite);
      setJustificativa("0");
    }
  }

  const Result = () => (
    <>
      {atletas.map((atleta) => (
        <Card className="m-0 text-center h-100 p-0 mb-2">
          <Row>
            <Col sm="2" xs="5" className="p-1">
              <Link
                to={`/atleta/${atleta.idHashAtleta}`}
                className="mr-3 ml-3 mt-0 mb-0"
              >
                {atleta.fotoAtleta === null ? (
                  <Avatar name={atleta.nomeAtleta} size="100" round={true} maxInitials={2} />
                ) : (
                  <img
                    src={atleta.fotoAtleta}
                    style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '50%' }}
                  />
                )}
              </Link>
            </Col>

            <Col sm="8" xs="7" className="p-0">
              <CardBody className="p-0">
                <CardSubtitle className="pt-2 m-0 text_medium">
                  ({atleta.apelidoAtleta}) {atleta.nomeAtleta}
                </CardSubtitle>
                <CardText className="m-0 text_form">
                  {atleta.cidadeAtleta === null ? "Brasil" : (atleta.cidadeAtleta + " - " + atleta.ufAtleta)}
                </CardText>
                <CardText className="p-0 text_small">
                  {id === "convites" ? (
                    <Label>Envio do convite {atleta.dataRequesicao}</Label>
                  ) : id === "solicitacoes" ? (
                    <Label>Envio da solicitação {atleta.dataRequesicao}</Label>
                  ) : (
                    <Label>Atleta {atleta.siglaTime} desde{" "}
                      {atleta.dataInicioContato}</Label>
                  )}
                </CardText>
              </CardBody>
            </Col>
            <Col sm="2" xs="12" className="p-2 pr-3 ">
              {id === "convites" ? (
                <Row>
                  <Col sm="12" xs="6" className="p-2">
                    <Link to={`/atleta/${atleta.idHashAtleta}`}>
                      <Badge color="primary" pill className="p-2">
                        Ver perfil
                      </Badge>
                    </Link>
                  </Col>
                  <Col sm="12" xs="6" className="pr-3 p-2">
                    <Link>
                      <Badge color="danger" pill className="p-2" onClick={() => {
                        RetirarConvite(atleta.idAtleta, atleta.nomeAtleta);
                      }} >
                        Retirar convite
                      </Badge>
                    </Link>
                  </Col>
                </Row>
              ) : id === "solicitacoes" ? (
                <Row>
                  <Col sm="12" xs="4" className="pr-3">
                    <Link to={`/atleta/${atleta.idHashAtleta}`}>
                      <Badge color="primary" pill className="p-2 mt-1">
                        Ver perfil
                      </Badge>
                    </Link>
                  </Col>
                  <Col sm="12" xs="4" className="pr-3">
                    <Link>
                      <Badge
                        color="success"
                        pill
                        className="p-2 mt-1"
                        onClick={() => {
                          AceitarAtleta(atleta.idAtleta);
                        }}
                      >
                        Aceitar atleta
                      </Badge>
                    </Link>
                  </Col>
                  <Col sm="12" xs="4" className="pr-3">
                    <Link>
                      <Badge
                        color="danger"
                        pill
                        className="p-2  mt-1"
                        onClick={() => {
                          NegarAtleta(atleta.idAtleta, atleta.nomeAtleta);
                        }}
                      >
                        Negar atleta
                      </Badge>
                    </Link>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col sm="12" xs="6" className="p-0">
                    <Link to={`/atleta/${atleta.idHashAtleta}`}>
                      <Badge color="primary" pill className="p-2">
                        Ver perfil
                      </Badge>
                    </Link>
                  </Col>
                  <Col sm="12" xs="6" className="p-1">
                    <Link to={`/adminTime/gerenciar/${atleta.idHashAtleta}`}>
                      <Badge color="secondary" pill className="p-2" >
                        Gerenciar
                      </Badge>
                    </Link>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Card>
      ))}
    </>
  );

  const NoResult = () => (
    <Card>
      <CardText className="text-center m-2">
        Que pena!
        <br />
        Não temos nenhum registro de atletas nesta consulta! <br />
        Aproveite agora e se convide atletas!
        <br />
        <Link to="/atletas">
          <Button color="success" className="mr-2" block>
            Convidar um atleta!
          </Button>
        </Link>
      </CardText>
    </Card>
  );

  return (
    <Container className="p-0 m-0">
      <Card className="m-0">
        <CardHeader className="text-center p-1" tag="h3">
          <Row>
            <Col sm="12" className="m-0 mb-2">
              {id === "associados"
                ? "Seus Atletas"
                : id === "convites"
                  ? "Solicitações pendentes com o Atleta"
                  : "Atletas que solicitaram fazer parte do time!"}
            </Col>
          </Row>
        </CardHeader>
      </Card>
      {messageAPI ? (
        <UncontrolledAlert className="m-2 p-1 text-center" color={resultColor}>
          {messageAPI}
        </UncontrolledAlert>
      ) : null}
      <div className="text-center">
        <ClipLoader color="gray" loading={loading} size={60} />
      </div>
      {showResult ? <Result /> : null}
      {showNoResult ? <NoResult /> : null}
      <div>
        <Modal isOpen={modalRejeitarAtleta} className="p-0">
          <ModalHeader>Negar a solicitação do Atleta!</ModalHeader>
          <ModalBody className="pt-1 pb-3 text-left">
            <label>
              Você está negando a solicitação de acesso ao time do atleta{" "}
              {atletaNegado}{" "}
            </label>
            <label>Poderia nos informar o motivo?</label>
            <Input
              type="select"
              name="justificativa"
              id="justificativa"
              onChange={(event) => {
                setJustificativa(event.target.value);
              }}
            >
              <option value="0" selected>
                Selecione
              </option>
              <option value="Não deseja informar">Não deseja informar</option>
              <option value="Atleta desconhecido">Atleta desconhecido</option>
              <option value="Atleta não está mais conosco">
                Atleta não faz parte do time
              </option>
              <option value="Atleta falecido">Atleta falecido</option>
            </Input>
          </ModalBody>
          {messageAPI ? (
            <UncontrolledAlert
              className="m-2 p-1 text-center"
              color={resultColor}
            >
              {messageAPI}
            </UncontrolledAlert>
          ) : null}
          <ModalFooter>
            <Button
              color="secondary"
              outline
              onClick={() => setModalRejeitarAtleta(!modalRejeitarAtleta)}
            >
              Cancelar
            </Button>
            <Button color="primary" outline onClick={ConfirmarNegarAtleta}>
              Confirmar
            </Button>
          </ModalFooter>
        </Modal>
      </div>
      <div>
        <Modal isOpen={modalRetiradaConvite} className="p-0">
          <ModalHeader>Retirada do convite ao Atleta!</ModalHeader>
          <ModalBody className="pt-1 pb-3 text-left">
            <label>
              Você está retirando o convite de acesso ao time do atleta{" "}
              {atletaNegado}{" "}
            </label>
            <label>Poderia nos informar o motivo?</label>
            <Input
              type="select"
              name="justificativa"
              id="justificativa"
              onChange={(event) => {
                setJustificativa(event.target.value);
              }}
            >
              <option value="0" selected>
                Selecione
              </option>
              <option value="Não deseja informar">Não deseja informar</option>
              <option value="Atleta desconhecido">Atleta desconhecido</option>
              <option value="Atleta não está mais conosco">
                Atleta não faz parte do time
              </option>
              <option value="Atleta falecido">Atleta falecido</option>
            </Input>
          </ModalBody>
          {messageAPI ? (
            <UncontrolledAlert
              className="m-2 p-1 text-center"
              color={resultColor}
            >
              {messageAPI}
            </UncontrolledAlert>
          ) : null}
          <ModalFooter>
            <Button
              color="secondary"
              outline
              onClick={() => setModalRetiradaConvite(!modalRetiradaConvite)}
            >
              Cancelar
            </Button>
            <Button color="primary" outline onClick={ConfirmarRetirarConvite}>
              Confirmar
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </Container >
  );
};

export default ListaAtletas;
