import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardImg,
  CardLink,
  CardText,
  CardTitle,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap";
import fotoEvento from "../../assets/img/no-photo.png";
import { busca } from "../../api/api_adm_team";
import ClipLoader from "react-spinners/ClipLoader";
import Avatar from 'react-avatar';

const CalendarioAtletas = ({ url }) => {
  const [resultado, setResultado] = useState([]);
  const [search, setSearch] = useState(null);
  let [loading, setLoading] = useState(true);
  const [showResult, setShowResult] = useState(false);
  const [showNoResult, setShowNoResult] = useState(false);
  const [atualizar, setAtualizar] = useState(true);
  const [cardsVisiveis, setCardsVisiveis] = useState(Array(10).fill(false));

  const ordenarAtletas = (atletas) => {
    return atletas.sort((a, b) => a.nomeAtleta.localeCompare(b.nomeAtleta));
  };
  const ordenarEventos = (eventos) => {
    return eventos.sort((a, b) => {
      const dataA = new Date(a.dataMilisegundos).toISOString();
      const dataB = new Date(b.dataMilisegundos).toISOString();
      return dataA.localeCompare(dataB);
    });
  };

  const toggleCard = (index) => {
    const novosCardsVisiveis = [...cardsVisiveis];
    novosCardsVisiveis[index] = !novosCardsVisiveis[index];
    setCardsVisiveis(novosCardsVisiveis);
  };

  useEffect(() => {
    busca(
      "/calendario/" + search,
      setResultado,
      setLoading,
      setShowResult,
      setShowNoResult
    );
  }, [atualizar]);

  function trocaBusca(pagina) {
    setShowResult(false);
    if (pagina === "") {
      setSearch(null);
    } else {
      setSearch(pagina);
    }
    setLoading(true);
    setAtualizar(!atualizar);
  }

  const Result = () => (
    <Card className="m-0 p-0 text-center h-100">
      {search === 'planejamentoCorridas' ? (
        resultado.map((evento, index) => (

          <Card className="m-0 p-0 text-center h-100">
            <Row>
              <Col xs='3' sm="2">
                <Link to={`/corridas/${evento.id}`}>
                  <CardImg
                    src={evento.foto === null ? fotoEvento : evento.foto}
                    className="img-fluid rounded-lg m-0 p-1 "
                  />
                </Link>
              </Col>
              <Col xs='9' sm="9">
                <CardBody className="p-0">
                  <CardTitle className="m-0 text_medium_center">
                    {evento.nome}
                  </CardTitle>
                  <CardText className="m-0 text_banco">
                    {evento.cidade}
                  </CardText>
                  <CardText className="m-0  text_banco">
                    {evento.dataInicio}
                  </CardText>
                  <div key={index}>
                    <Button color="secondary" className="m-1 p-0" outline onClick={() => toggleCard(index)}>
                      {cardsVisiveis[index] ? 'Esconder lista de Atletas' : 'Mostrar lista de Atletas'}
                    </Button>
                    {cardsVisiveis[index] && (
                      <Card className="mb-3">
                        <CardText className="m-0 text-start" >
                          <Row>
                            <Col sm="8">
                              <Label className="m-0 text_medium">&nbsp;&nbsp;Atleta</Label>
                            </Col>
                            <Col sm="4">
                              <Label className="m-0 text_medium">Modalidade</Label>
                            </Col>
                          </Row>
                        </CardText>

                        {ordenarAtletas(evento.registros).map((registro) => (

                          <Card>
                            <CardLink href={"/atleta/" + registro.hashAtleta}>
                              <CardText className="m-0 text-start" >
                                <Row>
                                  <Col sm="8">
                                    <Label className="m-0 text_banco">&nbsp;
                                      {registro.fotoAtleta === null ? (
                                        <Avatar name={registro.nomeAtleta} size="30" round={true} maxInitials={2} />
                                      ) : (
                                        <img
                                          src={registro.fotoAtleta}
                                          style={{ width: '30px', height: '30px', objectFit: 'cover', borderRadius: '50%' }}
                                        />
                                      )}
                                      &nbsp;&nbsp;{registro.nomeAtleta}
                                    </Label>

                                  </Col>
                                  <Col sm="4">
                                    <Label className="m-0 text_banco">{registro.modalidade ? registro.modalidade : "Não Informado"}</Label>
                                  </Col>
                                </Row>
                              </CardText>
                            </CardLink>
                          </Card>
                        ))}
                      </Card>
                    )}
                  </div>

                </CardBody>
              </Col>
            </Row>
          </Card>
        ))
      ) :
        search === 'planejamentoAtletas' ? (
          resultado.map((atleta, index) => (

            <Card className="m-0 p-0 text-center h-100">
              <Row>
                <Col xs='3' sm="2" className="p-2">
                  <Link
                    to={`/atleta/${atleta.idHashAtleta}`}
                    className="mr-3 ml-3 mt-0 mb-0" style={{ cursor: 'pointer' }}
                  >
                    {atleta.fotoAtleta === null ? (
                      <Avatar name={atleta.nomeAtleta} size="80" round={true} maxInitials={2} />
                    ) : (
                      <img
                        src={atleta.fotoAtleta}
                        style={{ width: '80px', height: '80px', objectFit: 'cover', borderRadius: '50%', cursor: 'pointer' }}
                      />
                    )}
                  </Link>
                </Col>
                <Col xs='9' sm="9">
                  <CardBody className="p-0">
                    <CardTitle className="m-0 text_medium_center">
                      {atleta.nomeAtleta}
                    </CardTitle>
                    <CardText className="m-0  text_banco">
                      {atleta.apelido}
                    </CardText>
                    <CardText className="m-0 text_banco">
                      {atleta.cidadeAtleta}
                    </CardText>
                    <div key={index}>
                      <Button color="secondary" className="m-1 p-0" outline onClick={() => toggleCard(index)}>
                        {cardsVisiveis[index] ? 'Esconder lista de Corridas' : 'Mostrar lista de Corridas'}
                      </Button>
                      {cardsVisiveis[index] && (
                        <Card>
                          <CardText className="m-0 text-start" >
                            <Label className="m-0 text_medium">
                              &nbsp;&nbsp;Modalidade - Corrida
                            </Label>
                          </CardText>
                          {ordenarEventos(atleta.registros).map((registro) => (
                            <Card>
                              <CardLink href={"/atleta/" + registro.hashAtleta}>
                                <CardText className="m-0 text-start" >
                                  <Row>
                                    <Col sm="2">
                                      <CardImg
                                        src={
                                          registro.logoEvento === null ? fotoEvento : registro.logoEvento
                                        }
                                        className="img-fluid rounded-lg p-2"
                                      />
                                    </Col>
                                    <Col sm="10">
                                      <Label className="m-0 text_banco">
                                        {registro.modalidade} - {registro.nomeEvento}<br /><br />
                                        {registro.dataEvento}
                                      </Label>
                                    </Col>
                                  </Row>
                                </CardText>
                              </CardLink>
                            </Card>
                          ))}
                        </Card>
                      )}
                    </div>

                  </CardBody>
                </Col>
              </Row>
            </Card>
          ))

        ) :
          search === 'proximasCorridas' ? (
            resultado.map((evento, index) => (
              <>
              <Label className="text-start text_big">{evento.dataInicio}</Label>
              <Card className="m-0 p-0 text-center h-100">
                <Row>
                  <Col xs='3' sm="2">
                    <Link to={`/corridas/${evento.id}`}>
                      <CardImg
                        src={evento.foto === null ? fotoEvento : evento.foto}
                        className="img-fluid rounded-lg m-0 p-1 "
                      />
                    </Link>
                  </Col>
                  <Col xs='9' sm="9">
                    <CardBody className="p-0">
                      <CardTitle className="m-0 text_medium_center">
                        {evento.nome}
                      </CardTitle>
                      <CardText className="m-0 text_banco">
                        {evento.cidade}
                      </CardText>
                      <CardText className="m-0  text_banco">
                        {evento.dataInicio}
                      </CardText>
                      <Card className="mb-3">
                        <CardText className="m-0 text-start" >
                          <Row>
                            <Col sm="8">
                              <Label className="m-0 text_medium">&nbsp;&nbsp;Atleta</Label>
                            </Col>
                            <Col sm="4">
                              <Label className="m-0 text_medium">Modalidade</Label>
                            </Col>
                          </Row>
                        </CardText>

                        {ordenarAtletas(evento.registros).map((registro) => (
                          <Card>
                            <CardLink href={"/atleta/" + registro.hashAtleta}>
                              <CardText className="m-0 text-start" >
                                <Row>
                                  <Col sm="8">
                                    <Label className="m-0 text_banco">&nbsp;
                                      {registro.fotoAtleta === null ? (
                                        <Avatar name={registro.nomeAtleta} size="30" round={true} maxInitials={2} />
                                      ) : (
                                        <img
                                          src={registro.fotoAtleta}
                                          style={{ width: '30px', height: '30px', objectFit: 'cover', borderRadius: '50%' }}
                                        />
                                      )}
                                      &nbsp;&nbsp;{registro.nomeAtleta}
                                    </Label>

                                  </Col>
                                  <Col sm="4">
                                    <Label className="m-0 text_banco">{registro.modalidade ? registro.modalidade : "Não Informado"}</Label>
                                  </Col>
                                </Row>
                              </CardText>
                            </CardLink>
                          </Card>
                        ))}
                      </Card>
                    </CardBody>
                  </Col>
                </Row>
              </Card>
              </>
            ))
          ) : null}

    </Card>
  );

  const NoResult = () => (
    <Card className="m-2">
      <CardText className="text_medium_center m-2">
        <Row>
          <Col xs="12" md="7" className="mt-2">
            Não encontramos corridas programadas para este filtro!
          </Col>
          <Col xs="12" md="5">
            <Link to="/novoEvento" className="no-underline">
              <Button color="success" >
                Cadastre uma nova corrida!
              </Button>
            </Link>
          </Col>
        </Row>
      </CardText>
    </Card>
  );

  return (
    <Container className="p-2">
      <Card className="p-1 text-center">
        <CardText>
          <Button color="primary" outline className="p-1 mb-2" onClick={(event) => { trocaBusca("planejamentoCorridas") }}>
            Planejamento por corrida
          </Button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Button color="primary" outline className="p-1 mb-2" onClick={(event) => { trocaBusca("planejamentoAtletas") }}>
            Planejamento por Atleta
          </Button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Button color="primary" outline className="p-1 mb-2" onClick={(event) => { trocaBusca("proximasCorridas") }}>
            Próximas Corridas
          </Button>
          &nbsp;&nbsp;&nbsp;&nbsp;
        </CardText>
      </Card>
      <Card className="text-center">
        <Label className="text_big">
          {search === "planejamentoCorridas" ? "Planejamento por Corridas" : search === "planejamentoAtletas" ? "Planejamento por Atleta" : search === "proximasCorridas" ? "Próximas Corridas" : null}
        </Label>
        {showResult ? <Result /> : null}
        {showNoResult ? <NoResult /> : null}
        <Label className="text-center"><ClipLoader color="gray" loading={loading} size={60} /></Label>
      </Card>
    </Container>
  );
};

export default CalendarioAtletas;
