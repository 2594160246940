import React from 'react';
import MenuOrganizadora from "./MenuOrganizadora"

import { Container, Col, Row, Alert } from "reactstrap";
import Patrocinio from '../Home/Patrocinio';
import Info from './Info';

function Home(){
    return (
        <Container className="pl-0 pr-0">
            <Alert color="light" className="mt-2 p-1 pl-0 mb-0 text-center">
                Módulo Organizadora de Eventos
            </Alert>            
            <Row>
                <Col sm="2" className="p-2 pb-2 pt-0">
        	        <MenuOrganizadora url={'/menuOrganizadora'} />
                </Col>
                <Col sm="8" className="p-2 pt-0">
        	        <Info url={'/info'} />
                </Col>
                <Col sm="2" className="p-0 pt-3">
        	        <Patrocinio />
                </Col>
            </Row>
        </Container>    
    )
}


export default Home