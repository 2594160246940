import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Alert,
  CardImg,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap";
import ClipLoader from "react-spinners/ClipLoader";
import logo from "../../assets/img/logo_minhas_medalhas_lg.svg";
import { Redirect, Link } from "react-router-dom/cjs/react-router-dom";
import { getUrlApi } from "../../api/auth";


function ConfirmarCadastro() {
  const { idHash } = useParams();
  const [messageAPI, setMessageAPI] = useState("");
  let [loading, setLoading] = useState(true);
  const [resultColor, setResultColor] = useState("");
  const [block, setBlock] = useState(true);
  const [showResult, setShowResult] = useState(false);
  const [showNoResult, setShowNoResult] = useState(false);

  useEffect(() => {

    const requestInfo = {
      method: "GET",
      headers: new Headers({
        "Content-type": "application/json",
      })
    };

    fetch(
      getUrlApi() +"/novoAtleta/confirmarCadastro/" + idHash,
      requestInfo
    ).then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          setMessageAPI(data.field);
          setResultColor("success");
        } else {
          if (response.status === 202) {
            setMessageAPI(data.message);
          } else {
            if (response.status === 400) {
              setMessageAPI("Erro Inesperado"); //data[0].message);
            }
          }
          setResultColor("warning");
        }
      });
    });
    setLoading(false);
  }

  );

  if (block && messageAPI === 'success' ){
    setShowResult(false);
    setShowNoResult(true);
    setBlock(false);
    setLoading(false);
  }  
  const Result = () => (
    <Container className="text-center">
      <Row>
        <Col sm="3">&nbsp;</Col>
        <Col sm="6">
          <Link to="/login"><CardImg src={logo} alt="Minhas Medalhas" /></Link>
          <Label className="text_big">
            <center>Algo deu errado!!!</center>
          </Label>
        </Col>
        <Col sm="3">&nbsp;</Col>
        <Col sm="3">&nbsp;</Col>
        <Col sm="6">
          {messageAPI ? (<><br /><Alert color={resultColor} className="text_small">{messageAPI}</Alert></>) : null}
        </Col>
        <Col sm="3">&nbsp;</Col>
      </Row>
    </Container>
  );

  
  const NoResult = () => (
    <Redirect to={{ pathname: "/login/validacaoOk" }} />
  );

  return (
    <>
      <div className="text-center">
        <ClipLoader color="gray" loading={loading} size={60} />
      </div>
      {
        showResult ? <Result /> : null}
      {showNoResult ? <NoResult /> : null}
    </>
  );
};

export default ConfirmarCadastro;