import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { getToken, getUrlApi } from "../../api/auth";

const LinkAnuncio = () => {
  const { idHash } = useParams()
  const { url } = useParams()
  const [redirecionado, setRedirecionado] = useState(false);
  let [loading, setLoading] = useState(true);
  const [messageAPI, setMessageAPI] = useState("");

  useEffect(() => {
    let urlEnvio = url.replace(/-/g, "/");
    if (!redirecionado) {
      const token = getToken();
      const requestInfo = {
        method: "GET",
        headers: new Headers({
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
      };
      fetch(
        getUrlApi() + "/anuncio/registraClick/"+idHash, 
        requestInfo
      ).then((response) => {
        response.json().then((data) => {
          if (response.status === 200) {
            window.location.href = 'https://' + urlEnvio;
            setRedirecionado(true);
          } else {
            if (response.status === 202) {
              setMessageAPI(data.message);
            } else {
              if (response.status === 400) {
                setMessageAPI(data.message);
              }
              if (response.status === 500) {
                setMessageAPI("erro inesperado");
              }
            }
          }
          setLoading(false);
        });
      });
    }
  }, [redirecionado]);


  return (
    <div className="text_medium">
      <ClipLoader color="gray" loading={loading} size={100} />
    </div>
  );
};

export default LinkAnuncio;
