import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardGroup,
  CardHeader,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap";
import Patrocinio from "../Home/Patrocinio";
import { Link } from "react-router-dom";
import { busca } from "../../api/api_athlete";
import ClipLoader from "react-spinners/ClipLoader";
import Avatar from "react-avatar";

const ListaContatos = () => {
  let { id } = useParams();
  const [contatos, setContatos] = useState([]);
  let [loading, setLoading] = useState(true);
  const [showResult, setShowResult] = useState(false);
  const [showNoResult, setShowNoResult] = useState(false);
  const [mensagem, setMensagem] = useState("");

  useEffect(() => {
    setLoading(true)
    setShowResult(false);
    setShowNoResult(false);
    busca(
      `contatos/${id}`,
      setContatos,
      setLoading,
      setShowResult,
      setShowNoResult
    );
    Mensagem(id);
  }, [id]);

  function Mensagem(idDiv) {
    switch (idDiv) {
      case "amigos":
        setMensagem("Amigos na sua rede de contatos.");
        break;
      case "convites":
        setMensagem("Convites que você fez, aguardando aprovação.");
        break;
      case "solicitacoes":
        setMensagem("Solicitações de amizade recebidas.");
        break;
      default:
        setMensagem("Todos contatos.");
    }
  }

  const Result = () => (
    <Card className="pb-2">
      <CardGroup className="pl-3 pr-3">
        {contatos.map((atletaContato) =>
          id !== "solicitacoes" ? (
            <Col sm="12" md="3" className="p-1">
              <Card className="m-1 text-center h-100">
                <Row>
                  <Col xs="6" md="12" className="p-2">
                    <Link to={`/atleta/${atletaContato.idHashContatoEnviado}`} className="mr-3 ml-3 mt-0 mb-0">
                      {atletaContato.fotoContatoEnviado === null ? (
                        <Avatar name={atletaContato.nomeContatoEnviado} size="150" round={true} maxInitials={2} />
                      ) : (
                        <img
                          src={atletaContato.fotoContatoEnviado}
                          style={{ width: '150px', height: '150px', objectFit: 'cover', borderRadius: '50%' }}
                        />
                      )}
                    </Link>
                  </Col>
                  <Col xs="6" md="12">
                    <CardBody className="p-0">
                      <CardTitle className="text_big">{atletaContato.apelidoContatoEnviado}</CardTitle>
                      <CardSubtitle className="text_banco">{atletaContato.nomeContatoEnviado}</CardSubtitle>
                      <CardText className="text_banco">
                        {atletaContato.ufContatoEnviado ? atletaContato.cidadeContatoEnviado+" - "+atletaContato.ufContatoEnviado : atletaContato.cidadeContatoEnviado }
                      </CardText>
                    </CardBody>
                    <CardFooter className="p-1">
                      <Link to={`/atleta/${atletaContato.idHashContatoEnviado}`}>
                        <Badge color="primary" pill className="p-2">
                          Ver perfil
                        </Badge>
                      </Link>
                    </CardFooter>
                  </Col>
                </Row>
              </Card>
            </Col>
          ) : (
            <Col sm="12" md="3" className="p-1">
              <Card className="m-1 text-center h-100">
                <Row>
                  <Col xs="6" md="12" className="p-2">
                    <Link to={`/atleta/${atletaContato.idHashContatoRecebido}`} className="mr-3 ml-3 mt-0 mb-0">
                      {atletaContato.fotoContatoRecebido === null ? (
                        <Avatar name={atletaContato.nomeContatoRecebido} size="150" round={true} maxInitials={2} />
                      ) : (
                        <img
                          src={atletaContato.fotoContatoRecebido}
                          style={{ width: '150px', height: '150px', objectFit: 'cover', borderRadius: '50%' }}
                        />
                      )}
                    </Link>
                  </Col>
                  <Col xs="6" md="12">
                    <CardBody className="p-0">
                      <CardTitle tag="h3">
                        {atletaContato.apelidoContatoRecebido}
                      </CardTitle>
                      <CardSubtitle tag="h6">
                        {atletaContato.nomeContatoRecebido}
                      </CardSubtitle>
                      <CardText>
                        {atletaContato.ufContatoRecebido !== null ? (atletaContato.cidadeContatoRecebido+" - "+atletaContato.ufContatoRecebido) : atletaContato.cidadeContatoRecebido}
                      </CardText>
                    </CardBody>
                    <CardFooter className="p-1">
                      <Link to={`/atleta/${atletaContato.idHashContatoRecebido}`}>
                        <Badge color="primary" pill className="p-2">
                          Ver perfil
                        </Badge>
                      </Link>
                    </CardFooter>
                  </Col>
                </Row>
              </Card>
            </Col>
          )
        )}
      </CardGroup>
    </Card>
  );

  const NoResult = () => (
    <Card>
      <CardText className="text-center m-2">
        Que pena!<br />
        Não temos nenhum registro!<br />
        Aproveite agora e se contecte a outros atletas!
        <br />
        <Link to="/atletas" className="no-underline">
          <Button color="success"  block>
            Se conecte a um amigo!
          </Button>
        </Link>
      </CardText>
    </Card>
  );

  return (
    <Container>
      <Row>
        <Col sm="10" className="p-2 pt-2">
          <Card className="mt-2">
            <CardHeader className="text-center p-1">
              <Label className="text_big">Meus Contatos</Label>
            </CardHeader>
          </Card>
          <Card>
            <Row className="m-0">
              <Col xs="12" sm="4" className="text-center p-1">
                <Link to={"amigos"} className="no-underline text_medium">
                  <Alert
                    color={id === "amigos" ? "success" : "secondary"}
                    id="0"
                    className={"p-1"}
                  >
                    Amigos
                  </Alert>
                </Link>
              </Col>
              <Col xs="12" sm="4" className="text-center p-1">
                <Link to={"convites"} className="no-underline text_medium">
                  <Alert
                    color={id === "convites" ? "success" : "secondary"}
                    id="1"
                    className={"p-1"}
                  >
                    Convites enviados
                  </Alert>
                </Link>
              </Col>
              <Col xs="12" sm="4" className="text-center p-1">
                <Link to={"solicitacoes"} className="no-underline text_medium">
                  <Alert
                    color={id === "solicitacoes" ? "success" : "secondary"}
                    id="2"
                    className={"p-1"}
                  >
                    Solicitações de amizade
                  </Alert>
                </Link>
              </Col>
            </Row>
            <CardFooter className="text-center">
              <Label className="m-0 text_medium">{mensagem}</Label>
            </CardFooter>
          </Card>
          <div className="text-center">
            <ClipLoader color="gray" loading={loading} size={60} />
          </div>
          {showResult ? <Result /> : null}
          {showNoResult ? <NoResult /> : null}
        </Col>
        <Col sm="2" className="p-0 pt-2">
          <Patrocinio />
        </Col>
      </Row>
    </Container>
  );
};

export default ListaContatos;
