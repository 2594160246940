import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Alert,
  Col,
  Row,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Card,
  CardText,
  Container,
  CardTitle,
  CardSubtitle,
  CardFooter,
  Badge,
  UncontrolledAlert,
} from "reactstrap";
import "../../assets/css/minha_medalha.css";
import { upload } from "../../api/api_file_upload";
import { busca } from "../../api/api_adm_team";
import ClipLoader from "react-spinners/ClipLoader";
import { getToken, getUrlApi } from "../../api/auth";
import Avatar from 'react-avatar';


const Info = () => {
  const { id } = useParams();
  const [assessoria, setAssessoria] = useState([]);
  let [loading, setLoading] = useState(true);
  const [showResult, setShowResult] = useState(false);
  const [showNoResult, setShowNoResult] = useState(false);
  const [modalInclusao, setModalInclusao] = useState(false);
  const [modalExclusao, setModalExclusao] = useState(false);
  const [confirmadoOk, setConfirmadoOk] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);


  function IncluirLogo() {
    upload(

      "/admin/time/uploadLogo",
      selectedFile,
      setConfirmadoOk,
      confirmadoOk
    );
    AbrirEFecharModalTrocarLogo();
  }

  function fileSelectedHandler(event) {
    setSelectedFile(event.target.files[0])
  }

  const AbrirEFecharModalTrocarLogo = () => setModalInclusao(!modalInclusao);

  function ExcluirLogo() {
    const token = getToken();
    const requestInfo = {
      method: "GET",
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };
    fetch(getUrlApi() + "/admin/time/removerLogo/", requestInfo).then(
      (response) => {
        response.json().then((data) => {
          if (response.status === 200) {
            setMessageAPI(data.message);
            setResultColor("success");
          } else {
            if (response.status === 202) {
              setMessageAPI(data.message);
            } else {
              if (response.status === 400) {
                setMessageAPI("Erro Inesperado"); //data[0].message);
              }
            }
            setResultColor("warning");
          }
        });
      }
    );
    setConfirmadoOk(!confirmadoOk);
    setModalExclusao(!modalExclusao);
  }

  const CancelarExcluirLogo = () => setModalExclusao(!modalExclusao);

  function Associar(codigoTime) {
    const token = getToken();
    const requestInfo = {
      method: "GET",
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };

    fetch(
      getUrlApi() + "/admin/time/solicitarTime/" + codigoTime,
      requestInfo
    ).then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          setMessageAPI(data.message);
          setResultColor("success");
        } else {
          if (response.status === 202) {
            setMessageAPI(data.message);
          } else {
            if (response.status === 400) {
              setMessageAPI("Erro Inesperado"); //data[0].message);
            }
          }
          setResultColor("warning");
        }
      });
    });
  }
  const [messageAPI, setMessageAPI] = useState("");
  const [resultColor, setResultColor] = useState("");

  useEffect(() => {
    busca(
      `/dashboard`,
      setAssessoria,
      setLoading,
      setShowResult,
      setShowNoResult
    );
  }, [id]);

  const Result = () => (
    <>
      <Card>
        <Row>
          <Col xs="11" sm="4" className="mt-3 m-0 p-0">
            <CardText className="p-4 m-0 text-center">
              {assessoria.logo === null ? (
                <Avatar name={assessoria.nome} size="150" round={true} maxInitials={2} />
              ) : (
                <img
                  src={assessoria.logo}
                  style={{ width: '150px', height: '150px', objectFit: 'cover', borderRadius: '50%' }}
                />
              )}
            </CardText>
            <CardText className="text-center p-0">
              <Button color="primary" className="p-1 mb-2" onClick={AbrirEFecharModalTrocarLogo}>
                Trocar logo
              </Button>&nbsp;&nbsp;
              <Button color="danger" className="p-1 mb-2" onClick={ExcluirLogo}>
                Remover logo
              </Button>
            </CardText>
          </Col>
          <Col xs="12" sm="7" className="m-0 mt-3 p-2 pr-0 ">
            <Card>
              <CardTitle tag="h4" className="text-center">
                {assessoria.sigla} - {assessoria.nome}
              </CardTitle>
              <CardSubtitle className="text-center mb-2">
                Cidade: {assessoria.nomeCidade ? (assessoria.nomeCidade + " - " + assessoria.uf) : "Não informado"}
              </CardSubtitle>
              <CardText className="text-center mb-1">
                E-mail: {assessoria.email ? assessoria.email : "Não informado"}
              </CardText>
              <CardText className="text-center mb-1">
                Fone: {assessoria.fone ? assessoria.fone : "Não informado"}
              </CardText>
              <CardText className="text-center mb-1">
                Website: {assessoria.site ? assessoria.site : "Não informado"}
              </CardText>
              <CardText className="text-center mb-1">
                Desde: {assessoria.dataFundacao ? assessoria.dataFundacao : "Não informado"}
              </CardText>
            </Card>
            <Card className="mt-3 p-1 text-justify">
              Informações da assessoria: <br />
              {assessoria.resumo ? assessoria.resumo : (<Alert color="secondary">Nenhuma informação da sua assessoria.</Alert>)}
            </Card>
          </Col>
        </Row>
        {messageAPI ? (
          <UncontrolledAlert color={resultColor} className="m-2 text-center">
            {messageAPI}
          </UncontrolledAlert>
        ) : null}
        <CardFooter className="text-end">
          <Link to={"/adminTime/editar"}>
            <Badge color="primary p-2" block>
              Alterar dados
            </Badge>
          </Link>
        </CardFooter>
      </Card>
      <Card className="mt-1 p-1">
        <CardText className="text-center m-2 p-1">
          <Button color="primary" outline className="p-1 mb-2" >
            Calendário de corridas
          </Button>&nbsp;&nbsp;&nbsp;&nbsp;
          <Button color="primary" outline className="p-1 mb-2" >
            Histórico de corridas
          </Button>&nbsp;&nbsp;&nbsp;&nbsp;
          <Link to="/adminTime/atletas/associados">
            <Button color="primary" outline className="p-1 mb-2" >
              Lista de Atletas
            </Button>
          </Link>
        </CardText>
      </Card>
    </>
  );
  const NoResult = () => (
    <CardText className="p-3">
      Algo deu errado! Volte e selecione novamente uma assessoria!
    </CardText>
  );

  return (
    <Container className="p-1">
      <div className="text-center">
        <ClipLoader color="gray" loading={loading} size={60} />
      </div>
      {showResult ? <Result /> : null}
      {showNoResult ? <NoResult /> : null}
      <div>
        <Modal
          isOpen={modalInclusao}
          toggle={AbrirEFecharModalTrocarLogo}
          className="p-0"
        >
          <ModalHeader toggle={AbrirEFecharModalTrocarLogo}>
            Selecione uma Logo!
          </ModalHeader>
          <ModalBody>
            <Card className="p-0 text-center m-0">
              <input type="file" name="file" id="file" onChange={(event) => {
                fileSelectedHandler(event);
              }}
              />
            </Card>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={IncluirLogo} >
              Confirmar
            </Button>{" "}
            <Button color="secondary" onClick={AbrirEFecharModalTrocarLogo}>
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>
      </div>
      <div>
        <Modal
          isOpen={modalExclusao}
          toggle={CancelarExcluirLogo}
          className="p-0"
        >
          <ModalHeader toggle={CancelarExcluirLogo}>
            Remover a Logo do perfil!
          </ModalHeader>
          <ModalBody className="pt-5 pb-5 text-center">
            Confirma a exclusão da sua Logo do perfil!!
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={ExcluirLogo}>
              Confirmar
            </Button>{" "}
            <Button color="secondary" onClick={CancelarExcluirLogo}>
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </Container>
  );
};

export default Info;
