import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { busca } from "../../api/api_adm_team";
import ClipLoader from "react-spinners/ClipLoader";
import { getToken, getUrlApi } from "../../api/auth";

function EditarPerfil () {
  const [assessoria, setAssessoria] = useState([]);
  let [loading, setLoading] = useState(true);
  const [showResult, setShowResult] = useState(false);
  const [showNoResult, setShowNoResult] = useState(false);
 
  const [modalCidade, setModalCidade] = useState(false);
  const Buscar = () => setModalCidade(!modalCidade);
  const [searchCity, setSearchCity] = useState([]);
  const [hoje, setHoje] = useState(Intl.DateTimeFormat("pt-BR", { timeZone: "UTC" }).format(new Date()));
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [sigla, setSigla] = useState("");
  const [dataFundacao, setDataFundacao] = useState("");
  const [fone, setFone] = useState("");
  const [idCidade, setIdCidade] = useState("");
  const [cidade, setCidade] = useState("");
  const [resumo, setResumo] = useState("");
  const [messageAPI, setMessageAPI] = useState("");
  const [resultColor, setResultColor] = useState("");
  const [website, setWebsite] = useState("");
  const [block, setBlock] = useState(true);
  
  function Selecionar(eventoCidade) {
    setCidade(`${eventoCidade.nome} - ${eventoCidade.uf}`)
    setIdCidade(eventoCidade.id)
    setModalCidade(!modalCidade);
  }
  
  const Confirmar = () => {
    envia();
  };
 
  useEffect(() => {
    busca("/assessoria", setAssessoria, setLoading, setShowResult,setShowNoResult );

  }, []); 
  
  if (assessoria.id !== undefined && block){
    const input = assessoria.dataFundacao
    const [dia, mes, ano] =  input.split('/')
    setDataFundacao(`${ano}-${mes}-${dia}`);
    setNome(assessoria.nome);
    setCnpj(assessoria.cnpj);
    setEmail(assessoria.email);
    setSigla(assessoria.sigla);
    setFone(assessoria.fone);
    setWebsite(assessoria.site);
    setResumo(assessoria.resumo);
    if (assessoria.nomeCidade === null){
      setCidade("");  
    }else{
      setCidade(assessoria.nomeCidade + " - " + assessoria.uf)
    }
    setBlock(false);
    const [diaH, mesH, anoH] =  hoje.split('/')
    setHoje(`${anoH}-${mesH}-${diaH}`);
  
  }  

  const [cidades, setCidades] = useState([]);

  function buscaCidade(nomeCidade) {
    const token = getToken();
    const requestCity = {
      method: "POST",
      body: JSON.stringify({
        nome: `${nomeCidade}`,
      }),
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };
    fetch(
      getUrlApi()+"/cidade/buscaCidade/",
      requestCity
    ).then((response) => {
      response.json().then((data) => {
        setCidades(data)
      });
    })
  }

  function envia() {
    const token = getToken();
    let inputCreate = dataFundacao
    const [ano1, mes1, dia1 ] =  inputCreate.split('-')
    inputCreate = `${dia1}/${mes1}/${ano1}`;
    const requestInfo = {
      method: "POST",
      body: JSON.stringify({
        nome: `${nome}`,
        email: `${email}`,
        sigla: `${sigla}`,
        dataFundacao: `${inputCreate}`,
        cidade: idCidade,
        fone: `${fone}`,
        site: `${website}`,
        resumo: `${resumo}`
      }),
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };
    fetch(
      getUrlApi()+"/admin/time/atualizaDados",
      requestInfo
    ).then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          setMessageAPI(data.message);
          setResultColor("success");
        } else {
          if (response.status === 202) {
            setMessageAPI(data.message);
          } else {
            if (response.status === 400) {
              setMessageAPI(data[0].message);
            }
          }
          setResultColor("warning");
        }
      });
    })
  }

    return(
      <Card>
      <div className="text-center">
        <ClipLoader color="gray" loading={loading} size={60} />
      </div>
      <Container className="p-0 pt-2">
      <Row>
        <Col sm="3"></Col>
        <Col sm="6">
          <Card className="p-2">
            <Form  >
            <CardTitle className="text-center mt-2 pt-2" tag="h4">Alteração de dados da Assessoria</CardTitle>
            <CardBody>
              <CardText>Razão Social<br /> {assessoria.razaoSocial}
              <Input type="hidden" /></CardText>
              <Row className="pb-3">
                <Col sm="8">
                  <CardText>Responsável<br /> {assessoria.email}</CardText>
                </Col>
                <Col sm="4">
                  <CardText>CNPJ<br /> {assessoria.cnpj}</CardText>
                </Col>
              </Row>
              <FormGroup>
                <Label>Nome Fantasia</Label>
                <Input
                  type="text"
                  name="nome"
                  minLength="5"
                  maxLength="100"
                  id="nome"
                  value={nome}
                  onChange={(event) => {
                    setNome(event.target.value);
                  }}
                  placeholder="informe o nome fantasia da assessoria"
                />
                <FormFeedback invalid>
                  Nome fantasia incorreto
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label>Sigla ou apelido da assessoria</Label>
                <Input
                  type="text"
                  name="sigla"
                  minLength="2"
                  maxLength="20"
                  id="sigla"
                  value={sigla}
                  onChange={(event) => {
                    setSigla(event.target.value);
                  }}
                  placeholder="informe seu apelido ou a sigla da assessoria"
                />
                <FormFeedback invalid>
                  Apelido incorreto
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label>Data fundação</Label>
                <Input
                  type="date"
                  name="dataFundacao"
                  minLength="5"
                  maxLength="8"
                  id="dataFundacao"
                  max={hoje}
                  value={dataFundacao}
                  pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                  onChange={(event) => {
                    setDataFundacao(event.target.value);
                  }}
                  required
                />
                <FormFeedback invalid>
                 data invalida
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label>Telefone</Label>
                <Input
                  type="text"
                  name="fone"
                  minLength="5"
                  maxLength="15"
                  id="fone"
                  value={fone}
                  onChange={(event) => {
                    setFone(phoneMask(event.target.value));
                  }}
                  placeholder="informe o telefone de contato da assessoria"
                />
                <FormFeedback invalid>
                  E-mail inválido! favor informe um email correto
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label>Cidade Sede da Assessoria</Label>
                <Input
                  type="text"
                  name="cidade"
                  minLength="5"
                  id="cidade"
                  value={cidade}
                  placeholder="Click para selecionar a cidade"
                  onClick={Buscar}
                />
                <Input type="hidden" name="idCidade"  value={idCidade}/>
                <FormFeedback invalid>
                  E-mail inválido! favor informe um email correto
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label>Website</Label>
                <Input
                  type="text"
                  name="website"
                  minLength="2"
                  maxLength="20"
                  id="website"
                  value={website}
                  onChange={(event) => {
                    setWebsite(event.target.value);
                  }}
                  placeholder="informe o site da assessoria"
                />
                <FormFeedback invalid>
                  website informado incorreto
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label>Email de contato</Label>
                <Input
                  type="text"
                  name="email"
                  minLength="2"
                  maxLength="200"
                  id="email"
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                  placeholder="informe o email de contato da assessoria"
                />
                <FormFeedback invalid>
                  email informado incorreto
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label>Informe dados sobre sua Assessoria (max 250 caracteres)</Label>
                <Input
                  type="textarea"
                  name="resumo"
                  id="resumo"
                  value={resumo}
                  onChange={(event) => {
                    setResumo(event.target.value);
                  }}
                  placeholder="..."
                />
                <FormFeedback invalid>
                  E-mail inválido! favor informe um email correto
                </FormFeedback>
              </FormGroup>
              {messageAPI ? <Alert color={resultColor}>{messageAPI}</Alert> : null}
              <CardText className="text-end">
              <Button color="primary" outline  onClick={Confirmar}>Salvar</Button>
              <Link to="/adminTime"><Button color="secondary" className="ml-4" outline>Voltar</Button></Link>
              
                </CardText>            

            </CardBody>
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>

      <div>
      <Modal isOpen={modalCidade}  className="p-0">
      <ModalHeader >Selecione a cidade!</ModalHeader>
      <ModalBody className="pt-1 pb-3 text-left">
        <Form>
          <CardText className="pb-2">
            <small>Cidade:*</small>
            <Input
                onChange={(event) => {
                setSearchCity(event.target.value);
                buscaCidade(event.target.value);
              }}
              type="text"
              value={searchCity}
              name="searchCity"
              id="searchCity"
              placeholder="Informe o nome da cidade"
              minLength="3"
              maxlength="80"
              required
            />
          </CardText>
        </Form>
      {cidades.map((cidade) => (
        <Card onClick={() => {
          Selecionar(cidade);
        }}  id={cidade.id}>
          <CardText className="m-1">
             {cidade.nome} - {cidade.uf}
          </CardText>
        </Card>
      ))}

        </ModalBody>
      <ModalFooter>
        <Button color="primary" >
          Confirmar
        </Button>
        <Button color="secondary" onClick={Buscar}>
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
      </div>
    </Card>
    )

};


function phoneMask(v) {

  let r = v.replace(/\D/g, "");
  r = r.replace(/^0/, "");
  if (r.length > 11) {
    r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
  } else if (r.length > 7) {
    r = r.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, "($1) $2-$3");
  } else if (r.length > 2) {
    r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
  } else if (v.trim() !== "") {
    r = r.replace(/^(\d*)/, "($1");
  }
  return r;
}

export default EditarPerfil;
