import React, { useEffect, useState } from "react";
import { busca } from "../../api/api_athlete";
import logo from "../../assets/img/no-photo.png";
import { useParams, Link } from "react-router-dom";
import {
  CardBody,
  CardImg,
  CardText,
  Card,
  CardHeader,
  Row,
  Col,
  Badge,
  Button,
  CardGroup,
  Container,
  Label,
  CardTitle,
  CardSubtitle,
} from "reactstrap";
import ClipLoader from "react-spinners/ClipLoader";
import Patrocinio from "../Home/Patrocinio";
import Avatar from "react-avatar";

const TodosEventosRealizados = () => {
  const { id } = useParams();
  const [todosEventos, setTodosEventos] = useState([]);
  const [atletaPerfil, setAtletaPerfil] = useState("");
  let [loading, setLoading] = useState(true);
  const [showResult, setShowResult] = useState(false);
  const [showNoResult, setShowNoResult] = useState(false);

  useEffect(() => {
    busca("quadroMedalhaAtleta/" + id, setTodosEventos, setLoading, setShowResult, setShowNoResult);

    busca(
      `/perfil/${id}`,
      setAtletaPerfil,
      setLoading,
      setShowResult,
      setShowNoResult
    );
  }, [id]);

  const Result = () => (
    <>
      <Card>
        <Row>
          <Col xs="12" sm="4" >
            <CardText className="p-3 m-0 text-center">
              {atletaPerfil.foto === null ? (
                <Avatar name={atletaPerfil.nome} size="100" round={true} maxInitials={2} />
              ) : (
                <img
                  src={atletaPerfil.foto}
                  style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '50%' }}
                />
              )}
            </CardText>
          </Col>
          <Col xs="12" sm="7" className="m-0 mt-3 p-3 pr-0 ">
            <Card>
              <CardTitle tag="h4" className="text_big_center">
                {atletaPerfil.nome}
              </CardTitle>
              <CardSubtitle tag="h3" className="text_medium_center mb-3">
                {atletaPerfil.apelido}
              </CardSubtitle>
            </Card>
          </Col>
        </Row>
      </Card>
      <CardHeader className="text-left p-1">
        <Row>
          <Col xs="9" sm="8" className="m-0 pt-1 " tag="h6">
            &nbsp;
          </Col>
          <Col xs="3" sm="4" className="m-0 text-end">
            {showResult ? (
              <Link
                to={`/atleta/` + id}
                className="mr-3 ml-3 mt-0 mb-0"
              >
                <Badge color="secondary" className="p-2" pill>
                  Voltar para o Perfil do Atleta
                </Badge>
              </Link>
            ) : null}
          </Col>
        </Row>
      </CardHeader>{" "}

      <CardGroup className="pl-3 pr-3">
        {todosEventos.map((registro) => (
          <Col sm="12" md="3" className="p-1">
            <Card className="m-1 text-center h-100">
              <Row>
                <Col xs="6" md="12">
                  <CardText className="p-1">
                    <Link to={"/corridas/" + registro.idEvento}>
                      <CardImg
                        top
                        alt="Imagem do evento"
                        src={registro.fotoMedalha ? registro.fotoMedalha : (registro.logoEvento ? registro.logoEvento : logo)}
                        className="img-fluid rounded-lg m-0 p-0 "
                      />
                    </Link>
                  </CardText>
                </Col>
                <Col xs="6" md="12">
                  <CardBody className="p-0">
                    <Label className="m-0 text_medium_center">{registro.nomeEvento}</Label><br />
                    <Label className="m-0 text_banco"> {registro.nomeCidadeEvento} - {registro.ufEstadoEvento}</Label><br />
                    <Label className="m-0 text_banco">Data: {registro.dataEvento}</Label><br />
                    <Label className="m-0 text_banco">Distância: {registro.modalidade}</Label> <br />
                    <Label className="m-0 text_banco">Tempo/Pace: {registro.tempoProva} / {registro.pace}</Label>
                  </CardBody>
                </Col>
              </Row>
            </Card>
          </Col>
        ))}
      </CardGroup>
    </>
  );

  const NoResult = () => (
    <CardText className="text-center m-2">
      Que pena!
      <br />Não temos nenhum registro das suas corridas! Aproveite agora
      mesmo e cadastre sua corrida!
      <br />
      <Link to="/novoEvento">
        <Button color="success" className="mr-2" block>
          Cadastre uma corrida!
        </Button>
      </Link>
    </CardText>
  );

  return (
    <Container>
      <Row>
        <Col sm="10" className="p-2 pt-2">
          <Card className="mt-2">
            <CardHeader className=" p-1 text_big text-center" >
              Quadro de Medalhas
            </CardHeader>
          </Card>
          <Card className="p-0 m-0 mt-2 text-center">
            <div className="text-center">
              <ClipLoader color="gray" loading={loading} size={60} />
            </div>
            {showResult ? <Result /> : null}
            {showNoResult ? <NoResult /> : null}
          </Card>
        </Col>
        <Col sm="2" className="p-0 pt-2">
          <Patrocinio />
        </Col>
      </Row>
    </Container>
  )

};

export default TodosEventosRealizados;
