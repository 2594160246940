import React  from "react";
import Login from "./login";

function Logout() {
   localStorage.clear();
   localStorage.setItem('url-api', "https://api.minhasmedalhas.com.br/api/v1");
   localStorage.setItem('url-auth', "https://api.minhasmedalhas.com.br/auth");
   //localStorage.setItem('url-api', "http://localhost:8080/api/v1");
   //localStorage.setItem('url-auth', "http://localhost:8080/auth");
    return (
        <Login />
    );
}
export default Logout ; 