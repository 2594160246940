import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Alert,
  Button,
  CardImg,
  Col,
  Container,
  Form,
  Label,
  Row,
} from "reactstrap";
import logo from "../../assets/img/logo_minhas_medalhas_lg.svg";
import img_login from "../../assets/img/img_signin_finish.svg";
import { getUrlApi } from "../../api/auth";
import { Link } from "react-router-dom/cjs/react-router-dom";

function CadastroFinalizado() {
  const { idHash } = useParams();
  const [messageAPI, setMessageAPI] = useState("");
  const [resultColor, setResultColor] = useState("");

  function envia() {
    const requestInfo = {
      method: "POST",
      body: JSON.stringify({
        idHash: `${idHash}`

      }),
      headers: new Headers({
        "Content-type": "application/json",
      }),
    };

    fetch(
      getUrlApi() + "/novoAtleta/enviaNovamente/",
      requestInfo
    ).then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          setMessageAPI("Enviamos o e-mail novamente! Esperamos que agora funcione!");
          setResultColor("success");
        } else {
          if (response.status === 202) {
            setMessageAPI(data.message);
          } else {
            if (response.status === 400) {
              setMessageAPI(data.message);
            }
          }
          setResultColor("warning");
        }
      });
    });
  }

  return (
    <Container className="text-center">
      <Row>
        <Col sm="3">&nbsp;</Col>
        <Col sm="6">
          <Link to="/login"><CardImg src={logo} alt="Minhas Medalhas" /></Link>
          <Label className="text_big">
            <center>Tudo certo!!!</center>
          </Label>
        </Col>
        <Col sm="3">&nbsp;</Col>
        <Col sm="3">&nbsp;</Col>
        <Col sm="6">
          <Label className="text_small">
            <center>Enviamos uma mensagem para o email informado. <br/>Agora é só confirmar seu cadastro e entrar na nossa comunidade.</center>
          </Label>
          {messageAPI ? (<><br /><Alert color={resultColor} className="text_small">{messageAPI}</Alert></>) : null}
          <CardImg src={img_login} />
          <Form
                method="Post"
                onSubmit={(event) => {
                  event.preventDefault();
                  envia();
                }}
              >
          <Label className="text_small">
            <center> Caso você não tenha recebido o email! click neste botão <Button color="primary" className="text_small" outline type="submit" >Enviar novamente!</Button></center>
          </Label>
          </Form>
        </Col>
        <Col sm="3">&nbsp;</Col>
      </Row>
    </Container>
  );
}

export default CadastroFinalizado;
