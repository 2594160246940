import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Badge,
  Card,
  CardFooter,
  CardHeader,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Container,
  Label,
  FormGroup,
  Input,
  Row,
  CardGroup,
  CardBody,
  Form,
  CardSubtitle,
  UncontrolledAlert,
} from "reactstrap";
import foto from "../../assets/img/no-athlete.png";
import { busca } from "../../api/api_adm_team";
import ClipLoader from "react-spinners/ClipLoader";
import { getToken, getUrlApi } from "../../api/auth";
import Avatar from "react-avatar";

const NovosAtletas = ({ url }) => {
  const [atletas, setAtletas] = useState([]);
  let [loading, setLoading] = useState(true);
  const [showResult, setShowResult] = useState(false);
  const [showNoResult, setShowNoResult] = useState(false);

  const [messageAPI, setMessageAPI] = useState("");
  const [resultColor, setResultColor] = useState("");
  const [atletaSemAssessoria, setAtletaSemAssessoria] = useState(false);

  const [searchAtleta, setSearchAtleta] = useState([]);
  const [searchSemAssessoria, setSearchSemAssessoria] = useState(false);

  function buscaAtleta(nameAtleta) {
    const token = getToken();
    const requestAtleta = {
      method: "POST",
      body: JSON.stringify({
        nome: `${nameAtleta}`,
        semAssessoria: `${searchSemAssessoria}`
      }),
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };
    fetch(
      getUrlApi() + "/admin/time/buscaAtleta/",
      requestAtleta
    ).then((response) => {
      response.json().then((data) => {
        setAtletas(data);
      });
    });
  }

  function chaveadorAtletaSemAssessoria(event) {
    if (atletaSemAssessoria === "on") {
      setAtletaSemAssessoria(null);
      setSearchSemAssessoria(true);
    } else {
      setAtletaSemAssessoria(event);
      setSearchSemAssessoria(false);
    }
    buscaAtleta(searchAtleta);
  }

  function ConvidarAtleta(idAtleta) {
    const token = getToken();
    const requestInfo = {
      method: "GET",
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };
    fetch(
      getUrlApi() + "/admin/time/convidarAtleta/" + idAtleta,
      requestInfo
    ).then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          setMessageAPI(data.message);
          setResultColor("success");
        } else {
          if (response.status === 202) {
            setMessageAPI(data.message);
          } else {
            if (response.status === 400) {
              setMessageAPI("Erro Inesperado"); //data[0].message);
            }
          }
          setResultColor("warning");
        }
      });
    });
  }

  useEffect(() => {
    busca("/listaAtletas", setAtletas, setLoading, setShowResult, setShowNoResult);
  }, [url]);

  return (
    <Container>
      <Row>
        <Col className="p-0 pt-2 pr-2">
          <Card className="mt-2">
            <CardHeader className="text-center p-1 m-0" tag="h3">
              Atletas
            </CardHeader>
            <CardText className="text-center">
              <Form>
                <Row>
                  <Col sm="8">
                    <FormGroup className="p-2 m-0">
                      <Input
                        type="text"
                        name="atleta"
                        id="atleta"
                        placeholder="Informe o nome de um(a) Atleta para filtrar"
                        onChange={(event) => {
                          setSearchAtleta(event.target.value);
                          buscaAtleta(event.target.value);
                        }}
                        value={searchAtleta}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="4">
                    <FormGroup className="p-0 pt-3 m-0">
                      <Label>
                        <Input
                          type="switch"
                          id="corridaVirtual"
                          name="corridaVirtual"
                          checked={atletaSemAssessoria}
                          onChange={(event) => {
                            chaveadorAtletaSemAssessoria(event.target.value);
                          }}
                          label="Somente sem assessoria"
                        />
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </CardText>
          </Card>
          <Card>
            <div className="text-center">
              <ClipLoader color="gray" loading={loading} size={60} />
            </div>
            {messageAPI ? (
              <UncontrolledAlert className="m-1 text-center" color={resultColor}>
                {messageAPI}
              </UncontrolledAlert>
            ) : null}
            <CardGroup className="mt-2">
              {atletas.map((atleta) => (
                <Col sm="12" md="3" className="p-1">
                  <Card className="m-1 text-center h-100">
                    <Row>
                      <Col xs="6" md="12">
                        <Link to={`/atleta/${atleta.idHash}`} className="mr-3 ml-3 mt-0 mb-0">
                          {atleta.foto === null ? (
                            <Avatar name={atleta.nome} size="150" round={true} maxInitials={2} />
                          ) : (
                            <img
                              src={atleta.foto}
                              style={{ width: '150px', height: '150px', objectFit: 'cover', borderRadius: '50%' }}
                            />
                          )}
                        </Link>
                      </Col>
                      <Col xs="6" md="12">
                        <CardBody className="p-0">
                          <CardTitle className="text_big">{atleta.apelido}</CardTitle>
                          <CardSubtitle className="text_banco">{atleta.nome}</CardSubtitle>
                          <CardText className="text_small">{atleta.nomeCidade} - {atleta.uf}</CardText>
                        </CardBody>
                        <CardFooter className="p-1">
                          {atleta.nomeTime !== null ? (
                            <Badge color="secondary" pill className="p-2">
                              {atleta.nomeTime}
                            </Badge>) : (
                            <Link >
                              <Badge color="primary" pill className="p-2" onClick={() => {
                                ConvidarAtleta(atleta.id);
                              }}>
                                Convidar para Assessoria
                              </Badge>
                            </Link>
                          )}
                        </CardFooter>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ))}
            </CardGroup>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default NovosAtletas;
