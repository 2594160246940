import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Col,
  Row,
  Card,
  CardText,
  Container,
  CardTitle,
  CardSubtitle,
  ModalHeader,
  Modal,
  ModalBody,
  ModalFooter,
  CardFooter,
  UncontrolledAlert,
  Label,
} from "reactstrap";
import "../../assets/css/minha_medalha.css";
import { busca } from "../../api/api_athlete";
import { getToken, getUrlApi, getHash } from "../../api/auth.js";
import ClipLoader from "react-spinners/ClipLoader";
import Avatar from "react-avatar";
import { Link } from "@material-ui/core";
import ProximosEventos from "./ProximosEventos.jsx";
import UltimosEventos from "./UltimosEventos.jsx";

const DetalheAtleta = () => {
  const { id } = useParams();
  const [atletaPerfil, setAtletaPerfil] = useState([]);
  let [loading, setLoading] = useState(true);
  let [loadingAcoes, setLoadingAcoes] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [showNoResult, setShowNoResult] = useState(false);
  const [atualizar, setAtualizar] = useState(false);

  useEffect(() => {
    busca(
      `/perfil/${id}`,
      setAtletaPerfil,
      setLoading,
      setShowResult,
      setShowNoResult
    );
  }, [atualizar, id]);

  const [modalDesfazerAmizade, setModalDesfazerAmizade] = useState(false);
  const [modalIgnorarAmizade, setModalIgnorarAmizade] = useState(false);
  const [messageAPI, setMessageAPI] = useState("");
  const [resultColor, setResultColor] = useState("");

  const Desfazer = () => setModalDesfazerAmizade(!modalDesfazerAmizade);
  const Ignorar = () => setModalIgnorarAmizade(!modalIgnorarAmizade);

  function SolicitarAmizade(codigoAtleta) {
    setMessageAPI(null);
    setLoadingAcoes(true);
    const token = getToken();
    const requestInfo = {
      method: "GET",
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };

    fetch(
      getUrlApi() + "/atleta/solicitarAmizade/" + codigoAtleta,
      requestInfo
    ).then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          setMessageAPI(data.message);
          setResultColor("success");
          setAtualizar(!atualizar);
        } else {
          if (response.status === 202) {
            setMessageAPI(data.message);
          } else {
            if (response.status === 400) {
              setMessageAPI("Erro Inesperado"); //data[0].message);
            }
          }
          setResultColor("warning");
        }
      });
    });
    setLoadingAcoes(false);
  }

  function Seguir(codigoAtleta) {
    setMessageAPI(null);
    setLoadingAcoes(true);
    const token = getToken();
    const requestInfo = {
      method: "GET",
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };

    fetch(
      getUrlApi() + "/atleta/seguir/" + codigoAtleta,
      requestInfo
    ).then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          setMessageAPI(data.message);
          setResultColor("success");
          setAtualizar(!atualizar);
        } else {
          if (response.status === 202) {
            setMessageAPI(data.message);
          } else {
            if (response.status === 400) {
              setMessageAPI("Erro Inesperado"); //data[0].message);
            }
          }
          setResultColor("warning");
        }
      });
    });
    setLoadingAcoes(false);
  }


  function PararDeSeguir(codigoAtleta) {
    setMessageAPI(null);
    setLoadingAcoes(true);
    const token = getToken();
    const requestInfo = {
      method: "GET",
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };

    fetch(
      getUrlApi() + "/atleta/pararDeSeguir/" + codigoAtleta,
      requestInfo
    ).then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          setMessageAPI(data.message);
          setResultColor("success");
          setAtualizar(!atualizar);
        } else {
          if (response.status === 202) {
            setMessageAPI(data.message);
          } else {
            if (response.status === 400) {
              setMessageAPI("Erro Inesperado"); //data[0].message);
            }
          }
          setResultColor("warning");
        }
      });
    });
    setLoadingAcoes(false);
  }


  function RetirarSolicitacao(codigoAtleta) {
    setMessageAPI(null);
    setLoadingAcoes(true);
    const token = getToken();
    const requestInfo = {
      method: "GET",
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };

    fetch(
      getUrlApi() + "/atleta/retirarSolicitacao/" + codigoAtleta,
      requestInfo
    ).then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          setMessageAPI(data.message);
          setResultColor("success");
          setAtualizar(!atualizar);
        } else {
          if (response.status === 202) {
            setMessageAPI(data.message);
          } else {
            if (response.status === 400) {
              setMessageAPI("Erro Inesperado"); //data[0].message);
            }
          }
          setResultColor("warning");
        }
      });
    });
    setLoadingAcoes(false);
  }

  function DesfazerAmizade(codigoAtleta) {
    setMessageAPI(null);
    setLoadingAcoes(true);
    const token = getToken();
    const requestInfo = {
      method: "GET",
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };

    fetch(
      getUrlApi() + "/atleta/desfazerAmizade/" + codigoAtleta,
      requestInfo
    ).then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          setMessageAPI(data.message);
          setResultColor("success");
          setModalDesfazerAmizade(!modalDesfazerAmizade)
          setAtualizar(!atualizar);
        } else {
          if (response.status === 202) {
            setMessageAPI(data.message);
          } else {
            if (response.status === 400) {
              setMessageAPI("Erro Inesperado"); //data[0].message);
            }
          }
          setResultColor("warning");
        }
      });
    });
    setLoadingAcoes(false);
  }

  function IgnorarAmizade(codigoAtleta) {
    setMessageAPI(null);
    setLoadingAcoes(true);
    const token = getToken();
    const requestInfo = {
      method: "GET",
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };

    fetch(
      getUrlApi() + "/atleta/ignorarAmizade/" + codigoAtleta,
      requestInfo
    ).then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          setMessageAPI(data.message);
          setResultColor("success");
          setAtualizar(!atualizar);
          Ignorar();
        } else {
          if (response.status === 202) {
            setMessageAPI(data.message);
          } else {
            if (response.status === 400) {
              setMessageAPI("Erro Inesperado"); //data[0].message);
            }
          }
          setResultColor("warning");
        }
      });
    });
    setLoadingAcoes(false);
  }


  function AceitarAmizade(codigoAtleta) {
    setMessageAPI(null);
    setLoadingAcoes(true);
    const token = getToken();
    const requestInfo = {
      method: "GET",
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };

    fetch(
      getUrlApi() + "/atleta/aceitarAmizade/" + codigoAtleta,
      requestInfo
    ).then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          setMessageAPI(data.message);
          setResultColor("success");
          setAtualizar(!atualizar);
        } else {
          if (response.status === 202) {
            setMessageAPI(data.message);
          } else {
            if (response.status === 400) {
              setMessageAPI("Erro Inesperado"); //data[0].message);
            }
          }
          setResultColor("warning");
        }
      });
    });
    setLoadingAcoes(false);
  }


  const Result = () => (
    <>
      <Card>
        <Row>
          <Col xs="12" sm="4" >
            <CardText className="p-3 m-0 text-center">
              {atletaPerfil.foto === null ? (
                <Avatar name={atletaPerfil.nome} size="250" round={true} maxInitials={2} />
              ) : (
                <img
                  src={atletaPerfil.foto}
                  style={{ width: '250px', height: '250px', objectFit: 'cover', borderRadius: '50%' }}
                />
              )}
            </CardText>
          </Col>
          <Col xs="12" sm="7" className="m-0 mt-3 p-3 pr-0 ">
            <Card>
              <CardTitle tag="h4" className="text_big_center">
                {atletaPerfil.nome}
              </CardTitle>
              <CardSubtitle tag="h3" className="text_medium_center mb-3">
                {atletaPerfil.apelido}
              </CardSubtitle>
              <Row xs="3">
                <Col sm="5" xs="5" className="text-end text_form">
                  Aniversário
                </Col>
                <Col sm="7" xs="7" className="pl-0 text_banco">
                  {atletaPerfil.diaMesNascimento}
                </Col>
                <Col sm="5" xs="5" className="text-end text_form">
                  Localidade
                </Col>
                <Col sm="7" xs="7" className="pl-0 text_banco">
                  {atletaPerfil.uf ? atletaPerfil.nomeCidade + " - " + atletaPerfil.uf
                    : atletaPerfil.nomeCidade}
                </Col>
                {atletaPerfil.sexo ? (
                  <>
                    <Col sm="5" xs="5" className="text-end text_form">
                      Sexo
                    </Col>
                    <Col sm="7" xs="7" className="pl-0 text_banco">
                      {atletaPerfil.sexo === "Nao_informado" ? "Não informado" : atletaPerfil.sexo}
                    </Col>
                  </>
                ) : null}
                {atletaPerfil.numeroTenis ? (
                  <>
                    <Col sm="5" xs="5" className="text-end text_form">
                      Calçado
                    </Col>
                    <Col sm="7" xs="7" className="pl-0 text_banco">
                      {atletaPerfil.numeroTenis}
                    </Col>
                  </>
                ) : null}
                {atletaPerfil.tamanhoCamiseta ? (
                  <>
                    <Col sm="5" xs="5" className="text-end text_form">
                      Tamanho da camiseta
                    </Col>
                    <Col sm="7" xs="7" className="pl-0 text_banco">
                      {atletaPerfil.tamanhoCamiseta}
                    </Col>
                  </>
                ) : null}
              </Row>
            </Card>
            <Card className="mt-3 p-1 text-justify text_form">
              Informações do(a) Atleta: <br />
              <Label className="text_banco">{atletaPerfil.resumo ? atletaPerfil.resumo : "Não há dados para mostrar!"}</Label>
            </Card>
            {atletaPerfil.nomeTime ? (
              <Card className="mt-3 p-1 text-justify">
                <Row>
                  <Col xs="4" sm="3">
                    <CardText className="p-2 m-0">
                      <Link to={`/assessoria/${atletaPerfil.idTime}`}>
                        {atletaPerfil.logoTime === null ? (
                          <Avatar name={atletaPerfil.nomeTime} size="70" round={true} maxInitials={2} />
                        ) : (
                          <Avatar src={atletaPerfil.logoTime} size="70" round={true} />
                        )}
                      </Link>
                    </CardText>
                  </Col>
                  <Col xs="8" sm="9" className="text-center pl-0">
                    <CardText className="text_form">
                      {atletaPerfil.nome} faz parte do time {atletaPerfil.nomeTime}
                    </CardText>
                    <CardText className="text_banco">{atletaPerfil.emailTime}</CardText>
                    <CardText className="p-0 m-0 text_banco">
                      <svg
                        width="1em"
                        height="1em"
                        viewBox="0 0 16 16"
                        class="bi bi-telephone-plus"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM12.5 1a.5.5 0 0 1 .5.5V3h1.5a.5.5 0 0 1 0 1H13v1.5a.5.5 0 0 1-1 0V4h-1.5a.5.5 0 0 1 0-1H12V1.5a.5.5 0 0 1 .5-.5z"
                        />
                      </svg>
                      {atletaPerfil.contatoTime}
                    </CardText>
                  </Col>
                </Row>
              </Card>
            ) : null}
          </Col>
        </Row>
        <div className="text-center">
          <ClipLoader color="gray" loading={loadingAcoes} size={60} />
        </div>
        {messageAPI ? (
          <UncontrolledAlert color={resultColor} className="m-2 text-center text_alert">
            {messageAPI}
          </UncontrolledAlert>
        ) : null}
        <CardFooter className="text-end">
          {getHash() === atletaPerfil.idHash ? (
            <Label className="text-end text_medium">Este é o seu Perfil!</Label>
          ) : (
            atletaPerfil.convite === "received" ? (
              <>
                {atletaPerfil.statusContato !== "Ignorado" ? (
                  <Button color="secondary" className="p-1 m-1" onClick={Ignorar}>
                    Ignorar amizade
                  </Button>
                ) : <Button color="secondary" disabled className="p-1">Atleta Ignorado</Button>}
                <Button color="success" className="p-1 m-1" onClick={() => {
                  AceitarAmizade(atletaPerfil.id);
                }}>Aceitar amizade</Button>
              </>
            ) : (
              <>
                {atletaPerfil.statusContato === "Inativo" && (
                  <Button
                    color="primary"
                    className="p-1 "
                    onClick={() => {
                      SolicitarAmizade(atletaPerfil.id);
                    }}
                  >
                    Solicitar amizade
                  </Button>
                )}
                {atletaPerfil.statusContato === "Ativo" && (
                  <Button color="danger" className="p-1" onClick={Desfazer}>
                    Deixar a amizade
                  </Button>
                )}
                {atletaPerfil.statusContato === "Pendente" && (
                  <Button color="danger" className="p-1 m-1" onClick={() => RetirarSolicitacao(atletaPerfil.id)}>
                    Retirar solicitação
                  </Button>
                )}
                {atletaPerfil.statusContato !== "Inativo" && (
                  atletaPerfil.seguindo ? (
                    <Button color="info" className="p-1 m-1" onClick={() => PararDeSeguir(atletaPerfil.id)}>
                      Parar de seguir
                    </Button>
                  ) : (
                    <Button color="info" className="p-1 m-1" onClick={() => Seguir(atletaPerfil.id)}>
                      Seguir
                    </Button>
                  )
                )}
              </>
            ))}
        </CardFooter>
      </Card>
      {atletaPerfil.proximasCorridas ? <ProximosEventos /> : null }
      {atletaPerfil.historicoCorridas ? <UltimosEventos /> : null }
    </>
  );

  const NoResult = () => (
    <CardText className="p-3 text_alert">Opss!!! Ocorreu algum problema</CardText>
  );

  return (
    <Container className="p-1">
      <div className="text-center">
        <ClipLoader color="gray" loading={loading} size={60} />
      </div>
      {showResult ? <Result /> : null}
      {showNoResult ? <NoResult /> : null}
      <div>
        <Modal isOpen={modalDesfazerAmizade} toggle={Desfazer} className="p-0">
          <ModalHeader toggle={Desfazer}>Desfazer a amizade!</ModalHeader>
          <ModalBody>
            Você está prestes a desfazer uma amizade. Se tiver certeza, clique
            em confirmar!
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                DesfazerAmizade(atletaPerfil.id);
              }}
            >
              Confirmar
            </Button>{" "}
            <Button color="secondary" onClick={Desfazer}>
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>
      </div>
      <div>
        <Modal isOpen={modalIgnorarAmizade} toggle={Ignorar} className="p-0">
          <ModalHeader toggle={Ignorar}>Ignorar a solicitação de amizade!</ModalHeader>
          <ModalBody>
            Você está prestes a ignorar a solicitação de amizade. Se tiver certeza, clique
            em confirmar!
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                IgnorarAmizade(atletaPerfil.id);
              }}
            >
              Confirmar
            </Button>{" "}
            <Button color="secondary" onClick={Ignorar}>
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </Container>
  );
};

export default DetalheAtleta;
