import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardGroup,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Row,
  Label,
} from "reactstrap";
import { busca } from "../../api/api_event";
import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";

import ClipLoader from "react-spinners/ClipLoader";
import { upload } from "../../api/api_file_upload";
import { FaUser, FaMedal } from 'react-icons/fa';

const Fotos = () => {
  const { id } = useParams();
  const [atletaFotos, setAtletaFotos] = useState([]);
  let [loading, setLoading] = useState(true);
  const [showResult, setShowResult] = useState(false);
  const [showNoResult, setShowNoResult] = useState(false);
  const [modalInclusao, setModalInclusao] = useState(false);
  const [confirmadoOk, setConfirmadoOk] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const [idImagem, setIdImagem] = useState(null);
  const [urlImagem, setUrlImagem] = useState(null);
  const [campos, setCampos] = useState({});

  const fileSelectedHandler = (e) => {
    const file = e.target.files[0];

    if (file) {
      // Verifica se o arquivo é uma imagem
      if (file.type.startsWith('image/')) {
        resizeImage(file, 800, 600, function(resizedBlob) {
          const resizedFile = new File([resizedBlob], file.name, {
            type: file.type,
            lastModified: Date.now()
          });
          setSelectedFile(resizedFile);
        });

      } else {
        alert('Por favor, escolha um arquivo de imagem.');
      }
    }
  }

  function IncluirFoto() {
    upload(
      "/evento/uploadFotos/" + id + "/" + idImagem,
      selectedFile,
      setConfirmadoOk,
      confirmadoOk
    );
    setModalInclusao(!modalInclusao);
    setLoading(true);
  }

  function ExcluirFoto() {
    upload(
      "/evento/removerFoto/" + id + "/" + idImagem,
      selectedFile,
      setConfirmadoOk,
      confirmadoOk
    );
    setModalInclusao(!modalInclusao);
    setLoading(true);
  }

  function AbrirEFecharModalTrocarFoto(idImagem) {
    setModalInclusao(!modalInclusao);
    setIdImagem(idImagem);
    if (idImagem === 1) {
      setUrlImagem(campos[`campo1`]);
    } else {
      setUrlImagem(campos[`campo2`]);
    }
  }

// Função para redimensionar uma imagem
function resizeImage(file, maxWidth, maxHeight, callback) {
  const reader = new FileReader();

  reader.onload = function(event) {
    const image = new Image();

    image.onload = function() {
      const canvas = document.createElement('canvas');
      let width = image.width;
      let height = image.height;

      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else {
        if (height > width) {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }else{
          width   = maxWidth;
          height  = maxWidth;
        }
      }

      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext('2d');
      ctx.drawImage(image, 0, 0, width, height);

      canvas.toBlob(function(blob) {
        callback(blob);
      }, file.type);
    };

    image.src = event.target.result;
  };

  reader.readAsDataURL(file);
}

  useEffect(() => {
    busca(
      `/corridaAtletaFotos/${id}`,
      setAtletaFotos,
      setLoading,
      setShowResult,
      setShowNoResult
    );
  }, [confirmadoOk, id]);


  useEffect(() => {
    if (atletaFotos) {
      const campos = {};
      atletaFotos.forEach((foto, index) => {
        campos[`campo${foto.posicaoFoto}`] = foto.nomeFoto;
      });
      setCampos(campos);
    }
  }, [atletaFotos]);

  const Result = () => (
    <Card>
      <CardHeader className="p-1 text_big">
        Suas fotos neste evento
      </CardHeader>
      <CardGroup className="text-center">
        <Card>
          <Row>
            <Col sm="6" className="text-center">
              <Card className="m-2 p-4 text-center text_small" onClick={(event) => { AbrirEFecharModalTrocarFoto(1) }}>
                {campos['campo1'] ? (
                  <img
                    src={campos['campo1']}
                    alt="foto"
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  />
                ) : (
                  <center>
                    <FaUser color="gray" size="150px" /><br />
                    <Label className="text_small">
                      <br /> Clique para incluir uma foto sua!!
                    </Label>
                  </center>
                )}
              </Card>
            </Col>
            <Col sm="6">
              <Card className="m-2 p-4 text-center text_small" onClick={(event) => { AbrirEFecharModalTrocarFoto(2) }}>
                {campos['campo2'] ? (
                  <img
                    src={`${campos['campo2']}?${Date.now()}`}
                    alt="foto"
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  />
                ) : (
                  <center>
                    <FaMedal color="gray" size="150px" /><br />
                    <Label className="text_small">
                      <br />  Clique para incluir uma foto da sua medalha!!
                    </Label>
                  </center>
                )}
              </Card>
            </Col>
          </Row>
        </Card>
      </CardGroup>
    </Card >
  );

  const NoResult = () => (
    <></>
  );

  return (
    <>
      <div className="text-center">
        <ClipLoader color="gray" loading={loading} size={60} />
      </div>
      {showResult ? <Result /> : null}
      {showNoResult ? <NoResult /> : null}
      <div>
        <Modal
          isOpen={modalInclusao}
          toggle={AbrirEFecharModalTrocarFoto}
          className="p-0"
        >
          <ModalHeader toggle={AbrirEFecharModalTrocarFoto}>
            Selecione suas fotos!
          </ModalHeader>
          <ModalBody>
            <Card className="p-0 text-center m-0">
              {urlImagem ? (
                <center>
                  <img
                    src={`${urlImagem}?${Date.now()}`}
                    alt="foto"
                    style={{ width: '30%', height: '30%', objectFit: 'cover' }}
                  />
                </center>
              ) : null}
              <input type="file" name="file" id="file" accept="image/*" onChange={(event) => {
                fileSelectedHandler(event);
              }}
              />
            </Card>
          </ModalBody>
          <ModalFooter>
            {urlImagem ? (
              <>
                <Button color="primary" onClick={IncluirFoto} >
                  Trocar
                </Button>{" "}
                <Button color="secondary" onClick={AbrirEFecharModalTrocarFoto}>
                  Cancelar
                </Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Button color="danger" onClick={ExcluirFoto} >
                  Excluir
                </Button>
              </>
            ) : (
              <>
                <Button color="primary" onClick={IncluirFoto} >
                  Incluir
                </Button>{" "}
                <Button color="secondary" onClick={AbrirEFecharModalTrocarFoto}>
                  Cancelar
                </Button>
              </>
            )}
            </ModalFooter>
            </Modal>
      </div>

    </>
  );
};

export default Fotos;
