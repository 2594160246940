import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, CardImg, CardText } from "reactstrap";
import { busca } from "../../api/api_anuncio";
import ClipLoader from "react-spinners/ClipLoader";
import Rodape from "./Rodape";

const Patrocinio = () => {
  const [anuncios, setAnuncios] = useState([]);
  let [loading, setLoading] = useState(true);
  const [showResult, setShowResult] = useState(false);
  const [showNoResult, setShowNoResult] = useState(false);

  useEffect(() => {
    busca(
      `/barraLateral`,
      setAnuncios,
      setLoading,
      setShowResult,
      setShowNoResult
    );
  }, []);

  const Result = () => (
    anuncios &&
    anuncios.map((cardAnuncio) => (
      <Link to={"/linkAnuncio/" +cardAnuncio.idHash+"/"+ cardAnuncio.url}  target="_blank" rel="noopener noreferrer" >
        <Card className="mt-2 pb-2 text-center">
          {cardAnuncio.foto ? (
            <CardImg src={cardAnuncio.foto} />
          ) : (
            <CardText className="m-2 text_small">{cardAnuncio.texto}</CardText>
          )}
        </Card >
      </Link>
    ))
  );

  const NoResult = () => (
    <Card className="mt-2 pb-2 text-center">
      <CardText className="m-2 text_small">Anuncie aqui!!</CardText>
    </Card>
  );

  return (
    <>
      <div className="text_medium">
        <ClipLoader color="gray" loading={loading} size={60} />
      </div>
      {showResult ? <Result /> : null}
      {showNoResult ? <NoResult /> : null}
      <Rodape />
    </>
  );
};

export default Patrocinio;
