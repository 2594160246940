import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  CardImg,
  Alert,
  Form,
  Input,
  Button,
  Label,
  Badge,
  Card,
} from "reactstrap";
import logo from "../assets/img/logo_minhas_medalhas_lg.svg";
import img_login from "../assets/img/img_login.svg";
import amizade from "../assets/img/amizade.svg";
import noticias from "../assets/img/noticias.svg";
import Rodape from "./Home/Rodape";

function Login() {
  const { validacao } = useParams();
  const [msg, setMsg] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");


  function envia() {
    const requestInfo = {
      method: "POST",
      body: JSON.stringify({
        "email": `${email}`,
        "password": `${password}`,
      }),
      headers: new Headers({
        "Content-type": "application/json",
      }),
    };
  //  fetch("http://localhost:8080/auth", requestInfo)
         fetch("https://api.minhasmedalhas.com.br/auth", requestInfo)
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          setMsg('Usuário ou senha inválidos! Não foi possível fazer o login!');
          return false;
        }
      })
      .then((token) => {
        localStorage.setItem('auth-token', token);
        if (token !== false) {
          window.location.href = window.location.origin;
        }
      })
  }

  return (
    <>
      <Container >
        <Row>
          <Col sm="6">
            <Row>
              <Col sm="8" className="pt-5 pb-5">
                <CardImg src={logo} alt="Minhas Medalhas" />
              </Col>
              <Col sm="4">&nbsp;</Col>
            </Row>
            <Label className="title_style">
              Bem vindo à <br /> comunidade de corredores
            </Label>


            <Form onSubmit={(event) => {
              event.preventDefault();
              envia();
            }}>
              <Row>
                <Col sm="8" className="pt-1 pb-1">
                  {msg ?
                    <Alert color="warning" className="text_alert" >{msg}</Alert>
                    : null}
                  {validacao === "validacaoErro" ?
                    <Alert color="warning" className="text_alert" >Alteta não esta ativo! Acesse seu email para confirmar o cadastro ou clique em 'Esqueceu a senha?'</Alert>
                    : validacao === "validacaoOk" ?
                      <Alert color="success" className="text_alert" >Cadastro validado. faça o login!</Alert>
                      : null
                  }
                  <Label className="text_form">Email</Label>
                  <Input
                    type="text"
                    value={email}
                    bsSize="sm"
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                    id="email"
                    label="email"
                  />
                  <Label className="text_form">Senha</Label>
                  <Input
                    type="password"
                    bsSize="sm"
                    value={password}
                    onChange={(event) => {
                      setPassword(event.target.value);
                    }}
                    id="password"
                    label="password"
                  />
                  <Button color="primary" type="submit" className="button_form" block>
                    Entrar
                  </Button>
                  <Link to={`/novaSenha/`} className="text_link">Esqueceu a senha?</Link>
                </Col>
                <Col sm="4">&nbsp;</Col>
              </Row>
            </Form>

            <Row>
              <Col sm="8" className="pt-4 pb-1">
                <Link to={`/inscrever/`} className="no-underline">
                  <Button color="success" outline block>
                    Criar uma conta
                  </Button>
                </Link>
              </Col>
              <Col sm="4">&nbsp;</Col>
            </Row>
          </Col>
          <Col sm="6">
            <CardImg src={img_login} className="img_lateral" />
          </Col>
        </Row>
        <br /><br /><br /><br /><br /><br />
        <Row>
          <Col sm="5">
            <Label className="title_style">
              Tenha o seu Quadro de medalhas na internet
            </Label>
            <Label className="text_medium">Um lugar com todas as suas medalhas, para você compartilhar com quem quiser! </Label>
          </Col>
          <Col sm="1">&nbsp;</Col>
          <Col sm="6" className="text-center">
            <Badge pill color="primary" className="p-3 m-2 text_medium">Suas conquistas</Badge>
            <Badge pill color="primary" className="p-3 m-2 text_medium">Tempo de cada corrida</Badge>
            <Badge pill color="primary" className="p-3 m-2 text_medium">Pace</Badge>
            <Badge pill color="primary" className="p-3 m-2 text_medium">Foto com a medalha</Badge>
            <Badge pill color="primary" className="p-3 m-2 text_medium">Recordes nas corridas</Badge>
            <Badge pill color="primary" className="p-3 m-2 text_medium">Colocação na corrida</Badge>
            <Badge pill color="primary" className="p-3 m-2 text_medium">Colocação por idade</Badge>
            <Badge pill color="primary" className="p-3 m-2 text_medium">Colocação por sexo</Badge>
          </Col>
        </Row>
        <br /><br /><br /><br />
        <Row>
          <Col sm="1">&nbsp;</Col>
          <Col sm="10">
            <Card className="p-3">
              <Row>
                <Col sm="6" >
                  <Label className="title_style p-2">
                    Espaço exclusivo <br />para Assessorias
                  </Label>
                </Col>
                <Col sm="6" className="text-center">
                  <Badge pill color="secondary" className="p-3 m-2 text_medium">Planejamento da corridas</Badge>
                  <Badge pill color="secondary" className="p-3 m-2 text_medium">Divulgação</Badge>
                  <Badge pill color="secondary" className="p-3 m-2 text_medium">Comunicação com o time</Badge>
                  <Badge pill color="secondary" className="p-3 m-2 text_medium">Histórico das corridas</Badge>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col sm="1">&nbsp;</Col>
        </Row>
        <br /><br /><br /><br /><br /><br />
        <Row>
          <Col sm="5">
            <Label className="title_style">
              Cadastre qualquer corrida!
            </Label>
            <Label className="text_medium">Não estamos limitados a uma ou outra organizadora de eventos! <br /> Correu com seus amigos em um evento menor, registre aqui TODAS suas corridas!</Label>
          </Col>
          <Col sm="1">&nbsp;</Col>
          <Col sm="6" className="text-center">
            <Badge pill color="primary" className="p-3 m-2 text_medium">Todas corridas</Badge>
            <Badge pill color="primary" className="p-3 m-2 text_medium">Registro de uma nova corrida</Badge>
            <Badge pill color="primary" className="p-3 m-2 text_medium">Você registra seu tempo</Badge>
          </Col>
        </Row>
        <br /><br /><br /><br />
        <Row>
          <Col sm="1">&nbsp;</Col>
          <Col sm="10">
            <Card className="p-3">
              <Row>
                <Col sm="6" >
                  <Label className="title_style p-2">
                    Espaço exclusivo <br />para Organizadoras de Eventos
                  </Label>
                </Col>
                <Col sm="6" className="text-center">
                  <Badge pill color="secondary" className="p-3 m-2 text_medium">Controle da corridas</Badge>
                  <Badge pill color="secondary" className="p-3 m-2 text_medium">Divulgação</Badge>
                  <Badge pill color="secondary" className="p-3 m-2 text_medium">Acompanhamento</Badge>
                  <Badge pill color="secondary" className="p-3 m-2 text_medium">Gestão da informação</Badge>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col sm="1">&nbsp;</Col>
        </Row>
        <Row>
          <Col sm="6" className="text-center">
            <CardImg src={amizade} className="img_lateral p-4" />
            <Label className="title_style">Conecte-se a outros atletas</Label>
            <Label className="text_medium">Encontre amigos e faça novas amizades!</Label>
          </Col>
          <Col sm="6" className="text-center"><br /> <br />
            <CardImg src={noticias} className="img_lateral p-4" />
            <br /> <br /> <br />
            <Label className="title_style">Receba as corridas do seu interesse </Label>
            <Label className="text_medium">Configure para receber as melhores corridas para seu perfil!</Label>
          </Col>
        </Row>
        <br /><br /><br /><br />
      </Container >
      <Rodape />
    </>
  );
}
localStorage.setItem('url-api', "https://api.minhasmedalhas.com.br/api/v1");
localStorage.setItem('url-auth', "https://api.minhasmedalhas.com.br/auth");
//localStorage.setItem('url-api', "http://localhost:8080/api/v1");
//localStorage.setItem('url-auth', "http://localhost:8080/auth");
export default Login; 
