import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Badge,
  Button,
  Col,
  Row,
  Card,
  CardImg,
  CardSubtitle,
  CardText,
  CardBody,
  Label,
} from "reactstrap";
import "../../assets/css/minha_medalha.css";
import { busca } from "../../api/api_adm_team";
import logo from "../../assets/img/no-logo.png";
import ClipLoader from "react-spinners/ClipLoader";

const MenuAssessoria = ({ url }) => {
  const [assessoria, setAssessoria] = useState([]);
  let [loading, setLoading] = useState(true);
  const [showResult, setShowResult] = useState(false);
  const [showNoResult, setShowNoResult] = useState(false);
  useEffect(() => {
    busca(url, setAssessoria, setLoading, setShowResult, setShowNoResult);
  }, [url]);

  const Result = () => (
    <Card className="mb-3 mt-2 p-1 text-center">
      <CardBody className="p-0 text-center">
        <Row>
          <Col md="5" xs="3">
            <Link to={"/adminTime/"}>
              <CardImg
                top
                width="50"
                alt="Logo da Assessoria"
                src={assessoria.logo === null ? logo : assessoria.logo}
                className="img-fluid rounded-circle "
              />
            </Link>

          </Col>
          <Col md="7" xs="9" className="pl-0 mt-1 ">
            <CardSubtitle  >
              {assessoria.nome}
            </CardSubtitle>
          </Col>
        </Row>
      </CardBody>
      <div align="center">
        <div className="pt-3 pb-3">
          <Link to={"/adminTime/editar"}>
            <Badge color="primary p-2" block>
              Detalhes do Time
            </Badge>
          </Link>
        </div>
        <Link to="/adminTime/atletas/associados">
          <Card className="mb-1">
            <Row align="left">
              <Col xs="9">
                <Label className="text_small no-underline">Atletas associados:</Label>
              </Col>
              <Col xs="3" className="pr-4 text-end">
                <Label className="text_small no-underline">
                  <b>{assessoria.atletasAssociados}&nbsp;</b>
                </Label>
              </Col>
            </Row>
          </Card>
        </Link>
        <Link to="/adminTime/atletas/convites">
          <Card className="mb-1">
            <Row align="left">
              <Col xs="9">
                <Label className="text_small no-underline">Convites enviados:</Label>
              </Col>
              <Col xs="3" className="pr-4 text-end">
                <Label className="text_small no-underline">
                  <b>{assessoria.atletasConvidados}&nbsp;</b>
                </Label>
              </Col>
            </Row>
          </Card>
        </Link>
        <Link to="/adminTime/atletas/solicitacoes">
          <Card className="mb-1">
            <Row align="left">
              <Col xs="9">
                <Label className="text_small no-underline">Solicit. recebidas:</Label>
              </Col>
              <Col xs="3" className="pr-4 text-end">
                <Label className="text_small no-underline">
                  <b>{assessoria.atletasQueremEntrar}&nbsp;</b>
                </Label>
              </Col>
            </Row>
          </Card>
        </Link>
        <div className="pt-3">
          <Link to="/adminTime/calendario/atletas" className="no-underline">
            <Button className="p-1" outline color="primary" block>
              Planejamento dos Atletas
            </Button>
          </Link>
        </div>
        <div className="pt-3">
          <Link to="/adminTime/calendario/atletas" className="no-underline">
            <Button className="p-1" outline color="secondary" block>
              Histórico dos Atletas
            </Button>
          </Link>
        </div>
        <div className="pt-3">
          <Link to="/adminTime/novosAtletas" className="no-underline">
            <Button className="p-1" color="success" block>
              Convidar Atletas
            </Button>
          </Link>
        </div>
        <div className="pt-3">
          <Link to="/"  className="no-underline">
            <Button className="p-1" color="secondary" block>
              Sair do Módulo
            </Button>
          </Link>
        </div>
      </div>
    </Card>
  );

  const NoResult = () => (
    <CardText className="p-3">Opss!!! Ocorreu algum problema. Verifique se você está utilizando o usuário correto. Qualquer problema mande-nos um email contato@minhasmedalhas.com.br</CardText>
  );

  return (
    <>
      <div className="text-center">
        <ClipLoader color="gray" loading={loading} size={60} />
      </div>
      {showResult ? <Result /> : null}
      {showNoResult ? <NoResult /> : null}
    </>
  );
};

export default MenuAssessoria;
