import { React, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
    Alert,
    Button,
    FormGroup,
    CardImg,
    Input,
    Col,
    Card,
    Row,
    CardTitle,
    CardBody,
    Label,
    CardText,
    ModalHeader,
    Modal,
    ModalFooter,
    ModalBody,
} from "reactstrap";
import { getToken, getUrlApi, getAuthOrg } from "../../api/auth";
import ClipLoader from "react-spinners/ClipLoader";
import { busca } from "../../api/api_event_admin";
import fotoEvento from "../../assets/img/no-photo.png";
import { upload } from "../../api/api_file_upload_evento";

const ImagemEvento = () => {
    const { id } = useParams();
    const [showResult, setShowResult] = useState(false);
    const [showNoResult, setShowNoResult] = useState(false);
    const [messageAPI, setMessageAPI] = useState("");
    const [resultColor, setResultColor] = useState("");
    let [loading, setLoading] = useState(false);
    const [evento, setEvento] = useState([]);
    const idOrganizadoraAtleta = getAuthOrg();
    const [modalInclusao, setModalInclusao] = useState(false);
    const [modalExclusao, setModalExclusao] = useState(false);
    const [confirmadoOk, setConfirmadoOk] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    function fileSelectedHandler(event) {
        setSelectedFile(event.target.files[0])
    }

    useEffect(() => {
        busca(
            "/corrida/" + id,
            setEvento,
            setLoading,
            setShowResult,
            setShowNoResult,
            idOrganizadoraAtleta
        );
    }, [confirmadoOk]);

    function IncluirFoto() {
        upload(
            "/admin/uploadImagem",
            selectedFile,
            id,
            setConfirmadoOk,
            confirmadoOk
        );
        AbrirEFecharModalTrocarFoto();
    }

    const AbrirEFecharModalTrocarFoto = () => setModalInclusao(!modalInclusao);

    function ExcluirFoto() {
        const token = getToken();
        const requestInfo = {
            method: "GET",
            headers: new Headers({
                "Content-type": "application/json",
                Authorization: `Bearer ${token}`,
            }),
        };
        fetch(getUrlApi() + "/admin/removerImagem/" + id, requestInfo).then(
            (response) => {
                response.json().then((data) => {
                    if (response.status === 200) {
                        setMessageAPI(data.message);
                        setResultColor("success");
                    } else {
                        if (response.status === 202) {
                            setMessageAPI(data.message);
                        } else {
                            if (response.status === 400) {
                                setMessageAPI(data.message);
                            }
                        }
                        setResultColor("warning");
                    }
                });
            }
        );
        setConfirmadoOk(!confirmadoOk);
        setModalExclusao(!modalExclusao);
    }

    const CancelarExcluirFoto = () => setModalExclusao(!modalExclusao);

    function IncluirModalidade(pModalidade) {
        const token = getToken();
        const requestInfo = {
            method: "GET",
            headers: new Headers({
                "Content-type": "application/json",
                Authorization: `Bearer ${token}`,
            }),
        };
        fetch(
            getUrlApi() + "/admin/incluirModalidadeEvento/" + id + "/" + pModalidade,
            requestInfo
        ).then((response) => {
            response.json().then((data) => {
                if (response.status === 200) {
                    setMessageAPI(data.message);
                    setResultColor("success");
                } else {
                    if (response.status === 202) {
                        setMessageAPI(data.message);
                    } else {
                        if (response.status === 400) {
                            setMessageAPI(data[0].message);
                        }
                    }
                    setResultColor("warning");
                }
            });
        });
    }

    function RemoverModalidade(pModalidade) {
        const token = getToken();
        const requestInfo = {
            method: "GET",
            headers: new Headers({
                "Content-type": "application/json",
                Authorization: `Bearer ${token}`,
            }),
        };
        fetch(
            getUrlApi() + "/admin/removerModalidadeEvento/" + id + "/" + pModalidade,
            requestInfo
        ).then((response) => {
            response.json().then((data) => {
                if (response.status === 200) {
                    setMessageAPI(data.message);
                    setResultColor("success");
                } else {
                    if (response.status === 202) {
                        setMessageAPI(data.message);
                    } else {
                        if (response.status === 400) {
                            setMessageAPI(data[0].message);
                        }
                    }
                    setResultColor("warning");
                }
            });
        });
    }

    return (
        <Card>
            <div className="text-center">
                <ClipLoader color="gray" loading={loading} size={60} />
            </div>

            <Card>
                <Row>
                    <Col sm="3"></Col>
                    <Col sm="6">
                        <Card className="p-2">
                            <CardTitle className="text-center mt-2 pt-2" tag="h4">
                                Evento <br />
                                {evento.nome}
                            </CardTitle>
                            <CardBody>
                                <FormGroup>
                                    <Label>Data do Evento</Label>
                                    <Input
                                        type="text"
                                        name="dataEvento"
                                        minLength="5"
                                        maxLength="8"
                                        value={evento.dataInicioEvento}
                                        readOnly
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Cidade</Label>
                                    <Input
                                        type="text"
                                        name="cidade"
                                        minLength="5"
                                        value={evento.nomeCidade + " / " + evento.uf}
                                        readOnly
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Card>
                                        <CardImg
                                            src={
                                                evento.foto === null ? fotoEvento : evento.foto
                                            }
                                            className="img-fluid rounded-lg p-2"
                                        />
                                    </Card>
                                </FormGroup>
                            </CardBody>


                            {evento.status === "ATIVO" ? (
                            <CardText className="text-center p-1">
                                <Button color="primary" className="p-1" onClick={AbrirEFecharModalTrocarFoto}>
                                    Trocar Imagem
                                </Button>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <Button color="danger" className="p-1" onClick={ExcluirFoto}>
                                    Remover Imagem
                                </Button>
                                <Link to={"/admin/editarEvento/" + evento.id}>
                                    <Button color="secondary" className="ml-4" outline>
                                        Voltar
                                    </Button>
                                </Link>
                            </CardText>
                            ) : null}
                                <Alert className="text-center" color={resultColor}>
                                    {messageAPI}
                                </Alert>
                        </Card>
                    </Col>
                </Row>
            </Card>
            <div>
                <Modal
                    isOpen={modalInclusao}
                    toggle={AbrirEFecharModalTrocarFoto}
                    className="p-0"
                >
                    <ModalHeader toggle={AbrirEFecharModalTrocarFoto}>
                        Selecione uma foto!
                    </ModalHeader>
                    <ModalBody>
                        <Card className="p-0 text-center m-0">
                            <input type="file" name="file" id="file" onChange={(event) => {
                                fileSelectedHandler(event);
                            }}
                            />
                        </Card>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={IncluirFoto} >
                            Confirmar
                        </Button>{" "}
                        <Button color="secondary" onClick={AbrirEFecharModalTrocarFoto}>
                            Cancelar
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
            <div>
                <Modal
                    isOpen={modalExclusao}
                    toggle={CancelarExcluirFoto}
                    className="p-0"
                >
                    <ModalHeader toggle={CancelarExcluirFoto}>
                        Remover a imagem do Evento!
                    </ModalHeader>
                    <ModalBody className="pt-5 pb-5 text-center">
                        Confirma a exclusão da imagem deste evento!!
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={ExcluirFoto}>
                            Confirmar
                        </Button>{" "}
                        <Button color="secondary" onClick={CancelarExcluirFoto}>
                            Cancelar
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        </Card>
    );

};


export default ImagemEvento;


