import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Card,
  CardText,
  Container,
  CardTitle,
  Label,
  Button,
} from "reactstrap";
import "../../assets/css/minha_medalha.css";
import { busca } from "../../api/api_teams";
import ClipLoader from "react-spinners/ClipLoader";
import Avatar from 'react-avatar';
import Patrocinio from "../Home/Patrocinio";

const ConfirmarNovaData = () => {
  const [assessoria, setAssessoria] = useState([]);
  let [loading, setLoading] = useState(true);
  const [showResult, setShowResult] = useState(false);
  const [showNoResult, setShowNoResult] = useState(false);

  useEffect(() => {
    setLoading(true);
    busca(
      `/assessoria/confirmarNovaData`,
      setAssessoria,
      setLoading,
      setShowResult,
      setShowNoResult
    );

  }, []);

  const Result = () => (
    <>
      <Card>
        <Card className="text-center">
          <CardText className="p-3 pt-2 m-0 text-center">
            {assessoria.logo === null ? (
              <Avatar name={assessoria.nome} size="200" round={true} maxInitials={3} />
            ) : (
              <Avatar src={assessoria.logo} size="200" round={true} />
            )}
          </CardText>
          <CardText tag="h4" className="text_big">
            {assessoria.sigla} {assessoria.nome}
          </CardText>
        </Card>
        <Card className="mt-3 p-1 text-center">
          <Label className="text_big">Nova data atualizada com sucesso!!</Label>
        </Card>
      </Card>

    </>
  );
  const NoResult = () => (
    <Card>
      <br /><br />
      <CardText className="p-3 text_big text-center">
        Não encontramos mudança de data de início com sua Assessoria!
      </CardText>
      <CardText className="p-3 text_big text-center"> Em caso de dúvida acesse o seu <Button href="/perfil" outline color="primary">Perfil</Button></CardText>
      <br /><br /><br />
    </Card>
  );

  return (
    <Container className="p-1">
      <div className="text-center">
        <ClipLoader color="gray" loading={loading} size={60} />
      </div>
      <Row>
        <Col xs="12" sm="8" className="mt-2 pr-0 ">
          {showResult ? <Result /> : <NoResult />}
        </Col>
        <Col>
          <Patrocinio />
        </Col>
      </Row>
    </Container>
  );
};

export default ConfirmarNovaData;
