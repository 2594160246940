import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Alert,
  Button,
  Col,
  Row,
  Card,
  Form,
  Input,
  CardText,
  Container,
  CardTitle,
  CardSubtitle,
  FormFeedback,
  ModalHeader,
  Modal,
  ModalBody,
  ModalFooter,
  Badge,
  Label,
} from "reactstrap";
import "../Comuns/FotoQuadro";
import "../../assets/css/minha_medalha.css";
import { busca } from "../../api/api_athlete";
import { upload } from "../../api/api_file_upload";
import ClipLoader from "react-spinners/ClipLoader";
import { getToken, getUrlApi } from "../../api/auth.js";
import ListaSolicitacaoTimes from "./ListaSolicitacaoTimes";
import Avatar from 'react-avatar';
import RecordesPessoais from "../Medalhas/RecordesPessoais.jsx";


const DetalhePerfil = () => {
  const [atletaPerfil, setAtletaPerfil] = useState([]);
  let [loading, setLoading] = useState(true);
  const [showResult, setShowResult] = useState(false);
  const [showNoResult, setShowNoResult] = useState(false);
  const [confirmadoOk, setConfirmadoOk] = useState(false);
  useEffect(() => {
    busca(
      "/meuPerfil/",
      setAtletaPerfil,
      setLoading,
      setShowResult,
      setShowNoResult
    );
  }, [confirmadoOk]);

  const [modalInclusao, setModalInclusao] = useState(false);
  const [modalExclusao, setModalExclusao] = useState(false);
  const [modalSaidaAssessoria, setModalSaidaAssessoria] = useState(false);
  const [modalAlteraSenha, setModalAlteraSenha] = useState(false);
  const [messageAPI, setMessageAPI] = useState("");
  const [resultColor, setResultColor] = useState("");
  const [messageAPIPass, setMessageAPIPass] = useState("");
  const [resultColorPass, setResultColorPass] = useState("");
  const [erro, setErro] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);



  const [avatar, setAvatar] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      // Verifica se o arquivo é uma imagem
      if (file.type.startsWith('image/')) {
        setSelectedFile(file);
        const reader = new FileReader();

        reader.onload = (event) => {
          setAvatar(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert('Por favor, escolha um arquivo de imagem.');
      }
    }
  };

  function IncluirFoto() {
    upload(

      "/atleta/uploadFoto",
      selectedFile,
      setConfirmadoOk,
      confirmadoOk
    );
    AbrirEFecharModalTrocarFoto();
  }

  const AbrirEFecharModalTrocarFoto = () => setModalInclusao(!modalInclusao);
  const AbrirEFecharModalExcluirFoto = () => setModalExclusao(!modalExclusao)

  function ExcluirFoto() {
    const token = getToken();
    const requestInfo = {
      method: "GET",
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };
    fetch(getUrlApi() + "/atleta/removerFoto/", requestInfo).then(
      (response) => {
        response.json().then((data) => {
          if (response.status === 200) {
            setMessageAPI(data.message);
            setResultColor("success");
          } else {
            if (response.status === 202) {
              setMessageAPI(data.message);
            } else {
              if (response.status === 400) {
                setMessageAPI("Erro Inesperado"); //data[0].message);
              }
            }
            setResultColor("warning");
          }
        });
      }
    );
    setConfirmadoOk(!confirmadoOk);
    setModalExclusao(!modalExclusao);
  }

  const AlterarSenha = () => {
    setModalAlteraSenha(!modalAlteraSenha);
    setResultColor("");
    setMessageAPI("");
  };

  const ConfirmarSaida = () => {
    const token = getToken();
    const requestInfo = {
      method: "GET",
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };

    fetch(getUrlApi() + "/atleta/sairTime", requestInfo).then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          setMessageAPI(data.message);
          setResultColor("success");
        } else {
          if (response.status === 202) {
            setMessageAPI(data.message);
          } else {
            if (response.status === 400) {
              setMessageAPI(data[0].message);
            }
          }
          setResultColor("warning");
        }
        setConfirmadoOk(!confirmadoOk);
      });
    });
  };
  const CancelarSaida = () => setModalSaidaAssessoria(!modalSaidaAssessoria);

  function ConfirmaAlterarSenha() {
    const token = getToken();
    const requestInfo = {
      method: "POST",
      body: JSON.stringify({
        password: `${password}`,
        password2: `${password2}`,
      }),
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };

    fetch(getUrlApi() + "/atleta/alteraSenha", requestInfo).then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          setMessageAPIPass(data.message);
          setResultColorPass("success");
        } else {
          if (response.status === 202) {
            setMessageAPIPass(data.message);
          } else {
            if (response.status === 400) {
              setMessageAPIPass(data[0].message);
            }
          }
          setResultColorPass("warning");
        }
      });
    });
  }

  const handleSenhaChange = (event) => {
    const novaSenha = event.target.value;
    setPassword(novaSenha);
    setErro(validarSenha(novaSenha));
  };

  const validarSenha = (senha) => {
    // A expressão regular verifica se a senha contém pelo menos um número, uma letra e um caractere especial
    const regex = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[\W_]).{8,}$/;

    if (!regex.test(senha)) {
      return 'A senha deve conter pelo menos 8 caracteres, incluindo números, letras e caracteres especiais.';
    }

    return '';
  };

  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [validPass, setPasswordValid] = useState("");
  const [invalidPass, setPasswordInvalid] = useState("");

  const Result = () => (
    <>
      <Card>
        <Row>
          <Col xs="12" sm="4" >
            <CardText className="p-3 m-0 text-center">
              {atletaPerfil.foto === null ? (
                <Avatar name={atletaPerfil.nome} size="250" round={true} maxInitials={2} />
              ) : (
                <img
                  src={atletaPerfil.foto}
                  alt="Avatar Preview"
                  style={{ width: '250px', height: '250px', objectFit: 'cover', borderRadius: '50%' }}
                />
              )}
            </CardText>
            <CardText className="text-center p-1">
              <Button color="primary" onClick={AbrirEFecharModalTrocarFoto} className="button_form">
                Trocar Foto
              </Button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Button color="danger" onClick={AbrirEFecharModalExcluirFoto} className="button_form">
                Remover Foto
              </Button>
            </CardText>
          </Col>
          <Col xs="12" sm="7" className="m-0 mt-2 p-3 ">
            <Card style={{ boxShadow: '0 5px 10px rgba(0, 0, 0, 0.5)' }}>
              <CardTitle className="text_big_center">
                {atletaPerfil.nome}
              </CardTitle>
              <CardSubtitle className="text_banco_center">
                {atletaPerfil.apelido}
              </CardSubtitle>
              <Row xs="3">
                <Col sm="4" className="text-end">
                  <Label className="text_form">Aniversário</Label>
                </Col>
                <Col sm="8" xs="8" className="pl-0">
                  <Label className="text_banco">{atletaPerfil.dataNascimento}</Label>
                </Col>
                <Col sm="4" className="text-end">
                  <Label className="text_form">Contato</Label>
                </Col>
                <Col sm="8" xs="8" className="pl-0">
                  <Label className="text_banco">{atletaPerfil.fone === "null" ? "" : atletaPerfil.fone} </Label>
                </Col>
                <Col sm="4" className="text-end">
                  <Label className="text_form">Email</Label>
                </Col>
                <Col sm="8" xs="8" className="pl-0">
                  <Label className="text_banco">{atletaPerfil.email}</Label>
                </Col>
                <Col sm="4" className="text-end">
                  <Label className="text_form">Localidade</Label>
                </Col>
                <Col sm="8" xs="8" className="pl-0">
                  <Label className="text_banco"> {atletaPerfil.nomeCidade} {atletaPerfil.nomeCidade ? " - " : null}{" "}
                    {atletaPerfil.uf}</Label>
                </Col>
                <Col sm="4" className="text-end">
                  <Label className="text_form">Sexo</Label>
                </Col>
                <Col sm="8" xs="8" className="pl-0">
                  <Label className="text_banco">{atletaPerfil.sexo === "Nao_informado" ? "Não informado" : atletaPerfil.sexo}</Label>
                </Col>
                <Col sm="4" className="text-end">
                  <Label className="text_form">Tipo Sanguineo</Label>
                </Col>
                <Col sm="8" xs="8" className="pl-0">
                  <Label className="text_banco">{atletaPerfil.tipoSangue} {atletaPerfil.fatorRH}</Label>
                </Col>
                <Col sm="4" className="text-end">
                  <Label className="text_form">Calçado</Label>
                </Col>
                <Col sm="8" xs="8" className="pl-0">
                  <Label className="text_banco">{atletaPerfil.numeroTenis}</Label>
                </Col>
                <Col sm="4" className="text-end">
                  <Label className="text_form">Camiseta</Label>
                </Col>
                <Col sm="8" xs="8" className="pl-0">
                  <Label className="text_banco">{atletaPerfil.tamanhoCamiseta === "Não_informado" ? "" : atletaPerfil.tamanhoCamiseta}</Label>
                </Col>
              </Row>
            </Card>
            <Card className="mt-3 p-1 text-justify" style={{ boxShadow: '0 5px 10px rgba(0, 0, 0, 0.5)' }}>
              <Label className="text_form">Informações do atleta:</Label> <br />
              <Label className="text_banco">{atletaPerfil.resumo === "null" ? "" : atletaPerfil.resumo}</Label>
            </Card>
            <CardText className="text-end mt-2">
              <Link to="/configuracao">
                <Button color="secondary" outline className="button_form">
                  Configurações
                </Button>
              </Link>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Button color="secondary" outline onClick={AlterarSenha} className="button_form">
                Alterar senha
              </Button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Link to="/perfil/editar">
                <Button color="primary" outline className="button_form">
                  Alterar dados
                </Button>
              </Link>
            </CardText>
          </Col>
        </Row>
      </Card>
      {atletaPerfil.idTime !== null ? (
        <Card className="mt-3 p-1 text-justify">
          <Row>
            <Col xs="4" sm="2">
              <CardText className="p-2 m-0">
                <Link to={`/assessoria/${atletaPerfil.idTime}`}>
                {atletaPerfil.logoTime === null ? (
                    <Avatar name={atletaPerfil.nomeTime} size="120" round={true} maxInitials={2} />
                  ) : (
                    <Avatar src={atletaPerfil.logoTime} size="120" round={true} />
                  )}
                </Link>
              </CardText>
            </Col>
            <Col xs="8" sm="7" className="text-center pl-0">
              <CardText className="text_form">
                Você faz parte do time {atletaPerfil.nomeTime}
              </CardText>
              <CardText className="text_banco">{atletaPerfil.emailTime}</CardText>
              <CardText className="p-0 m-0 text_banco">
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 0 16 16"
                  class="bi bi-telephone-plus"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM12.5 1a.5.5 0 0 1 .5.5V3h1.5a.5.5 0 0 1 0 1H13v1.5a.5.5 0 0 1-1 0V4h-1.5a.5.5 0 0 1 0-1H12V1.5a.5.5 0 0 1 .5-.5z"
                  />
                </svg>
                {atletaPerfil.contatoTime}
              </CardText>
            </Col>
            <Col xs="12" sm="3" className="text-center">
              <Link to={`/assessoria/${atletaPerfil.idTime}`}>
                <Badge color="info" className="p-2 m-3" pill>
                  Veja outros atletas
                </Badge>
              </Link>
              <Link onClick={CancelarSaida}>
                <Badge color="secondary" className="p-2 m-3" pill>
                  Sair da assessoria
                </Badge>
              </Link>
            </Col>
          </Row>
        </Card>
      ) : null}
      <RecordesPessoais url={'/recordesPessoais'} />
      <ListaSolicitacaoTimes confirmadoOk timeAtletaId={atletaPerfil.idTime} />
    </>
  );

  const NoResult = () => (
    <Card>
      <CardText className="text-center m-4 p-2">
        Algo deu erro! por favor, faça o login novamente!
      </CardText>
    </Card>
  );

  return (
    <Container className="p-1">
      <div className="text-center">
        <ClipLoader color="gray" loading={loading} size={60} />
      </div>
      {showResult ? <Result /> : null}
      {showNoResult ? <NoResult /> : null}
      <div>
        <Modal
          isOpen={modalInclusao}
          toggle={AbrirEFecharModalTrocarFoto}
          className="p-0"
        >
          <ModalHeader toggle={AbrirEFecharModalTrocarFoto} className="text_big">
            Selecione uma foto!
          </ModalHeader>
          <ModalBody>
            <Card className="p-0 text-center m-0">
              <label htmlFor="file">Escolher Avatar:</label>
              <input
                type="file"
                id="file"
                name="file"
                accept="image/*"
                onChange={handleFileChange}
              />

              {avatar && (
                <div style={{ marginTop: '20px' }}>
                  <p>Preview do Avatar:</p>
                  <img
                    src={avatar}
                    alt="Avatar Preview"
                    style={{ width: '250px', height: '250px', objectFit: 'cover', borderRadius: '50%' }}
                  />
                </div>
              )}
            </Card>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={IncluirFoto} className="button_form">
              Confirmar
            </Button>{" "}
            <Button color="secondary" onClick={AbrirEFecharModalTrocarFoto} className="button_form">
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>
      </div>
      <div>
        <Modal
          isOpen={modalExclusao}
          toggle={AbrirEFecharModalExcluirFoto}
          className="p-0"
        >
          <ModalHeader toggle={AbrirEFecharModalExcluirFoto} className="text_big">
            Remover a foto do perfil!
          </ModalHeader>
          <ModalBody className="pt-5 pb-5 text_form text-center">
            Confirma a exclusão da sua foto do perfil!!
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={ExcluirFoto} className="button_form">
              Confirmar
            </Button>{" "}
            <Button color="secondary" onClick={AbrirEFecharModalExcluirFoto} className="button_form">
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>
      </div>
      <div>
        <Modal
          isOpen={modalSaidaAssessoria}
          toggle={CancelarSaida}
          className="p-0"
        >
          <ModalHeader toggle={CancelarSaida} className="text_big">
            Sair da Assessoria Esportiva!
          </ModalHeader>
          <ModalBody className="pt-5 pb-5 text_form">
            Confirma sua retirada da Assessoria Esportiva a partir deste
            momento?
          </ModalBody>
          {messageAPI ? (
            <Alert className="text_alert" color={resultColor}>
              {messageAPI}
            </Alert>
          ) : null}
          {confirmadoOk === true ? (
            <ModalFooter>
              <Button color="primary" onClick={ConfirmarSaida} className="button_form">
                Confirmar
              </Button>
              <Button color="secondary" onClick={CancelarSaida} className="button_form">
                Cancelar
              </Button>
            </ModalFooter>
          ) : (
            <ModalFooter>
              <Button color="secondary" onClick={CancelarSaida}>
                Fechar
              </Button>
            </ModalFooter>
          )}
        </Modal>
      </div>
      <div>
        <Modal isOpen={modalAlteraSenha} toggle={AlterarSenha} className="p-0">
          <ModalHeader toggle={AlterarSenha} className="text_big">Alterar a senha!</ModalHeader>
          {resultColor !== "success" ? (
            <ModalBody className="pt-3 pb-5 text-left">
              <Form>
                <CardText className="text_form">Para trocar a sua senha, informe e confirme a nova senha!</CardText>
                <CardText>
                  <Label className="text_form">Senha:*</Label>
                  <Input
                    bsSize="sm"
                    value={password}
                    onChange={
                      handleSenhaChange
                    }
                    onBlur={(event) => {
                      if (validaPassword({ password, password2 })) {
                        setPasswordValid(true);
                        setPasswordInvalid(false);
                      } else {
                        setPasswordValid(false);
                        setPasswordInvalid(true);
                      }
                    }}
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Informe um senha"
                    minLength="4"
                    maxlength="80"
                    required
                  />
                </CardText>
                {erro && <Label className="text_small_erro">{erro}</Label>}
                <CardText>
                  <Label className="text_form">Confirme a senha:*</Label>
                  <Input
                    bsSize="sm"
                    value={password2}
                    onChange={(event) => {
                      setPassword2(event.target.value);
                    }}
                    onBlur={(event) => {
                      if (validaPassword({ password, password2 })) {
                        setPasswordValid(true);
                        setPasswordInvalid(false);
                      } else {
                        setPasswordValid(false);
                        setPasswordInvalid(true);
                      }
                    }}
                    type="password"
                    name="password2"
                    id="password2"
                    placeholder="Confirme a senha"
                    minLength="4"
                    maxlength="80"
                    required
                    valid={validPass}
                    invalid={invalidPass}
                  />
                  <FormFeedback invalid>Senhas não confere!</FormFeedback>
                  <FormFeedback valid>Senha ok!</FormFeedback>
                </CardText>
              </Form>
            </ModalBody>
          ) : null}

          {messageAPI ? (
            <Alert className="text_alert" color={resultColorPass}>
              {messageAPIPass}
            </Alert>
          ) : null}
          {resultColor !== "success" ? (
            <ModalFooter>
              <Button color="primary" onClick={ConfirmaAlterarSenha} className="button_form">
                Confirmar
              </Button>
              <Button color="secondary" onClick={AlterarSenha} className="button_form">
                Cancelar
              </Button>
            </ModalFooter>
          ) : (
            <ModalFooter>
              <Button color="primary" onClick={AlterarSenha} className="button_form">
                Fechar
              </Button>
            </ModalFooter>
          )}
        </Modal>
      </div>
    </Container>
  );
};

function validaPassword(data) {
  if (data.password2 === null) {
    return false;
  }
  if (data.password === data.password2) {
    return true;
  }
  return false;
}

export default DetalhePerfil;
