export const TOKEN_KEY = "auth-token";
export const URL_API = "url-api";
export const URL_AUTH = "url-auth";
export const FOTO_ATL = "foto_atl";
export const NOME_ATL = "nome_atl";
export const HASH_ATL = "hash_atl";
export const AUTH_ORG = "auth-org";
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getUrlApi = () => localStorage.getItem(URL_API);
export const getUrlAuth = () => localStorage.getItem(URL_AUTH);
export const getFoto = () => localStorage.getItem(FOTO_ATL);
export const getNome = () => localStorage.getItem(FOTO_ATL);
export const getHash = () => localStorage.getItem(HASH_ATL);
export const getAuthOrg = () => localStorage.getItem(AUTH_ORG);

export const login = token => {
  localStorage.setItem(TOKEN_KEY, token);
 // localStorage.setItem(URL_API, "http://api.minhasmedalhas.com.br/mm/api/v1");
 // localStorage.setItem(URL_API, "http://localhost:8080/api/v1");
  //localStorage.setItem(URL_API, "http://107.22.24.11:8080/api/v1");
  
};
export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(URL_API);
  localStorage.removeItem(URL_AUTH);
  localStorage.removeItem(FOTO_ATL);
  localStorage.removeItem(NOME_ATL);
  localStorage.removeItem(HASH_ATL);
  localStorage.removeItem(AUTH_ORG);
};
