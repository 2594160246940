import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { busca } from "../../api/api_athlete";
import ClipLoader from "react-spinners/ClipLoader";
import { getToken, getUrlApi } from "../../api/auth";

function EditarPerfil() {
  const [atletaPerfil, setAtletaPerfil] = useState([]);
  let [loading, setLoading] = useState(true);
  const [showResult, setShowResult] = useState(false);
  const [showNoResult, setShowNoResult] = useState(false);

  const Confirmar = () => {
    envia();
  };

  const [modalCidade, setModalCidade] = useState(false);
  const Buscar = () => setModalCidade(!modalCidade);
  function Selecionar(cidade) {
    setCidade(`${cidade.nome} - ${cidade.uf}`)
    setIdCidade(cidade.id)
    setModalCidade(!modalCidade);
  }

  const [buscaCidade, setBuscaCidade] = useState([]);
  const [hoje, setHoje] = useState(Intl.DateTimeFormat("pt-BR", { timeZone: "UTC" }).format(new Date()));
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [cpf, setCpf] = useState("");
  const [apelido, setApelido] = useState("");
  const [dataNascimento, setDataNascimento] = useState("");
  const [sexo, setSexo] = useState("");
  const [fone, setFone] = useState("");
  const [idCidade, setIdCidade] = useState("");
  const [cidade, setCidade] = useState("");
  const [tipoSangue, setTipoSangue] = useState("");
  const [fatorRH, setRh] = useState("");
  const [numeroTenis, setNumeroTenis] = useState("");
  const [tamanhoCamiseta, setTamanhoCamiseta] = useState("");
  const [resumo, setResumo] = useState("");
  const [messageAPI, setMessageAPI] = useState("");
  const [resultColor, setResultColor] = useState("");
  const [block, setBlock] = useState(true);

  useEffect(() => {
    busca("/meuPerfil", setAtletaPerfil, setLoading, setShowResult, setShowNoResult);

  }, []);


  if (atletaPerfil.id !== undefined && block) {
    const input = atletaPerfil.dataNascimento
    const [dia, mes, ano] = input.split('/')
    setNome(atletaPerfil.nome);
    setCpf(atletaPerfil.cpf);
    setEmail(atletaPerfil.email);
    setApelido(atletaPerfil.apelido);
    setDataNascimento(`${ano}-${mes}-${dia}`);
    setSexo(atletaPerfil.sexo);
    setTipoSangue(atletaPerfil.tipoSangue);
    setFone(atletaPerfil.fone);
    setRh(atletaPerfil.fatorRH);
    setNumeroTenis(atletaPerfil.numeroTenis);
    setTamanhoCamiseta(atletaPerfil.tamanhoCamiseta);
    if (atletaPerfil.resumo === null) {
      setResumo("")
    }else{
      setResumo(atletaPerfil.resumo);
    }
    if (atletaPerfil.nomeCidade === null) {
      setCidade("");
    } else {
      setCidade(atletaPerfil.nomeCidade + " - " + atletaPerfil.uf)
    }
    setBlock(false);
    const [diaHoje, mesHoje, anoHoje] = hoje.split('/')
    setHoje(`${anoHoje}-${mesHoje}-${diaHoje}`);

  }

  const [cidades, setCidades] = useState([]);

  function buscarCidade(nomeCidade) {
    const token = getToken();
    const requestCity = {
      method: "POST",
      body: JSON.stringify({
        nome: `${nomeCidade}`,
      }),
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };
    fetch(
      getUrlApi() + "/cidade/buscaCidade/",
      requestCity
    ).then((response) => {
      response.json().then((data) => {
        setCidades(data)
      });
    })
  }

  function envia() {
    const token = getToken();
    let dataNascimento2 = dataNascimento;
    const [anoEnviar, mesEnviar, diaEnviar] = dataNascimento2.split('-')
    dataNascimento2 = `${diaEnviar}/${mesEnviar}/${anoEnviar}`;
    let rh = fatorRH;
    if (rh === null || rh === "") {
      rh = " ";
    }
    const requestInfo = {
      method: "POST",
      body: JSON.stringify({
        nome: `${nome}`,
        apelido: `${apelido}`,
        dataNascimento: `${dataNascimento2}`,
        cidade: idCidade,
        sexoForm: `${sexo}`,
        sangueForm: `${tipoSangue}`,
        fone: `${fone}`,
        fatorRH: `${rh}`,
        numeroTenis: numeroTenis,
        tamanhoCamiseta: `${tamanhoCamiseta}`,
        resumo: `${resumo}`
      }),
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };
    fetch(
      getUrlApi() + "/atleta/atualizaDados",
      requestInfo
    ).then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          setMessageAPI(data.message);
          setResultColor("success");
        } else {
          if (response.status === 202) {
            setMessageAPI(data.message);
          } else {
            if (response.status === 400) {
              setMessageAPI(data[0].message);
            }
          }
          setResultColor("warning");
        }
      });
    })
  }

  return (
    <Card>
      <Label className="text-center">
        <ClipLoader color="gray" loading={loading} size={60} />
      </Label>

      {showResult ? null : null }  
      {showNoResult ? null : null}
      <Container className="p-0 pt-2">
        <Row>
          <Col sm="3"></Col>
          <Col sm="6">
            <Card className="p-2">
              <Form  >
                <CardTitle className="text_big_center mt-2 pt-2" >Alteração de dados cadastrais</CardTitle>
                <CardBody>
                  <CardText><Label className="text_form">Nome Atleta</Label><br /><Label className="text_banco">{atletaPerfil.nome}</Label>
                    <Input type="hidden" /></CardText>
                  <Row className="pb-3">
                    <Col sm="8">
                      <CardText><Label className="text_form">Email</Label><br /><Label className="text_banco">{atletaPerfil.email}</Label></CardText>
                    </Col>
                    <Col sm="4">
                      <CardText><Label className="text_form">CPF</Label><br /> <Label className="text_banco">{atletaPerfil.cpf}</Label></CardText>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Label className="text_form">Apelido / Nome no peito</Label>
                    <Input
                      bsSize="sm"
                      type="text"
                      name="apelido"
                      minLength="2"
                      maxLength="20"
                      id="apelido"
                      value={apelido}
                      onChange={(event) => {
                        setApelido(event.target.value);
                      }}
                    />
                    <FormFeedback invalid>
                      E-mail inválido! favor informe um email correto
                    </FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label className="text_form">Data nascimento</Label>
                    <Input
                      bsSize="sm"
                      type="date"
                      name="dataNascimento"
                      minLength="5"
                      maxLength="8"
                      id="dataNascimento"
                      max={hoje}
                      value={dataNascimento}
                      pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                      onChange={(event) => {
                        setDataNascimento(event.target.value);
                      }}
                      required
                    />
                    <FormFeedback invalid>
                      E-mail inválido! favor informe um email correto
                    </FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label  className="text_form">Sexo</Label>
                    <Input bsSize="sm" type="select" name="sexo" id="sexo" onChange={(event) => {
                      setSexo(event.target.value);
                    }} value={sexo}>
                      <option value="Nao_Informado">Não Informado</option>
                      <option value="Feminino">Feminino</option>
                      <option value="Masculino">Masculino</option>
                    </Input>
                    <FormFeedback invalid>
                      E-mail inválido! favor informe um email correto
                    </FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label  className="text_form">Telefone</Label>
                    <Input
                      bsSize="sm"
                      type="text"
                      name="fone"
                      minLength="5"
                      maxLength="15"
                      id="fone"
                      value={fone}
                      onChange={(event) => {
                        setFone(phoneMask(event.target.value));
                      }}
                    />
                    <FormFeedback invalid>
                      E-mail inválido! favor informe um email correto
                    </FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label className="text_form">Localidade</Label>
                    <Input
                      bsSize="sm"
                      type="text"
                      name="cidade"
                      minLength="5"
                      id="cidade"
                      value={cidade}
                      placeholder="Click para selecionar a cidade"
                      onClick={Buscar}
                    />
                    <Input type="hidden" name="idCidade" value={idCidade} />
                    <FormFeedback invalid>
                      E-mail inválido! favor informe um email correto
                    </FormFeedback>
                  </FormGroup>
                  <Row>
                    <Col sm="8">
                      <FormGroup>
                        <Label className="text_form">Tipo sanguineo</Label>
                        <Input bsSize="sm" type="select" name="tipoSangue" id="tipoSangue" onChange={(event) => {
                          setTipoSangue(event.target.value);
                        }} value={tipoSangue}>
                          <option value="NI">-- selecione --</option>
                          <option value="A">A</option>
                          <option value="B">B</option>
                          <option value="AB">AB</option>
                          <option value="O">O</option>
                        </Input>
                        <FormFeedback invalid>
                          E-mail inválido! favor informe um email correto
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm="4">
                      <FormGroup>
                        <Label className="text_form">RH</Label>
                        <Input
                          bsSize="sm"
                          type="text"
                          name="fatorRH"
                          minLength="1"
                          maxLength="1"
                          id="fatorRH"
                          value={fatorRH}
                          onChange={(event) => {
                            setRh(event.target.value);
                          }}
                          placeholder="+/-"
                        />
                        <FormFeedback invalid>
                          E-mail inválido! favor informe um email correto
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label className="text_form">Número de tênis</Label>
                        <Input
                          bsSize="sm"
                          type="number"
                          name="numeroTenis"
                          minLength="1"
                          maxLength="2"
                          max="2"
                          id="numeroTenis"
                          onChange={(event) => {
                            setNumeroTenis(event.target.value);
                          }}
                          value={numeroTenis}
                        />
                        <FormFeedback invalid>
                          E-mail inválido! favor informe um email correto
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label className="text_form">Camiseta</Label>
                        <Input bsSize="sm" type="select" name="tamanhoCamiseta" id="tamanhoCamiseta" onChange={(event) => {
                          setTamanhoCamiseta(event.target.value);
                        }} value={tamanhoCamiseta}>
                          <option value="Não informado" selected>
                            --Selecione--
                          </option>
                          <option value="INFANTIL"> Infantil</option>
                          <option value="PP">PP</option>
                          <option value="P">P</option>
                          <option value="M">M</option>
                          <option value="G">G</option>
                          <option value="GG">GG</option>
                          <option value="XG">XG</option>
                          <option value="XXG">XG3</option>
                        </Input>
                        <FormFeedback invalid>
                          E-mail inválido! favor informe um email correto
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Label  className="text_form">Conte mais sobre você</Label>
                    <Input
                      bsSize="sm"
                      type="textarea"
                      name="resumo"
                      id="resumo"
                      value={resumo}
                      onChange={(event) => {
                        setResumo(event.target.value);
                      }}
                      placeholder="..."
                    />
                    <FormFeedback invalid>
                      E-mail inválido! favor informe um email correto
                    </FormFeedback>
                  </FormGroup>
                  {messageAPI ? <Alert color={resultColor}>{messageAPI}</Alert> : null}
                  <CardText className="text-end">
                    <Button color="primary" outline onClick={Confirmar} className="button_form">Salvar</Button> &nbsp;&nbsp;&nbsp;&nbsp;  
                    <Link to="/perfil"><Button color="secondary" outline className="button_form">Fechar</Button></Link>

                  </CardText>
                </CardBody>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>

      <div>
        <Modal isOpen={modalCidade} className="p-0">
          <ModalHeader className="text_big">Selecione a cidade!</ModalHeader>
          <ModalBody className="pt-1 pb-3 text-left">
            <Form>
              <CardText className="pb-2">
                <Label className="text_form">Cidade:*</Label>
                <Input
                  onChange={(event) => {
                    setBuscaCidade(event.target.value);
                    buscarCidade(event.target.value);
                  }}
                  bsSize="sm"
                  type="text"
                  value={buscaCidade}
                  name="searchCity"
                  id="searchCity"
                  placeholder="Informe o nome da cidade"
                  minLength="3"
                  maxlength="80"
                  required
                />
              </CardText>
            </Form>
            {cidades.map((cidade) => (
              <Card onClick={() => {
                Selecionar(cidade);
              }} id={cidade.id}>
                <CardText className="m-1">
                  {cidade.nome} - {cidade.uf}
                </CardText>
              </Card>
            ))}

          </ModalBody>
          <ModalFooter>
            <Button color="primary" >
              Confirmar
            </Button>
            <Button color="secondary" onClick={Buscar}>
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </Card>
  )

};


function phoneMask(v) {

  let r = v.replace(/\D/g, "");
  r = r.replace(/^0/, "");
  if (r.length > 11) {
    r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
  } else if (r.length > 7) {
    r = r.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, "($1) $2-$3");
  } else if (r.length > 2) {
    r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
  } else if (v.trim() !== "") {
    r = r.replace(/^(\d*)/, "($1");
  }
  return r;
}

export default EditarPerfil;
