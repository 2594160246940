import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardGroup,
  CardHeader,
  CardImg,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap";
import Patrocinio from "../Home/Patrocinio";
import { Link } from "react-router-dom";
import logo from "../../assets/img/no-photo.png";
import { busca } from "../../api/api_athlete";
import ClipLoader from "react-spinners/ClipLoader";
import Countdown from "react-countdown";
import { FaTrophy } from 'react-icons/fa';

const ListaEventosAtleta = () => {
  let { id } = useParams();
  const [eventos, setEventos] = useState([]);
  let [loading, setLoading] = useState(true);
  const [showResult, setShowResult] = useState(false);
  const [showNoResult, setShowNoResult] = useState(false);
  const [mensagem, setMensagem] = useState("");
  const Completionist = () => <span>É hora de Correr!</span>;

  useEffect(() => {
    setLoading(true)
    busca(
      `eventos/${id}`,
      setEventos,
      setLoading,
      setShowResult,
      setShowNoResult
    );
    Mensagem(id);
  }, [id]);

  function Mensagem(idDiv) {
    switch (idDiv) {
      case "participou":
        setMensagem("Corridas que você participou.");
        break;
      case "queriaIr":
        setMensagem("Corridas que você disse que iria, mas falta dados da sua participação.");
        break;
      case "vou":
        setMensagem("Corridas que você planejou para participar.");
        break;
      case "queroIr":
        setMensagem("Corridas que você marcou que quer ir.");
        break;
      default:
        setMensagem("Todas as corridas que você participou ou planejou.");
    }
  }

  const Result = () => (
    <Card>
      <CardGroup className="pl-3 pr-3">
        {eventos.map((evento) => (
          id === "participou" || id === "queriaIr" ? (
            <Col sm="12" md="3" className="p-1">
              <Card className="m-1 text-center h-100">
                <CardText className="p-1">
                  <Link to={"/corridas/" + evento.idEvento}>
                    <CardImg
                      top
                      alt="Imagem do evento"
                      src={evento.logoEvento ? evento.logoEvento : logo}
                      className="img-fluid rounded-lg m-0 p-0 "
                    />
                  </Link>
                </CardText>
                <CardBody className="p-1 text-center">
                  <CardTitle className="p-0 text_medium">
                    {evento.nomeEvento}
                  </CardTitle>
                  <CardSubtitle className="mb-2 text_form">
                  {evento.recordPessoal === true ? (
                    <>
                    <FaTrophy color="#FFD43B" size="20px" /><Label className="text_medium">Recorde Pessoal {evento.modalidade}</Label><FaTrophy color="#FFD43B" size="20px" />
                    </>
                ) : null}
                    {evento.nomeCidadeEvento} - {evento.ufEstadoEvento}
                  </CardSubtitle>
                  <CardText className="m-0 text_form">
                    Data: {evento.dataEvento}
                  </CardText>
                  <CardText className="m-0 text_small">
                    Distância: {evento.modalidade}
                  </CardText>
                  <CardText className="m-0 text_small">
                    Tempo/Pace: {evento.tempoProva} / {evento.pace}
                  </CardText>
                </CardBody>
              </Card>
            </Col>
          ) : (
            <Col sm="12" md="3" className="p-1">
              <Card className="m-1 text-center h-100">
                <CardText className="p-1">
                  <Link to={"/corridas/" + evento.idEvento}>
                    <CardImg
                      top
                      alt="Imagem do evento"
                      src={evento.logoEvento ? evento.logoEvento : logo}
                      className="img-fluid rounded-lg m-0 p-0 "
                    />
                  </Link>
                </CardText>
                <Alert color="warning " className="text_alert m-0 p-0">
                  <Countdown date={Date.now() + evento.dataMilisegundos}>
                    <Completionist />
                  </Countdown>
                </Alert>
                <CardBody className="p-1 text-center">
                  <CardTitle className="text_medium p-0">
                    {evento.nomeEvento}
                  </CardTitle>
                  <CardSubtitle className="mb-2 text_form">
                    {evento.nomeCidadeEvento} - {evento.ufEstadoEvento}
                  </CardSubtitle>
                  <CardText className="text_small">{evento.dataEvento}</CardText>
                </CardBody>
              </Card>
            </Col>
          )

        ))}
      </CardGroup>
    </Card>
  );

  const NoResult = () => (
    <Card>
      <CardText className="text-center m-2">
        <Label className="text_medium">
          Que pena!
          <br />
          Não temos nenhum registro das suas corridas! Aproveite agora mesmo e
          cadastre sua corrida!
          <br />
          <Link to="/novoEvento">
            <Button color="success" className="mr-2" block>
              Cadastre uma corrida!
            </Button>
          </Link>
        </Label>
      </CardText>
    </Card>
  );

  return (
    <Container>
      <Row>
        <Col sm="10" className="p-2 pt-2">
          <Card className="mt-2">
            <CardHeader className="text-center p-1">
              <Label className="text_big">Meus Eventos</Label>
            </CardHeader>
          </Card>
          <Card>
            <Row className="m-0">
              <Col xs="6" sm="3" className="text-center p-1">
                <Link to={"participou"} className="no-underline text_medium">
                  <Alert
                    color={id === "participou" ? "success" : "secondary"}
                    id="0"
                    className={"p-1"}
                  >
                    &nbsp;&nbsp;PARTIPEI&nbsp;&nbsp;
                  </Alert>
                </Link>
              </Col>
              <Col xs="6" sm="3" className="text-center p-1">
                <Link to="queriaIr" className="no-underline text_medium">
                  <Alert
                    color={id === "queriaIr" ? "success" : "secondary"}
                    id="1"
                    className="p-1"
                  >
                    &nbsp;&nbsp;FUI?&nbsp;&nbsp;
                  </Alert>
                </Link>
              </Col>
              <Col xs="6" sm="3" className="text-center p-1">
                <Link to="vou" className="no-underline text_medium">
                  <Alert
                    color={id === "vou" ? "success" : "secondary"}
                    id="2"
                    className={"p-1"}
                  >
                    &nbsp;&nbsp;CONFIRMEI&nbsp;&nbsp;
                  </Alert>
                </Link>
              </Col>
              <Col xs="6" sm="3" className="text-center p-1">
                <Link to="queroIr" className="no-underline text_medium">
                  <Alert
                    color={id === "queroIr" ? "success" : "secondary"}
                    id="3"
                    className={"p-1"}
                  >
                    &nbsp;&nbsp;QUERO IR&nbsp;&nbsp;
                  </Alert>
                </Link>
              </Col>
            </Row>
            <CardFooter className="text-center">
              <Label className="m-0 text_medium">
                {mensagem}
              </Label>
            </CardFooter>
          </Card>
          <div className="text-center">
            <ClipLoader color="gray" loading={loading} size={60} />
          </div>
          {showResult ? <Result /> : <NoResult />}
          {showNoResult ? <NoResult /> : null}
        </Col>
        <Col sm="2" className="p-0 pt-2">
          <Patrocinio />
        </Col>
      </Row>
    </Container>
  );
}

export default ListaEventosAtleta;
