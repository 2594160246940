import React, { useEffect, useState } from "react";
import { busca } from "../../api/api_previa";
import {
  Alert,
 Badge,
} from "reactstrap";
import ClipLoader from "react-spinners/ClipLoader";
import { Link } from "react-router-dom/cjs/react-router-dom";

const Notificacao = ({ url }) => {
  const [temEvento, setTemEvento] = useState([]);
  let [loading, setLoading] = useState(true);
  const [showResult, setShowResult] = useState(false);
  const [showNoResult, setShowNoResult] = useState(false);

  useEffect(() => {
    busca(url, setTemEvento, setLoading, setShowResult, setShowNoResult);
  }, [url]);

  const Result = () => (
    <Alert className="mt-2 p-1 text_alert " color="danger">
      Encontramos corridas em seu nome. <Link to="/eventosEncontrados"> <Badge color="dark" className="p-2 text_alert">Entre e confirme!!</Badge> </Link>
    </Alert>

  );
  const NoResult = () => (
      <></>
  );


  return (
    <>
      <div className="text_medium">
        <ClipLoader color="gray" loading={loading} size={60} />
      </div>
      {temEvento ? <Result /> :  <NoResult /> }
    </>
  );
};

export default Notificacao;
