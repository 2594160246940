import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Alert,
  Col,
  Row,
  Button,
  Modal, 
  ModalBody,
  ModalHeader,
  ModalFooter,
  Card,
  CardText,
  Container,
  CardImg,
  CardTitle,
  CardSubtitle,
  CardFooter,
  Badge,
  UncontrolledAlert,
} from "reactstrap";
import "../../assets/css/minha_medalha.css";
import logo from "../../assets/img/no-logo.png";
import { upload } from "../../api/api_file_upload";
import { busca } from "../../api/api_adm_organizadora";
import ClipLoader from "react-spinners/ClipLoader";
import { getToken, getUrlApi } from "../../api/auth";

const Info = () => {
  const { id } = useParams();
  const [organizadora, setOrganizadora] = useState([]);
  let [loading, setLoading] = useState(true);
  const [showResult, setShowResult] = useState(false);
  const [showNoResult, setShowNoResult] = useState(false);
  const [modalInclusao, setModalInclusao] = useState(false);
  const [modalExclusao, setModalExclusao] = useState(false);
  const [confirmadoOk, setConfirmadoOk] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  
  
  function IncluirLogo() {
   upload(

      "/atleta/uploadLogo",
      selectedFile,
      setConfirmadoOk,
      confirmadoOk
    );
    AbrirEFecharModalTrocarLogo();
  }

  function fileSelectedHandler(event) {
    setSelectedFile(event.target.files[0])
  }

  const AbrirEFecharModalTrocarLogo = () => setModalInclusao(!modalInclusao);

  function ExcluirLogo() {
    const token = getToken();
    const requestInfo = {
      method: "GET",
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };
    fetch(getUrlApi() + "/atleta/removerLogo/", requestInfo).then(
      (response) => {
        response.json().then((data) => {
          if (response.status === 200) {
            setMessageAPI(data.message);
            setResultColor("success");
          } else {
            if (response.status === 202) {
              setMessageAPI(data.message);
            } else {
              if (response.status === 400) {
                setMessageAPI("Erro Inesperado"); //data[0].message);
              }
            }
            setResultColor("warning");
          }
        });
      }
    );
    setConfirmadoOk(!confirmadoOk);
    setModalExclusao(!modalExclusao);
  }

  const CancelarExcluirLogo = () => setModalExclusao(!modalExclusao);

  function Associar(codigoTime) {
    const token = getToken();
    const requestInfo = {
      method: "GET",
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };

    fetch(
      getUrlApi()+"/atleta/solicitarTime/" + codigoTime,
      requestInfo
    ).then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          setMessageAPI(data.message);
          setResultColor("success");
        } else {
          if (response.status === 202) {
            setMessageAPI(data.message);
          } else {
            if (response.status === 400) {
              setMessageAPI("Erro Inesperado"); //data[0].message);
            }
          }
          setResultColor("warning");
        }
      });
    });
  }
  const [messageAPI, setMessageAPI] = useState("");
  const [resultColor, setResultColor] = useState("");

  useEffect(() => {
    busca(
      `/dashboard`,
      setOrganizadora,
      setLoading,
      setShowResult,
      setShowNoResult
    );
  }, [id]);

  const Result = () => (
    <>
      <Card>
        <Row>
          <Col xs="11" sm="3" className="mt-3 ml-3 m-0 p-0">
            <CardText className="p-3 m-0">
              <CardImg
                src={organizadora.logo === null ? logo : organizadora.logo}
                alt="Logo da Assessoria"
                className="img-fluid rounded-circle"
                width="70"
                top
              />
            </CardText>
            <CardText className="text-center p-0">
              <Button color="primary" className="p-1 mb-2" onClick={AbrirEFecharModalTrocarLogo}>
                Trocar logo
              </Button>
              <Button color="danger" className="p-1 mb-2" onClick={ExcluirLogo}>
                Remover logo
              </Button>
            </CardText>
          </Col>
          <Col xs="12" sm="8" className="m-0 mt-3 p-2 pr-0 ">
            <Card>
              <CardTitle tag="h4" className="text-center">
                {organizadora.nome}
              </CardTitle>
              <CardSubtitle className="text-center mb-2">
               Cidade: {organizadora.nomeCidade ?   (organizadora.nomeCidade +" - "+ organizadora.uf): "Não informado" }
              </CardSubtitle>
              <CardText className="text-center mb-1">
                E-mail: {organizadora.email ? organizadora.email : "Não informado"}
              </CardText>
              <CardText className="text-center mb-1">
                Fone: {organizadora.fone ? organizadora.fone : "Não informado"}
              </CardText>
              <CardText className="text-center mb-1">
                Website: {organizadora.site ? organizadora.site : "Não informado"}
              </CardText>
              <CardText className="text-center mb-1">
                Desde: {organizadora.dataFundacao ? organizadora.dataFundacao : "Não informado"}
              </CardText>
            </Card>
            <Card className="mt-3 p-1 text-justify">
              Informações da assessoria: <br />
              {organizadora.resumo ? organizadora.resumo : (<Alert color="secondary">Nenhuma informação da sua assessoria.</Alert>) }
            </Card>
          </Col>
        </Row>
        {messageAPI ? (
          <UncontrolledAlert color={resultColor} className="m-2 text-center">
            {messageAPI}
          </UncontrolledAlert>
        ) : null}
        <CardFooter className="text-end">
        <Link to={"/teamAdmin/editar"}>
            <Badge color="primary p-2" block>
              Alterar dados
            </Badge>
          </Link>
        </CardFooter>
      </Card>
    </>
  );
  const NoResult = () => (
    <CardText className="p-3">
      Algo deu errado! Volte e verifique seu login!
    </CardText>
  );

  return (
    <Container className="p-1">
      <div className="text-center">
        <ClipLoader color="gray" loading={loading} size={60} />
      </div>
      {showResult ? <Result /> : null}
      {showNoResult ? <NoResult /> : null}
      <div>
        <Modal
          isOpen={modalInclusao}
          toggle={AbrirEFecharModalTrocarLogo}
          className="p-0"
        >
          <ModalHeader toggle={AbrirEFecharModalTrocarLogo}>
            Selecione uma Logo!
          </ModalHeader>
          <ModalBody>
            <Card className="p-0 text-center m-0">
              <input type="file" name="file" id="file" onChange={(event) => {
                    fileSelectedHandler(event);
                  }}
                   />
            </Card>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={IncluirLogo} >
              Confirmar
            </Button>{" "}
            <Button color="secondary" onClick={AbrirEFecharModalTrocarLogo}>
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>
      </div>
      <div>
        <Modal
          isOpen={modalExclusao}
          toggle={CancelarExcluirLogo}
          className="p-0"
        >
          <ModalHeader toggle={CancelarExcluirLogo}>
            Remover a Logo do perfil!
          </ModalHeader>
          <ModalBody className="pt-5 pb-5 text-center">
            Confirma a exclusão da sua Logo do perfil!!
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={ExcluirLogo}>
              Confirmar
            </Button>{" "}
            <Button color="secondary" onClick={CancelarExcluirLogo}>
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </Container>
  );
};

export default Info;
