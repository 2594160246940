import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardText,
  Col,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  ModalFooter,
  Row,
  UncontrolledAlert,
  Label,
  CardFooter,
  Form,
  Alert,
} from "reactstrap";
import { Link } from "react-router-dom";
import { busca } from "../../api/api_adm_team";
import ClipLoader from "react-spinners/ClipLoader";
import { getToken, getUrlApi } from "../../api/auth";
import Avatar from 'react-avatar';

function GerenciarAtleta() {
  let { id } = useParams();
  const [atletaPerfil, setAtletaPerfil] = useState([]);
  let [loading, setLoading] = useState(true);
  const [showResult, setShowResult] = useState(false);
  const [showNoResult, setShowNoResult] = useState(false);
  const [modalRejeitarAtleta, setModalRejeitarAtleta] = useState(false);
  const [modalRetiradaConvite, setModalRetiradaConvite] = useState(false);
  const [modalRemoverAtleta, setModalRemoverAtleta] = useState(false);
  const Remover = () => { 
    setModalRemoverAtleta(!modalRemoverAtleta);
    setMessageAPI(null);
    setAtualizar(!atualizar);
  };

  const [modalAlterarDataAtleta, setModalAlterarDataAtleta] = useState(false);
  const AjustarData = () => setModalAlterarDataAtleta(!modalAlterarDataAtleta);
  const [justificativa, setJustificativa] = useState("0");
  const [novaDataInicio, setNovaDataInicio] = useState(null);
  const [atualizar, setAtualizar] = useState(false);
  const [messageAPI, setMessageAPI] = useState("");
  const [resultColor, setResultColor] = useState("");
  const [siglaTime, setSiglaTime] = useState(null);
  const [dataInicioContato, setDataInicioContato] = useState(null);
  const [hoje, setHoje] = useState(Intl.DateTimeFormat("pt-BR", { timeZone: "UTC" }).format(new Date()));
  const [nome, setNome] = useState("");
  const [foto, setFoto] = useState("");
  const [idAtleta, setIdAtleta] = useState("");
  const [idHash, setIdHash] = useState("");
  const [apelido, setApelido] = useState("");
  const [cidade, setCidade] = useState("");
  const [block, setBlock] = useState(true);

  useEffect(() => {
    busca(
      `/gerenciaAtleta/${id}`,
      setAtletaPerfil,
      setLoading,
      setShowResult,
      setShowNoResult
    );
  }, []);


  if (atletaPerfil.id !== undefined && block) {
    const input = atletaPerfil.dataNascimento;
    setNome(atletaPerfil.nomeAtleta);
    setFoto(atletaPerfil.fotoAtleta);
    setApelido(atletaPerfil.apelidoAtleta);
    setSiglaTime(atletaPerfil.siglaTime);
    setIdAtleta(atletaPerfil.idAtleta);
    setIdHash(atletaPerfil.idHashAtleta);
    setDataInicioContato(atletaPerfil.dataInicioContato);
    if (atletaPerfil.cidadeAtleta === null) {
      setCidade(null);
    } else {
      setCidade(atletaPerfil.cidadeAtleta + " - " + atletaPerfil.ufAtleta)
    }
    setBlock(false);
    const [diaHoje, mesHoje, anoHoje] = hoje.split('/')
    setHoje(`${anoHoje}-${mesHoje}-${diaHoje}`);

  }

  function ConfirmarRemoverAtleta() {
    if (justificativa === "0") {
      setResultColor("warning");
      setMessageAPI("Selecione uma opção!");
      return;
    } else {
      setLoading(true);
      const token = getToken();
      const requestInfo = {
        method: "GET",
        headers: new Headers({
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
      };
      fetch(
        getUrlApi() +
        "/admin/time/removerAtleta/" +
        idAtleta +
        "/" +
        justificativa,
        requestInfo
      ).then((response) => {
        response.json().then((data) => {
          if (response.status === 200) {
            setAtualizar(!atualizar);
          } else {
            if (response.status === 202) {
              setMessageAPI(data.message);
            } else {
              if (response.status === 400) {
                setMessageAPI(data[0].message);
              }
            }
            setResultColor("warning");
          }
        });
      });
      Remover();
      setJustificativa("0");
      setLoading(false);
    }
  }

  function ConfirmarNovaDataAtleta() {
    setLoading(true);
    if (novaDataInicio === null) {
      setResultColor("warning");
      setMessageAPI("Informe a nova data!");
      return;
    } else {
      setLoading(true);
      let novaData = novaDataInicio;
      const [anoEnviar, mesEnviar, diaEnviar] = novaData.split('-')
      novaData = `${diaEnviar}/${mesEnviar}/${anoEnviar}`;
      const token = getToken();
      const requestInfo = {
        method: "GET",
        headers: new Headers({
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
      };
      fetch(
        getUrlApi() + "/admin/time/alterarDataAtleta/" + idAtleta + "/" + novaDataInicio,
        requestInfo
      ).then((response) => {
        response.json().then((data) => {
          if (response.status === 200) {
            setMessageAPI(data.message);
            setResultColor("success");
          } else {
            if (response.status === 202) {
              setMessageAPI(data.message);
            } else {
              if (response.status === 400) {
                setMessageAPI(data.message);
              }
            }
            setResultColor("warning");
          }
        });
      });
      AjustarData();
      setLoading(false);
    }
  }


  return (
    <>
      <Container className="pt-2 m-0">

        <Card className="m-0">
          <CardHeader className="text-center p-1 text_big" >
            <Row>
              <Col sm="12" className="m-0">
                Gerenciamento do Atleta
              </Col>
            </Row>
          </CardHeader>
        </Card>
        <Card className="m-0 text-center h-100 p-0 mb-2">
          <CardBody className="p-0 pt-3">
            <Row>
              <Col sm="4" xs="5" className="p-1 text-center">
                <Link
                  to={`/atleta/${idHash}`}
                  className="mr-3 ml-3 mt-0 mb-0"
                >
                  {foto === null ? (
                    <Avatar name={nome} size="100" round={true} maxInitials={2} />
                  ) : (
                    <img
                      src={foto}
                      style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '50%' }}
                    />
                  )}
                </Link>
              </Col>
              <Col sm="8" xs="7" className="p-0">
                <CardSubtitle className="pt-2 m-0 text_medium">
                  ({apelido})<br /> {nome}
                </CardSubtitle>
                <CardText className="m-0 text_form">
                  {cidade === null ? "Brasil" : cidade}
                </CardText>
                <CardText className="p-0 text_small">
                  <Label>Atleta {siglaTime} desde{" "}
                    {dataInicioContato}</Label>
                </CardText>
              </Col>
            </Row>
            <CardText className="p-4">
              <Row>
                <Col sm="4" xs="4" >
                  <Label className="text_medium">Total de corridas</Label><br />
                  <Label className="text_big">25</Label>
                </Col>
                <Col sm="4" xs="4" >
                  <Label className="text_medium">Corridas pela Assessoria</Label><br />
                  <Label className="text_big">17</Label>
                </Col>
                <Col sm="4" xs="4" >
                  <Label className="text_medium">Corridas planejadas</Label><br />
                  <Label className="text_big">3</Label>
                </Col>
              </Row>
            </CardText>
          </CardBody>
          <CardFooter>
            <Row>
              <Col sm="4" xs="4" className="p-0">
                <Link to={`/atleta/${idHash}`}>
                  <Badge color="primary" pill className="p-2">
                    Ir para perfil
                  </Badge>
                </Link>
              </Col>
              <Col sm="4" xs="4" className="p-1">
                <Badge color="secondary" pill className="p-2"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    AjustarData();
                  }} >
                  Ajustar data início
                </Badge>
              </Col>
              <Col sm="4" xs="4" className="p-1">
                <Badge color="danger" pill className="p-2"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    Remover();
                  }} >
                  Remover atleta
                </Badge>
              </Col>
            </Row>
          </CardFooter>
        </Card>

      </Container >
      <div>
        <Modal isOpen={modalRemoverAtleta} toggle={Remover} c className="p-0">
          <ModalHeader toggle={Remover} className="text_big">Remover Atleta do Time!</ModalHeader>
          <Form>
            <ModalBody className="pt-1 pb-3 text-left">
              <Label className="text_medium">
                Você está retirando o atleta{" "}
                {nome}{" "}do seu time.
              </Label>
              <Label className="text_form">Poderia nos informar o motivo?</Label>
              <Input
                bsSize="sm"
                type="select"
                name="justificativa"
                id="justificativa"
                onChange={(event) => {
                  setJustificativa(event.target.value);
                }}
              >
                <option value="0" selected>
                  Selecione
                </option>
                <option value="Não deseja informar">Não deseja informar</option>
                <option value="Atleta desconhecido">Atleta desconhecido</option>
                <option value="Atleta não está mais conosco">
                  Atleta não faz parte do time
                </option>
                <option value="Atleta falecido">Atleta falecido</option>
              </Input>
              <ModalFooter className="text_small">
                Lembrando que mesmo você removendo o atleta do time, as corridas registradas para este atleta no período serão contabilizadas para a sua Assessoria!
              </ModalFooter>
                <div className="text-center">
                  <ClipLoader color="gray" loading={loading} size={60} />
                </div>
                {messageAPI ? (
                  <Alert
                    className="m-2 p-1 text-center"
                    color={resultColor}
                  >
                    {messageAPI}
                  </Alert>
                ) : null}
            </ModalBody>
          </Form>
          <ModalFooter>
            <Button
              color="secondary"
              outline
              onClick={Remover}
            >
              Cancelar
            </Button>
            <Button color="primary" outline onClick={ConfirmarRemoverAtleta}>
              Confirmar
            </Button>
          </ModalFooter>
        </Modal>
      </div>
      <div>
        <Modal isOpen={modalAlterarDataAtleta} className="p-0">
          <ModalHeader className="text_big">Alterar data de início do Contrato com Atleta do Time!</ModalHeader>
          <ModalBody className="pt-1 pb-3 text-left">
            <Form>
              <Label className="text_medium">
                {nome}{" "}é atleta do seu time desde {dataInicioContato}.
              </Label>
              <Label className="text_form">Informe a data correta do início do Atleta</Label>
              <Input
                bsSize="sm"
                type="date"
                name="novaDataInicio"
                minLength="5"
                maxLength="8"
                id="novaDataInicio"
                value={novaDataInicio}
                pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                onChange={(event) => {
                  setNovaDataInicio(event.target.value);
                }}
                required
              />
            </Form>
          </ModalBody>
          <ModalFooter className="text_small">
            Após confirmação, iremos enviar um e-mail para o atleta solicitando a confirmação desta mudança.<br />
            A mudança só irá acontecer quando o atleta confirmar, então, iremos atualizar todas as corridas do novo período!
          </ModalFooter>
          <div className="text-center">
            <ClipLoader color="gray" loading={loading} size={60} />
          </div>
          {messageAPI ? (
            <UncontrolledAlert
              className="m-2 p-1 text-center"
              color={resultColor}
            >
              {messageAPI}
            </UncontrolledAlert>
          ) : null}
          <ModalFooter>
            <Button
              color="secondary"
              outline
              onClick={AjustarData}
            >
              Cancelar
            </Button>
            <Button color="primary" outline onClick={ConfirmarNovaDataAtleta}>
              Confirmar
            </Button>
          </ModalFooter>
        </Modal>
      </div>
      {messageAPI ? (
        <Alert  className="m-3 p-3 text-center" color={resultColor}>
          {messageAPI}
        </Alert>
      ) : null}
      <div className="text-center">
        <ClipLoader color="gray" loading={loading} size={60} />
      </div>
    </>
  );
};

export default GerenciarAtleta;
