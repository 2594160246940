import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  UncontrolledAlert,
  Col,
  Row,
  CardBody,
  Label,
} from "reactstrap";
import { busca } from "../../api/api_event";
import ClipLoader from "react-spinners/ClipLoader";
import RegistrarAtleta from "./EditaRegistroAtleta";
import { FaTrophy } from 'react-icons/fa';

const DetalheAtletaEvento = (props) => {
  const { id } = useParams();
  const [atletaCorrida, setAtletaCorrida] = useState([]);
  let [loading, setLoading] = useState(true);
  const [showResult, setShowResult] = useState(false);
  const [showNoResult, setShowNoResult] = useState(false);
  const [showInfoAtleta, setShowInfoAtleta] = useState(false);
  const [atualiza, setAtualiza] = useState(false);
  const [messageAPI, setMessageAPI] = useState("");
  const [resultColor, setResultColor] = useState("");


  function AlterarCancelar(atualizar) {
    setShowResult(!showResult);
    if (atualizar) {
      setAtualiza(!atualiza);
      setMessageAPI("Registro atualizado com sucesso!");
      setResultColor("success");
    } else {
      setMessageAPI("");
      setResultColor("");
    }
  }

  function EhDataAntiga(dataInput) {
    var hoje2 = new Date();
    let inputDate = dataInput;
    const [year1, month1, day1] = inputDate.split("/");
    inputDate = `${day1}-${month1}-${year1}`;
    var dataInformada = new Date(inputDate);
    if (hoje2 > dataInformada) {
      setShowInfoAtleta(false);
    } else {
      setShowInfoAtleta(true);
    }
  }


  useEffect(() => {
    busca(
      `/corridaAtleta/${id}`,
      setAtletaCorrida,
      setLoading,
      setShowResult,
      setShowNoResult
    );
  }, [atualiza, id]);

  const Result = () => (
    <Card className="mb-2 mt-2">
      <CardHeader className="p-1">
        <Row>
          <Col xs="8" tag="h5" className=" mt-1 text_big">
            Suas informações
          </Col>
          <Col xs="4" className="text-end">
            <Button
              color="primary"
              outline
              onClick={() => {
                AlterarCancelar(false);
                EhDataAntiga(atletaCorrida.dataEvento);
              }}
            >
              Alterar
            </Button>
          </Col>
        </Row>
      </CardHeader>
      {messageAPI ? <UncontrolledAlert className="m-2 p-2 text_alert" color={resultColor}>{messageAPI}</UncontrolledAlert> : null}
      <CardBody className="text-center">
        <Row>
          {atletaCorrida.recordPessoal === true ? (
            <Col sm="12" xs="12" className="text-center">
              <FaTrophy color="#FFD43B" size="30px" />&nbsp;<Label className="text_big">Recorde Pessoal nos {atletaCorrida.modalidade}</Label>&nbsp;<FaTrophy color="#FFD43B" size="30px" />
            </Col>
          ) : null}
          <Col sm="6" >
            <Row>
              <Col sm="6" xs="6" className="text-end">
                <Label className="text_banco">Distância: </Label>
              </Col>
              <Col sm="6" xs="6" className="text-start">
                <Label className="text_banco "> {atletaCorrida.modalidade}</Label>
              </Col>
              <Col sm="6" xs="6" className="text-end">
                <Label className="text_banco">Número camiseta: </Label>
              </Col>
              <Col sm="6" xs="6" className="text-start">
                <Label className="text_banco">{atletaCorrida.numeroAtleta}</Label>
              </Col>
              <Col sm="6" xs="6" className="text-end">
                <Label className="text_banco">Tempo: </Label>
              </Col>
              <Col sm="6" xs="6" className="text-start">
                <Label className="text_banco"> {atletaCorrida.tempoProva}</Label>
              </Col>
              <Col sm="6" xs="6" className="text-end">
                <Label className="text_banco">Pace: </Label>
              </Col>
              <Col sm="6" xs="6" className="text-start">
                <Label className="text_banco">{atletaCorrida.pace}</Label>
              </Col>
            </Row>
          </Col>
          <Col sm="6" >
            <Row>
              <Col sm="6" xs="6" className="text-end">
                <Label className="text_banco">Colocação geral: </Label>
              </Col>
              <Col sm="6" xs="6" className="text-start">
                <Label className="text_banco "> {atletaCorrida.posicaoChegadaGeral}</Label>
              </Col>
              <Col sm="6" xs="6" className="text-end">
                <Label className="text_banco">Colocação por sexo: </Label>
              </Col>
              <Col sm="6" xs="6" className="text-start">
                <Label className="text_banco">{atletaCorrida.posicaoChegadaSexo}</Label>
              </Col>
              <Col sm="6" xs="6" className="text-end">
                <Label className="text_banco">Colocação por categoria: </Label>
              </Col>
              <Col sm="6" xs="6" className="text-start">
                <Label className="text_banco"> {atletaCorrida.posicaoChegadaIdade}</Label>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );

  const NoResult = () => <></>;

  return (
    <>
      <div className="text-center">
        <ClipLoader color="gray" loading={loading} size={60} />
      </div>
      {showResult ? <Result /> : (
        <Card className="mb-2 mt-2">
          <RegistrarAtleta mod={props.mod} atleta={atletaCorrida} showInfos={showInfoAtleta} alterarCancelar={AlterarCancelar} />
        </Card>
      )
      }
      {showNoResult ? <NoResult /> : null}
    </>
  );
};
export default DetalheAtletaEvento;
