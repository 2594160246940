import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Col,
  Row,
  Card,
  CardText,
  Container,
  CardTitle,
  CardSubtitle,
  CardFooter,
  CardGroup,
  CardBody,
  Badge,
  Label,
  Alert,
  CardLink,
} from "reactstrap";
import "../../assets/css/minha_medalha.css";
import { busca } from "../../api/api_teams";
import ClipLoader from "react-spinners/ClipLoader";
import { getToken, getUrlApi } from "../../api/auth";
import Avatar from 'react-avatar';

const DetalheAssessoria = () => {
  const { id } = useParams();
  const [assessoria, setAssessoria] = useState([]);
  const [atletas, setAtletas] = useState([]);
  let [loading, setLoading] = useState(true);
  const [showResult, setShowResult] = useState(false);
  const [showNoResult, setShowNoResult] = useState(false);

  function Associar(codigoTime) {
    const token = getToken();
    const requestInfo = {
      method: "GET",
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };

    fetch(
      getUrlApi() + "/atleta/solicitarTime/" + codigoTime,
      requestInfo
    ).then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          setMessageAPI(data.message);
          setResultColor("success");
        } else {
          if (response.status === 202) {
            setMessageAPI(data.message);
          } else {
            if (response.status === 400) {
              setMessageAPI("Erro Inesperado"); //data[0].message);
            }
          }
          setResultColor("warning");
        }
      });
    });
  }
  const [messageAPI, setMessageAPI] = useState("");
  const [resultColor, setResultColor] = useState("");

  useEffect(() => {
    setLoading(true);
    busca(
      `/assessoria/${id}`,
      setAssessoria,
      setLoading,
      setShowResult,
      setShowNoResult
    );

    busca(
      `/listaAtletas/${id}`,
      setAtletas,
      setLoading,
      setShowResult,
      setShowNoResult
    );

  }, [id]);

  const Result = () => (
    <>
      <Card>
        <Row>
          <Col xs="11" sm="3" className="mt-3 ml-3 m-0 p-0">
            <CardText className="p-3 pt-2 m-0 text-center">
              {assessoria.logo === null ? (
                <Avatar name={assessoria.nome} size="200" round={true} maxInitials={3} />
              ) : (
                <Avatar src={assessoria.logo} size="200" round={true} />
              )}
            </CardText>
          </Col>
          <Col xs="12" sm="8" className="m-0 mt-3 p-3 pr-0 ">
            <Card className="text-center">
              <CardTitle tag="h4" className="text_big">
                {assessoria.sigla} {assessoria.nome}
              </CardTitle>
              <CardSubtitle className="text_banco mb-3">
                {assessoria.nomeCidade} - {assessoria.uf}
              </CardSubtitle>
              <CardSubtitle className="text_banco mb-3">
                {assessoria.email}
              </CardSubtitle>
              <CardSubtitle className="text_banco mb-3">
                {assessoria.fone}
              </CardSubtitle>
              <CardLink className="text_banco mb-3" href={"http://"+assessoria.site} target="_blank">
                {assessoria.site}
              </CardLink>
            </Card>
            <Card className="mt-3 p-1 text-justify">
              <Label className="text_form">Informações da assessoria:</Label>
              <Label className="text_banco">{assessoria.resumo}</Label>
            </Card>
          </Col>
        </Row>
        {messageAPI ? (
          <Alert color={resultColor} className="m-2 text-center text_alert">
            {messageAPI}
          </Alert>
        ) : null}
        <CardFooter className="text-end">
          {assessoria.myTeam ? (
            <Badge color="secondary" pill className="p-2">
              Deixar a assessoria
            </Badge>
          ) : (
            <Link>
              <Badge
                color="primary"
                pill
                className="p-2"
                onClick={() => {
                  Associar(assessoria.id);
                }}
              >
                Solicitar Associação
              </Badge>
            </Link>
          )}
        </CardFooter>
      </Card>
      <Card className="mt-2 pb-3">
        {assessoria.atletas.length === 0 ? (
          <CardText className="text-center text_form p-3">
            Está Assessoria não possuí atletas associados.
          </CardText>
        ) : (
          <CardGroup>
            {atletas.map((atleta) => (
              <Col sm="12" md="3" className="p-1">
                <Card className="m-2 pt-2 text-center h-100">
                  <Row>
                  <Col xs="6" md="12">
                  <Link to={`/atleta/${atleta.idHash}`}>
                    {atleta.foto === null ? (
                      <Avatar name={atleta.nome} size="150" round={true} maxInitials={3} />
                    ) : (
                      <img
                      src={atleta.foto}
                      style={{ width: '150px', height: '150px', objectFit: 'cover', borderRadius: '50%' }}
                    />  
                    )}
                  </Link>
                  </Col>
                  <Col xs="6" md="12">
                  <CardBody className="p-0">
                    <CardTitle className="text_big">{atleta.apelido}</CardTitle>
                    <CardSubtitle className="text_banco">{atleta.nome}</CardSubtitle>
                    <CardText  className="text_banco">
                      {atleta.uf ? atleta.nomeCidade+" - "+atleta.uf : atleta.nomeCidade }
                    </CardText>
                  </CardBody>
                  <CardFooter className="p-1">
                    <Link to={`/atleta/${atleta.idHash}`}>
                      <Badge color="primary" pill className="p-2">
                        Ver perfil
                      </Badge>
                    </Link>
                  </CardFooter>
                  </Col>
                  </Row>
                </Card>
              </Col>
            ))}
          </CardGroup>
        )}
      </Card>
    </>
  );
  const NoResult = () => (
    <CardText className="p-3 text_alert">
      Algo deu errado! Volte e selecione novamente uma assessoria!
    </CardText>
  );

  return (
    <Container className="p-1">
      <div className="text-center">
        <ClipLoader color="gray" loading={loading} size={60} />
      </div>
      {showResult ? <Result /> : null}
      {showNoResult ? <NoResult /> : null}
    </Container>
  );
};

export default DetalheAssessoria;
