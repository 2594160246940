import { React, useState } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { getToken, getUrlApi, getHash } from "../../../api/auth";
import ClipLoader from "react-spinners/ClipLoader";

const NovoAnuncio = () => {
  const [idAnuncio, setIdAnuncio] = useState(null);
  const [nomeAnuncio, setNomeAnuncio] = useState("");
  const [messageAPI, setMessageAPI] = useState("");
  const [resultColor, setResultColor] = useState("");
  let [loadingPage, setLoadingPage] = useState(false);

  function CadastrarAnuncio() {
    setLoadingPage(true);
    const token = getToken();
    const requestInfo = {
      method: "POST",
      body: JSON.stringify({
        nome: `${nomeAnuncio}`,
      }),
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };
    fetch(
      getUrlApi() + "/admin/anuncio/enviaDados",
      requestInfo
    ).then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          setIdAnuncio(data.idSuccess);
          setMessageAPI(data.message);
          setResultColor("success");
          window.location.href = `editarAnuncio/${data.idSucesso}`;
        } else {
          if (response.status === 202) {
            setMessageAPI(data.message);
          } else {
            if (response.status === 400) {
              setMessageAPI(data[0].message);
            }
            if (response.status === 500) {
              setMessageAPI("erro inesperado");
            }
            setLoadingPage(false);
          }
          setResultColor("warning");
        }
      });
    });
  }

  return (
    <Container className="p-0 pt-2">
      <Row>
        <Col sm="3"></Col>
        <Col sm="6">
          <Card className="p-2">
            <Form
              method="Post"
              onSubmit={(event) => {
                event.preventDefault();
              }}
            >
              <CardTitle className="text-center mt-2 pt-2">
                <Label className="text_big">Cadastro de Novo Anúncio</Label>
              </CardTitle>
              <CardBody>
                <FormGroup>
                  <Label className="text_form">Informe o nome do Anúncio</Label>
                  <Input
                    bsSize="sm"
                    name="nomeAnuncio"
                    id="nomeAnuncio"
                    minLength="5"
                    onChange={(event) => {
                      event.preventDefault();
                      setNomeAnuncio(event.target.value);
                    }}
                    value={nomeAnuncio}
                    required
                  />
                </FormGroup>
                {messageAPI ? (
                  <Alert color={resultColor}>{messageAPI}</Alert>
                ) : null}
                <CardText>
                  <Row>
                    <Col xs="3">&nbsp;</Col>
                    <Col xs="6">
                      {getHash() !== 'rubensrk' ? (
                        <Alert color="warning">Você não é administrador</Alert>
                      ) : (
                        <Button
                          name="Cadastrar"
                          color="primary"
                          block
                          onClick={CadastrarAnuncio}
                          className="button_form"
                        >
                          Cadastrar
                        </Button>
                      )}
                    </Col>
                    <Col xs="3">&nbsp;</Col>
                  </Row>
                </CardText>
              </CardBody>
              <div className="text-center">
                <ClipLoader color="gray" loading={loadingPage} size={60} />
              </div>
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default NovoAnuncio;