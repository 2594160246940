import React from 'react';
import { useParams } from "react-router-dom";
import MenuAssessoria from "./MenuOrganizadora"

import { Container, Col, Row,Alert } from "reactstrap";
import Patrocinio from '../Home/Patrocinio';
import ListaEventos from './ListaEventos';

function Lista(){
    let { id } = useParams();
    return (
        <Container>
            <Alert color="info" className="mt-2 p-0 mb-0">
                Módulo Organizadora de Eventos
            </Alert>                
            <Row>
                <Col sm="2" className="p-0 pb-2 pt-0">
        	        <MenuAssessoria url={'/menuOrganizadora'} />
                </Col>
                <Col sm="8" className="p-2 pt-0">
        	        <ListaEventos url={'/eventos/'+id} />
                </Col>
                <Col sm="2" className="p-0 pt-2">
        	        <Patrocinio />
                </Col>
            </Row>
        </Container>    
    )
}


export default Lista