import axios from "axios";
import { getToken, getUrlApi } from "./auth";

export const api = axios.create({
  baseURL: getUrlApi()+"/admin/anuncio/"
 
});

api.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export const busca = async (
  url,
  setDado,
  setLoading,
  setShowResult,
  setShowNoResult
) => {
  try {
    const resposta = await api.get(url);
    setDado(resposta.data);
    setLoading(false);
    if ( resposta.data.length === 0 ){
       setShowNoResult(true);
    }else{
      setShowNoResult(false);
    }
    setShowResult(true);
  } catch (resposta) {
    setLoading(false);
    setShowNoResult(true);
  }
};
