import axios from "axios";
import { getToken, getUrlApi } from "./auth";


export const api = axios.create({
  baseURL: getUrlApi()+"/evento/",
});

api.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export const busca = async (
  url,
  setDado,
  setLoading,
  setShowResult,
  setShowNoResult,
  idOrganizadoraAtleta
) => {
  try {
    const resposta = await api.get(url);
    setDado(resposta.data);
    setLoading(false);
    if ( resposta.data.length === 0 ){
       setShowNoResult(true);
    }else{
      if (resposta.data.idOrganizadoraEvento === idOrganizadoraAtleta / 7) {
        setShowNoResult(false);
        setShowResult(true);
      }else{
        setShowNoResult(true);
      }
    }
  } catch (resposta) {
    setLoading(false);
    setShowNoResult(true);
  }
};
