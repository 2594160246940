import React from "react";
import {
  Container,
  Label,
  Card,
  CardTitle,
  CardText,
  Row,
  Col,
} from "reactstrap";
import Rodape from "../Home/Rodape";
import CabecalhoAjuda from "./CabecalhoAjuda";

function PoliticaDeCookies() {

  return (
    <Container className="text-center">
      <CabecalhoAjuda/>
      <Card className="p-3 text-start">
        <Row>
          <Col xs="12" sm="2" >&nbsp;</Col>
          <Col xs="12" sm="8"  >
            <CardTitle className="text-center">Política de Cookies</CardTitle>
            <CardText>Sobre cookies</CardText>
            <CardText>
              <Label className="text_medium">O que são cookies?</Label>
              <Label className="text_small">Cookies são pequenos arquivos de texto contendo uma cadeia de caracteres que podem ser armazenados em seu computador ou dispositivo móvel e identificam seu navegador ou dispositivo de modo exclusivo. Podemos usar tecnologias como cookies, pixels e armazenamento local para veicular, proteger e entender produtos, serviços e anúncios.</Label>
            </CardText>
            <CardText>
              <Label className="text_medium">Qual é a função dos cookies?</Label>
              <Label className="text_small">Os cookies assim como outras tecnologias permitem que um site ou serviços saibam se seu computador ou dispositivo os visitou antes. Essas tecnologias podem ser usadas para veicular produtos, serviços e anúncios, ajudar a entender como o site ou serviço está sendo usado, auxiliar a navegar pelas páginas com eficiência e a lembrar suas preferências, além de melhorar sua experiência de uso de nossos serviços de forma geral. Os cookies também podem ajudar a garantir que a publicidade vista online é mais relevante para você e seus interesses.</Label>
            </CardText>
            <CardText>
              <Label className="text_medium">Por que o utilizamos cookies?</Label>
              <Label className="text_small">Usamos cookies, armazenamento local e tecnologias semelhantes para mostrar conteúdo relevante para você, melhorar sua experiência. Podemos usar essas tecnologias para veicular o serviço, fornecer um serviço fácil de usar, permitir que você circule pelo Serviço e use os recursos dele, como acessar áreas seguras e armazenar informações para que o Minhas Medalhas responda de forma mais rápida. Podemos usar essas tecnologias para coletar informações sobre como você usa o Serviço, como por exemplo, quais páginas você acessa com mais frequência e se recebe mensagens de erro de determinadas páginas. Podemos usar essas tecnologias para retomar suas opções (como seu nome de usuário ou região em que se encontra) e personalizar o Serviço para fornecer conteúdo e recursos aprimorados para você. Esses cookies também podem ser usados para lembrar alterações feitas por você em relação a partes personalizáveis do site. O Minha Medalhas ou nossos parceiros anunciantes podem usar essas tecnologias para veicular publicidade relevante para seus interesses. Essas tecnologias podem lembrar que seu dispositivo acessou um site ou serviço. Essas informações podem ser compartilhadas com organizações fora do Minhas Medalhas, como anunciantes e/ou redes de anunciantes, para veicular anúncios e ajudar a mensurar a eficácia de uma campanha publicitária.</Label>
            </CardText>
            <CardText>
              <Label className="text_medium">Por quanto tempo os cookies permanecerão em meu dispositivo?</Label>
              <Label className="text_small">
                O tempo que um cookie permanecerá em seu computador ou dispositivo móvel varia conforme se trate de um cookie “persistente” ou de “sessão”. Os cookies de sessão permanecerão em seu dispositivo até que você feche o navegador. Cookies persistentes permanecem em seu computador ou dispositivo móvel até expirarem ou serem excluídos.
                Cookies internos e de terceiros
                Os cookies internos são aqueles que pertencem ao Minhas Medalhas ou a outro Produto da Meta. Cookies de terceiros são cookies que terceiros colocam em seu dispositivo por meio de nosso Serviço. Os cookies de terceiros podem ser colocados em seu dispositivo por alguém que forneça serviços de publicidade, mensuração, marketing e analytics para o Minhas Medalhas, ou com o objetivo de fornecer determinados recursos e melhorar nossos serviços para você. Por exemplo, cookies de outras empresas podem nos ajudar a entender como nosso serviço está sendo usado. Os cookies de terceiros também podem ser colocados em seu dispositivo por nossos parceiros de negócios para que eles possam usá-los para anunciar produtos e serviços para você, mensurar o desempenho e eficácia desses anúncios e dar suporte ao marketing e ao analytics.
              </Label>
            </CardText>
            <CardText>

              <Label className="text_medium">Como você pode controlar os cookies?</Label>
              <Label className="text_small">
                Usamos cookies para ajudar a personalizar e melhorar o conteúdo e os serviços, proporcionar uma experiência mais segura e mostrar a você anúncios úteis e relevantes dentro e fora do Minhas Medalhas. Você pode controlar como usamos os dados para mostrar anúncios e muito mais usando as ferramentas descritas abaixo.
                Se você tiver uma conta do Minhas Medalhas, poderá gerenciar suas configurações de privacidade em Configurações.
                Siga as instruções fornecidas pelo seu site ou navegador para celular (geralmente localizados em "Ajuda", "Ferramentas" ou "Editar") para controlar nossos cookies em outros aplicativos e sites, e cookies de outras empresas no Minhas medalhas. Observe que se você configurar seu navegador para desativar cookies ou outras tecnologias, talvez não seja possível acessar algumas partes de nosso Serviço, e outras partes de nosso Serviço talvez não funcionem apropriadamente.
              </Label>
            </CardText>
          </Col>
          <Col xs="12" sm="2" >&nbsp;</Col>
        </Row>
      </Card>
      <Rodape />
    </Container>
  );
}

export default PoliticaDeCookies;
