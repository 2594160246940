import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Badge,
  Col,
  Row,
  Card,
  CardTitle,
  CardSubtitle,
  CardText,
  CardBody,
  Label,
  Alert,
} from "reactstrap";
import "../../assets/css/minha_medalha.css";
import { busca } from "../../api/api_athlete";
import ClipLoader from "react-spinners/ClipLoader";
import { NOME_ATL, FOTO_ATL, HASH_ATL, AUTH_ORG } from "../../api/auth";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
import Avatar from "react-avatar";

const MenuAtleta = ({ url }) => {
  const [atleta, setAtleta] = useState([]);
  let [loading, setLoading] = useState(true);
  const [showResult, setShowResult] = useState(false);
  const [showNoResult, setShowNoResult] = useState(false);
  localStorage.setItem(FOTO_ATL, atleta.foto);
  localStorage.setItem(NOME_ATL, atleta.nome);
  localStorage.setItem(HASH_ATL, atleta.idhash);
  localStorage.setItem(AUTH_ORG, atleta.idOrganizadora);
  const [block, setBlock] = useState(true);
  const cardStyle = { border: 'none' };

  useEffect(() => {
    busca(url, setAtleta, setLoading, setShowResult, setShowNoResult);

  }, [url]);

  if (atleta.id !== undefined && block) {
    if (atleta.status === 'PENDENTE') {
      setShowResult(false);
      setShowNoResult(true);
    }

    setBlock(false);
  }

  const Result = () => (
    <Card className="mb-3 mt-2 p-1 text-center">
      <CardBody className="p-0 text-center">
        <Row>
          <Col xs="4" md="12">
            <CardText className="pt-2 pr-3 pl-3 pb-0">
              <Link to={"/perfil/"}>
                {atleta.foto === null ? (
                  <Avatar name={atleta.nome} size="150" round={true} maxInitials={3} />
                ) : (
                  <img
                    src={atleta.foto}
                    style={{ width: '150px', height: '150px', objectFit: 'cover', borderRadius: '50%' }}
                  />
                )}
              </Link>
            </CardText>
          </Col>
          <Col xs="8" md="12">
            <CardTitle className="p-0 text_big">
              {atleta.apelido}
            </CardTitle>
            <CardSubtitle className="mb-2 text_medium">
              {atleta.nome}
            </CardSubtitle>
            <CardText className="text_banco">
              {atleta.nomeCidade} - {atleta.uf}
            </CardText>
            <Link to={"/perfil/editar"}>
              <Badge color="primary p-2">Editar dados</Badge>
            </Link>
            {atleta.ehAdministradorTime ? (
              <div className="p-2">
                <Link to={"/AdminTime"}>
                  <Badge color="success p-2">Acessar Módulo Assessoria</Badge>
                </Link>
              </div>
            ) : null}
            {atleta.ehAdministradorOrganizadora ? (
              <div className="p-2">
                <Link to={"/AdminOrganizadora"}>
                  <Badge color="success p-2">Módulo Organizadora</Badge>
                </Link>
              </div>
            ) : null}
            {atleta.id === 2 ? (
              <div className="p-2">
                <Link to={"/Admin"}>
                  <Badge color="success p-2">Módulo Administrador</Badge>
                </Link>
              </div>
            )
              : null}
          </Col>
        </Row>
      </CardBody>
      <div align="center">
        <Card className="mt-2" >
          <Label className="p-1 text_medium">Eventos:</Label>
          <Row>
            <Col xs="6" md="12">
              <Link to="/eventosAtleta/participou">
                <Card className="m-1" style={cardStyle}  >
                  <Alert color="success" className="p-0 m-0">
                    <Row align="left">
                      <Col xs="9">
                        <Label className="text_medium">
                          <Link to="/eventosAtleta/participou" className="no-underline text_medium">
                            Participou:
                          </Link>
                        </Label>
                      </Col>
                      <Col xs="3" className="text-end">
                        <Label className="text_medium">
                          <Link to="/eventosAtleta/participou" className="no-underline text_medium">
                            {atleta.eventosQueFui}
                          </Link>
                        </Label>
                      </Col>
                    </Row>
                  </Alert>
                </Card>
              </Link>
              <Link to="/eventosAtleta/queriaIr">
                <Card className="m-1" style={cardStyle}   >
                  <Alert color="success" className="p-0 m-0">
                    <Row align="left">
                      <Col xs="9">
                        <Label className="text_medium">
                          <Link to="/eventosAtleta/queriaIr" className="no-underline text_medium">
                            Marcou que iria:
                          </Link>
                        </Label>
                      </Col>
                      <Col xs="3" className="text-end">
                        <Label className="text_medium">
                          <Link to="/eventosAtleta/queriaIr" className="no-underline text_medium">
                            {atleta.eventosQueQueriaIr}
                          </Link>
                        </Label>
                      </Col>
                    </Row>
                  </Alert>
                </Card>
              </Link>
            </Col>
            <Col xs="6" md="12">
              <Link to="/eventosAtleta/vou">
                <Card className="m-1" style={cardStyle} >
                  <Alert color="warning" className="p-0 m-0">
                    <Row align="left">
                      <Col xs="9">
                        <Label className="text_medium">
                          <Link to="/eventosAtleta/vou" className="no-underline text_medium">
                            Você vai:
                          </Link>
                        </Label>
                      </Col>
                      <Col xs="3" className="text-end">
                        <Label className="text_medium">
                          <Link to="/eventosAtleta/vou" className="no-underline text_medium">
                            {atleta.eventosQueVou}
                          </Link>
                        </Label>
                      </Col>
                    </Row>
                  </Alert>
                </Card>
              </Link>
              <Link to="/eventosAtleta/queroIr">
                <Card style={cardStyle} className="m-1" >
                  <Alert color="warning" className="p-0 m-0">
                    <Row align="left">
                      <Col xs="9">
                        <Label className="text_medium">
                          <Link to="/eventosAtleta/queroIr" className="no-underline text_medium">
                            Quero ir:
                          </Link>
                        </Label>
                      </Col>
                      <Col xs="3" className="text-end">
                        <Label className="text_medium">
                          <Link to="/eventosAtleta/queroIr" className="no-underline text_medium">
                            {atleta.eventosQueQueroIr}
                          </Link>
                        </Label>
                      </Col>
                    </Row>
                  </Alert>
                </Card>
              </Link>
            </Col>
          </Row>

        </Card>
        <Card className="mt-2">
          <Label className="pt-2 text_medium">Contatos:</Label>
          <Row>
            <Col xs="6" md="12">
              <Link to="/contatos/amigos">
                <Card className="m-1" style={cardStyle} >
                  <Alert color="success" className="p-0 m-0">
                    <Row align="left">
                      <Col xs="9">
                        <Label className="text_medium">
                          <Link to="/contatos/amigos" className="no-underline text_medium">
                            Amigos:
                          </Link>
                        </Label>
                      </Col>
                      <Col xs="3" className="text-end">
                        <Label className="text_medium">
                          <Link to="/contatos/amigos" className="no-underline text_medium">
                            {atleta.amigos}
                          </Link>
                        </Label>
                      </Col>
                    </Row>
                  </Alert>
                </Card>
              </Link>
            </Col>
            <Col xs="6" md="12">
              <Link to="/contatos/convites">
                <Card className="m-1" style={cardStyle} >
                  <Alert color="warning" className="p-0 m-0">
                    <Row align="left">
                      <Col xs="9">
                        <Label className="text_medium">
                          <Link to="/contatos/convites" className="no-underline text_medium">
                            Convites:
                          </Link>
                        </Label>
                      </Col>
                      <Col xs="3" className="text-end">
                        <Label className="text_medium">
                          <Link to="/contatos/convites" className="no-underline text_medium">
                            {atleta.convitesEnviados}
                          </Link>
                        </Label>
                      </Col>
                    </Row>
                  </Alert>
                </Card>
              </Link>
              <Link to="/contatos/solicitacoes">
                <Card style={cardStyle} className="m-1">
                  <Alert color="warning" className="p-0 m-0">
                    <Row align="left">
                      <Col xs="9">
                        <Label className="text_medium">
                          <Link to="/contatos/solicitacoes" className="no-underline text_medium">
                            Solicitações:
                          </Link>
                        </Label>
                      </Col>
                      <Col xs="3" className="text-end">
                        <Label className="text_medium">
                          <Link to="/contatos/solicitacoes" className="no-underline text_medium">
                            {atleta.convitesRecebidos}
                          </Link>
                        </Label>
                      </Col>
                    </Row>
                  </Alert>
                </Card>
              </Link>
            </Col>
          </Row>
        </Card>
      </div>
    </Card >
  );

  const NoResult = () => (
    <Redirect to={{ pathname: "/login/validacaoErro" }} />
  );

  return (
    <>
      <div className="text-center">
        <ClipLoader color="gray" loading={loading} size={60} />
      </div>
      {showResult ? <Result /> : null}
      {showNoResult ? <NoResult /> : null}
    </>
  );
};

export default MenuAtleta;
