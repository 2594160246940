import React from "react";
import { Link } from "react-router-dom";
import {
  Col,
  Row,
  Card,
  Label,
} from "reactstrap";
import "../../assets/css/minha_medalha.css";

const MenuConfiguracao = () => {

  return (
    <>
      <Card className="mb-3 mt-2 p-1 text-center">
        <div align="center">
            <Label className="p-1 text_big">Configurações</Label>
          <Card className="mt-1" >
            <Link to="/configuracao/visibilidade" className="no-underline text_medium">
              <Row align="left" className="mt-3 m-0">
                <Col xs="9">
                  <Link to="/configuracao/visibilidade" className="no-underline text_medium">
                    Visibilidade
                  </Link>
                </Col>
                <Col xs="3" className="text-end">
                  <Link to="/configuracao/visibilidade" className="no-underline text_medium">
                  &gt;
                  </Link>
                </Col>
              </Row>
            </Link>
            <Label className="mt-2">&nbsp;</Label>
          </Card>
        </div>
      </Card >
    </>
  );
};

export default MenuConfiguracao;
