import React, { useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Alert,
  Button,
  CardImg,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap";
import logo from "../../assets/img/logo_minhas_medalhas_lg.svg";
import img_login from "../../assets/img/img_signin.svg";
import { getUrlApi } from "../../api/auth";

function EmailEnviado() {
  const { token, email } = useParams();
  const [codigoVerificacao, setCodigoVerificacao] = useState("");
  const [messageAPI, setMessageAPI] = useState("");
  const [tipoErro, setTipoErro] = useState("error");
  const [resultColor, setResultColor] = useState("");
  const emailInput = useRef(null);

  function envia() {
    setMessageAPI(false);
    const requestInfo = {
      method: "POST",
      body: JSON.stringify({
        token: token,
        codigoVerificacao: codigoVerificacao
      }),
      headers: new Headers({
        "Content-type": "application/json",
      }),
    };

    fetch(
      getUrlApi() + "/novoAtleta/confirmarCodigoVerificacao/",
      requestInfo
    ).then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          setMessageAPI(data.message);
          setResultColor("success");
          window.location.href = window.location.origin + "/alterarSenha/"+token+"/"+email;
        } else {
          if (response.status === 202) {
            setMessageAPI(data.message);
            setTipoErro(data.field)
          } else {
            if (response.status === 400) {
              setMessageAPI(data[0].message);
            }
          }
          setResultColor("warning");
        }
      });
    });
  }

  return (
    <Container >
      <Row>
        <Col sm="6">
          <Row>
            <Col sm="8" className="pt-4 pb-3">
              <Link to={`/login`}>
                <CardImg src={logo} alt="Minhas Medalhas" />
              </Link>
            </Col>
            <Col sm="4">&nbsp;</Col>
          </Row>
          <Row>
            <Col sm="8" className="pt-1 pb-1">
              <Label className="text_big">
                Entre com os códido de 6 dígitos
              </Label>
              <Label className="text_small">
                Acesse o email {email} para obter o codigo de verificação.
              </Label>
              {tipoErro !== 'limite' ? (
              <Form className="pt-4 pb-1"
                method="Post"
                onSubmit={(event) => {
                  event.preventDefault();
                  envia();
                }}
              >
                  <Input
                    bsSize="sm"
                    value={codigoVerificacao}
                    onChange={(event) => {
                      setCodigoVerificacao(event.target.value);
                    }}
                    ref={emailInput}
                    type="number"
                    name="codigoVerificacao"
                    id="codigoVerificacao"
                    required
                  />
                <Button color="primary" className="button_form" type="submit" block>
                  Próximo
                </Button>
              </Form>
              ): (
                <Link to={`/login`}>
                <Button color="secondary" className="button_form" outline block >
                  Voltar 
                </Button>
                </Link>
              )}
                {messageAPI ? <Alert color={resultColor} className="text_small" >{messageAPI}</Alert> : null}
            </Col>
            <Col sm="4">&nbsp;</Col>
          </Row>
        </Col>
        <Col sm="6">
          <CardImg src={img_login} className="img_lateral" />
        </Col>
      </Row>

    </Container>
  );
}

export default EmailEnviado;
