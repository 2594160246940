import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Alert,
  Button,
  CardImg,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import logo from "../../assets/img/logo_minhas_medalhas_lg.svg";
import img_login from "../../assets/img/img_signin.svg";
import { getUrlApi } from "../../api/auth";

function AlterarSenha() {
  const { token, email } = useParams();
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [validPass, setPasswordValid] = useState("");
  const [invalidPass, setPasswordInvalid] = useState("");
  const [messageAPI, setMessageAPI] = useState("");
  const [resultColor, setResultColor] = useState("");
  const [erro, setErro] = useState('');

  const handleSenhaChange = (event) => {
    const novaSenha = event.target.value;
    setPassword(novaSenha);
    setErro(validarSenha(novaSenha));
  };

  const validarSenha = (senha) => {
    // A expressão regular verifica se a senha contém pelo menos um número, uma letra e um caractere especial
    const regex = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[\W_]).{8,}$/;

    if (!regex.test(senha)) {
      return 'A senha deve conter pelo menos 8 caracteres, incluindo números, letras e caracteres especiais.';
    }

    return '';
  };
  
  function envia() {
    setMessageAPI(false);
    const requestInfo = {
      method: "POST",
      body: JSON.stringify({
        token: token,
        password: `${password}`,
        password2: `${password2}`,
      }),
      headers: new Headers({
        "Content-type": "application/json",
      }),
    };

    fetch(
      getUrlApi() + "/novoAtleta/alterarSenha/",
      requestInfo
    ).then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          //setMessageAPI(data.message);
          setResultColor("success");
          window.location.href = window.location.origin+"/login" ;
        } else {
          if (response.status === 202) {
            setMessageAPI(data.message);
          } else {
            if (response.status === 400) {
              setMessageAPI(data[0].message);
            }
          }
          setResultColor("warning");
        }
      });
    });
  }

  return (
    <Container >
      <Row>
        <Col sm="6">
          <Row>
            <Col sm="8" className="pt-4 pb-3">
              <Link to={`/login`}>
                <CardImg src={logo} alt="Minhas Medalhas" />
              </Link>
            </Col>
            <Col sm="4">&nbsp;</Col>
          </Row>
          <Row>
            <Col sm="8" className="pt-1 pb-1">
              <Label className="text_big">
                Ok, tudo certo!
              </Label>
              <Label className="text_small">
                Informe a nova senha para o email {email}.
              </Label>
                <Form className="pt-4 pb-1"
                  method="Post"
                  onSubmit={(event) => {
                    event.preventDefault();
                    envia();
                  }}
                >
                  <Form>
                    <Label className="text_form">Senha:*</Label>
                    <Input
                      bsSize="sm"
                      value={password}
                      onChange={
                        handleSenhaChange
                      }
                      onBlur={(event) => {
                        if (validaPassword({ password, password2 })) {
                          setPasswordValid(true);
                          setPasswordInvalid(false);
                        } else {
                          setPasswordValid(false);
                          setPasswordInvalid(true);
                        }
                      }}
                      type="password"
                      name="password"
                      id="password"
                      minLength="4"
                      maxlength="80"
                      required
                    />
                    {erro && <Label className="text_small_erro">{erro}</Label>}
                    <Label className="text_form">Confirme a senha:*</Label>
                    <Input
                      bsSize="sm"
                      value={password2}
                      onChange={(event) => {
                        setPassword2(event.target.value);
                      }}
                      onBlur={(event) => {
                        if (validaPassword({ password, password2 })) {
                          setPasswordValid(true);
                          setPasswordInvalid(false);
                        } else {
                          setPasswordValid(false);
                          setPasswordInvalid(true);
                        }
                      }}
                      type="password"
                      name="password2"
                      id="password2"
                      minLength="4"
                      maxlength="80"
                      required
                      valid={validPass}
                      invalid={invalidPass}
                    />
                    <FormFeedback invalid>Senhas não confere!</FormFeedback>
                    <FormFeedback valid>Senha ok!</FormFeedback>
                  </Form>
                  <Button color="primary" className="button_form" type="submit" block>
                    Alterar
                  </Button>
                </Form>
              {messageAPI ? <Alert color={resultColor} className="text_small" >{messageAPI}</Alert> : null}
            </Col>
            <Col sm="4">&nbsp;</Col>
          </Row>
        </Col>
        <Col sm="6">
          <CardImg src={img_login} className="img_lateral" />
        </Col>
      </Row>

    </Container>
  );
}

function validaPassword(data) {
  if (data.password2 === null) {
    return false;
  }
  if (data.password === data.password2) {
    return true;
  }
  return false;
}

export default AlterarSenha;
