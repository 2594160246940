import React, { useState  } from "react";
import { useParams  } from "react-router-dom";
import {
  Alert,
  Card,
  Col,
  Row,
  FormGroup,
  Input,
  Button,
} from "reactstrap";
import { getToken, getUrlApi, getFoto, getNome } from "../../api/auth";
import Avatar from "react-avatar";

const NovoComentario = (props) => {
  const { id } = useParams();
  const [comentarioAtleta, setComentarioAtleta] = useState("");
  const [messageAPI, setMessageAPI] = useState("");
  const [resultColor, setResultColor] = useState("");
  const [atualiza, setAtualiza] = useState(true);
  const fotoAtleta = getFoto();
  const nomeAtleta = getNome();

  function publicaComentario() {
    const token = getToken();
    const requestComentarioAtleta = {
      method: "POST",
      body: JSON.stringify({
        comentario: `${comentarioAtleta}`,
        idEvento: `${id}`,
      }),
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };
    fetch(
      getUrlApi()+"/evento/novoComentario/",
      requestComentarioAtleta
    ).then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          setMessageAPI(data.message);
          setAtualiza(!atualiza);
          props.atualizarComentarios(atualiza);
        } else {
          if (response.status === 202) {
            setMessageAPI(data.message);
          } else {
            if (response.status === 400) {
              setMessageAPI(data[0].message);
            }
          }
          setResultColor("warning");
        }
      });
    });
  };

  return (
    <Row>
    <Col xs="2" sm="1" className="pt-2 p-4">
      {fotoAtleta === null ? (
        <Avatar name={nomeAtleta} size="30" round={true} maxInitials={2} />
      ) : (
        <img
        src={fotoAtleta}
        style={{ width: '30px', height: '30px', objectFit: 'cover', borderRadius: '50%' }}
      />  
      )}
    </Col>
    <Col xs="10" sm="9" className=" mt-1 mb-3">
        <FormGroup className=" m-0">
          <Input
            type="text"
            name="comentarioAtleta"
            id="comentarioAtleta"
            placeholder="Comente sobre o evento"
            onChange={(event) => {
                event.preventDefault();
                setComentarioAtleta(event.target.value);
              }}
            value={comentarioAtleta}
          />
        </FormGroup>
    </Col>
    <Col xs="12" sm="2" className="mt-1">
      <Card>
        <Button color= "primary" name="Publicar" onClick={publicaComentario} block>Publicar</Button>
      </Card>
    </Col>
    <Col xs="12" className="mt-1">
      {messageAPI ? <Alert color={resultColor}>{messageAPI}</Alert> : null}
    </Col>
  </Row>
);
};

export default NovoComentario;
