import { React, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
    Badge,
    Button,
    FormGroup,
    CardGroup,
    Input,
    Col,
    Card,
    Row,
    CardTitle,
    CardBody,
    Label,
    CardText,
} from "reactstrap";
import { getToken, getUrlApi, getAuthOrg } from "../../api/auth";
import ClipLoader from "react-spinners/ClipLoader";
import { busca as buscaModalidade } from "../../api/api_modalidade";
import { busca } from "../../api/api_event_organizadora";

const Modalidade = () => {
    const { id } = useParams();
    let [passou, setPassou] = useState(false);
    const [showResult, setShowResult] = useState(false);
    const [showNoResult, setShowNoResult] = useState(false);
    const [outraModalidade, setOutraModalidade] = useState("");
    const [messageAPI, setMessageAPI] = useState("");
    const [resultColor, setResultColor] = useState("");
    let [loading, setLoading] = useState(false);
    const [modalidadesLista, setModalidadesLista] = useState([]);
    const [novaModalidade, setNovaModalidade] = useState(false);
    const [evento, setEvento] = useState([]);
    const idOrganizadoraAtleta = getAuthOrg();

    useEffect(() => {
        buscaModalidade(
            `/buscaModalidadeEvento/` + id,
            setModalidadesLista,
            setLoading,
            setShowResult,
            setShowNoResult
        );

    }, [id]);

    useEffect(() => {
        busca(
            "/corrida/" + id,
            setEvento,
            setLoading,
            setShowResult,
            setShowNoResult,
            idOrganizadoraAtleta
        );
    }, [id, idOrganizadoraAtleta]);

    function IncluirModalidade(pModalidade) {
        const token = getToken();
        const requestInfo = {
            method: "GET",
            headers: new Headers({
                "Content-type": "application/json",
                Authorization: `Bearer ${token}`,
            }),
        };
        fetch(
            getUrlApi() + "/admin/organizadora/incluirModalidadeEvento/" + id + "/" + pModalidade,
            requestInfo
        ).then((response) => {
            response.json().then((data) => {
                if (response.status === 200) {
                    setMessageAPI(data.message);
                    setResultColor("success");
                } else {
                    if (response.status === 202) {
                        setMessageAPI(data.message);
                    } else {
                        if (response.status === 400) {
                            setMessageAPI(data[0].message);
                        }
                    }
                    setResultColor("warning");
                }
            });
        });
    }

    function RemoverModalidade(pModalidade) {
        const token = getToken();
        const requestInfo = {
            method: "GET",
            headers: new Headers({
                "Content-type": "application/json",
                Authorization: `Bearer ${token}`,
            }),
        };
        fetch(
            getUrlApi() + "/admin/organizadora/removerModalidadeEvento/" + id + "/" + pModalidade,
            requestInfo
        ).then((response) => {
            response.json().then((data) => {
                if (response.status === 200) {
                    setMessageAPI(data.message);
                    setResultColor("success");
                } else {
                    if (response.status === 202) {
                        setMessageAPI(data.message);
                    } else {
                        if (response.status === 400) {
                            setMessageAPI(data[0].message);
                        }
                    }
                    setResultColor("warning");
                }
            });
        });
    }

    function MarcaDesmarcaModalidade(pIdModalidade) {
        if (pIdModalidade === 99) {
            setNovaModalidade(!novaModalidade)
        }
        let element = document.getElementById(pIdModalidade);
        if (element.className !== "p-2 badge bg-primary badge-pill") {
            element.removeAttribute = "";
            element.className = "p-2 badge bg-primary badge-pill";
            IncluirModalidade(pIdModalidade);
        } else {
            element.className = "p-2 badge bg-secondary badge-pill";
            RemoverModalidade(pIdModalidade);
        }
        setPassou(true);
    }


    return (
        <Card>
            <div className="text-center">
                <ClipLoader color="gray" loading={loading} size={60} />
            </div>

            <Card>
                <Row>
                    <Col sm="3"></Col>
                    <Col sm="6">
                        <Card className="p-2">
                            <CardTitle className="text-center mt-2 pt-2" tag="h4">
                                Evento <br />
                                {evento.nome}
                            </CardTitle>
                            <CardBody>
                                <FormGroup>
                                    <Label>Data do Evento</Label>
                                    <Input
                                        type="text"
                                        name="dataEvento"
                                        minLength="5"
                                        maxLength="8"
                                        value={evento.dataInicioEvento}
                                        readOnly
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Cidade</Label>
                                    <Input
                                        type="text"
                                        name="cidade"
                                        minLength="5"
                                        value={evento.nomeCidade + " / " + evento.uf}
                                        readOnly
                                    />
                                </FormGroup>
                                <FormGroup>
                                    Modalidades<br />
                                    <CardGroup className="p-0 text-center 1" id="carregar" alt="modalidades">
                                        {modalidadesLista.map((modalidadeCombo) => (
                                            <Col sm="6" md="4" className="p-1 ">
                                                <Card className="border-0" onClick={() => (MarcaDesmarcaModalidade(modalidadeCombo.id))} >
                                                    <Badge className="p-2" color={modalidadeCombo.cor} pill id={modalidadeCombo.id}  >
                                                        {modalidadeCombo.nome}
                                                    </Badge>
                                                </Card>
                                            </Col>

                                        ))}
                                    </CardGroup>
                                    <CardGroup className="p-0 text-center">
                                        {novaModalidade ? (
                                            <Col sm="12" md="12" className="p-1 ">
                                                <div id="novaModalidade" >
                                                    <Input
                                                        type="text"
                                                        id="outraModalidade"
                                                        name="outraModalidade"
                                                        placeholder="Informe a(s) nova(s) modalidade(s)"
                                                        maxLength="20"
                                                        value={outraModalidade}
                                                        onChange={(event) => {
                                                            setOutraModalidade(event.target.value);
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                        ) : null}

                                    </CardGroup>

                                </FormGroup>
                            </CardBody>
                            <CardText className="text-end">
                                <Link to={"/adminOrganizadora/editarEvento/" + evento.id}>
                                    <Button color="secondary" className="ml-4" outline>
                                        Voltar
                                    </Button>
                                </Link>
                            </CardText>
                        </Card>
                    </Col>
                </Row>
            </Card>

        </Card>
    );

};


export default Modalidade;


