import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Badge,
  Card,
  CardFooter,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
  CardGroup,
  CardBody,
  Form,
  CardSubtitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledAlert,
} from "reactstrap";
import Patrocinio from "../Home/Patrocinio";
import { busca } from "../../api/api_athlete";
import ClipLoader from "react-spinners/ClipLoader";
import { getToken, getUrlApi } from "../../api/auth";
import Avatar from "react-avatar";

const ListaAtletas = () => {
  const [atletas, setAtletas] = useState([]);
  let [loading, setLoading] = useState(true);
  const [modalInclusao, setModalInclusao] = useState(false);
  const [searchAtleta, setSearchAtleta] = useState([]);
  const [messageAPI, setMessageAPI] = useState("");
  const [resultColor, setResultColor] = useState("");
  const [emailAmigo, setEmailAmigo] = useState("");

  const [showResult, setShowResult] = useState(false);
  const [showNoResult, setShowNoResult] = useState(false);

  function buscaAtleta(nomeAtleta) {
    setLoading(true);
    const token = getToken();
    const requestAtleta = {
      method: "POST",
      body: JSON.stringify({
        nome: `${nomeAtleta}`,
      }),
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };
    fetch(
      getUrlApi() + "/atleta/buscaAtleta/",
      requestAtleta
    ).then((response) => {
      response.json().then((data) => {
        setAtletas(data);
        setLoading(false);
      });
    });
  }

  const IncluirAmigo = () => setModalInclusao(!modalInclusao);

  function EnviarConvite() {
    const token = getToken();
    const requestInfo = {
      method: "POST",
      body: JSON.stringify({
        email: `${emailAmigo}`,
      }),
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };
    fetch(
      getUrlApi() + "/atleta/convidaAmigo",
      requestInfo
    ).then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          setMessageAPI(data.message);
          setResultColor("success");
        } else {
          if (response.status === 202) {
            setMessageAPI(data.message);
          } else {
            if (response.status === 400) {
              setMessageAPI(data[0].message);
            }
          }
          setResultColor("warning");
        }
      });
    });
  }

  useEffect(() => {
    busca(
      "/listaAtletas/0",
      setAtletas,
      setLoading,
      setShowResult,
      setShowNoResult
    );
    if (Array.isArray(atletas)) {
      setShowResult(true)
    } else {
      setShowResult(false)
      console.error("atletas não é uma array");
    }
  }, []);


  const Result = () => (
    <CardGroup className="mt-2">

      {atletas.map((atleta) => (
        <Col sm="12" md="3" className="p-1">
          <Card className="m-0 text-center h-100">
            <Row>
              <Col xs="6" md="12">
                <Link to={`/atleta/${atleta.idHash}`} className="mt-1 mb-2">
                  {atleta.foto === null ? (
                    <Avatar name={atleta.nome} size="150" round={true} maxInitials={2} className="mt-1 mb-0"/>
                  ) : (
                    <img className="mt-1 mb-21"
                      src={atleta.foto}
                      style={{ width: '150px', height: '150px', objectFit: 'cover', borderRadius: '50%' }}
                    />
                  )}
                </Link>
              </Col>
              <Col xs="6" md="12">
                <CardBody className="p-0">
                  <CardTitle className="text_big">{atleta.apelido}</CardTitle>
                  <CardSubtitle className="text_banco">{atleta.nome}</CardSubtitle>
                  <CardText className="text_banco">
                    {atleta.uf ? (atleta.nomeCidade+" - "+atleta.uf):atleta.nomeCidade}
                  </CardText>
                </CardBody>
                <CardFooter className="p-1">
                  <Link to={`/atleta/${atleta.idHash}`}>
                    <Badge color="primary" pill className="p-2">
                      Ver perfil
                    </Badge>
                  </Link>
                </CardFooter>
              </Col>
            </Row>
          </Card>
        </Col>
      ))}
    </CardGroup>
  );

  const NoResult = () => (
    <CardText className="p-3 text_alert">
      Algo deu errado! Volte e selecione novamente um atleta!
    </CardText>
  );

  return (
    <Container>
      <Row>
        <Col sm="9" className="p-0 pt-2 pr-2">
          <Card className="mt-2">
            <CardHeader className="text-center p-1 m-0" tag="h3">
              <Row>
                <Col sm="9" className="mt-2 text_big">
                  Atletas
                </Col>
                <Col sm="3" className="m-0">
                  <Button
                    className="button_form m-0"
                    color="success"
                    onClick={IncluirAmigo}
                    block
                  >
                    Convide um Amigo!
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <CardText className="text-center">
              <Form>
                <Row>
                  <Col sm="12">
                    <FormGroup className="p-2 m-0">
                      <Input
                        bsSize="sm"
                        type="text"
                        name="atleta"
                        id="atleta"
                        placeholder="Informe o nome de um(a) Atleta para filtrar"
                        onChange={(event) => {
                          setSearchAtleta(event.target.value);
                          buscaAtleta(event.target.value);
                        }}
                        value={searchAtleta}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </CardText>
          </Card>
          <Card>
            {messageAPI ? (
              <UncontrolledAlert
                color={resultColor}
                className="m-2 text-center text_alert"
              >
                {messageAPI}
              </UncontrolledAlert>
            ) : null}
            <div className="text-center">
              <ClipLoader color="gray" loading={loading} size={60} />
            </div>

            {showResult ? <Result /> : null}
            {showNoResult ? <NoResult /> : null}
          </Card>
        </Col>
        <Col sm="3" className="p-0 pt-2">
          <Patrocinio />
        </Col>
      </Row>
      <div>
        <Modal isOpen={modalInclusao} toggle={IncluirAmigo} className="p-0">
          <ModalHeader toggle={IncluirAmigo}>Convide um(a) amigo(a)!</ModalHeader>
          {messageAPI ? (
            <UncontrolledAlert className="m-1 text-center text_alert" color={resultColor}>
              {messageAPI}
            </UncontrolledAlert>
          ) : null}
          <ModalBody className="pt-3 pb-5">
            <Form>
              Informe o e-mail:
              <Input
                type="email"
                id="emailAmigo"
                name="emailAmigo"
                onChange={(event) => {
                  setEmailAmigo(event.target.value);
                }}
                placeholder="informe o email da(o) amiga(o)"
                required
              />
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={EnviarConvite}>
              Enviar convite
            </Button>{" "}
            <Button color="secondary" onClick={IncluirAmigo}>
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </Container>
  );
};

export default ListaAtletas;
