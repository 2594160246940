import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import MenuConfiguracao from './MenuConfiguracao';
import HomeConfiguracao from './HomeConfiguracao';

function Configuracao(){
    return (
        <Container >
            <Row>
                <Col sm="2" className="p-0 pt-2">
                    <MenuConfiguracao />
                </Col>
                <Col sm="1" className="p-0 pt-2">
                    &nbsp;
                </Col>
                <Col sm="7" className="p-0 pt-2">
                    <HomeConfiguracao />
                </Col>
            </Row>
        </Container>    
    )

}

export default Configuracao