import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Alert,
  Button,
  CardImg,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap";
import logo from "../../assets/img/logo_minhas_medalhas_lg.svg";
import img_login from "../../assets/img/img_signin.svg";
import { getUrlApi } from "../../api/auth";
import { Link } from "react-router-dom/cjs/react-router-dom";


function FinalizarCadastro() {
  const { idHash } = useParams();
  const [dia, setDia] = useState("");
  const [mes, setMes] = useState("");
  const [ano, setAno] = useState("");
  const [sexo, setSexo] = useState("");
  const [messageAPI, setMessageAPI] = useState("");
  const [resultColor, setResultColor] = useState("");

  function envia() {
    if (dia === '0' || mes === '0' || ano === '0' || dia === '' || mes === '' || ano === '') {
      setMessageAPI("O data deve ser informada!");
      return
    }

    if (sexo === '0' || sexo === "") {
      setMessageAPI("O sexo deve ser informado!");
      return
    }

    const requestInfo = {
      method: "POST",
      body: JSON.stringify({
        idHash: `${idHash}`,
        dataNascimento: `${dia}/${mes}/${ano}`,
        sexo: `${sexo}`,
      }),
      headers: new Headers({
        "Content-type": "application/json",
      }),
    };

    fetch(
      getUrlApi() + "/novoAtleta/atualizaDados/",
      requestInfo
    ).then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          //setMessageAPI(data.message); retirada a msg pois vai para a proxima etapa
          setResultColor("success");
          window.location.href = window.location.origin + "/cadastroFinalizado/" + idHash;
        } else {
          if (response.status === 202) {
            setMessageAPI(data.message);
          } else {
            if (response.status === 400) {
              setMessageAPI(data.message);
            }
          }
          setResultColor("warning");
        }
      });
    });
  }

  return (
    <Container >
      <Row>
        <Col sm="6">
          <Row>
            <Col sm="8" className="pt-4 pb-3">
              <Link to="/login"><CardImg src={logo} alt="Minhas Medalhas" /></Link>
            </Col>
            <Col sm="4">&nbsp;</Col>
          </Row>
          <Row>
            <Col sm="8" className="pt-1 pb-1">
              <Label className="text_small">
                <center>Ficamos muito contente e ter você em nossa comunidade! Para fecharmos o cadastro, gostaríamos de solicitar sua data de Nascimento e o sexo para colocar você na categoria correta, ok?</center>
              </Label>
              <Form
                method="Post"
                onSubmit={(event) => {
                  event.preventDefault();
                  envia();
                }}
              >
                <Row>
                  <Col sm="4" xs="4" className="pt-1 pb-1">
                    <Label className="text_form">Dia*</Label>
                    <Input
                      bsSize="sm"
                      value={dia}
                      onChange={(event) => {
                        setDia(event.target.value);
                      }}
                      type="select"
                      name="dia"
                      id="dia"
                      required
                    >
                      <option value="0"></option>
                      <option value="01">1</option>
                      <option value="02">2</option>
                      <option value="03">3</option>
                      <option value="04">4</option>
                      <option value="05">5</option>
                      <option value="06">6</option>
                      <option value="07">7</option>
                      <option value="08">8</option>
                      <option value="09">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                      <option value="13">13</option>
                      <option value="14">14</option>
                      <option value="15">15</option>
                      <option value="16">16</option>
                      <option value="17">17</option>
                      <option value="18">18</option>
                      <option value="19">19</option>
                      <option value="20">20</option>
                      <option value="21">21</option>
                      <option value="22">22</option>
                      <option value="23">23</option>
                      <option value="24">24</option>
                      <option value="25">25</option>
                      <option value="26">26</option>
                      <option value="27">27</option>
                      <option value="28">28</option>
                      <option value="29">29</option>
                      <option value="30">30</option>
                      <option value="31">31</option>
                    </Input>
                  </Col>
                  <Col sm="4" xs="4" className="pt-1 pb-1">
                    <Label className="text_form">Mês*</Label>
                    <Input
                      type="select"
                      bsSize="sm"
                      name="mes"
                      id="mes"
                      required
                      value={mes}
                      onChange={(event) => {
                        setMes(event.target.value);
                      }}
                    >
                      <option value="0"></option>
                      <option value="01">1</option>
                      <option value="02">2</option>
                      <option value="03">3</option>
                      <option value="04">4</option>
                      <option value="05">5</option>
                      <option value="06">6</option>
                      <option value="07">7</option>
                      <option value="08">8</option>
                      <option value="09">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                    </Input>
                  </Col>
                  <Col sm="4" xs="4" className="pt-1 pb-1">
                    <Label className="text_form">Ano*</Label>
                    <Input bsSize="sm"
                      value={ano}
                      onChange={(event) => {
                        setAno(event.target.value);
                      }}
                      type="select"
                      name="ano"
                      id="ano"
                      required
                      className="input_form"
                    >
                      <option value="0"></option>
                      <option value="2015">2015</option>
                      <option value="2014">2014</option>
                      <option value="2013">2013</option>
                      <option value="2012">2012</option>
                      <option value="2011">2011</option>
                      <option value="2010">2010</option>
                      <option value="2009">2009</option>
                      <option value="2008">2008</option>
                      <option value="2007">2007</option>
                      <option value="2006">2006</option>
                      <option value="2005">2005</option>
                      <option value="2004">2004</option>
                      <option value="2003">2003</option>
                      <option value="2002">2002</option>
                      <option value="2001">2001</option>
                      <option value="2000">2000</option>
                      <option value="1999">1999</option>
                      <option value="1998">1998</option>
                      <option value="1997">1997</option>
                      <option value="1996">1996</option>
                      <option value="1995">1995</option>
                      <option value="1994">1994</option>
                      <option value="1993">1993</option>
                      <option value="1992">1992</option>
                      <option value="1991">1991</option>
                      <option value="1990">1990</option>
                      <option value="1989">1989</option>
                      <option value="1988">1988</option>
                      <option value="1987">1987</option>
                      <option value="1986">1986</option>
                      <option value="1985">1985</option>
                      <option value="1984">1984</option>
                      <option value="1983">1983</option>
                      <option value="1982">1982</option>
                      <option value="1981">1981</option>
                      <option value="1980">1980</option>
                      <option value="1979">1979</option>
                      <option value="1978">1978</option>
                      <option value="1977">1977</option>
                      <option value="1976">1976</option>
                      <option value="1975">1975</option>
                      <option value="1974">1974</option>
                      <option value="1973">1973</option>
                      <option value="1972">1972</option>
                      <option value="1971">1971</option>
                      <option value="1970">1970</option>
                      <option value="1969">1969</option>
                      <option value="1968">1968</option>
                      <option value="1967">1967</option>
                      <option value="1966">1966</option>
                      <option value="1965">1965</option>
                      <option value="1964">1964</option>
                      <option value="1963">1963</option>
                      <option value="1962">1962</option>
                      <option value="1961">1961</option>
                      <option value="1960">1960</option>
                      <option value="1959">1959</option>
                      <option value="1958">1958</option>
                      <option value="1957">1957</option>
                      <option value="1956">1956</option>
                      <option value="1955">1955</option>
                      <option value="1954">1954</option>
                      <option value="1953">1953</option>
                      <option value="1952">1952</option>
                      <option value="1951">1951</option>
                      <option value="1950">1950</option>

                    </Input>
                  </Col>
                </Row>
                <Label className="text_form">Sexo:*</Label>
                <Input
                  bsSize="sm"
                  value={sexo}
                  onChange={(event) => {
                    setSexo(event.target.value);
                  }}
                  type="select"
                  name="sexo"
                  id="sexo"
                  required
                >
                  <option value="0"></option>
                  <option value="Nao_informado">Não informar</option>
                  <option value="Masculino">Masculino</option>
                  <option value="Feminino">Feminino</option>
                </Input>
                {messageAPI ? (<><br /><Alert color={resultColor} className="text_small">{messageAPI}</Alert></>) : null}
                <Button color="success" className="button_form" type="submit" block>
                  Finalizar
                </Button>
                <Link to={"/confirmarCadastro/" + idHash} className="no-underline">
                  <Button color="secondary" className="button_form" outline block >
                    Preencher depois
                  </Button>
                </Link>
              </Form>
            </Col>
            <Col sm="4">&nbsp;</Col>
          </Row>
        </Col>
        <Col sm="6">
          <CardImg src={img_login} className="img_lateral" />
        </Col>
      </Row>

    </Container>
  );
}

export default FinalizarCadastro;
