import React from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import Login from "./components/login";
import Logout from "./components/logout";
import Home from "./components/Home/Home";
import Medalhas from "./components/Medalhas/ListaMedalhas";
import Calendario from "./components/Calendario/Calendario";
import Evento from "./components/Eventos/Evento";
import NovoEvento from "./components/Eventos/NovoEvento";
import Atletas from "./components/Atletas/ListaAtletas";
import AtletaEvento from './components/Atletas/ListaEventosAtleta';
import Assessorias from "./components/Assessorias/ListaAssessorias";
import Assessoria from "./components/Assessorias/Assessoria";
import ConfirmarNovaData from "./components/Assessorias/ConfirmarNovaData";
import Atleta from "./components/Atletas/Atleta";
import Contatos from "./components/Atletas/ListaContatos";
import Perfil from "./components/Perfil/Perfil";
import Cabecalho from "./components/Cabecalho";
import EditarPerfil from "./components/Perfil/Editar";
import Inscrever from "./components/Inscrever/Inscrever";
import TodosEventosPlanejados from "./components/Medalhas/TodosPlanejados";
import TodosEventosRealizados from "./components/Medalhas/TodosRealizados";
import Pesquisa from "./components/Pesquisa/Pesquisa";
import LinkAnuncio from "./components/Home/LinkAnuncio";
import QuadroMedalhasAtleta from "./components/Atletas/TodosRealizados";
import EventosEncontrados from "./components/Atletas/EventosEncontrados";

import HomeTime from "./components/AdminTime/Home";
import ListaTimeAtletas from "./components/AdminTime/Lista";
import NovoAtletaTime from "./components/AdminTime/Novos";
import EditarTime from "./components/AdminTime/Editar";
import CalendarioTime from "./components/AdminTime/Calendario";

import HomeOrganizadora from "./components/AdminOrganizadora/Home";
import ListaEventosOrganizadora from "./components/AdminOrganizadora/Lista";
import EditarOrganizadora from "./components/AdminOrganizadora/Editar";
import EditarEventoOrganizadora from "./components/AdminOrganizadora/EditarEvento";
import NovoEventoOrganizadora from "./components/AdminOrganizadora/NovoEvento";
import EditarEventoOrganizadoraModalidade from "./components/AdminOrganizadora/Modalidades";
import ImagemEventoOrganizadora from "./components/AdminOrganizadora/ImagemEvento";

import NovoEventoAdmin from "./components/Admin/NovoEvento";
import EditarEventoAdmin from "./components/Admin/EditarEvento";
import EditarEventoAdminModalidade from "./components/Admin/Modalidades";
import ImagemEventoAdmin from "./components/Admin/ImagemEvento";
import NovoAnuncio from "./components/Admin/anuncio/NovoAnuncio";
import EditarAnuncio from "./components/Admin/anuncio/EditarAnuncio";
import ListaAnuncios from "./components/Admin/anuncio/ListaAnuncios";
import ImagemAnuncioAdmin from "./components/Admin/anuncio/ImagemAnuncio";

import Pagina404 from "./paginas/Pagina404";
import Faq from "./components/faq/inscricao";
import Sobre from "./components/CentralDeAjuda/Sobre";
import PoliticaDeCookies from "./components/CentralDeAjuda/PoliticaDeCookies";
import PoliticaDePrivacidade from "./components/CentralDeAjuda/PoliticaDePrivacidade";
import Publicidade from "./components/Home/Publicidade";
import "./assets/css/minha_medalha.css";

import { Container } from "reactstrap";
import { isAuthenticated } from "./api/auth";
import Feeds from "./components/Home/FeedPrincipal";
import FinalizarCadastro from "./components/Inscrever/FinalizarCadastro";
import EsqueceiSenha from "./components/Inscrever/EsqueciSenha";
import EmailEnviado from "./components/Inscrever/EmailEnviado";
import AlterarSenha from "./components/Inscrever/AlterarSenha";
import CadastroFinalizado from "./components/Inscrever/CadastroFinalizado";
import ConfirmarCadastro from "./components/Inscrever/ConfirmarCadastro";
import SegurancaEmail from "./components/CentralDeAjuda/Ajuda/SegurancaEmail";
import ConfirmarDadosEvento from "./components/Eventos/ConfirmarDadosEvento";
import ConfirmarDadosNovoEvento from "./components/Eventos/ConfirmarDadosNovoEvento";

import Configuracao from "./components/Privacidade/Configuracao";
import VisibilidadeAtleta from "./components/Privacidade/VisibilidadeAtleta";
import TermosDeUso from "./components/CentralDeAjuda/TermosDeUso";
import Gerencia from "./components/AdminTime/Gerencia";

function App() {
  const PrivateRoute = ({ component: Component, ...rest }) => (
    <>
      <Route
        {...rest}
        render={props =>
          isAuthenticated() ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
          )
        }
      />
    </>
  );

  return (
    <Container fluid="lg">
      <Router>
        <Switch>
          <PrivateRoute exact path="/" >
            <Cabecalho />
            <Home />
          </PrivateRoute>
          <Route path="/inscrever" >
            <Inscrever />
          </Route>
          <Route path="/finalizarCadastro/:idHash" >
            <FinalizarCadastro />
          </Route>
          <Route path="/cadastroFinalizado/:idHash" >
            <CadastroFinalizado />
          </Route>
          <Route path="/confirmarCadastro/:idHash" >
            <ConfirmarCadastro />
          </Route>
          <Route path="/novaSenha" >
            <EsqueceiSenha />
          </Route>
          <Route path="/emailEnviado/:token/:email" >
            <EmailEnviado />
          </Route>
          <Route path="/alterarSenha/:token/:email" >
            <AlterarSenha />
          </Route>
          <Route path="/login/:validacao">
            <Login />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/logout">
            <Logout />
          </Route>
          <PrivateRoute path="/medalhas" component={Medalhas}>
            <Cabecalho />
            <Medalhas />
          </PrivateRoute>
          <PrivateRoute path="/confirmarDadosEvento/:idHash/:id" >
            <ConfirmarDadosEvento />
          </PrivateRoute>
          <PrivateRoute path="/confirmarDadosNovoEvento/:idHash/:id" >
            <ConfirmarDadosNovoEvento />
          </PrivateRoute>
          <PrivateRoute path="/medalha/todasPlanejadas" >
            <Cabecalho />
            <TodosEventosPlanejados url={"/medalha/todasPlanejadas"} />
          </PrivateRoute>
          <PrivateRoute path="/medalha/todasRealizadas" >
            <Cabecalho />
            <TodosEventosRealizados url={"/medalha/todasRealizadas"} />
          </PrivateRoute>
          <Route path="/calendario">
            <Cabecalho />
            <Calendario />
          </Route>
          <PrivateRoute path="/feeds">
            <Cabecalho />
            <Feeds />
          </PrivateRoute>
          <PrivateRoute path="/corridas/:id">
            <Cabecalho />
            <Evento />
          </PrivateRoute>
          <PrivateRoute path="/novoEvento" >
            <Cabecalho />
            <NovoEvento />
          </PrivateRoute>
          <PrivateRoute path="/atletas">
            <Cabecalho />
            <Atletas />
          </PrivateRoute>
          <PrivateRoute path="/assessorias">
            <Cabecalho />
            <Assessorias />
          </PrivateRoute>
          <PrivateRoute path="/assessoria/confirmarNovaData">
            <Cabecalho />
            <ConfirmarNovaData />
          </PrivateRoute>
          <PrivateRoute path="/assessoria/:id">
            <Cabecalho />
            <Assessoria />
          </PrivateRoute>
          <PrivateRoute path="/perfil/editar">
            <Cabecalho />
            <EditarPerfil />
          </PrivateRoute>
          <PrivateRoute path="/perfil">
            <Cabecalho />
            <Perfil />
          </PrivateRoute>
          <PrivateRoute path="/atleta/quadroMedalhas/:id">
            <Cabecalho />
            <QuadroMedalhasAtleta />
          </PrivateRoute>
          <PrivateRoute path="/atleta/:id">
            <Cabecalho />
            <Atleta />
          </PrivateRoute>
          <PrivateRoute path="/eventosAtleta/:id">
            <Cabecalho />
            <AtletaEvento />
          </PrivateRoute>
          <PrivateRoute path="/contatos/:id">
            <Cabecalho />
            <Contatos />
          </PrivateRoute>
          <PrivateRoute path="/pesquisa/:search">
            <Cabecalho />
            <Pesquisa />
          </PrivateRoute>
          <PrivateRoute path="/eventosEncontrados">
            <Cabecalho />
            <EventosEncontrados />
          </PrivateRoute>
          <Route path="/linkAnuncio/:idHash/:url">
            <LinkAnuncio />
          </Route>

          <PrivateRoute path="/configuracao/visibilidade">
            <Cabecalho />
            <VisibilidadeAtleta />
          </PrivateRoute>
          <PrivateRoute path="/configuracao">
            <Cabecalho />
            <Configuracao />
          </PrivateRoute>


          <PrivateRoute exact path="/adminTime" >
            <Cabecalho />
            <HomeTime />
          </PrivateRoute>
          <PrivateRoute path="/adminTime/atletas/:id" >
            <Cabecalho />
            <ListaTimeAtletas />
          </PrivateRoute>
          <PrivateRoute path="/adminTime/gerenciar/:id" >
            <Cabecalho />
            <Gerencia />
          </PrivateRoute>
          <PrivateRoute path="/adminTime/editar">
            <Cabecalho />
            <EditarTime />
          </PrivateRoute>
          <PrivateRoute path="/adminTime/novosAtletas">
            <Cabecalho />
            <NovoAtletaTime />
          </PrivateRoute>
          <PrivateRoute path="/adminTime/calendario/:tipo">
            <Cabecalho />
            <CalendarioTime />
          </PrivateRoute>

          <PrivateRoute exact path="/adminOrganizadora" >
            <Cabecalho />
            <HomeOrganizadora />
          </PrivateRoute>
          <PrivateRoute path="/adminOrganizadora/editar">
            <Cabecalho />
            <EditarOrganizadora />
          </PrivateRoute>
          <PrivateRoute path="/adminOrganizadora/editarEvento/:id">
            <Cabecalho />
            <EditarEventoOrganizadora />
          </PrivateRoute>
          <PrivateRoute path="/adminOrganizadora/editarModalidades/:id">
            <Cabecalho />
            <EditarEventoOrganizadoraModalidade />
          </PrivateRoute>
          <PrivateRoute path="/adminOrganizadora/imagemEvento/:id">
            <Cabecalho />
            <ImagemEventoOrganizadora />
          </PrivateRoute>
          <PrivateRoute path="/adminOrganizadora/eventos/:id" >
            <Cabecalho />
            <ListaEventosOrganizadora />
          </PrivateRoute>
          <PrivateRoute path="/adminOrganizadora/novoEvento" >
            <Cabecalho />
            <NovoEventoOrganizadora />
          </PrivateRoute>

          <PrivateRoute path="/admin/novoEvento" >
            <Cabecalho />
            <NovoEventoAdmin />
          </PrivateRoute>
          <PrivateRoute path="/admin/editarEvento/:id">
            <Cabecalho />
            <EditarEventoAdmin />
          </PrivateRoute>
          <PrivateRoute path="/admin/editarModalidades/:id">
            <Cabecalho />
            <EditarEventoAdminModalidade />
          </PrivateRoute>
          <PrivateRoute path="/admin/imagemEvento/:id">
            <Cabecalho />
            <ImagemEventoAdmin />
          </PrivateRoute>
          <PrivateRoute path="/admin/novoAnuncio" >
            <Cabecalho />
            <NovoAnuncio />
          </PrivateRoute>
          <PrivateRoute path="/admin/editarAnuncio/:id" >
            <Cabecalho />
            <EditarAnuncio />
          </PrivateRoute>
          <PrivateRoute path="/admin/listaAnuncios" >
            <Cabecalho />
            <ListaAnuncios />
          </PrivateRoute>
          <PrivateRoute path="/admin/imagemAnuncio/:id">
            <Cabecalho />
            <ImagemAnuncioAdmin />
          </PrivateRoute>


          <Route path="/sobre">
            <Sobre />
          </Route>
          <Route path="/politicaDeCookies">
            <PoliticaDeCookies />
          </Route>
          <Route path="/politicaDePrivacidade">
            <PoliticaDePrivacidade />
          </Route>
          <Route path="/termosDeUso">
            <TermosDeUso />
          </Route>
          <Route path="/ajuda/segurancaEmail">
            <SegurancaEmail />
          </Route>
          <Route path="/publicidade">
            <Publicidade />
          </Route>
          <Route path="/faq">
            <Faq />
          </Route>
          <Route>
            <Pagina404 />
          </Route>
        </Switch>
      </Router>
    </Container>
  );
}

export default App;
