
function compareDates (date) {
    let parts = date && date.split('/') // separa a data pelo caracter '/'
    let today = new Date()      // pega a data atual
    var data = new Date(parts && parts[2], parts && parts[1] - 1, parts && parts[0]) // formata 'date'
    
    // compara se a data informada é maior que a data atual
    // e retorna true ou false
    let ehFuturo = data >= today ? true : false

    return ehFuturo;
  }
 

  export default compareDates;
  