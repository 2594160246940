import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardImg,
  CardLink,
  CardText,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import fotoEvento from "../../assets/img/no-photo.png";
import Patrocinio from "../Home/Patrocinio";
import { busca } from "../../api/api_event";
import ClipLoader from "react-spinners/ClipLoader";
import InfiniteScroll from 'react-infinite-scroll-component';

const Calendario = ({ url }) => {
  const [eventos, setEventos] = useState([]);
  const [searchEvento, setSearchEvento] = useState([]);
  const [search, setSearch] = useState(null);
  let [loading, setLoading] = useState(true);
  const [showResult, setShowResult] = useState(false);
  const [showNoResult, setShowNoResult] = useState(false);
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth()+1);
  const [estado, setEstado] = useState("todos");
  const [atualizar, setAtualizar] = useState(true);
  let [ pagina, setPagina] = useState(0);

  useEffect(() => {
    busca(
      "/calendario/"+ pagina+"/" + year + "/" + month + "/" + estado + "/" + search,
      setEventos,
      setLoading,
      setShowResult,
      setShowNoResult
    );
  }, [pagina, atualizar, month, search, year]);

  function buscaEvento(event) {
    if (event === "") {
      setSearch(null);
    } else {
      setSearch(event);
    }
    setLoading(true);
    setAtualizar(!atualizar);
  }

  function TrocaAno(ano) {
    setLoading(true);
    setYear(ano);
    setAtualizar(!atualizar);
  }

  function TrocaMes(mes) {
    setLoading(true);
    setMonth(mes);
    setAtualizar(!atualizar);
  }

  function TrocaUf(uf) {
    setLoading(true);
    setEstado(uf);
    setAtualizar(!atualizar);
  }


  const Result = () => (
    <Card className="m-0 p-0 text-center h-100">
        {eventos.map((evento) => (
          <Card>
          <CardLink  href={`/corridas/${evento.id}`} className="m-0 p-0 text-center h-100 no-underline">
            <Row>
              <Col xs='3' sm="2">
                <Link to={`/corridas/${evento.id}`}>
                  <CardImg
                    src={evento.foto === null ? fotoEvento : evento.foto}
                    className="img-fluid rounded-lg m-0 p-1 "
                  />
                </Link>
              </Col>
              <Col xs='9' sm="9">
                <CardBody className="p-0">
                  <CardText className="m-0 text_medium_center">
                    {evento.nome}
                  </CardText>
                  <CardText className="m-2 text_banco">
                    {evento.cidade}
                  </CardText>
                  <CardText className="m-1 text_banco">{evento.dataInicio}</CardText>
                </CardBody>
                <CardFooter className="m-0 p-0">
                  <Alert color={evento.statusCor} className="p-0 m-0 text_alert ">
                    EVENTO {evento.statusEvento}
                  </Alert>
                </CardFooter>
              </Col>
            </Row>
          </CardLink>
          </Card>
        ))}
    </Card>
  );

  const NoResult = () => (
    <Card className="m-2">
      <CardText className="text_medium_center m-2">
        Não encontramos corridas programadas para este mês!
        <br />
        <Link to="/novoEvento" className="no-underline">
          <Button color="success" block className="mr-2 button_form">
            Cadastre uma nova corrida!
          </Button>
        </Link>
      </CardText>
    </Card>
  );

  return (
    <Container>
      <Row>
        <Col sm="10" className="p-0 pr-2 pt-2">
          <Card className="p-1 text-center">
            <CardText>
              <Row>
                <Col xs="3" sm="4" className="text-end text_form pt-3">
                  Eventos de
                </Col>
                <Col xs="7" sm="2" className="pt-3" >
                  <Input
                    bsSize="sm"
                    type="select"
                    name="year"
                    value={year}
                    onChange={(event) => {
                      TrocaAno(event.target.value);
                    }}
                    id="year"
                  >
                    <option value="2017">2017</option>
                    <option value="2018">2018</option>
                    <option value="2019">2019</option>
                    <option value="2020">2020</option>
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                  </Input>
                </Col>
                <Col xs="3" sm="1" className="text-end text_form pt-3">Mês </Col>
                <Col xs="7" sm="2" className="pt-3">
                  <Input bsSize="sm" type="select" name="month" id="month" onChange={(event) => {
                    TrocaMes(event.target.value);
                  }} value={month}>
                    <option value="0">Todos</option>
                    <option value="13">Sem data definida</option>
                    <option value="1">Janeiro</option>
                    <option value="2">Fevereiro</option>
                    <option value="3">Março</option>
                    <option value="4">Abril</option>
                    <option value="5">Maio</option>
                    <option value="6">Junho</option>
                    <option value="7">Julho</option>
                    <option value="8">Agosto</option>
                    <option value="9">Setembro</option>
                    <option value="10">Outubro</option>
                    <option value="11">Novembro</option>
                    <option value="12">Dezembro</option>
                  </Input>
                </Col>
              </Row>
            </CardText>
            <CardText>
              <Row>
                <Col xs="3" sm="4" className="text-end text_form"> Estado </Col>
                <Col xs="7" sm="5">
                  <Input bsSize="sm" type="select" name="estado" id="estado" onChange={(event) => {
                    TrocaUf(event.target.value);
                  }} value={estado}>
                    <option value="Todos">Todos</option>
                    <option value="AC">Acre</option>
                    <option value="AL">Alagoas</option>
                    <option value="AP">Amapa</option>
                    <option value="AM">Amazonas</option>
                    <option value="BA">Bahia</option>
                    <option value="CE">Ceára</option>
                    <option value="DF">Distrito Federal</option>
                    <option value="ES">Espírito Santo</option>
                    <option value="GO">Goias</option>
                    <option value="MA">Maranhão</option>
                    <option value="MT">Mato Grosso</option>
                    <option value="MS">Mato Grosso do Sul</option>
                    <option value="MG">Minas Gerais</option>
                    <option value="PA">Pará</option>
                    <option value="PB">Paraíba</option>
                    <option value="PR">Paraná</option>
                    <option value="PE">Pernambuco</option>
                    <option value="PI">Piauí</option>
                    <option value="RJ">Rio de Janeiro</option>
                    <option value="RN">Rio Grande do Norte</option>
                    <option value="RS">Rio Grande do Sul</option>
                    <option value="RO">Rondônia</option>
                    <option value="RR">Roraima</option>
                    <option value="SC">Santa Catarina</option>
                    <option value="SP">São Paulo</option>
                    <option value="SE">Sergipe</option>
                    <option value="TO">Tocantins</option>
                  </Input>

                </Col>
              </Row>

            </CardText>
            <CardText>
              <Row>
                <Col xs="1" sm="3">&nbsp;</Col>
                <Col xs="10" sm="6">
                  <FormGroup className="p-1 m-0">
                    <Form>
                      <Input
                        bsSize="sm"
                        type="text"
                        name="nomeCorrida"
                        id="nomeCorrida"
                        placeholder="Informe o nome de uma Corrida para filtrar"
                        onChange={(event) => {
                          setSearchEvento(event.target.value);
                          buscaEvento(event.target.value);
                        }}
                        value={searchEvento}
                      />
                    </Form>
                  </FormGroup>
                </Col>
              </Row>
            </CardText>

          </Card>
          <Card className="mt-2">
            <CardHeader className="text-center p-1" >
              <Row>
                <Col xs="12" sm="8" className="m-0 text_big">
                  Corridas de Rua
                </Col>
                <Col xs="12" sm="4" className="m-0 text-end">
                  <Link to="/novoEvento" className="no-underline">
                    <Button color="success" className="m-0 button_form" block>
                      Cadastre uma nova corrida!
                    </Button>
                  </Link>
                </Col>
              </Row>
            </CardHeader>
          </Card>
          <Card className="text-center">
            <ClipLoader color="gray" loading={loading} size={60} className="text-center" />
            {showResult ? <Result /> : null}
            {showNoResult ? <NoResult /> : null}
          </Card>
        </Col>
        <Col sm="2" className="p-0 pt-2">
          <Patrocinio />
        </Col>
      </Row>
    </Container>
  );
};

export default Calendario;
