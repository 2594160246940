import React from "react";
import {
  Container,
  Label,
  Card,
  CardTitle,
  CardText,
  Row,
  Col,
  Navbar,
  NavbarBrand,
} from "reactstrap";
import Rodape from "../../Home/Rodape";
import CabecalhoAjuda from "../CabecalhoAjuda";
import { Link } from "react-router-dom";

function SegurancaEmail() {

  return (
    <Container className="text-center">
      <CabecalhoAjuda />
      <Card className="p-3 text-start">
        <Row>
          <Col xs="12" sm="2" >&nbsp;</Col>
          <Col xs="12" sm="8"  >
            <CardTitle className="text-center">Mensagem de segurança no rodapé de e-mails</CardTitle>
            <CardText>
              <Label className="text_small p-2">
                Em todas as mensagens enviadas a você pelo Minhas Medalhas, incluímos uma mensagem de segurança no rodapé contendo o nome informado 
                no cadastro para ajudá-lo a distinguir e-mails autênticos do Minhas Medalhas de mensagens de e-mails tipo “phishing”. 
                E-mails tipo "phishing" geralmente são muito semelhantes a e-mails legítimos, mas provavelmente não incluem esses dados personalizados;
                eles também podem conter links que levam a sites nocivos, em vez de levá-lo ao Minhas Medalhas.
              </Label>
              <Label className="text_small p-2">
                Embora esta informação presente na mensagem por si só não garanta a legitimidade do e-mail, ela é mais uma garantia de que
                o e-mail é proveniente do Minhas Medalhas. A maioria das tentativas de “phishing” dirigidas a listas de e-mails extensas não contém
                essas informações. Quando houver alguma dúvida, abra uma nova janela no navegador e acesse o site www.minhasMedalhas.com.br diretamente;
                em seguida, verifique se a solicitação de conexão ou ação que foi informada está no seu menu. Fraudadores podem usar
                uma prática denominada phishing para obter dados confidenciais, como nomes de usuários, senhas e informações de 
                cartões de crédito. Clique no link caso queria mais informações sobre <Link to="https://pt.wikipedia.org/wiki/Phishing" >phishing</Link>.
              </Label>
              <Label className="text_small p-2">
                Se suspeitar que recebeu um e-mail tipo “phishing”, como se fosse do Minhas Medalhas; por favor, encaminhe-o para minhasmedalhas@minhasmedalhas.com.br
              </Label>
            </CardText>
          </Col>
          <Col xs="12" sm="2" >&nbsp;</Col>
        </Row>
      </Card>
      <Rodape />
    </Container>
  );
}

export default SegurancaEmail;
