import axios from "axios";
import { getToken, getUrlApi } from "./auth";

export const api = axios.create({
  baseURL: getUrlApi(),
});

api.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export const upload = async (
  url,
  selectedFile,
  idEvento,
  setConfirmadoOk,
  confirmadoOk
) => {
  try {
  
    if (selectedFile) {
      const fd = new FormData();
      fd.append("file", selectedFile, selectedFile.name);
      fd.set("idEvento", idEvento)
      const resposta = await api.post(url, fd, {
        onUploadProgress: (ProgressEvent) => {
          console.log(
            "Upload" +
              Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100) +
              "%"
          );
        },
      });
      console.log(resposta.data);
    } else {
      console.log("sem arquivo")
    } 
    setConfirmadoOk(!confirmadoOk);
  } catch (resposta) {
    console.log("erro > " + resposta);
  }
};
