import React, { useEffect, useState } from "react";
import { busca } from "../../api/api_athlete";
import logo from "../../assets/img/no-photo.png";
import { Link } from "react-router-dom";
import {
  CardImg,
  CardText,
  Card,
  CardHeader,
  Row,
  Col,
  Badge,
  Button,
  CardGroup,
  Label,
  CardBody,
} from "reactstrap";
import ClipLoader from "react-spinners/ClipLoader";

const UltimosEventos = ({ url }) => {
  const [quatroEventos, setQuatroEventos] = useState([]);
  let [loading, setLoading] = useState(true);
  const [showResult, setShowResult] = useState(false);
  const [showNoResult, setShowNoResult] = useState(false);

  useEffect(() => {
    busca(url, setQuatroEventos, setLoading, setShowResult, setShowNoResult);
  }, [url]);

  const Result = () => (
    <CardGroup className="pl-3 pr-3">
      {quatroEventos.map((registro) => (
        <Col sm="12" md="3" className="p-1">
          <Card className="m-0 h-100">
            <Row>
              <Col xs="6" md="12">
                <CardText className="p-1">
                  <Link to={"/corridas/" + registro.idEvento}>
                    <CardImg
                      top
                      alt="Imagem do evento"
                      src={registro.fotoMedalha ? registro.fotoMedalha : (registro.logoEvento ? registro.logoEvento : logo)}
                      className="img-fluid rounded-lg m-0 p-0 "
                    />
                  </Link>
                </CardText>
              </Col>
              <Col xs="6" md="12" >
                <CardBody className="p-0">
                  <Label className="m-0 text_medium_center">{registro.nomeEvento}</Label><br />
                  <Label className="m-0 text_banco"> {registro.nomeCidadeEvento} - {registro.ufEstadoEvento}</Label><br />
                  <Label className="m-0 text_banco">Data: {registro.dataEvento}</Label><br />
                  <Label className="m-0 text_banco">Distância: {registro.modalidade}</Label> <br />
                  <Label className="m-0 text_banco">Tempo/Pace: {registro.tempoProva} / {registro.pace}</Label>
                </CardBody>
              </Col>
            </Row>
          </Card>
        </Col>
      ))}
    </CardGroup>
  );

  const NoResult = () => (
    <CardText className="text-center m-2 text_alert">
      Que pena!
      <br />Não temos nenhum registro das suas corridas! Aproveite agora
      mesmo e cadastre sua corrida!
      <br />
      <Link to="/novoEvento">
        <Button color="success" className="m-2" block>
          Cadastre uma corrida!
        </Button>
      </Link>
    </CardText>
  );

  return (
    <Card className="p-0 m-0 mt-2 text-center">
      <CardHeader className="text-left p-1">
        <Row>
          <Col xs="8" sm="8" className="m-0 pt-1 pr-0 text_medium" >
            Últimos 4 Eventos realizados
          </Col>
          <Col xs="4" sm="4" className="m-0 pl-0 text-end">
            {showResult ? (
              <Link
                to={`/medalha/todasRealizadas`}
                className="mr-3 ml-3 mt-0 mb-0"
              >
                <Badge color="primary" className="p-2" pill >
                  Ver todos
                </Badge>
              </Link>
            ) : null}
          </Col>
        </Row>
      </CardHeader>{" "}
      <div className="text-center">
        <ClipLoader color="gray" loading={loading} size={60} />
      </div>
      {showResult ? <Result /> : null}
      {showNoResult ? <NoResult /> : null}
    </Card>
  );
};

export default UltimosEventos;
