import { Link } from "react-router-dom";
import React from "react";
import { Card, CardImg, CardText, Label } from "reactstrap";
import minha_medalha_cinza from "../../assets/img/logo_small_mail.png";

const Rodape = () => {
  return (
    <>
      <Card className="mt-2 pb-2 text-center" color="#cccccc">
        <CardText className="mt-1 mb-0 p-3">
          <Label className="text_small">
            <Link to="/sobre" className="text_small">Sobre</Link>
          </Label>&nbsp;&nbsp;
          <Label className="text_small">
            <Link to="/politicaDeCookies" className="text_small">Politica de cookies</Link>
          </Label>&nbsp;&nbsp;
          <Label className="text_small">
            <Link to="/politicaDePrivacidade" className="text_small">Politica de Privacidade</Link>
          </Label>&nbsp;&nbsp;
          <Label className="text_small">
            <Link to="/termosDeUso" className="text_small">Termos de Uso</Link>
          </Label>&nbsp;&nbsp;
          <Label className="text_small">
            <Link to="/publicidade" className="text_small">Publicidade</Link>
          </Label>&nbsp;&nbsp;
        </CardText>
        <CardText className="m-1">
          <Label className="text_small">
          <img src={minha_medalha_cinza}  />®</Label>
        </CardText>
      </Card>
    </>
  );
};

export default Rodape;
