import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardText,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import { busca } from "../../api/api_athlete";
import ClipLoader from "react-spinners/ClipLoader";
import Avatar from 'react-avatar';

const AssessoriaAtleta = ({ url }) => {
  const [assessoria, setAssesssoria] = useState([]);
  let [loading, setLoading] = useState(true);
  const [showResult, setShowResult] = useState(false);
  const [showNoResult, setShowNoResult] = useState(false);
  useEffect(() => {
    busca("/minhaAssessoria", setAssesssoria, setLoading, setShowResult, setShowNoResult);
  }, [url]);

  const Result = () => (
    <Row>
      <Col sm="3" className="m-0">
        <Link to={"/assessoria/" + assessoria.id}>
          {assessoria.logo === null ? (
            <Avatar name={assessoria.nome} size="150" round={true} maxInitials={2} />
          ) : (
            <Avatar src={assessoria.logo} size="150" round={true} />
          )}
        </Link>
      </Col>
      <Col sm="9">
        <CardTitle className="text_big_center">Você é um atleta {assessoria.sigla}</CardTitle>
        <CardText className="text_banco">{assessoria.email}</CardText>
        <CardText className="text_banco">{assessoria.fone}</CardText>
        <CardText>
          <Link to={"/assessoria/" + assessoria.id}>
            <Button color="primary" className="p-0 pr-2 pl-2">
              Veja os Atletas
            </Button>
          </Link>
        </CardText>
      </Col>
    </Row>
  )

  const NoResult = () => (
    <CardText className="p-3 text_alert" >
      Você não faz parte de nenhuma Assessoria! Aproveite e procure as assessorias aqui!
    </CardText>
  )

  return (
    <Card className="p-1 text-center">
      <div className="text-center">
        <ClipLoader color="gray" loading={loading} size={60} />
      </div>
      {showResult ? <Result /> : null}
      {showNoResult ? <NoResult /> : null}
    </Card>
  );

};

export default AssessoriaAtleta;

