import React from 'react';
import Patrocinio from '../Home/Patrocinio';
import { useParams } from 'react-router-dom';
import { Container, Col, Row } from "reactstrap";
import ListaPesquisa from './ListaPesquisa';


const Pesquisa = () => {
    const { search } = useParams()

    return (
        <Container className="p-0">
            <Row>
                <Col sm="9" className="p-2 pt-2">
        	        <ListaPesquisa url={`/atleta/${search}`} />
                </Col>
                <Col sm="3" className="p-0 pt-2">
        	        <Patrocinio />
                </Col>
            </Row>
        </Container>    
    )
}


export default Pesquisa