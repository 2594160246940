import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  CardHeader,
  CardFooter,
  Alert,
  Input,
  Col,
  Row,
  Form,
  Label,
  CardBody,
} from "reactstrap";
import ClipLoader from "react-spinners/ClipLoader";
import { getToken, getUrlApi } from "../../api/auth";

const EditaRegistroAtleta = (props) => {
  const { id } = useParams();
  const [modalidades, setModalidades] = useState(props.atleta.idModalidade);
  const [numeroAtleta, setNumeroAtleta] = useState(props.atleta.numeroAtleta);
  let [loading, setLoading] = useState(false);
  const [hora1, minuto1, segundo1] = (props.atleta.tempoProva || '').split(':');
  const [hora, setHora] = useState(hora1);
  const [minuto, setMinuto] = useState(minuto1);
  const [segundo, setSegundo] = useState(segundo1);
  const [tempoProva, setTempoProva] = useState(props.atleta.tempoProva);
  const [posicaoChegadaGeral, setPosicaoChegadaGeral] = useState(props.atleta.posicaoChegadaGeral);
  const [posicaoChegadaSexo, setPosicaoChegadaSexo] = useState(props.atleta.posicaoChegadaSexo);
  const [posicaoChegadaIdade, setPosicaoChegadaIdade] = useState(props.atleta.posicaoChegadaIdade);
  const [pace] = useState(props.atleta.pace);
  const [mensagemAPI, setMensagemAPI] = useState("");
  const [resultColor, setResultColor] = useState("");

  function Confirmar() {
    setLoading(true);
    const token = getToken();
    let modalidade = modalidades;
    setMensagemAPI("");
    setResultColor("warning");
    if (modalidade === null || modalidade === "0") {
      setMensagemAPI("Informe a distância percorrida.");
      setLoading(false);
      return;
    }
    let time ="";
    if (hora !== null ){
      if (hora > 999 || hora < 0){
        setMensagemAPI("A Hora deve estar entre 0 (zero) e 999.");
        setLoading(false);
        return;
      }else{
        if (hora !== null && hora !== undefined && hora !== ""){
          time = hora+":";
        }
      }
    }
    if (minuto !== undefined){
      if (minuto > 59 || minuto < 0 ){
        setMensagemAPI("Os minutos devem estar entre 0(zero) e 59.");
        setLoading(false);
        return;
      }else{
        if (time === "" || time === ":"){
          time ="00:";
        }
        if (minuto < 10 && minuto.length === 1){
          time = time + "0"+minuto+":";  
        }else{
          time = time + minuto+":";
        }
      }
    }else{
      if (time !== ""){
        time = time+"00:";
      }
    }
    if (segundo !== undefined) {
      if (segundo > 59 || segundo < 0){
        setMensagemAPI("Os segundos devem estar entre 0(zero) e 59");
        setLoading(false);
        return;
      }else{
        if (minuto === ""){
          setMensagemAPI("Os minutos devem ser informados!");
          setLoading(false);
          return;
        }
        if (segundo < 10 && segundo.length < 2){
          time = time+"0"+segundo;  
        }else{
          time = time+ segundo;
        }
      }
    }else{
      if (time !== ""){
        time = time+"00";
      }else{
        time = null;
      }
    }

    let numCamisa = null;
    if (numeroAtleta !== null) {
      numCamisa = numeroAtleta;
    }
    const requestInfo = {
      method: "POST",
      body: JSON.stringify({
        modalidadeForm: `${modalidades}`,
        numeroAtleta: numCamisa === null ? null : `${numeroAtleta}`,
        tempoProva: time,
        posicaoChegadaGeral: posicaoChegadaGeral,
        posicaoChegadaSexo: posicaoChegadaSexo,
        posicaoChegadaIdade: posicaoChegadaIdade,
        idEvento: id,
      }),
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };
    fetch(getUrlApi() + "/evento/atualizaRegistroAtleta", requestInfo).then(
      (response) => {
        response.json().then((data) => {
          if (response.status === 200) {
            setMensagemAPI(data.mensagem);
            setResultColor("success");
            props.alterarCancelar(true);
          } else {
            if (response.status === 202) {
              setMensagemAPI(data.mensagem);
            } else {
              if (response.status === 400) {
                setMensagemAPI(data[0].mensagem);
              }
            }
            setResultColor("warning");
            setLoading(false);
          }
        });
      }
    );
  }

  return (
    <>
      <CardHeader className="p-1">
        <Row>
          <Col xs="6" tag="h5" className=" mt-1 pr-0">
            Suas informações
          </Col>
          <Col xs="6" className="text-end pl-0">
            <Button
              color="primary"
              outline
              onClick={() => {
                Confirmar();
              }}
            >
              Confirmar
            </Button>
            <Button
              color="secondary"
              outline
              onClick={() => {
                props.alterarCancelar(false);
              }}
            >
              Cancelar
            </Button>
          </Col>
        </Row>
      </CardHeader>
      <Form
        method="Post"
        onSubmit={(event) => {
          event.preventDefault();
        }}
      >
        {mensagemAPI ? <Alert className="m-2 p-0 text-center" color={resultColor}>{mensagemAPI}</Alert> : null}
        <CardBody className="text-center">
          <Row>
            <Col sm="6" >
              <Row>
                <Col sm="6" xs="6" className="text-end">
                  <Label className="text_banco">Distância: </Label>
                </Col>
                <Col sm="6" xs="6" className="text-start">
                  <Input
                    bsSize="sm"
                    type="select"
                    name="modalidades"
                    id="modalidades"
                    value={modalidades}
                    onChange={(event) => {
                      setModalidades(event.target.value);
                    }}
                  >
                    <option value="0">Selecione</option>
                    {props.mod.map((modalidade) => (
                      <option value={modalidade.id} >{modalidade.nome}</option>
                    ))}
                  </Input>
                </Col>
                <Col sm="6" xs="6" className="text-end">
                  <Label className="text_banco">Número camiseta: </Label>
                </Col>
                <Col sm="6" xs="6" className="text-start">
                  <Input
                    bsSize="sm"
                    type="text"
                    name="numeroAtleta"
                    id="numeroAtleta"
                    placeholder="Número no peito"
                    maxlength="6"
                    value={numeroAtleta}
                    onChange={(event) => {
                      setNumeroAtleta(event.target.value);
                    }}
                  />
                </Col>
                {props.showInfos ? null : (
                  <>
                    <Col sm="6" xs="6" className="text-end">
                      <Label className="text_banco">Tempo: </Label>
                    </Col>
                    <Col sm="6" xs="6" className="text-start">
                      <Row>
                        <Col sm="4" xs="4" >
                    <Input
                        bsSize="sm"
                        type="number"
                        name="hora"
                        id="hora"
                        maxlength="2"
                        size="2"
                        value={hora}
                        onChange={(event) => {
                          setHora(event.target.value);
                        }}
                      />
                        </Col>
                        <Col sm="1" xs="1" className="m-0 p-0">:</Col>
                        <Col sm="3" xs="3" className="m-0 p-0" >
                    <Input
                        bsSize="sm"
                        type="number"
                        name="minuto"
                        id="minuto"
                        maxlength="2"
                        value={minuto}
                        onChange={(event) => {
                          setMinuto(event.target.value);
                        }}
                      />                    
                        </Col>
                        <Col sm="1" xs="1" className="p-0 ">:</Col>
                        <Col sm="3" xs="3" className="m-0 p-0">
                    <Input
                        bsSize="sm"
                        type="number"
                        name="segundo"
                        id="segundo"
                        maxlength="2"
                        value={segundo}
                        onChange={(event) => {
                          setSegundo(event.target.value);
                        }}
                      />
                        </Col>
                      </Row>
                      <Input
                        bsSize="sm"
                        type="hidden"
                        step="1"
                        name="tempoProva"
                        id="tempoProva"
                        placeholder="Tempo de prova 00:00:00"
                        maxlength="9"
                        value={tempoProva}
                        onChange={(event) => {
                          setTempoProva(event.target.value);
                        }}
                      />
                    </Col>
                    <Col sm="6" xs="6" className="text-end">
                      <Label className="text_banco">Pace: </Label>
                    </Col>
                    <Col sm="6" xs="6" className="text-start">
                      <Input
                        bsSize="sm"
                        id="pace"
                        value={pace}
                        type="text"
                        disabled
                        placeholder="calculado após confirmar"
                      />
                    </Col>
                  </>)}
              </Row>
            </Col>
            {props.showInfos ? null : (
              <>

                <Col sm="6" >
                  <Row>
                    <Col sm="6" xs="6" className="text-end">
                      <Label className="text_banco">Colocação geral: </Label>
                    </Col>
                    <Col sm="6" xs="6" className="text-start">
                      <Input
                        bsSize="sm"
                        type="number"
                        name="posicaoChegadaGeral"
                        id="posicaoChegadaGeral"
                        placeholder="Posição geral"
                        maxlength="5"
                        value={posicaoChegadaGeral}
                        onChange={(event) => {
                          setPosicaoChegadaGeral(event.target.value);
                        }}
                      />
                    </Col>
                    <Col sm="6" xs="6" className="text-end">
                      <Label className="text_banco">Colocação por sexo: </Label>
                    </Col>
                    <Col sm="6" xs="6" className="text-start">
                      <Input
                        bsSize="sm"
                        type="number"
                        name="posicaoChegadaSexo"
                        id="posicaoChegadaSexo"
                        placeholder="Posição por sexo"
                        maxlength="5"
                        value={posicaoChegadaSexo}
                        onChange={(event) => {
                          setPosicaoChegadaSexo(event.target.value);
                        }}
                      />
                    </Col>
                    <Col sm="6" xs="6" className="text-end">
                      <Label className="text_banco">Colocação por categoria: </Label>
                    </Col>
                    <Col sm="6" xs="6" className="text-start">
                      <Input
                        bsSize="sm"
                        type="number"
                        name="posicaoChegadaIdade"
                        id="posicaoChegadaIdade"
                        placeholder="Posição por idade"
                        maxlength="5"
                        value={posicaoChegadaIdade}
                        onChange={(event) => {
                          setPosicaoChegadaIdade(event.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </>)}
          </Row>
        </CardBody>

        {props.showInfos ? (
          <Row>
            <Col xs="12" className="text-center">
              <Alert color="info" className="text_alert">
                As demais informações são liberada quando o evento ocorrer!
              </Alert>
            </Col>
          </Row>
        ) : null}
      </Form>
      <div className="text-center">
        <ClipLoader color="gray" loading={loading} size={60} />
      </div>
      <CardFooter className="p-1">
        <Row>
          <Col xs="6" tag="h5" className=" mt-1 pr-0">
          </Col>
          <Col xs="6" className="text-end pl-0">
            <Button
              color="primary"
              outline
              onClick={() => {
                Confirmar();
              }}
            >
              Confirmar
            </Button>
            <Button
              color="secondary"
              outline
              onClick={() => {
                props.alterarCancelar(false);
              }}
            >
              Cancelar
            </Button>
          </Col>
        </Row>
      </CardFooter>
    </>
  );
};
export default EditaRegistroAtleta;
