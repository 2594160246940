import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import {
  Button,
  Card,
  CardSubtitle,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { getHash } from "../../api/auth";
import { busca } from "../../api/api_event";
import ClipLoader from "react-spinners/ClipLoader";
import NovoComentario from "./NovoComentario";
import { getToken, getUrlApi } from "../../api/auth";
import Avatar from "react-avatar";
import { FaEdit, FaTrash } from 'react-icons/fa';

const Comentarios = () => {
  const { id } = useParams();
  const [comentariosEvento, setComentariosEvento] = useState([]);
  let [loading, setLoading] = useState(true);
  const [showResult, setShowResult] = useState(false);
  const [showNoResult, setShowNoResult] = useState(false);
  const [atualiza, setAtualiza] = useState(false);
  const [idComentario, setIdComentario] = useState("");
  const [txComentario, setTxComentario] = useState("");
  const [modalExclusao, setModalExclusao] = useState(false);
  const [modalAlteracao, setModalAlteracao] = useState(false);
  const [messageAPI, setMessageAPI] = useState("");
  const [resultColor, setResultColor] = useState("");
  const idHash = getHash();

  function AtualizarComentarios(atualizar) {
    if (atualizar) {
      setAtualiza(!atualiza);
    }
  }

  function EditarComentario() {
    const token = getToken();
    const requestComentarioAtleta = {
      method: "POST",
      body: JSON.stringify({
        comentario: `${txComentario}`,
        idComentario: `${idComentario}`,
        idEvento: `${id}`,
      }),
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };
    fetch(
      getUrlApi()+"/evento/alterarComentario/",
      requestComentarioAtleta
    ).then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          setMessageAPI(data.message);
          setAtualiza(!atualiza);
        } else {
          if (response.status === 202) {
            setMessageAPI(data.message);
          } else {
            if (response.status === 400) {
              setMessageAPI(data[0].message);
            }
          }
          setResultColor("warning");
        }
      });
    });
    setModalAlteracao(!modalAlteracao);
  };

  function ModalAlteracaoComentario(idCom, txCom) {
    setModalAlteracao(!modalAlteracao);
    setIdComentario(idCom);
    setTxComentario(txCom);
  }

  function ModalExclusaoComentario(idCom, txCom) {
    setModalExclusao(!modalExclusao);
    setIdComentario(idCom);
    setTxComentario(txCom);
  }

  function RemoverComentario() {
    const token = getToken();
    const requestInfo = {
      method: "GET",
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };
    fetch(
      getUrlApi() + "/evento/comentarios/" + id + "/" + idComentario,
      requestInfo
    ).then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          setAtualiza(!atualiza);
        }
      });
    });
    setModalExclusao(!modalExclusao);
  }

  useEffect(() => {
    busca(
      `/comentarios/${id}`,
      setComentariosEvento,
      setLoading,
      setShowResult,
      setShowNoResult
    );
  }, [atualiza, id]);

  const Result = () => (
    <Card>
      <NovoComentario atualizarComentarios={AtualizarComentarios} />

      {comentariosEvento &&
        comentariosEvento.map((comentarios) => (
          <Card className="m-1">
            <Row>
              <Col xs="2" sm="1" className="pt-2">&nbsp;&nbsp;&nbsp;&nbsp;
                <Link to={`/atleta/${comentarios.idAtleta}`}>
                  {comentarios.fotoAtleta === null ? (
                    <Avatar name={comentarios.nomeAtleta} size="35" round={true} maxInitials={2} alt={comentarios.nomeAtleta} />
                  ) : (
                    <img alt=""
                      src={comentarios.fotoAtleta}
                      style={{ width: '35px', height: '35px', objectFit: 'cover', borderRadius: '50%' }}
                    />
                  )}
                </Link>
              </Col>
              {idHash === comentarios.idHashAtleta ? (
                <>
                  <Col xs="8" sm="9" className=" mt-1">
                    <Label className="text_small"><b>Você</b><br />
                      {comentarios.descricao}</Label>
                  </Col>
                  <Col xs="2" sm="2" className=" p-3 text-end">
                    <FaEdit size="18px" style={{ cursor: 'pointer' }} onClick={() => { ModalAlteracaoComentario(comentarios.idComentario, comentarios.descricao) }}/>
                    &nbsp;&nbsp;&nbsp;
                    <FaTrash color="red" size="18px" style={{ cursor: 'pointer' }} onClick={() => { ModalExclusaoComentario(comentarios.idComentario, comentarios.descricao) }} />
                  </Col>
                </>
              ) : (
                <Col xs="10" sm="11" className=" mt-1">
                  <Label className="text_small m-0"><b>{comentarios.nomeAtleta}</b><br />
                    {comentarios.descricao}</Label>
                </Col>
              )}
            </Row>
          </Card>
        ))}
      <br />
    </Card>
  );

  const NoResult = () => (
    <Card className="mt-3">
      <CardSubtitle className="text-center m-2">
        <small>
          Não encontramos nenhum comentário. seja o primeiro a fazer um
          comentário sobre o evento!
        </small>
      </CardSubtitle>
    </Card>
  );

  return (
    <>
      <div className="text-center">
        <ClipLoader color="gray" loading={loading} size={60} />
      </div>
      {showResult ? <Result /> : null}
      {showNoResult ? <NoResult /> : null}
      <div>
        <Modal
          isOpen={modalExclusao}
          toggle={() => { ModalExclusaoComentario(null, null) }}
          className="p-0"
        >
          <ModalHeader toggle={() => { ModalExclusaoComentario(null, null) }}>
            Confirma a exclusão do Comentário!
          </ModalHeader>
          <ModalBody>
            <Card className="p-0 text-start m-0">
              {txComentario}
            </Card>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={() => { RemoverComentario() }} >
              Excluir
            </Button>
            <Button color="secondary" onClick={() => { ModalExclusaoComentario(null, null) }}>
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>
      </div>
      <div>
        <Modal
          isOpen={modalAlteracao}
          toggle={() => { ModalAlteracaoComentario(null, null) }}
          className="p-0"
        >
          <ModalHeader toggle={() => { ModalAlteracaoComentario(null, null) }}>
            Alterar Comentário!
          </ModalHeader>
          <ModalBody>
            <Card className="p-0 text-start m-0">
              <Input
                type="text"
                name="txComentario"
                id="txComentario"
                onChange={(event) => {
                  event.preventDefault();
                  setTxComentario(event.target.value);
                }}
                value={txComentario}
              />
            </Card>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => { EditarComentario() }} >
              Alterar
            </Button>
            <Button color="secondary" onClick={() => { ModalAlteracaoComentario(null, null) }}>
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>
      </div>


    </>
  );
};

export default Comentarios;
