import React, { useEffect, useState } from "react";
import { busca } from "../../api/api_athlete";
import { Link } from "react-router-dom";
import {
  CardText,
  Card,
  CardHeader,
  Col,
  Button,
  CardGroup,
  Label,
  CardBody,
  CardTitle,
} from "reactstrap";
import ClipLoader from "react-spinners/ClipLoader";
import { FaTrophy } from 'react-icons/fa';

const RecordesPessoais = ({ url }) => {
  const [quatroEventos, setQuatroEventos] = useState([]);
  let [loading, setLoading] = useState(true);
  const [showResult, setShowResult] = useState(false);
  const [showNoResult, setShowNoResult] = useState(false);

  useEffect(() => {
    busca(url, setQuatroEventos, setLoading, setShowResult, setShowNoResult);
  }, [url]);

  const Result = () => (
    <CardGroup>
      {quatroEventos.map((registro) => (
        <Col sm="12" md="3" className="p-1">
          <Link to={"/corridas/" + registro.idEvento}>
            <Card className="m-0 h-100">
              <CardTitle>
                <Label className="m-0 text_big">{registro.modalidade}</Label><br />
                <Label className="m-0 text_banco">{registro.dataEvento}</Label>
              </CardTitle>
              <CardBody className="p-0">
                <Label className="m-0 text_medium_center">{registro.nomeEvento}</Label><br />
                <Label className="m-0 text_banco"> {registro.nomeCidadeEvento} - {registro.ufEstadoEvento}</Label><br />
                <Label className="m-0 text_banco">Tempo/Pace: {registro.tempoProva} / {registro.pace}</Label>
              </CardBody>
            </Card>
          </Link>
        </Col>
      ))}
    </CardGroup>
  );

  const NoResult = () => (
    <CardText className="text-center m-2 text_alert">
      Que pena!
      <br />Não temos nenhum registro das suas corridas! Aproveite agora
      mesmo e cadastre sua corrida!
      <br />
      <Link to="/novoEvento" className="no-underline">
        <Button color="success" className="m-2" block>
          Cadastre uma corrida!
        </Button>
      </Link>
    </CardText>
  );

  return (
    <Card className="p-0 m-0 mt-2 text-center">
      <CardHeader className="text-left p-1">
        <FaTrophy color="#FFD43B" size="30px" />&nbsp;<Label className="m-0 pt-1 pr-0 text_medium" >
          Recordes Pessoais
        </Label>&nbsp;<FaTrophy color="#FFD43B" size="30px" />
      </CardHeader>{" "}
      <div className="text-center">
        <ClipLoader color="gray" loading={loading} size={60} />
      </div>
      {showResult ? <Result /> : null}
      {showNoResult ? <NoResult /> : null}
    </Card>
  );
};

export default RecordesPessoais;
