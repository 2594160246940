import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardImg,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  Container,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Row,
  UncontrolledAlert,
} from "reactstrap";
import Patrocinio from "../Home/Patrocinio";
import { Link } from "react-router-dom";
import logo from "../../assets/img/no-photo.png";
import { busca } from "../../api/api_previa";
import ClipLoader from "react-spinners/ClipLoader";
import { getHash, getToken, getUrlApi } from "../../api/auth";

const EventosEncontrados = () => {
  let { id } = useParams();
  const [previas, setPrevias] = useState([]);
  let [loading, setLoading] = useState(true);
  const [showResult, setShowResult] = useState(false);
  const [showNoResult, setShowNoResult] = useState(false);
  const [previasInformados, setPreviasInformados] = useState([]);
  let [loadingInformados, setLoadingInformados] = useState(true);
  const [showResultInformados, setShowResultInformados] = useState(false);
  const [showNoResultInformados, setShowNoResultInformados] = useState(false);
  const [modalNaoReconhecerEvento, setModalNaoReconhecerEvento] = useState(false);
  const [eventoNaoReconhecido, setEventoNaoReconhecido] = useState("");
  const [idEventoNaoReconhecido, setIdEventoNaoReconhecido] = useState("");
  const [messageAPI, setMessageAPI] = useState("");
  const [resultColor, setResultColor] = useState("");
  const [atualizar, setAtualizar] = useState(false);


  useEffect(() => {
    setLoading(true)

    busca(
      `eventosInformados`,
      setPreviasInformados,
      setLoadingInformados,
      setShowResultInformados,
      setShowNoResultInformados
    );

    busca(
      `eventosDescobertos`,
      setPrevias,
      setLoading,
      setShowResult,
      setShowNoResult
    );

  }, [atualizar, id]);

  function ConfirmarEvento(idPrevia) {
    window.location.href = `/confirmarDadosEvento/${getHash()}/${idPrevia}`;
  }
    
  function NaoReconhece(idEvento, nomeEvento) {
    setModalNaoReconhecerEvento(!modalNaoReconhecerEvento);
    setEventoNaoReconhecido(nomeEvento);
    setIdEventoNaoReconhecido(idEvento);
  }

  function ConfirmarNaoReconhecerEvento() {
    const token = getToken();
    const requestInfo = {
      method: "GET",
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };
    fetch(
      getUrlApi() +
      "/previa/negarEventoEncontrado/"+idEventoNaoReconhecido,
      requestInfo
    ).then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          setAtualizar(!atualizar);
        } else {
          if (response.status === 202) {
            setMessageAPI(data.message);
          } else {
            if (response.status === 400) {
              setMessageAPI(data.message);
            }
          }
          setResultColor("warning");
        }
      });
    });
    setModalNaoReconhecerEvento(!modalNaoReconhecerEvento);

  }

  const Result = () => (
    previas.length > 0 ? (
    <Card className="mt-2">
      <CardHeader className="text_big_center" >Eventos que o Minhas medalhas encontrou com seu nome!</CardHeader>
      {previas.map((previa) => (
        <Card className="p-3 mb-2 text-center">
          <Row>
            <Col xs="4" md="3" className="p-1">
              <CardText className="p-1">
                <Link to={"/corridas/" + previa.idEvento}>
                  <CardImg
                    top
                    alt="Imagem do evento"
                    src={previa.logoEvento ? previa.logoEvento : logo}
                    className="img-fluid rounded-lg m-0 p-0 "
                  />
                </Link>
              </CardText>
            </Col>
            <Col xs="8" md="7" className="p-1">
              <CardBody className="p-1 text-start">
                <CardTitle className="p-0 text_medium">
                  {previa.nomeEvento}
                </CardTitle>
                <CardSubtitle className="mb-2 text_form">
                  {previa.nomeCidadeEvento} - {previa.ufEstadoEvento}
                </CardSubtitle>
                <CardText className="m-0 text_small">
                  Data: {previa.dataEvento}
                </CardText>
                <CardText className="m-0 text_small">
                  Numero camiseta: {previa.numeroAtleta}
                </CardText>
                <CardText className="m-0 text_small">
                  Posição chegada geral: {previa.posicaoChegadaGeral}
                </CardText>
                <CardText className="m-0 text_small">
                  Posição chegada Por Sexo: {previa.posicaoChegadaSexo}
                </CardText>
                <CardText className="m-0 text_small">
                  Posição chegada Por Idade: {previa.posicaoChegadaIdade}
                </CardText>
                <CardText className="m-0 text_small">
                  Distância: {previa.modalidade}
                </CardText>
                <CardText className="m-0 text_small">
                  Tempo/Pace: {previa.tempoProva} / {previa.pace}
                </CardText>
              </CardBody>
            </Col>
            <Col xs="12" md="2" className="p-1">
              <CardText className="p-1">
                <Badge color="primary" className="p-2 m-1 text_badge"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    ConfirmarEvento(previa.id);
                  }} pill>Confirmar</Badge>&nbsp;&nbsp;&nbsp;&nbsp;<Badge color="secondary" className="p-2 m-1 text_badge"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      NaoReconhece(previa.id, previa.nomeEvento);
                    }} pill>Não reconheço</Badge>
              </CardText>
            </Col>
          </Row>
        </Card>
      ))}
    </Card>
    ):null
  );


  const ResultInformados = () => (
    previasInformados.length > 0 ? (
    <Card className="mt-2">
      <CardHeader className="text_big_center" >Eventos que você marcou:  Eu vou ou Quero ir</CardHeader>
      {previasInformados.map((previaInformada) => (
        <Card className="p-3 mb-2 text-center">
          <Row>
            <Col xs="4" md="3" className="p-1">
              <CardText className="p-1">
                <Link to={"/corridas/" + previaInformada.idEvento}>
                  <CardImg
                    top
                    alt="Imagem do evento"
                    src={previaInformada.logoEvento ? previaInformada.logoEvento : logo}
                    className="img-fluid rounded-lg m-0 p-0 "
                  />
                </Link>
              </CardText>
            </Col>
            <Col xs="8" md="7" className="p-1">
              <CardBody className="p-1 text-start">
                <CardTitle className="p-0 text_medium">
                  {previaInformada.nomeEvento}
                </CardTitle>
                <CardSubtitle className="mb-2 text_form">
                  {previaInformada.nomeCidadeEvento} - {previaInformada.ufEstadoEvento}
                </CardSubtitle>
                <CardText className="m-0 text_small">
                  Data: {previaInformada.dataEvento}
                </CardText>
                <CardText className="m-0 text_small">
                  Numero camiseta: {previaInformada.numeroAtleta}
                </CardText>
                <CardText className="m-0 text_small">
                  Posição chegada geral: {previaInformada.posicaoChegadaGeral}
                </CardText>
                <CardText className="m-0 text_small">
                  Posição chegada Por Sexo: {previaInformada.posicaoChegadaSexo}
                </CardText>
                <CardText className="m-0 text_small">
                  Posição chegada Por Idade: {previaInformada.posicaoChegadaIdade}
                </CardText>
                <CardText className="m-0 text_small">
                  Distância: {previaInformada.modalidade}
                </CardText>
                <CardText className="m-0 text_small">
                  Tempo/Pace: {previaInformada.tempoProva} / {previaInformada.pace}
                </CardText>
              </CardBody>
            </Col>
            <Col xs="12" md="2" className="p-1">
              <CardText className="p-1">
                <Badge color="primary" className="p-2 m-1 text_badge"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    ConfirmarEvento(previaInformada.id);
                  }} pill>Confirmar</Badge>&nbsp;&nbsp;&nbsp;&nbsp;<Badge color="secondary" className="p-2 m-1 text_badge"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      NaoReconhece(previaInformada.id, previaInformada.nomeEvento);
                    }} pill>Não reconheço</Badge>
              </CardText>
            </Col>
          </Row>
        </Card>
      ))}
    </Card>
    ):null
  );

  const NoResult = () => (
    <Card>
      <CardText className="text-center m-2">
        <Label className="text_medium">
          Que pena!
          <br />
          Não temos nenhum registro em seu nome!
          <br />
          <Link to="/novoEvento">
            <Button color="success" className="mr-2 no-underline" block>
              Cadastre uma corrida!
            </Button>
          </Link>
        </Label>
      </CardText>
    </Card>
  );

  const NoResultInformados = () => (
    <>
    </>
  );


  return (
    <Container>
      <Row>
        <Col sm="10" className="p-2 pt-2">
          <Card className="mt-2">
            <CardHeader className="text-center p-1">
              <Label className="text_big">Eventos em seu nome não confirmados!</Label>
            </CardHeader>
          </Card>
          <div className="text-center">
            <ClipLoader color="gray" loading={loading} size={60} />
          </div>
          {showResultInformados ? <ResultInformados /> : <NoResultInformados />}
          {showNoResultInformados ? <NoResultInformados /> : null}
          {showResult ? <Result /> : <NoResult />}
          {showNoResult ? <NoResult /> : null}
          {messageAPI ? (
            <UncontrolledAlert
              className="m-2 p-1 text-center"
              color={resultColor}
            >
              {messageAPI}
            </UncontrolledAlert>
          ) : null}
        </Col>
        <Col sm="2" className="p-0 pt-2">
          <Patrocinio />
        </Col>
      </Row>
      <div>
        <Modal isOpen={modalNaoReconhecerEvento} className="p-0">
          <ModalHeader>Não Reconhece o Evento informado!</ModalHeader>
          <ModalBody className="pt-1 pb-3 text-left">
            <Label className="text_medium">
              Você confirma que <b>não</b> participou do Evento {" "}
              {eventoNaoReconhecido }{" "}?
            </Label>
            <Label className="text_small">Se não tiver certeza, pode cancelar e o evento ficará na sua lista ok?</Label>
            <Label className="text_small">Se você confirmar que não reconhece a corrida iremos remover da sua lista!</Label>

          </ModalBody>

          <ModalFooter>
            <Button
              color="secondary"
              outline
              onClick={() => setModalNaoReconhecerEvento(!modalNaoReconhecerEvento)}
            >
              Cancelar
            </Button>
            <Button color="primary" outline onClick={ConfirmarNaoReconhecerEvento}>
              Confirmar
            </Button>
          </ModalFooter>
        </Modal>
      </div>

    </Container>
  );
}

export default EventosEncontrados;
