import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { getToken, getUrlApi, getAuthOrg } from "../../api/auth";
import chevron_down from "../../assets/img/chevron-down.svg";
import {
  Badge,
  Col,
  Row,
  Card,
  CardText,
  CardHeader,
  Container,
  CardImg,
  CardBody,
  Alert,
  CardFooter,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  CardLink,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
} from "reactstrap";
import "../../assets/css/minha_medalha.css";
import { busca } from "../../api/api_event";
import fotoEvento from "../../assets/img/no-photo.png";
import Fotos from "./Fotos";
import DetalheAtletaEvento from "./DetalheAtletaEvento";
import ClipLoader from "react-spinners/ClipLoader";
import CompareDatas from "../../contexts/ValiadacoesDatas";
import ListaParticipantes from "./ListaParticipantes";
import Comentarios from "./Comentarios";

const Evento = () => {
  const { id } = useParams();
  const [eventoDetalhe, setEventoDetalhe] = useState([]);
  let [loading, setLoading] = useState(true);
  let [loadingAcao, setLoadingAcao] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [showNoResult, setShowNoResult] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [messageAPI, setMessageAPI] = useState("");
  const [resultColor, setResultColor] = useState("warning");
  const [participantes, setParticipantes] = useState(false);
  const [comentarios, setComentarios] = useState(false);
  const [chevron, setChevron] = useState(chevron_down);
  const [athletePresent, setAthletePresent] = useState(null);
  const idOrganizadoraAtleta = getAuthOrg();
  const [atualizar, setAtualizar] = useState(false);

  const [open, setOpen] = useState(null);
  const toggle = (idAcord) => {
    if (open === idAcord) {
      setOpen();
    } else {
      setOpen(idAcord);
    }
  }

  function AtletaPresente(presente) {
    if (athletePresent === null) {
      return presente;
    } else {
      return athletePresent;
    }
  }

  function RegistrarConfirmacao() {
    setLoadingAcao(true);
    const token = getToken();
    const requestInfo = {
      method: "GET",
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };
    fetch(getUrlApi() + "/evento/euVou/" + id, requestInfo).then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          setAthletePresent(!athletePresent);
        } else {
          if (response.status === 202) {
            setMessageAPI(data.message);
          } else {
            if (response.status === 400) {
              setMessageAPI(data[0].message);
            }
          }
          setResultColor("warning");
        }
      });
    });
    setLoadingAcao(false);
  }


  function RegistrarDesejoEmParticipar() {
    setLoadingAcao(true);
    const token = getToken();
    const requestInfo = {
      method: "GET",
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };
    fetch(getUrlApi() + "/evento/queroIr/" + id, requestInfo).then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          setAthletePresent(!athletePresent);
        } else {
          if (response.status === 202) {
            setMessageAPI(data.message);
          } else {
            if (response.status === 400) {
              setMessageAPI(data[0].message);
            }
          }
          setResultColor("warning");
        }
      });
    });
    setLoadingAcao(false);
  }

  function ConfirmarRemocao() {
    setModalSuccess(!modalSuccess);
  }

  function MostrarComentarios() {
    setComentarios(!comentarios);
  }

  function RemoverDoEvento() {
    setLoadingAcao(true);
    const token = getToken();
    const requestInfo = {
      method: "GET",
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };
    fetch(getUrlApi() + "/evento/remover/" + id, requestInfo).then(
      (response) => {
        response.json().then((data) => {
          if (response.status === 200) {
            setAthletePresent(!athletePresent);
            setAtualizar(!atualizar);
          } else {
            if (response.status === 202) {
              setMessageAPI(data.message);
            } else {
              if (response.status === 400) {
                setMessageAPI(data[0].message);
              }
            }
            setResultColor("warning");
          }
        });
      }
    );
    setModalSuccess(!modalSuccess);
    setLoadingAcao(false);
  }

  useEffect(() => {
    busca(
      `/corrida/${id}`,
      setEventoDetalhe,
      setLoading,
      setShowResult,
      setShowNoResult
    );
  }, [id, atualizar]);



  const Result = () => (
    <>
      <Card className="mt-2">
        <CardHeader className="text_big_center" >
          {eventoDetalhe.nome}
        </CardHeader>
        <Row>
          <Col sm="5">
            <Card>
              <CardImg
                src={
                  eventoDetalhe.foto === null ? fotoEvento : eventoDetalhe.foto
                }
                className="img-fluid rounded-lg p-2"
              />
            </Card>

          </Col>
          <Col sm="7" className="mb-3 text-center">
            <CardBody>
              <Alert
                color={eventoDetalhe.statusCor}
                className={`text_alert m-0 p-0 `}
              >
                {eventoDetalhe.statusEvento}
              </Alert>
              <CardText className="m-0 mt-2 text_banco">
                {eventoDetalhe.dataInicioEvento}
                {eventoDetalhe.horaLargada !== null
                  ? ` - início às ${eventoDetalhe.horaLargada} hs`
                  : null}
              </CardText>
              <CardText className="mt-0 text_banco">
                {eventoDetalhe.localLargada !== null
                  ? `Local de largada: ${eventoDetalhe.localLargada}`
                  : null}
              </CardText>
              <CardText className="mt-0 text_big">
                {eventoDetalhe.nomeCidade} - {eventoDetalhe.uf}
              </CardText>
              <CardText className="m-2">
                <Badge color="success" pill className="p-2 ">
                  Corrida de Rua
                </Badge>
              </CardText>
              <CardText>
                {eventoDetalhe.modalidades &&
                  eventoDetalhe.modalidades.map((categoria) => (
                    <Badge color={categoria.cor} className="p-1 m-1 text_badge">
                      {categoria.nome}
                    </Badge>
                  ))}
              </CardText>
              {eventoDetalhe.urlEvento !== null ? (
                <CardLink href={eventoDetalhe.urlEvento} target="_blank">
                  Acesse o site do evento
                </CardLink>
              ) : null}
              <CardText className="mt=2 text_banco">
                {eventoDetalhe.nomeOrganizadoraEvento !== null
                  ? `Organizadora: ` + eventoDetalhe.nomeOrganizadoraEvento
                  : ""}
              </CardText>
              <CardText className="m-2  text-start text_banco" style={{ whiteSpace: 'pre-line' }}>
                {eventoDetalhe.resumo}
              </CardText>
              {idOrganizadoraAtleta !== 0 ? (
                eventoDetalhe.idOrganizadoraEvento ===
                  idOrganizadoraAtleta / 7 ? (
                  eventoDetalhe.status === "ATIVO" ? (
                    <Link to={"/adminOrganizadora/editarEvento/" + id}>
                      <Button block color="primary">
                        Editar
                      </Button>
                    </Link>
                  ) : null
                ) : null
              ) : null}
            </CardBody>
          </Col>
        </Row>
        <CardFooter>
          {AtletaPresente(eventoDetalhe.atletaPresente) ? (
            <Badge
              color="danger"
              pill
              className="p-2 m-1 text_badge"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                ConfirmarRemocao();
              }}
            >
              Remover do evento
            </Badge>
          ) : CompareDatas(eventoDetalhe.dataInicioEvento) ? (
            <>
              <Badge
                color="primary"
                pill
                className="p-2 m-1 text_badge"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  RegistrarConfirmacao();
                }}
              >
                Eu vou
              </Badge>
              <Badge color="warning" pill className="p-2 m-1 text_badge" style={{ cursor: 'pointer' }}
                onClick={() => {
                  RegistrarDesejoEmParticipar();
                }}
              >
                Quero ir
              </Badge>
            </>
          ) : (
            <Badge
              color="primary"
              pill
              className="p-2 m-1 text_badge"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                RegistrarConfirmacao();
              }}
            >
              {" "}
              Eu fui
            </Badge>
          )}
          <ClipLoader color="gray" loading={loadingAcao} size={40} />
          <Badge
            color="secondary"
            pill
            className="p-2 m-1 text_badge" style={{ cursor: 'pointer' }}
            onClick={() => {
              MostrarComentarios();
            }}
          >
            Comentar
          </Badge>
          <Label className="text_small">
            {" "}
            {eventoDetalhe.quantidadeComentarios === 0
              ? "Seja o primeiro"
              : eventoDetalhe.quantidadeComentarios + " comentário"}
            {eventoDetalhe.quantidadeComentarios > 1 ? "s" : null}{" "}
          </Label>
        </CardFooter>
        {comentarios ? <Comentarios /> : null}
      </Card>
      {AtletaPresente(eventoDetalhe.atletaPresente) ? (
        <>
          <DetalheAtletaEvento mod={eventoDetalhe.modalidades} />
          <Fotos />
        </>
      ) : null}
      <Card className="mt-2">
        {eventoDetalhe.registros !== null ? (
          <Card>
            <Accordion open={open} toggle={toggle}>
              <AccordionItem>
                <AccordionHeader targetId="1" className="text-big">
                  Participantes deste Evento{" "}
                  {eventoDetalhe.quantidadeAtletas !== null
                    ? "(" + eventoDetalhe.quantidadeAtletas + ")"
                    : null}</AccordionHeader>
                <AccordionBody accordionId="1">
                  <ListaParticipantes />
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </Card>
        ) : (
          <Card className="text_alert">Ainda não temos atletas cadastrado para este evento!</Card>
        )}
      </Card>
      <br/><br/>
      <div>
        <Modal isOpen={modalSuccess} className="p-0">
          <ModalHeader className="text_big">Retirada do Evento!</ModalHeader>
          <ModalBody className="pt-1 pb-3 text_medium">
            <label>Você está retirando sua inscrição deste evento!</label>
            <label>Com isso, suas informações e fotos serão excluídas.</label>
            <label> Confirma a retirada? </label>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              outline
              onClick={() => setModalSuccess(!modalSuccess)}
            >
              Cancelar
            </Button>
            <Button color="primary" outline onClick={RemoverDoEvento}>
              Confirmar
            </Button>
          </ModalFooter>
        </Modal>
      </div>

    </>
  )

  const NoResult = () => (
    <CardText className="p-3 text_alert">
      <Alert className="text-center" color={resultColor}>
        Algo deu errado! Volte e selecione novamente o Evento!<br />
        {messageAPI}
      </Alert>
    </CardText>
  );

  return (
    <Container className="p-2 pt-0">
      <div className="text-center">
        <ClipLoader color="gray" loading={loading} size={60} />
      </div>
      {showResult ? <Result /> : null}
      {showNoResult ? <NoResult /> : null}
    </Container>
  );

};

export default Evento;
