import React from "react";
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import Patrocinio from "../Home/Patrocinio";
import ProximosEventos from "./ProximosEventos";
import UltimosEventos from "./UltimosEventos";
import { Link } from "react-router-dom";
import RecordesPessoais from "./RecordesPessoais";

function ListaMedalhas(){

  return (
        <Container >
        <Row>
          <Col sm="10" className="p-2 pt-2">
            <Card className="mt-2">
              <CardHeader className="text-center p-1" >
              <Row>
                  <Col sm="8" className="mt-2 text_big">
                  Minhas Medalhas
                  </Col>
                  <Col sm="4" className="m-0 text-end">
                  <Link to="/novoEvento" className="no-underline">
                    <Button color="success" className="m-0" block>
                      Cadastre uma nova corrida!
                    </Button>
                    </Link>
                  </Col>
                </Row>
                
              </CardHeader>
            </Card>
            <RecordesPessoais url={'/recordesPessoais'} />
            <ProximosEventos url={'/proximasCorridas'} />
            <UltimosEventos url={'/ultimasCorridas'} />
          </Col>
          <Col sm="2" className="p-0 pt-2">
            <Patrocinio />
          </Col>
        </Row>
      </Container>
    )

}

export default ListaMedalhas
