import { React, useState } from "react";
import { Link } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { getToken, getUrlApi } from "../../api/auth";
import ClipLoader from "react-spinners/ClipLoader";
import foto from "../../assets/img/no-photo.png";

const NovoEvento = () => {
  const [searchCity, setSearchCity] = useState([]);
  const [idEvento, setIdEvento] = useState(null);
  const [dataEvento, setDataEvento] = useState("");
  const [idCidade, setIdCidade] = useState("");
  const [cidade, setCidade] = useState("");
  const [cidades, setCidades] = useState([]);
  const [nomeOrganizadora, setNomeOrganizadora] = useState("");
  const [nomeEvento, setNomeEvento] = useState("");

  const [modalidade, setModalidade] = useState("");
  const [numeroAtleta, setNumeroAtleta] = useState(null);
  const [tempoProva, setTempoProva] = useState("");
  const [posicaoChegadaGeral, setPosicaoChegadaGeral] = useState(null);
  const [posicaoChegadaSexo, setPosicaoChegadaSexo] = useState(null);
  const [posicaoChegadaIdade, setPosicaoChegadaIdade] = useState(null);
  const [corridaVirtual, setCorridaVirtual] = useState("");
  const [outraModalidade, setOutraModalidade] = useState("");
  const [messageAPI, setMessageAPI] = useState("");
  const [resultColor, setResultColor] = useState("");
  let [loading, setLoading] = useState(false);
  const [showFirstStep, setShowFirstStep] = useState(true);
  const [eventoRealizado, setEventoRealizado] = useState(false);

  const [eventos, setEventos] = useState([]);
  const [modalidades, setModalidades] = useState([]);
  const [novaModalidade, setNovaModalidade] = useState(false);

  const [modalCidade, setModalCidade] = useState(false);
  const [modalEventos, setModalEventos] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);

  const BuscarCidade = () => setModalCidade(!modalCidade);

  function CancelarModalEvento() {
    setShowFirstStep(!showFirstStep);
    setModalEventos(!modalEventos);
  }

  function CadastrarEvento() {
    setLoading(true);
    const token = getToken();

    if (nomeEvento === "") {
      setMessageAPI("O Nome do Evento deve ser informado!");
      setResultColor("warning");
      return;
    } else {
      if (modalidade === "0" || modalidade === "") {
        console.log("entrou")
        setMessageAPI("A modalidade deve ser informada!");
        setResultColor("warning");
        return;
      } else {
        setMessageAPI("");
        setResultColor("");
      }
    }

    let resumo = "";
    if (modalidade === "99") {
      resumo = resumo + " [ Modalidade: " + outraModalidade + " ] ";
    }
    if (nomeOrganizadora !== "") {
      resumo = resumo + " [ Organizadora: " + nomeOrganizadora + " ] ";
    }

    let numCamisa = null;
    if (numeroAtleta !== null) {
      numCamisa = numeroAtleta;
    }

    const requestInfo = {
      method: "POST",
      body: JSON.stringify({
        nome: `${nomeEvento}`,
        dataInicio: `${dataEvento}`,
        idCidade: `${idCidade}`,
        idModalidade: `${modalidade}`,
        resumo: `${resumo}`,
        numeroAtleta: numCamisa === null ? null : `${numeroAtleta}`,
        tempoProva: `${tempoProva}`,
        posicaoChegadaGeral: posicaoChegadaGeral,
        posicaoChegadaIdade: posicaoChegadaIdade,
        posicaoChegadaSexo: posicaoChegadaSexo,
        origem: `atleta`,
      }),
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };
    fetch(
      getUrlApi() + "/novoEvento/enviaDados",
      requestInfo
    ).then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          setModalSuccess(true);
          console.log("id novo evento" + data.idSuccesso)
          setIdEvento(data.idSuccesso);
          setMessageAPI(data.message);
          setResultColor("success");
        } else {
          if (response.status === 202) {
            setMessageAPI(data.message);
          } else {
            if (response.status === 400) {
              setMessageAPI(data[0].message);
            }
          }
          setResultColor("warning");
        }
      });
    });
    setLoading(false);
  }

  function SelecionarCidade(cidade) {
    setCidade(`${cidade.nome} - ${cidade.uf}`);
    setIdCidade(cidade.id);
    setCorridaVirtual(null);
    setModalCidade(!modalCidade);
  }

  function Voltar() {
    setShowFirstStep(!showFirstStep);
  }

  function chaveadorCorridaVirtual(event) {
    if (corridaVirtual === "on") {
      setCorridaVirtual(null);
      setCidade("");
      setIdCidade("");
    } else {
      setCorridaVirtual(event);
      setCidade("Corrida Virtual / Corrida em Casa");
      setIdCidade(5571);
    }
  }

  function ehNovaModalidade(event) {
    if (event === "99") {
      setNovaModalidade(true)
    } else {
      setNovaModalidade(false)
    }
  }

  function buscaCidade(nomeCidade) {
    const token = getToken();
    const requestCidade = {
      method: "POST",
      body: JSON.stringify({
        nome: `${nomeCidade}`,
      }),
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };
    fetch(getUrlApi() + "/cidade/buscaCidade/", requestCidade).then(
      (response) => {
        response.json().then((data) => {
          setCidades(data);
          setLoading(false);
        });
      }
    );
  }

  function BuscaModalidades() {
    const token = getToken();
    const requestModalidades = {
      method: "GET",
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };
    fetch(getUrlApi() + "/modalidade/buscaModalidade/", requestModalidades).then(
      (response) => {
        response.json().then((data) => {
          setModalidades(data);
        });
      }
    );
  }

  function EhDataAntiga(dataInput) {
    var hoje2 = new Date();
    var dataInformada = new Date(dataInput);
    if (hoje2 > dataInformada) {
      return true;
    } else {
      return false;
    }
  }

  function Proximo() {
    if (idCidade === "" || dataEvento === "") {
      setMessageAPI("A cidade e a data devem ser informadas!");
      setResultColor("warning");
      return;
    } else {
      setMessageAPI("");
      setResultColor("");
    }
    const token = getToken();
    let dataInformada = dataEvento;
    const [year1, month1, day1] = dataInformada.split("-");
    dataInformada = `${day1}/${month1}/${year1}`;
    setDataEvento(dataInformada);

    if (EhDataAntiga(dataEvento)) {
      setEventoRealizado(true);
    } else {
      setEventoRealizado(false);
    }

    const requestInfo = {
      method: "POST",
      body: JSON.stringify({
        dataInicio: `${dataInformada}`,
        idCidade: idCidade,
      }),
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };
    fetch(getUrlApi() + "/novoEvento/validaEvento", requestInfo).then(
      (response) => {
        response.json().then((data) => {
          if (data.length > 0) {
            setModalEventos(!modalEventos);
            setEventos(data);
          } else {
            setShowFirstStep(!showFirstStep);
            BuscaModalidades();
          }
        });
      }
    );
  }

  return (
    <Container className="p-0 pt-2">
      <Row>
        <Col sm="3"></Col>
        <Col sm="6">
          <Card className="p-2">
            <Form
              method="Post"
              onSubmit={(event) => {
                event.preventDefault();
              }}
            >
              <CardTitle className="mt-2 pt-2 text_big_center" >
                Cadastro de Novo Evento/Corrida
              </CardTitle>
              {showFirstStep ? (
                <CardBody>
                  <FormGroup>
                    <Label className="text_form">Cidade onde ocorreu o evento</Label>
                    <Input
                      bsSize="sm"
                      type="text"
                      name="cidade"
                      minLength="5"
                      id="cidade"
                      value={cidade}
                      placeholder="Click para selecionar a cidade"
                      onClick={BuscarCidade}
                    />
                    <Input type="hidden" name="cityId" value={idCidade} />
                    <FormFeedback className="text_alert" invalid>Cidade invalida</FormFeedback>
                  </FormGroup>
                  <FormGroup switch>
                    <Label>
                      <Input type="switch" role="switch"
                        id="corridaVirtual"
                        name="corridaVirtual"
                        checked={corridaVirtual}
                        onClick={(event) => {
                          chaveadorCorridaVirtual(event.target.value);
                        }} />
                      <Label check className="text_banco" >Corrida Virtual?</Label>
                    </Label>
                  </FormGroup>
                  <FormGroup>
                    <Label className="text_form">Data do evento</Label>
                    <Input
                      bsSize="sm"
                      type="date"
                      name="dataEvento"
                      minLength="5"
                      maxLength="8"
                      id="dataEvento"
                      value={dataEvento}
                      onChange={(event) => {
                        setDataEvento(event.target.value);
                      }}
                    />
                    <FormFeedback invalid>Data invalida</FormFeedback>
                  </FormGroup>
                  {messageAPI ? (
                    <Alert color={resultColor}>{messageAPI}</Alert>
                  ) : null}
                  <CardText className="text-end">
                    <Button color="primary" id="proximo" onClick={Proximo}>
                      Próximo
                    </Button>
                  </CardText>
                </CardBody>
              ) : (
                <CardBody>
                  <CardText className="text_form">
                    Cidade:
                    <Input disabled value={cidade} bsSize="sm" />
                  </CardText>
                  <CardText className="text_form">
                    Data do evento:
                    <Input disabled value={dataEvento} bsSize="sm" />
                  </CardText>
                  <CardText className="text_form">
                    Informe a Organizadora de Eventos (Opcional)
                    <Input
                      bsSize="sm"
                      type="text"
                      name="organizerName"
                      id="organizerName"
                      onChange={(event) => {
                        event.preventDefault();
                        setNomeOrganizadora(event.target.value);
                      }}
                      value={nomeOrganizadora}
                    />
                  </CardText>
                  <CardText className="text_form">
                    Informe o nome do Evento completo
                    <Input
                      bsSize="sm"
                      name="name"
                      id="name"
                      onChange={(event) => {
                        event.preventDefault();
                        setNomeEvento(event.target.value);
                      }}
                      value={nomeEvento}
                      required
                    />
                  </CardText>
                  <CardText className="text_form">
                    Modalidade
                    <Input
                      bsSize="sm"
                      type="select"
                      name="modalidade"
                      id="modalidade"
                      value={modalidade}
                      onChange={(event) => {
                        setModalidade(event.target.value);
                        ehNovaModalidade(event.target.value);
                      }}
                    >
                      <option value="0">Selecione</option>
                      {modalidades.map((modalidadeCombo) => (
                        <option value={modalidadeCombo.id}>
                          {modalidadeCombo.nome}
                        </option>
                      ))}
                    </Input>
                    {novaModalidade ? (
                      <div id="novaModalidade" >
                        <Input
                          bsSize="sm"
                          type="text"
                          id="outraModalidade"
                          name="outraModalidade"
                          placeholder="Informe a modalidade"
                          maxLength="20"
                          value={outraModalidade}
                          onChange={(event) => {
                            setOutraModalidade(event.target.value);
                          }}
                        />
                      </div>
                    ) : null}
                  </CardText>
                  <CardText className="text_form">
                    Número no peito (pode informar se possuir)
                    <Input
                      bsSize="sm"
                      type="text"
                      name="numeroAtleta"
                      id="numeroAtleta"
                      maxlength="6"
                      value={numeroAtleta}
                      onChange={(event) => {
                        setNumeroAtleta(event.target.value);
                      }}
                    />
                  </CardText>
                  {eventoRealizado ? (
                    <>
                      <CardText className="text_form">
                        Qual foi o tempo de prova líquido?
                        <Input
                          bsSize="sm"
                          type="text"
                          name="tempoProva"
                          id="tempoProva"
                          placeholder="Tempo de prova 00:00:00"
                          maxlength="9"
                          value={tempoProva}
                          onChange={(event) => {
                            setTempoProva(event.target.value);
                          }}
                        />
                      </CardText>
                      <CardText className="text_form">
                        Qual posição você chegou no Geral?
                        <Input
                          bsSize="sm"
                          type="number"
                          name="posicaoChegadaGeral"
                          id="posicaoChegadaGeral"
                          maxlength="5"
                          value={posicaoChegadaGeral}
                          onChange={(event) => {
                            setPosicaoChegadaGeral(event.target.value);
                          }}
                        />
                      </CardText>
                      <CardText className="text_form" >
                        Qual posição você chegou por Sexo?
                        <Input
                          bsSize="sm"
                          type="number"
                          name="posicaoChegadaSexo"
                          id="posicaoChegadaSexo"
                          maxlength="5"
                          value={posicaoChegadaSexo}
                          onChange={(event) => {
                            setPosicaoChegadaSexo(event.target.value);
                          }}
                        />
                      </CardText>
                      <CardText className="text_form">
                        Qual posição você chegou nesta categoria?
                        <Input
                          bsSize="sm"
                          type="text"
                          name="posicaoChegadaIdade"
                          id="posicaoChegadaIdade"
                          maxlength="5"
                          value={posicaoChegadaIdade}
                          onChange={(event) => {
                            setPosicaoChegadaIdade(event.target.value);
                          }}
                        />
                      </CardText>
                    </>
                  ) : null}
                  {messageAPI ? (
                    <Alert color={resultColor}>{messageAPI}</Alert>
                  ) : null}
                  <CardText>
                    <Row>
                      <Col xs="6">
                        <Button
                          name="voltar"
                          color="secondary"
                          block
                          onClick={Voltar}
                          className="button_form"
                        >
                          Voltar
                        </Button>
                      </Col>
                      <Col xs="6">
                        <Button
                          name="Cadastrar"
                          color="primary"
                          block
                          onClick={CadastrarEvento}
                          className="button_form"
                        >
                          Cadastrar
                        </Button>
                      </Col>
                    </Row>
                  </CardText>
                  <div className="text-center">
                    <ClipLoader color="gray" loading={loading} size={60} />
                  </div>
                </CardBody>
              )}
            </Form>
          </Card>
        </Col>
      </Row>

      <div>
        <Modal isOpen={modalCidade} className="p-0">
          <ModalHeader className="text_big">Selecione a cidade!</ModalHeader>
          <ModalBody className="pt-1 pb-3 text-left">
            <Form>
              <CardText className="pb-2">
                <Label className="text_form">Cidade:*</Label>
                <Input
                  bsSize="sm"
                  onChange={(event) => {
                    setSearchCity(event.target.value);
                    buscaCidade(event.target.value);
                    setLoading(true);
                  }}
                  type="text"
                  value={searchCity}
                  name="searchCity"
                  id="searchCity"
                  placeholder="Informe o nome da cidade"
                  minLength="3"
                  maxlength="80"
                  required
                />
              </CardText>
            </Form>
            <div className="text-center">
              <ClipLoader color="gray" loading={loading} size={60} />
            </div>
            {cidades.map((cidade) => (
              <Card
                onClick={() => {
                  SelecionarCidade(cidade);
                }}
                id={cidade.id}
              >
                <CardText className="m-1">
                  {cidade.nome} - {cidade.uf}
                </CardText>
              </Card>
            ))}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={BuscarCidade} className="button_form">
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>
      </div>
      <div>
        <Modal isOpen={modalEventos} className="p-0">
          <ModalHeader>
            Eventos cadastrados em {cidade} no dia {dataEvento}!
          </ModalHeader>
          <ModalBody className="pt-1 pb-3 text-left">
            {eventos.map((evento) => (
              <Card id={evento.id}>
                <CardText className="m-1">
                  <Row>
                    <Col xs="4">
                      <Link to={`/corridas/${evento.id}`}>
                        <CardImg
                          src={evento.foto === null ? foto : evento.foto}
                          className="img-fluid rounded-lg m-3 "
                          width="50"
                        />
                      </Link>
                    </Col>
                    <Col xs="8">
                      <CardTitle tag="h4"> {evento.nome}</CardTitle>
                    </Col>
                  </Row>
                </CardText>
              </Card>
            ))}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" outline block onClick={CancelarModalEvento} >
              Seu evento não esta na lista? Cadastre agora!
            </Button>
          </ModalFooter>
        </Modal>
      </div>
      <div>
        <Modal isOpen={modalSuccess} className="p-0">
          <ModalHeader className="text-big">
            Evento cadastrado com sucesso!
          </ModalHeader>
          <ModalBody className="pt-1 pb-3 text-left text_banco">
            Obrigado por compartilhar sua conquista conosco!
          </ModalBody>
          <ModalFooter>
            <Link to={"/corridas/" + idEvento} className="no-underline" >
              <Button color="primary" outline block >
                Continuar
              </Button>
            </Link>
          </ModalFooter>
        </Modal>
      </div>
    </Container>
  );
};

export default NovoEvento;

