import React, { useEffect, useState } from "react";
import { busca } from "../../../api/api_admin_anuncio";
import { Link, useParams } from "react-router-dom";
import { getToken, getUrlApi } from "../../../api/auth";
import {
  CardBody,
  CardImg,
  CardText,
  Card,
  CardHeader,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Container,
  Alert,
} from "reactstrap";
import ClipLoader from "react-spinners/ClipLoader";

const ListaAnuncios = ({ url }) => {
  let { id } = useParams();
  const [anuncios, setAnuncios] = useState([]);
  let [loading, setLoading] = useState(true);
  const [showResult, setShowResult] = useState(false);
  const [showNoResult, setShowNoResult] = useState(false);
  const [searchAnuncio, setSearchAnuncio] = useState("");
  const [messageAPI, setMessageAPI] = useState("");
  const [resultColor, setResultColor] = useState("");
  const [atualiza, setAtualiza] = useState(false);

  useEffect(() => {
    setLoading(true);
    setShowResult(false);
    setShowNoResult(false);
    busca("/listarAnuncios", setAnuncios, setLoading, setShowResult, setShowNoResult);
  }, [atualiza, url]);

  function buscaAnuncio(filtro) {
    const token = getToken();
    const requestAnuncio = {
      method: "POST",
      body: JSON.stringify({
        nome: `${filtro}`,
      }),
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };
    fetch(getUrlApi(), requestAnuncio).then((response) => {
      response.json().then((data) => {
        setAnuncios(data);
        if (data.length === 0) {
          setShowNoResult(true);
        } else {
          setShowNoResult(false);
        }
      });
    });
  }

  const Result = () => (
    <>
      {anuncios.map((anuncio) => (
        <Card className="m-1 text-center ">
          <Row className="p-2 m-0">
            <Col sm="2" md="2" className="p-0 m-0">
              <CardText className="p-1">
                <Link to={"linkAnuncio/"+anuncio.url}>
                  <CardImg
                    top
                    alt="Imagem do evento"
                    src={anuncio.foto ? anuncio.foto : null}
                    className=" m-0 p-0 "
                  />
                </Link>
              </CardText>
            </Col>
            <Col sm="10" md="5" className="p-0">
              <CardBody className="p-1 text-center">
                <CardText tag="h6" className="p-0 text_banco">
                  Cod/Nome campanha: {anuncio.inicial} / {anuncio.nome}
                </CardText>
                <CardText tag="h6" className="mb-0 text_banco">
                  Tamanho do banner: {anuncio.tipoAnuncio}
                </CardText>
                <CardText className="m-0 text_banco">
                  Data Limite: {anuncio.dataLimite}
                </CardText>
              </CardBody>
            </Col>
            <Col sm="9" md="4" className="p-0">
              <CardBody className="p-1 text-center">
                <CardText tag="h6" className="p-0 text_medium">
                  Empresa: {anuncio.nomeEmpresa}
                </CardText>
                <CardText tag="h6" className="mb-0 text_banco">
                  Visualizações: {anuncio.views}
                </CardText>
                <CardText className="m-0 text_small">
                  Clicks: {anuncio.clicks}
                </CardText>
              </CardBody>
            </Col>
            <Col sm="3" md="1" className="p-1">
              <Alert color={anuncio.statusAnuncio === "ATIVO" ? "success" : anuncio.statusAnuncio === "INATIVO" ? "danger" : "warning"}  className="m-0 p-0 text-center text_alert">{anuncio.statusAnuncio}</Alert><br/>
              <Link to={"/admin/editarAnuncio/" + anuncio.id}>
                <Button block color="primary">
                  Editar
                </Button>
              </Link>
            </Col>
          </Row>
        </Card>
      ))}
    </>
  );

  const NoResult = () => (
    <CardText className="text-center m-2">
      Não encontramos anúncios!
      <br />
      <Link to="/novoAnuncio">
        <Button color="success" className="mr-2" >
          Novo anúncio
        </Button>
      </Link>
    </CardText>
  );

  return (
    <Container>
      <Row>
        <Col sm="12" className="p-2 pt-2">
          <Card className="mt-2">
            <CardHeader className="text-center p-1" tag="h3">
              <Row>
                <Col sm="10" className="m-0">
                  Anúncios
                </Col>
                <Col sm="2" className="m-0 text-end">
                  <Link to="/novoEvento">
                    <Button color="success" className="m-0" block>
                      Novo anúncio!
                    </Button>
                  </Link>
                </Col>
              </Row>
            </CardHeader>
            <CardText>
              <Form>
                <Row>
                  <Col sm="12">
                    <FormGroup className="p-2 m-0">
                      <Input
                        type="text"
                        name="filtro"
                        id="filtro"
                        placeholder="Informe o nome da Empresa ou Campanha para filtrar"
                        onChange={(event) => {
                          setSearchAnuncio(event.target.value);
                          buscaAnuncio(event.target.value);
                        }}
                        value={searchAnuncio}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </CardText>
          </Card>

          <Card className="p-0 m-0 mt-2 text-center">
            <CardHeader className="text-left p-1">
              <Row>
                <Col className="m-0 pt-1 " tag="h6">
                  Anúncios
                </Col>
              </Row>
            </CardHeader>{" "}
            <div className="text-center">
              <ClipLoader color="gray" loading={loading} size={60} />
            </div>
            {showResult ? <Result /> : null}
            {showNoResult ? <NoResult /> : null}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ListaAnuncios;
