import { React, useState } from "react";
import { Link } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { getToken, getUrlApi, getHash } from "../../api/auth";
import ClipLoader from "react-spinners/ClipLoader";
import foto from "../../assets/img/no-photo.png";

const NovoEvento = () => {
  const [searchCity, setSearchCity] = useState([]);
  const [idEvento, setIdEvento] = useState(null);
  const [dataEvento, setDataEvento] = useState("");
  const [dataFormatada, setDataFormatada] = useState("");
  const [idCidade, setIdCidade] = useState("");
  const [cidade, setCidade] = useState("");
  const [cidades, setCidades] = useState([]);
  const [nomeEvento, setNomeEvento] = useState("");

  const [corridaVirtual, setCorridaVirtual] = useState("");
  const [messageAPI, setMessageAPI] = useState("");
  const [resultColor, setResultColor] = useState("");
  let [loading, setLoading] = useState(false);
  let [loadingPage, setLoadingPage] = useState(false);

  const [eventos, setEventos] = useState([]);

  const [modalCidade, setModalCidade] = useState(false);
  const [modalEventos, setModalEventos] = useState(false);
  const BuscarCidade = () => setModalCidade(!modalCidade);

  function CancelarModalEvento() {
    setModalEventos(!modalEventos);
    CadastrarEvento();
  }

  function CadastrarEvento() {
    setLoadingPage(true);
    const token = getToken();
    const idEve = idEvento; //colocado para ver se precisa
    let dataInformada = dataEvento;
    const [year1, month1, day1] = dataInformada.split("-");
    dataInformada = `${day1}/${month1}/${year1}`;
    const requestInfo = {
      method: "POST",
      body: JSON.stringify({
        nome: `${nomeEvento}`,
        dataInicio: `${dataInformada}`,
        idCidade: `${idCidade}`,
        origem: `administrador`,
      }),
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };
    fetch(
      getUrlApi() + "/novoEvento/enviaDados",
      requestInfo
    ).then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          setIdEvento(data.idSuccess);
          setMessageAPI(data.message);
          setResultColor("success");
          window.location.href = `editarEvento/${data.idSucesso}`;
        } else {
          if (response.status === 202) {
            setMessageAPI(data.message);
          } else {
            if (response.status === 400) {
              setMessageAPI(data[0].message);
            }
            if (response.status === 500) {
              setMessageAPI("erro inesperado");
            }
            setLoadingPage(false);
          }
          setResultColor("warning");
        }
      });
    });
  }

  function SelecionarCidade(cidade) {
    setCidade(`${cidade.nome} - ${cidade.uf}`);
    setIdCidade(cidade.id);
    setCorridaVirtual(null);
    setModalCidade(!modalCidade);
  }

  function chaveadorCorridaVirtual(event) {
    if (corridaVirtual === "on") {
      setCorridaVirtual(null);
      setCidade("");
      setIdCidade("");
    } else {
      setCorridaVirtual(event);
      setCidade("Corrida Virtual / Corrida em Casa");
      setIdCidade(5571);
    }
  }

  function buscaCidade(nomeCidade) {
    const token = getToken();
    const requestCidade = {
      method: "POST",
      body: JSON.stringify({
        nome: `${nomeCidade}`,
      }),
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };
    fetch(getUrlApi() + "/cidade/buscaCidade/", requestCidade).then(
      (response) => {
        response.json().then((data) => {
          setCidades(data);
          setLoading(false);
        });
      }
    );
  }

  function Proximo() {
    if (idCidade === "" || dataEvento === "" || nomeEvento === "") {
      setMessageAPI("Todas as informações devem ser informadas!");
      setResultColor("warning");
      return;
    } else {
      setMessageAPI("");
      setResultColor("");
    }
    const token = getToken();
    let dataInformada = dataEvento;
    const [year1, month1, day1] = dataInformada.split("-");
    dataInformada = `${day1}/${month1}/${year1}`;
    setDataFormatada(dataInformada);

    const requestInfo = {
      method: "POST",
      body: JSON.stringify({
        dataInicio: `${dataInformada}`,
        idCidade: idCidade,
      }),
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };
    fetch(getUrlApi() + "/novoEvento/validaEvento", requestInfo).then(
      (response) => {
        response.json().then((data) => {
          if (data.length > 0) {
            setModalEventos(!modalEventos);
            setEventos(data);
          } else {
            CadastrarEvento();
          }
        });
      }
    );
  }

  return (
    <Container className="p-0 pt-2">
      <Row>
        <Col sm="3"></Col>
        <Col sm="6">
          <Card className="p-2">
            <Form
              method="Post"
              onSubmit={(event) => {
                event.preventDefault();
              }}
            >
              <CardTitle className="text-center mt-2 pt-2">
                <Label className="text_big">Cadastro de Nova Corrida</Label>
              </CardTitle>
              <CardBody>
                <FormGroup>
                  <Label className="text_form">Cidade do evento*</Label>
                  <Input
                    bsSize="sm"
                    type="text"
                    name="cidade"
                    minLength="5"
                    id="cidade"
                    value={cidade}
                    placeholder="Click para selecionar a cidade"
                    onClick={BuscarCidade}
                  />
                  <Input type="hidden" name="cityId" value={idCidade} />
                  <FormFeedback invalid>Cidade invalida</FormFeedback>
                  <FormGroup switch>

                  <Input type="switch" role="switch"
                    id="corridaVirtual"
                    name="corridaVirtual"
                    checked={corridaVirtual}
                    onClick={(event) => {
                      chaveadorCorridaVirtual(event.target.value);
                    }} />
                  <Label check >Corrida Virtual?</Label>
                  </FormGroup>
                </FormGroup>
                <FormGroup>
                  <Label className="text_form">Data do evento*</Label>
                  <Input
                    bsSize="sm"
                    type="date"
                    name="dataEvento"
                    minLength="5"
                    maxLength="8"
                    id="dataEvento"
                    value={dataEvento}
                    onChange={(event) => {
                      setDataEvento(event.target.value);
                    }}
                    required
                  />
                  <FormFeedback invalid>Data invalida</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label className="text_form">Informe o nome da corrida completo*</Label>
                  <Input
                    bsSize="sm"
                    name="nomeEvento"
                    id="nomeEvento"
                    minLength="5"
                    onChange={(event) => {
                      event.preventDefault();
                      setNomeEvento(event.target.value);
                    }}
                    value={nomeEvento}
                    required
                  />
                </FormGroup>
                {messageAPI ? (
                  <Alert color={resultColor}>{messageAPI}</Alert>
                ) : null}
                <CardText>
                  <Row>
                    <Col xs="6">
                      <Link to={`/AdminOrganizadora`}>
                        <Button
                          name="voltar"
                          color="secondary"
                          block
                          className="button_form"
                        >
                          Cancelar
                        </Button>
                      </Link>
                    </Col>
                    <Col xs="6">
                    {getHash() !== 'rubensrk' ? (
                      <Alert color="warning">Você não é administrador</Alert> 
                    ):(
                      <Button
                        name="Cadastrar"
                        color="primary"
                        block
                        onClick={Proximo}
                        className="button_form"
                      >
                        Cadastrar
                      </Button>
                      )}
                      </Col>
                  </Row>
                </CardText>
              </CardBody>
              <div className="text-center">
              <ClipLoader color="gray" loading={loadingPage} size={60} />
              </div>
            </Form>
          </Card>
        </Col>
      </Row>

      <div>
        <Modal isOpen={modalCidade} className="p-0">
          <ModalHeader>Selecione a cidade!</ModalHeader>
          <ModalBody className="pt-1 pb-3 text-left">
            <Form>
              <CardText className="pb-2">
                <small>Cidade:*</small>
                <Input
                  onChange={(event) => {
                    setSearchCity(event.target.value);
                    buscaCidade(event.target.value);
                    setLoading(true);
                  }}
                  type="text"
                  value={searchCity}
                  name="searchCity"
                  id="searchCity"
                  placeholder="Informe o nome da cidade"
                  minLength="3"
                  maxlength="80"
                  required
                />
              </CardText>
            </Form>
            <div className="text-center">
              <ClipLoader color="gray" loading={loading} size={60} />
            </div>
            {cidades.map((cidade) => (
              <Card
                onClick={() => {
                  SelecionarCidade(cidade);
                }}
                id={cidade.id}
              >
                <CardText className="m-1">
                  {cidade.nome} - {cidade.uf}
                </CardText>
              </Card>
            ))}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={BuscarCidade}>
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>
      </div>
      <div>
        <Modal isOpen={modalEventos} className="p-0">
          <ModalHeader>
            Já existe eventos cadastrados no dia {dataFormatada} em {cidade} para sua Empresa!
            <br /><small>Você pode selecionar para editar ou seguir e cadastrar outro.</small>
          </ModalHeader>
          <ModalBody className="pt-1 pb-3 text-left">
            {eventos.map((evento) => (
              <Link to={`/adminOrganizadora/editarEvento/${evento.id}`}>
                <Card id={evento.id}>
                  <CardText className="m-1">
                    <Row>
                      <Col xs="4">
                        <CardImg
                          src={evento.foto === null ? foto : evento.foto}
                          className="img-fluid rounded-lg m-3 "
                          width="50"
                        />
                      </Col>
                      <Col xs="8">
                        <CardTitle tag="h4"> {evento.nome}</CardTitle>
                      </Col>
                    </Row>
                  </CardText>
                </Card>
              </Link>
            ))}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" outline block onClick={CancelarModalEvento}>
              Seu evento não esta na lista? Cadastre agora!
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </Container>
  );
};

export default NovoEvento;