import { React, useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { busca } from "../../api/api_event_admin";
import ClipLoader from "react-spinners/ClipLoader";
import { getToken, getUrlApi, getHash } from "../../api/auth";

function EditarEvento() {
  const { id } = useParams();
  const [modalCidade, setModalCidade] = useState(false);
  const Buscar = () => setModalCidade(!modalCidade);
  const [searchCity, setSearchCity] = useState([]);

  const [nome, setNome] = useState("");
  const [dataEvento, setDataEvento] = useState("");
  const [idCidade, setIdCidade] = useState("");
  const [cidade, setCidade] = useState("");
  const [resumo, setResumo] = useState("");
  const [messageAPI, setMessageAPI] = useState("");
  const [resultColor, setResultColor] = useState("");
  const [website, setWebsite] = useState("");
  const [cidades, setCidades] = useState([]);
  const [localLargada, setLocalLargada] = useState("");
  const [horaLargada, setHoraLargada] = useState("");
  const [totalAtletas, setTotalAtletas] = useState("");
  const [block, setBlock] = useState(true);
  const [hoje, setHoje] = useState(
    Intl.DateTimeFormat("pt-BR", { timeZone: "UTC" }).format(new Date())
  );
  const [evento, setEvento] = useState([]);
  let [loading, setLoading] = useState(true);
  const hashAtleta = getHash();
  const [showResult, setShowResult] = useState(false);
  const [showNoResult, setShowNoResult] = useState(false);
  const [visible, setVisible] = useState(false);

  const onDismiss = () => setVisible(false);


  useEffect(() => {
    busca(
      "/corrida/" + id,
      setEvento,
      setLoading,
      setShowResult,
      setShowNoResult,
      hashAtleta
    );
  }, [id]);

  if (evento.id !== undefined && block) {
    setShowNoResult(false);
    setShowResult(true);
    const input = evento.dataInicioEvento;
    if (input != null) {
      const [dia, mes, ano] = input.split("/");
      setDataEvento(`${ano}-${mes}-${dia}`);
    } else {
      setDataEvento(null);
    }
    setNome(evento.nome);
    setLocalLargada(evento.localLargada);
    setHoraLargada(evento.horaLargada);
    setTotalAtletas(evento.totalAtletas);
    setWebsite(evento.urlEvento);
    setResumo(evento.resumo);
    if (evento.nomeCidade === null) {
      setCidade("");
    } else {
      setCidade(evento.nomeCidade + " - " + evento.uf);
      setIdCidade(evento.idCidade)
    }
    const [diaH, mesH, anoH] = hoje.split("/");
    setHoje(`${anoH}-${mesH}-${diaH}`);
    setBlock(false);
  }

  function Selecionar(eventoCidade) {
    setCidade(`${eventoCidade.nome} - ${eventoCidade.uf}`);
    setIdCidade(eventoCidade.id);
    setModalCidade(!modalCidade);
  }

  const Confirmar = () => {
    envia();
  };


  function envia() {
    const token = getToken();
    let pLocalLargada = null;
    let pWebsite = "";
    let pResumo = "";
    let pTotalAtletas = null;
    let inputCreate = dataEvento;
    if (inputCreate != null) {
      const [ano1, mes1, dia1] = inputCreate.split("-");
      inputCreate = `${dia1}/${mes1}/${ano1}`;
    }
    let horaString = horaLargada;
    if (horaString != null && horaString !== "") {
      const [hora, min] = horaString.split(":");
      horaString = `${hora}:${min}`;
    } else {
      horaString = null;
    }
    if (totalAtletas !== "" || totalAtletas != null) {
      pTotalAtletas = totalAtletas;
    }
    const requestInfo = {
      method: "POST",
      body: JSON.stringify({
        id: id,
        nome: `${nome}`,
        cidade: idCidade,
        dataInicio: inputCreate,
        localLargada: localLargada == null ? pLocalLargada : `${localLargada}`,
        horaLargada: `${horaString}`,
        totalAtletas: pTotalAtletas,
        urlEvento: website == null ? `${pWebsite}` : `${website}`,
        resumo: resumo == null ? `${pResumo}` : `${resumo}`,
      }),
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };
    fetch(
      getUrlApi() + "/admin/atualizaDadosEvento",
      requestInfo
    ).then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          setMessageAPI(data.message);
          setResultColor("success");
        } else {
          if (response.status === 202) {
            setMessageAPI(data.message);
          } else {
            if (response.status === 400) {
              setMessageAPI(data[0].message);
            }
          }
          setResultColor("warning");
        }
        setVisible(true);
      });
    })
  }

  function buscaCidade(nomeCidade) {
    const token = getToken();
    const requestCity = {
      method: "POST",
      body: JSON.stringify({
        nome: `${nomeCidade}`,
      }),
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };
    fetch(getUrlApi() + "/cidade/buscaCidade/", requestCity).then(
      (response) => {
        response.json().then((data) => {
          setCidades(data);
        });
      }
    );
  }
  return (
    <Card>
      <div className="text-center">
        <ClipLoader color="gray" loading={loading} size={60} />
      </div>

      <Card>
        <Row>
          <Col sm="3"></Col>
          <Col sm="6">
            <Card className="p-2">
              <Form
                method="Post"
                onSubmit={(event) => {
                  event.preventDefault();
                }}
              >
                <CardTitle className="text-center mt-2 pt-2" >
                  <Label className="text_big">Evento <br />
                    {evento.nome}</Label>
                </CardTitle>
                {hashAtleta !== 'rubensrk' ? (
                  <Alert color="warning">{hashAtleta} Você não é administrador</Alert>
                ) : (
                  <CardText className="text-center">
                    <Button color="primary" outline onClick={Confirmar} className="button_form m-2">
                      Salvar
                    </Button>
                    <Link to={"/admin/editarModalidades/" + evento.id}>
                      <Button color="primary" className="button_form m-2">
                        Modalidades
                      </Button>
                    </Link>
                    <Link to={"/admin/imagemEvento/" + evento.id}>
                      <Button color="warning" className="button_form m-2">
                        Editar Imagem
                      </Button>
                    </Link>
                  </CardText>
                )}
                <CardBody>
                  <FormGroup>
                    <Label className="text_form">Nome do Evento</Label>
                    <Input
                      bsSize="sm"
                      type="text"
                      name="nome"
                      minLength="5"
                      maxLength="100"
                      id="nome"
                      value={nome}
                      onChange={(event) => {
                        setNome(event.target.value);
                      }}
                    />
                    <FormFeedback invalid>Nome incorreto</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label className="text_form">Data do Evento</Label>
                    <Input
                      bsSize="sm"
                      type="date"
                      name="dataEvento"
                      minLength="5"
                      maxLength="8"
                      id="dataEvento"
                      value={dataEvento}
                      pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                      onChange={(event) => {
                        setDataEvento(event.target.value);
                      }}
                      required
                    />
                    <FormFeedback invalid>data invalida</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label className="text_form">Cidade</Label>
                    <Input
                      bsSize="sm"
                      type="text"
                      name="cidade"
                      minLength="5"
                      id="cidade"
                      value={cidade}
                      placeholder="Click para selecionar a cidade"
                      onClick={Buscar}
                    />
                    <Input type="hidden" name="idCidade" value={idCidade} />
                    <FormFeedback invalid>Cidade invalida</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label className="text_form">Local da Largada</Label>
                    <Input
                      bsSize="sm"
                      type="text"
                      name="localLargada"
                      minLength="5"
                      maxLength="100"
                      id="localLargada"
                      value={localLargada}
                      onChange={(event) => {
                        setLocalLargada(event.target.value);
                      }}
                    />
                    <FormFeedback invalid>Nome incorreto</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label className="text_form">Hora da Largada</Label>
                    <Input
                      bsSize="sm"
                      type="time"
                      name="horaLargada"
                      minLength="5"
                      maxLength="5"
                      id="horaLargada"
                      value={horaLargada}
                      onChange={(event) => {
                        setHoraLargada(event.target.value);
                      }}
                    />
                    <FormFeedback invalid>Nome incorreto</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label className="text_form">Máximo de Inscritos</Label>
                    <Input
                      bsSize="sm"
                      type="text"
                      name="totalAtletas"
                      minLength="6"
                      maxLength="6"
                      id="totalAtletas"
                      value={totalAtletas}
                      onChange={(event) => {
                        setTotalAtletas(event.target.value);
                      }}
                    />
                    <FormFeedback invalid>Nome incorreto</FormFeedback>
                  </FormGroup>

                  <FormGroup>
                    <Label className="text_form">Site de Digulgação</Label>
                    <Input
                      bsSize="sm"
                      type="text"
                      name="website"
                      minLength="8"
                      maxLength="255"
                      id="website"
                      value={website}
                      onChange={(event) => {
                        setWebsite(event.target.value);
                      }}

                    />
                    <FormFeedback invalid>
                      website informado incorreto
                    </FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label className="text_form">Informe um resumo descritivo do evento</Label>
                    <Input
                      bsSize="sm"
                      type="textarea"
                      name="resumo"
                      id="resumo"
                      rows="5"
                      value={resumo}
                      onChange={(event) => {
                        setResumo(event.target.value);
                      }}
                      placeholder="..."
                    />
                    <FormFeedback invalid>
                      E-mail inválido! favor informe um email correto
                    </FormFeedback>
                  </FormGroup>
                  {messageAPI ? (
                    <Alert color={resultColor} isOpen={visible} toggle={onDismiss}>{messageAPI}</Alert>
                  ) : null}
                </CardBody>
                {hashAtleta !== 'rubensrk' ? (
                  <Alert color="warning">{hashAtleta} Você não é administrador</Alert>
                ) : (
                  <CardText className="text-center">
                    <Button color="primary" className="button_form m-2" outline onClick={Confirmar}>
                      Salvar
                    </Button>
                    <Link to={"/admin/editarModalidades/" + evento.id}>
                      <Button color="primary" className="button_form m-2">
                        Modalidades
                      </Button>
                    </Link>
                    <Link to={"/admin/imagemEvento/" + evento.id}>
                      <Button color="warning" className="button_form m-2">
                        Editar Imagem
                      </Button>
                    </Link>
                    <Link to={"/corridas/" + evento.id}>
                      <Button color="secondary" className="button_form m-2" outline>
                        Ir para Evento
                      </Button>
                    </Link>
                    <Link to={"/admin/eventos/planejados/"}>
                      <Button color="info" className="button_form m-2" outline>
                        ir para lista de eventos
                      </Button>
                    </Link>
                  </CardText>
                )}
              </Form>
            </Card>
          </Col>
        </Row>
      </Card>

      <div>
        <Modal isOpen={modalCidade} className="p-0">
          <ModalHeader>Selecione a cidade!</ModalHeader>
          <ModalBody className="pt-1 pb-3 text-left">
            <Form>
              <CardText className="pb-2">
                <small>Cidade:*</small>
                <Input
                  onChange={(event) => {
                    setSearchCity(event.target.value);
                    buscaCidade(event.target.value);
                  }}
                  type="text"
                  value={searchCity}
                  name="searchCity"
                  id="searchCity"
                  placeholder="Informe o nome da cidade"
                  minLength="3"
                  maxlength="80"
                  required
                />
              </CardText>
            </Form>
            {cidades.map((cidade) => (
              <Card
                onClick={() => {
                  Selecionar(cidade);
                }}
                id={cidade.id}
              >
                <CardText className="m-1">
                  {cidade.nome} - {cidade.uf}
                </CardText>
              </Card>
            ))}
          </ModalBody>
          <ModalFooter>
            <Button color="primary">Confirmar</Button>
            <Button color="secondary" onClick={Buscar}>
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </Card>
  );
}

export default EditarEvento;
