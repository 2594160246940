import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
    Container,
    Card,
    Row,
    Badge,
    Col,
    CardImg,
    CardBody,
    CardTitle,
    CardText,
    CardFooter,
    Alert,
    CardLink,
    Label,

} from "reactstrap";
import ProximosEventos from "./ProximosEventos";
import Notificacao from "./Notificacao";
import ClipLoader from "react-spinners/ClipLoader";
import foto from "../../assets/img/no-photo-event.png";
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from "axios";
import { getToken, getUrlApi } from "../../api/auth";


const Feeds = ({ url }) => {
    const [eventos, setEventos] = useState([]);
    let [loading, setLoading] = useState(true);
    const [showResult, setShowResult] = useState(false);
    const [showNoResult, setShowNoResult] = useState(false);
    let [pagina, setPagina] = useState(0);
    const cardStyle = { border: 'none' };

    const api = axios.create({
        baseURL: getUrlApi() + "/evento/",
    });

    api.interceptors.request.use(async (config) => {
        const token = getToken();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    });

    const fetchMoreData = async () => {
        setLoading(true);
        try {
            const resposta = await api.get("/feeds/" + pagina);
            setEventos([...eventos.concat(resposta.data)]);
            setLoading(false);
            if (resposta.data.length === 0) {
                setShowNoResult(true);
            } else {
                setShowNoResult(false);
            }
            setShowResult(true);
        } catch (resposta) {
            setLoading(false);
            setShowNoResult(true);
        }
        setPagina(pagina + 1);
    };

    useEffect(() => {
        const fetchData = async () => {
        try {
            const resposta = await api.get("/feeds/0");
            setEventos(resposta.data);
            setLoading(false);
            if (resposta.data.length === 0) {
                setShowNoResult(true);
            } else {
                setShowNoResult(false);
            }
            setShowResult(true);
        } catch (resposta) {
            setLoading(false);
            setShowNoResult(true);
        }
        setPagina(pagina + 1);
        };
        fetchData();
    }, []);

    return (
        <Container className="text-center">
            <Notificacao url={'/existeEvento'} />
            <ProximosEventos url={'/proximasCorridas'} />
            <InfiniteScroll
                dataLength={eventos.length}
                next={fetchMoreData}
                hasMore={true}
            >
                {
                    eventos.map((evento) => (
                        <CardLink href={`corridas/${evento.id}`} className="no-underline">
                            <Card className="mt-3" >
                                <Row>
                                    <Col sm="5" xs="5">
                                        <Link to={`/corridas/${evento.id}`}>
                                            <CardImg
                                                src={evento.foto === null ? foto : evento.foto}
                                                className="img-fluid rounded-lg m-3 "
                                            />
                                        </Link>
                                    </Col>
                                    <Col sm="7" xs="12" className="mb-3 text-center">
                                        <Card className="h-100 " style={cardStyle}>
                                            <CardBody>
                                                <CardTitle><Label className="text_big"> {evento.nome}</Label></CardTitle>
                                                <CardText className="m-0 text_medium">{evento.dataInicio}</CardText>
                                                <CardText className="mt-0 text_medium">{evento.cidade}</CardText>
                                                <CardText className="p-2 ">
                                                    <Badge color="success" pill className="p-2 ">
                                                        Corrida de Rua
                                                    </Badge>
                                                </CardText>
                                                <CardText>
                                                    {evento.modalidades.map((modalidade) => (
                                                        <Badge color={modalidade.cor} className="p-1 m-1 text_badge" >
                                                            {modalidade.nome}
                                                        </Badge>
                                                    ))}
                                                </CardText>
                                                <CardText className="mt=2 text_small">
                                                    {evento.organizadora}
                                                </CardText>

                                            </CardBody>
                                            <CardFooter className="m-0 p-0">
                                                <Alert
                                                    color={evento.statusCor}
                                                    className={`text_medium m-0 p-2 statuEvento--${evento.statusEvento}`}
                                                >
                                                    Evento {evento.statusEvento === "ESTASEMANA" ? "ocorrerá esta semana" : (evento.statusEvento.charAt(0).toUpperCase() + evento.statusEvento.slice(1).toLowerCase())}
                                                </Alert>
                                            </CardFooter>
                                        </Card>
                                    </Col>
                                </Row>
                                <CardFooter className="text-start p-1 text_form">
                                    {evento.registros === 0 ? "Ainda não temos Atletas neste evento" : ("Temos " + evento.registros + " Atletas neste evento!")}
                                </CardFooter>
                            </Card>
                        </CardLink>
                    )

                    )}
            </InfiniteScroll>
            <ClipLoader color="gray" loading={loading} size={60} />
        </Container>
    );

};

export default Feeds;
