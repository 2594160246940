import React from 'react'
import logo from "../assets/img/logo_minhas_medalhas_lg.svg";
import img_404 from "../assets/img/img_404.svg";
import { CardImg, Col, Container, Label, Row } from 'reactstrap'
import { Link } from 'react-router-dom/cjs/react-router-dom';

const Pagina404 = () => {
  return (
    <Container className="text-center">
      <Row>
        <Col sm="3">&nbsp;</Col>
        <Col sm="6">
          <Link to="/login"><CardImg src={logo} alt="Minhas Medalhas" /></Link>
          <Label className="text_big">
            <center>Opss!!! Não encontramos a página informada!!</center>
          </Label>
          <Label className="text_small">
            <center>Retorne pelo navegador ou acesso o login novamente, basta clickar neste link <Link to="/login" >Login!</Link></center>
          </Label>
          <CardImg src={img_404} />

        </Col>
        <Col sm="3">&nbsp;</Col>
      </Row>
    </Container>
  )
}

export default Pagina404