import React, { useEffect, useState } from "react";
import { busca } from "../../api/api_athlete";
import { Link } from "react-router-dom";
import logo from "../../assets/img/no-photo.png";
import Countdown from "react-countdown";
import { getToken, getUrlApi } from "../../api/auth";
import {
  CardBody,
  CardImg,
  CardText,
  Card,
  Alert,
  CardHeader,
  Row,
  Col,
  Badge,
  Button,
  CardGroup,
  Form,
  FormGroup,
  Input,
  Container,
  Label,
  CardFooter,
} from "reactstrap";
import ClipLoader from "react-spinners/ClipLoader";
import Patrocinio from "../Home/Patrocinio";

const TodosEventosPlanejados = ({ url }) => {
  const [todosEventos, setTodosEventos] = useState([]);
  let [loading, setLoading] = useState(true);
  const [showResult, setShowResult] = useState(false);
  const [showNoResult, setShowNoResult] = useState(false);
  const [searchEvento, setSearchEvento] = useState("");

  useEffect(() => {
    busca(url, setTodosEventos, setLoading, setShowResult, setShowNoResult);
  }, [url]);

  const Completionist = () => <span>É hora de Correr!</span>;

  function buscaEvento(nomeEvento) {
    const token = getToken();
    const requestAtleta = {
      method: "POST",
      body: JSON.stringify({
        name: `${nomeEvento}`,
      }),
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };
    fetch(
      getUrlApi() + "/atleta/buscaEventoPlanejado/",
      requestAtleta
    ).then((response) => {
      response.json().then((data) => {
        setTodosEventos(data);
        if (data.length === 0) {
          setShowNoResult(true);
        } else {
          setShowNoResult(false);
        }
      });
    });
  }


  const Result = () => (
    <CardGroup className="pl-3 pr-3">
      {todosEventos.map((registro) => (
        <Col sm="12" md="3" className="p-1">
          <Card className="m-1 text-center h-100">
            <Row>
              <Col xs="6" md="12">
                <CardText className="p-1">
                  <Link to={"/corridas/" + registro.idEvento}>
                    <CardImg
                      top
                      alt="Imagem do evento"
                      src={registro.logoEvento ? registro.logoEvento : logo}
                      className="img-fluid rounded-lg m-0 p-0 "
                    />
                  </Link>
                </CardText>
              </Col>
              <Col xs="6" md="12">
                <CardBody className="p-0">
                  <Label className="m-0 text_medium_center">{registro.nomeEvento}</Label><br />
                  <Label className="m-0 text_banco">{registro.nomeCidadeEvento} - {registro.ufEstadoEvento}</Label><br />
                  <Label className="m-0 text_banco">{registro.dataEvento}</Label>
                </CardBody>
                <CardFooter className="text-center m-0 p-0">
                  <Alert color="warning " className="text-center m-0 p-0">
                    <Countdown date={Date.now() + registro.dataMilisegundos}>
                      <Completionist />
                    </Countdown>
                  </Alert>
                </CardFooter>
              </Col>
            </Row>
          </Card>
        </Col>
      ))}
    </CardGroup>
  );

  const NoResult = () => (
    <CardText className="text-center m-2">
      Não encontramos corridas programadas por você!
      <br />
      <Link to="/novoEvento">
        <Button color="success" className="mr-2" block>
          Programe uma nova corrida!
        </Button>
      </Link>
    </CardText>
  );

  return (
    <Container>
      <Row>
        <Col sm="10" className="p-2 pt-2">
          <Card className="mt-2">
            <CardHeader className="text-center p-1" tag="h3">
              <Row>
                <Col sm="8" className="m-0 text_big">
                  Minhas Medalhas
                </Col>
                <Col sm="4" className="m-0 text-end">
                  <Link to="/novoEvento" className="no-underline">
                    <Button color="success" className="m-0" block>
                      Cadastre uma nova corrida!
                    </Button>
                  </Link>
                </Col>
              </Row>
            </CardHeader>
            <CardText>
              <Form>
                <Row>
                  <Col sm="12">
                    <FormGroup className="p-2 m-0">
                      <Input
                        type="text"
                        name="assessoria"
                        id="assessoria"
                        placeholder="Informe o nome de uma Corrida para filtrar"
                        onChange={(event) => {
                          setSearchEvento(event.target.value);
                          buscaEvento(event.target.value);
                        }}
                        value={searchEvento}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </CardText>
          </Card>

          <Card className="p-0 m-0 mt-2 text-center">
            <CardHeader className="text-left p-1">
              <Row>
                <Col xs="8" sm="8" className="m-0 pt-1 " tag="h6">
                  Próximos Eventos planejados
                </Col>
                <Col xs="4" sm="4" className="m-0 pl-0 text-end">
                  {showResult ? (
                    <Link
                      to={`/medalhas`}
                      className="mr-3 ml-3 mt-0 mb-0"
                    >
                      <Badge color="secondary" className="p-2" pill>
                        Voltar
                      </Badge>
                    </Link>
                  ) : null}
                </Col>
              </Row>
            </CardHeader>{" "}
            <div className="text-center">
              <ClipLoader color="gray" loading={loading} size={60} />
            </div>
            {showResult ? <Result /> : null}
            {showNoResult ? <NoResult /> : null}
          </Card>
        </Col>
        <Col sm="2" className="p-0 pt-2">
          <Patrocinio />
        </Col>
      </Row>
    </Container>

  );
};

export default TodosEventosPlanejados;
