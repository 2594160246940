import React from "react";
import {
  Container,
  Label,
  CardTitle,
  Card,
  CardText,
  Navbar,
  NavbarBrand,
  Row,
  Col,
} from "reactstrap";
import logo from "../../assets/img/logo_minhas_medalhas_lg.svg";
import Rodape from "../Home/Rodape";
import CabecalhoAjuda from "./CabecalhoAjuda";


function PoliticaDeCookies() {

  return (
    <Container className="text-center">
      <CabecalhoAjuda />
      <Card className="p-3">
        <Row>
          <Col xs="12" sm="2" >&nbsp;</Col>
          <Col xs="12" sm="8"  >
            <CardTitle>
              <Label className="text_big pt-5">Sobre o Minhas Medalhas</Label>
            </CardTitle>
            <CardText>
              <Label className="text_small">Bem-vindo ao Minhas medalhas, a comunidade dos corredores de rua do Brasil.</Label>
            </CardText>
            <CardTitle>
              <Label className="text_big  pt-4">Visão</Label>
            </CardTitle>
            <CardText>
              <Label className="text_small">Criar oportunidades de compartilhar os eventos de corrida, unir os atletas a suas assessorisas esportivas e ser a sala de troféus dos atletas para exibir suas conquistas e ser a maior plataforma de corridas de rua do Brasil.</Label>
            </CardText>
            <CardTitle>
              <Label className="text_big  pt-4">Missão</Label>
            </CardTitle>
            <CardText>
              <Label className="text_small">Conectar atletas de todo o brasil, divulgando suas conquistas incentivando uns aos outros no espiríto atlético.</Label>
            </CardText>
            <CardTitle>
              <Label className="text_big  pt-4">Quem somos nós?</Label>
            </CardTitle>
            <CardText>
              <Label className="text_small  pb-5">O Minhas Medalhas começou no escritório do fundador Rubens Rossi Kuhnen no meio da pandemia, querendo aprender uma linguagem de programação nova. Surgiu a idéia de criar uma comunidade para divulgar eventos e as conquistas dos atletas.</Label>
            </CardText>
          </Col>
          <Col xs="12" sm="2" >&nbsp;</Col>
        </Row>
      </Card>
      <Rodape />
    </Container>
  );
}

export default PoliticaDeCookies;
