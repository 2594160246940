import React from "react";
import {
  Card,
  Row,
  Col,
  Navbar,
  NavbarBrand,
  Label,
} from "reactstrap";
import logo from "../../assets/img/logo_minhas_medalhas_lg.svg";

const CabecalhoAjuda = () =>  {

  return (
      <Card max='container-fluid' className="sticky-top menu-color text-center">
        <Row>
          <Col xs="6" sm="2" >
            <Navbar color="light" light expand="sm" className="p-0">
              <NavbarBrand href="/"><img alt="Minhas Medalhas" src={logo} width='150%' /></NavbarBrand>
            </Navbar>
          </Col>
          <Col xs="6" sm="10"  className="text-end">
            <Label className="text_big p-3 pt-4">Central de Ajuda</Label>
          </Col>
        </Row>
      </Card>
  );
}

export default CabecalhoAjuda;
