import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Patrocinio from '../Home/Patrocinio';
import DetalhePerfil from './DetalhePerfil';

function Perfil(){

    return (
        <Container >
            <Row>
                <Col sm="9" className="p-0 pt-2">
                    <DetalhePerfil url={`/detalhePerfil/`} />
                </Col>
                <Col sm="3" className="p-0 pt-2">
                    <Patrocinio />
                </Col>
            </Row>
        </Container>    
    )

}

export default Perfil