import React, { useEffect, useState } from "react";
import { useParams, Link  } from "react-router-dom";
import { busca } from "../../api/api_athlete";
import logo from "../../assets/img/no-photo.png";
import Countdown from "react-countdown";
import {
  CardBody,
  CardImg,
  CardText,
  Card,
  Alert,
  CardHeader,
  Row,
  Col,
  CardGroup,
  CardFooter,
  Label,
} from "reactstrap";
import ClipLoader from "react-spinners/ClipLoader";

const ProximosEventos = ( ) => {
  const { id } = useParams();
  const [quatroEventos, setQuatroEventos] = useState([]);
  let [loading, setLoading] = useState(true);
  const [showResult, setShowResult] = useState(false);
  const [showNoResult, setShowNoResult] = useState(false);

  useEffect(() => {
    busca('proximasCorridasAtleta/'+id, setQuatroEventos, setLoading, setShowResult, setShowNoResult);
  }, [id]);

  const Completionist = () => <span>É hora de Correr!</span>;

  const Result = () => (
    <CardGroup className="pl-3 pr-3">
      {quatroEventos.map((registro) => (
        <Col sm="12" md="3" className="p-1">
          <Card className="m-1 h-100">
            <Row>
              <Col xs="6" md="12">
                <CardText className="p-1">
                  <Link to={"/corridas/" + registro.idEvento}>
                    <CardImg
                      top
                      alt="Imagem do evento"
                      src={registro.logoEvento ? registro.logoEvento : logo}
                      className="img-fluid rounded-lg m-0 p-0 "
                    />
                  </Link>
                </CardText>
              </Col>
              <Col xs="6" md="12">
                <CardBody className="p-0">
                  <Label className="m-0 text_medium_center">{registro.nomeEvento}</Label><br />
                  <Label className="m-0 text_banco">{registro.nomeCidadeEvento} - {registro.ufEstadoEvento}</Label><br />
                  <Label className="m-0 text_banco">{registro.dataEvento}</Label>
                </CardBody>
                <CardFooter className="text-center m-0 p-0">
                  <Alert color="warning " className="text-center m-0 p-0">
                    <Countdown date={Date.now() + registro.dataMilisegundos}>
                      <Completionist />
                    </Countdown>
                  </Alert>
                </CardFooter>
              </Col>
            </Row>
          </Card>
        </Col>
      ))}
    </CardGroup>
  );

  const NoResult = () => (
    <CardText className="text-center m-2 text_alert">
      Não encontramos corridas planjeadas para o Atleta!
      <br />

    </CardText>
  );

  return (
    <Card className="p-0 m-0 mt-2 ">
      <CardHeader className="p-2 text_medium">
        <Label className="text_medium">Próximos Eventos do atleta</Label>
      </CardHeader>
      <div className="text-center">
        <ClipLoader color="gray" loading={loading} size={60} />
      </div>
      {showResult ? <Result /> : null}
      {showNoResult ? <NoResult /> : null}
    </Card>
  );
};

export default ProximosEventos;
