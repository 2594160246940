import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import {
  CardGroup as FormGroup,
  Col,
  Container,
  Label,
  Row,
  CardTitle,
  CardText,
  Card,
} from "reactstrap";
import CabecalhoAjuda from "./CabecalhoAjuda";

function TermosDeUso() {

  return (
    <Container >
      <CabecalhoAjuda />
      <Card className="p-3 text-start">
        <Row>
          <Col xs="12" sm="2" >&nbsp;</Col>
          <Col xs="12" sm="8"  >
            <CardTitle className="text_big pb-4">Termos de Uso</CardTitle>
            <CardText>
              <Label className="text_small">Em vigor desde 09/01/2023</Label>
              <Label className="text_central_ajuda">
                Estes Termos de Uso gerenciam seu uso do Minhas Medalhas, exceto quando afirmamos explicitamente que outros termos (e não estes) se aplicam, e fornecem informações sobre o Serviço do Minhas Medalhas, descritas abaixo. Ao usar ou criar uma conta do Minhas Medalhas, você concorda com estes termos.
              </Label>
              <Label className="text_central_ajuda">
                Você concorda que, ao clicar em “Cadastrar”, ou em um botão semelhante para se registrar, acessar ou utilizar os serviços descritos abaixo, você está concordando em firmar um contrato legalmente válido com o Minhas Medalhas (mesmo que esteja utilizando os Serviços em nome de uma empresa). Caso não concorde com este Termo de usuário, NÃO clique em “Cadastrar” (ou semelhante) e não acesse ou, de outra forma, utilize nossos Serviços. Se desejar rescindir este contrato a qualquer momento, bastará encerrar a sua conta e parar de acessar ou utilizar nossos Serviços.
              </Label>
              <Label className="text_central_ajuda">
                Poderemos alterar este Termo, a Política de Privacidade e as Políticas de Cookies a qualquer momento. Em caso de mudanças relevantes, notificaremos você por meio dos nossos Serviços para lhe dar a oportunidade de avaliar as alterações antes de elas entrarem em vigor. Concordamos que tais alterações não podem ser retroativas. Caso tenha não concorde com a alguma alteração, você poderá encerrar sua conta. A continuidade do uso dos nossos Serviços após a publicação ou comunicação de alterações nestes termos implicará concordância com os termos atualizados a partir da sua data de vigência.
              </Label>
            </CardText>
            <CardText className="pb-0">
              <Label className="text_central_ajuda_titulo">
                Sua Conta
              </Label>
            </CardText>
            <CardText className="mt-2">
              <Label className="text_central_ajuda mt-0">
                O Atleta são titulares de suas contas. E com isso você concorda que:<br />
                - Utiliza uma senha forte e em a mantêm em sigilo;<br />
                - Não transfere qualquer parte de sua conta;<br />
                - Cumpri a lei e nossa lista do que é proibido e permitido no Minhas Medalhas; <br />
                - É responsável por tudo o que acontece na sua conta, a menos que a encerre ou denuncie uma utilização indevida.

              </Label>
              <Label className="text_central_ajuda">
                Os Serviços não podem ser utilizados por crianças com menos de 16 anos de idade.<br />
                Para utilizar os Serviços do Minhas Medalhas, você concorda que: <br />
                - Possuí a Idade mínima de 16 anos ou mais; <br />
                - Terá apenas uma conta no Minhas medalhas, que deve ser criada com seu nome real;<br />
                - Não há restrições ao seu nome para o uso dos Serviços do Minhas Medalhas. <br />
                Criar uma conta com informações falsas viola nossos termos, incluindo contas registradas em nome de terceiros ou de pessoas com menos de 16 anos.
              </Label>
            </CardText>
            <CardText className="pb-0">
              <Label className="text_central_ajuda_titulo ">
                Mensagens e Notificações
              </Label>
            </CardText>
            <CardText className="mt-2">
              <Label className="text_central_ajuda mt-0">
                Você concorda que forneceremos avisos e mensagens das seguintes maneiras:<br />
                - Dentro do App;<br />
                - Notificação enviada com o email que você forneceu;<br />
                - Notificação enviada com o telefone que você forneceu;<br />
                - Notificação enviada com o endereço físico que você forneceu.
              </Label>
              <Label className="text_central_ajuda">
                Para isto, é necessário que você se compromete a manter suas informações de contato atualizadas. Verifique suas configurações para controlar e limitar as mensagens que você receberá de nós.
                Ainda neste tema os serviços do Minhas medalhas permitem o envio de mensagens e o compartilhamento de informações utilizando alguns meios tais como:
                - Exibição do seu perfil;<br />
                - Publicações de atletas da assessoria esportiva;<br />
                - Participação em eventos;<br />
                - Mensagens e email.
              </Label>
              <Label className="text_central_ajuda">
                As informações e o conteúdo que você compartilha ou publica podem ser vistos por outros Usuários, porém nos recursos que oferecem configurações onde você pode ajustar conforme sua preferência e respeitaremos as suas escolhas sobre quem pode visualizar o conteúdo.
              </Label>
              <Label className="text_central_ajuda">
                Não temos a obrigação de publicar informações ou conteúdos em nosso Serviço e podemos removê-los com ou sem aviso a você.
              </Label>
            </CardText>
            <CardText className="pb-0">
              <Label className="text_central_ajuda_titulo ">
                Direitos e limites do usuário
              </Label>
            </CardText>
            <CardText className="mt-2">
              <Label className="text_central_ajuda mt-0">
                De acordo com o contrato firmado entre você e o Minhas Medalhas, você é proprietário do conteúdo e das informações que envia ou pública nos Serviços, e você está apenas concedendo ao LinkedIn e às nossas afiliadas a seguinte licença não exclusiva:
              </Label>
              <Label className="text_central_ajuda">
                Um direito de utilizar, copiar, modificar, distribuir, publicar e processar as informações e o conteúdo que você fornecer por meio de nossos Serviços e dos serviços de terceiros, sem qualquer outro consentimento, aviso e/ou compensação para você ou terceiros. Esses direitos são limitados das seguintes formas:
              </Label>
              <Label className="text_central_ajuda">
                - Você pode cancelar essa licença para um conteúdo específico, excluindo o conteúdo dos Serviços ou, geralmente, encerrando a sua conta, exceto (a) na medida em que você compartilhou o conteúdo com terceiros como parte do Serviço e eles o copiaram, compartilharam ou armazenaram e (b) durante o período necessário para removê-lo do backup e de outros sistemas.
              </Label>
              <Label className="text_central_ajuda">
                - Nós não incluiremos o seu conteúdo em anúncios de produtos e serviços de terceiros sem o seu consentimento explícito. No entanto, nós temos o direito, sem pagamento a você ou a terceiros, de publicar anúncios ao lado dos seus conteúdos e informações. Se você utiliza um recurso de Serviço, podemos mencioná-lo com seu nome ou foto para promover o recurso nos nossos Serviços de acordo com as suas configurações.
              </Label>
              <Label className="text_central_ajuda">
                - Embora possamos editar e alterar a formatação do seu conteúdo (como traduzi-lo ou transcrevê-lo, modificar seu tamanho, layout ou tipo de arquivo ou remover metadados), não modificaremos o significado do conteúdo.
              </Label>
              <Label className="text_central_ajuda">
                - Como você é proprietário de seus conteúdos e das informações, e que nós temos somente direitos não exclusivos sobre eles, você poderá optar por disponibilizá-los a terceiros.
              </Label>
              <Label className="text_central_ajuda">
                Você e o Minhas Medalhas concordam que poderemos acessar, armazenar, processar e utilizar quaisquer informações e dados pessoais que você forneça de acordo com os termos da Política de Privacidade e com suas escolhas.
              </Label>
              <Label className="text_central_ajuda">
                Você concorda que somente fornecerá conteúdos e informações que não violem a lei ou os direitos de terceiros (inclusive direitos de propriedade intelectual). Você também concorda que as suas informações de perfil serão verídicas. O Minhas Medalhas poderá ser exigido por lei a remover determinadas informações ou conteúdo.
              </Label>
              <Label className="text_central_ajuda">
                Nos reservamos o direito de limitar a sua utilização dos Serviços, inclusive o número das suas conexões e a sua capacidade de interagir com outros Usuários. O Minhas Medalhas reserva-se o direito de restringir, suspender ou encerrar a sua conta se você violar este Contrato ou a lei ou utilizar os Serviços de forma indevida.
              </Label>
            </CardText>
            <CardText className="pb-0">
              <Label className="text_central_ajuda_titulo ">
                Disponibilidade de Serviço
              </Label>
            </CardText>
            <CardText className="mt-2">
              <Label className="text_central_ajuda mt-0">
                Nós podemos alterar, suspender ou interromper qualquer um de nossos Serviços. Também podemos modificar nossos preços prospectivamente após notificação em prazo razoável, na medida permitida por lei.
              </Label>
              <Label className="text_central_ajuda">
                Não nos comprometemos em armazenar ou continuar exibindo informações ou conteúdos por você publicados. O Minhas Medalhas não é um serviço de armazenamento. Você concorda que não temos a obrigação de armazenar, manter ou fornecer a você uma cópia dos conteúdos ou informações que você ou terceiros fornecerem, exceto na medida exigida pela legislação em vigor e conforme indicado na nossa Política de Privacidade
              </Label>
            </CardText>
            <CardText className="pb-0">
              <Label className="text_central_ajuda_titulo ">
                Conteúdos e aplicativos de terceiros
              </Label>
            </CardText>
            <CardText className="mt-2">
              <Label className="text_central_ajuda mt-0">
                A utilização de conteúdo ou informações de terceiros publicados nos nossos Serviços é realizada por sua própria conta e risco.
              </Label>
              <Label className="text_central_ajuda">
                Terceiros poderão oferecer seus próprios produtos e serviços por meio dos nossos Serviços, sem que nos responsabilizemos por tais atividades de terceiros.
              </Label>
              <Label className="text_central_ajuda">
                Ao utilizar nossos Serviços, você poderá encontrar conteúdo ou informações que podem ser imprecisos, incompletos, desatualizados, enganosos, ilícitos, ofensivos ou, de alguma outra forma, prejudiciais. Geralmente, o Minhas Medalhas não analisa o conteúdo fornecido por nossos Usuários ou terceiros. Você concorda que não somos responsáveis por conteúdos e informações de terceiros (inclusive de outros Usuários). Nem sempre podemos impedir o uso indevido de nossos Serviços, e você concorda que não somos responsáveis por tal uso indevido. Você reconhece também que você ou sua organização poderão ser associados erroneamente a conteúdos sobre terceiros quando informarmos às conexões e aos seguidores que você foi mencionado na mídia. Os usuários têm opções sobre este recurso.
              </Label>
              <Label className="text_central_ajuda">
                O Minhas Medalhas pode ajudar a conectar Usuários que oferecem seus serviços (planejamento de corridas, assessorias esportivas, etc.) com Usuários que estejam buscando esses serviços. O Minhas Medalhas não realiza nem emprega indivíduos para a realização desses serviços. Você deve ter no mínimo 18 anos de idade para oferecer, realizar ou buscar esses serviços. Você reconhece que o Minhas Medalhas não supervisiona, dirige, controla nem monitora os Usuários durante a realização desses serviços e concorda que:
              </Label>
              <Label className="text_central_ajuda">
                - O Minhas Medalhas não é responsável pela oferta, realização ou contratação desses serviços; <br />
                - O Minhas Medalhas não endossa os serviços oferecidos por qualquer Usuário em particular; e <br />
                - Nada criará um relacionamento de emprego, agenciamento ou joint venture entre o Minhas Medalhas e qualquer Usuário que ofereça serviços. Se for Usuário e oferecer serviços, você declara e garante que detém todas as licenças necessárias e que oferecerá serviços consistentes
              </Label>
              <Label className="text_central_ajuda">
                Do mesmo modo, o Minhas Medalhas pode ajudar você a se cadastrar e/ou a comparecer a eventos publicados em nosso app, fazer parte de uma assessoria esportiva dentro do app e a se conectar com outros Usuários que participarão de tais eventos. Você concorda que:
              </Label>
              <Label className="text_central_ajuda">
                - O Minhas Medalhas não é responsável pela conduta de nenhum Usuário ou de outros participantes de tais eventos; <br />
                - O Minhas Medalhas não endossa nenhum evento particular listado nos nossos Serviços; <br />
                - O Minhas Medalhas não avalia e/ou aprova quaisquer desses eventos e <br />
                - Você cumprirá esses termos e condições que se apliquem a tais eventos.
              </Label>
            </CardText>
            <CardText className="pb-0">
              <Label className="text_central_ajuda_titulo ">
                Limites e Direitos de propriedade intelectual
              </Label>
            </CardText>
            <CardText className="mt-2">
              <Label className="text_central_ajuda mt-0">
                Nos reservamos o direito de limitar a sua utilização dos Serviços, inclusive o número das suas conexões e a sua capacidade de interagir com outros Usuários. O Minhas Medalhas reserva-se o direito de restringir, suspender ou encerrar a sua conta se você violar este Contrato ou a lei ou utilizar os Serviços de forma indevida.
              </Label>
              <Label className="text_central_ajuda">
                O Minhas Medalhas reserva-se todos os direitos de propriedade intelectual nos Serviços. As marcas comerciais e logomarcas utilizadas em conexão com o Minhas Medalhas constituem marcas comerciais dos seus respectivos proprietários. O Minhas Medalhas, as logomarcas “m” e as demais marcas comerciais, marcas de serviço, elementos gráficos e logomarcas do Minhas Medalhas utilizados para nossos Serviços constituem marcas comerciais ou marcas registradas do Minhas Medalhas.
              </Label>
            </CardText>
            <CardText className="pb-0">
              <Label className="text_central_ajuda_titulo ">
                Processamento Automático
              </Label>
            </CardText>
            <CardText className="mt-2">
              <Label className="text_central_ajuda mt-0">
                Utilizamos as informações e os dados que você forneceu e que temos sobre os Usuários para fazer recomendações de conexões, conteúdos e recursos que possam ser úteis para você. Por exemplo, utilizamos dados e informações a seu respeito para recomendar corridas a você e recomendar o seu perfil para as assessorias. Manter seu perfil atualizado e correto nos ajuda a tornar essas recomendações mais precisas e relevantes.
              </Label>
            </CardText>
            <CardText className="pb-0">
              <Label className="text_central_ajuda_titulo ">
                Isenção de responsabilidade e limite de responsabilidade
              </Label>
            </CardText>
            <CardText className="mt-2">
              <Label className="text_central_ajuda mt-0">
                O Minhas Medalhas não faz declarações nem garantias sobre os serviços, inclusive qualquer declaração de que os serviços não sofrerão interrupções ou estarão isentos de erros, e oferecem os serviços “no estado em que se encontram” e “na forma disponibilizada”. Dentro do limite permitido pela legislação em vigor, o Minhas Medalhas isenta-se de toda e qualquer garantia legal ou implícita, incluindo qualquer garantia implícita de titularidade, precisão de dados, não violação, comercialização ou adequação a um dado propósito.
              </Label>
              <Label className="text_central_ajuda">
                As limitações de responsabilidade nesta Seção fazem parte do acordo firmado entre você e o Minhas Medalhas e deverão se aplicar a todas as reivindicações de responsabilidade (como, por exemplo, garantia, delito, negligência, contrato e lei), mesmo que o Minhas Medalhas tenha sido avisado da possibilidade de tal dano e ainda que esses recursos não cumpram seu propósito essencial.
              </Label>
              <Label className="text_central_ajuda">
                Essas limitações de responsabilidade não se aplicam à responsabilidade por morte ou ferimentos pessoais ou por fraude, negligência grave ou dolo ou, em casos de negligência em que uma obrigação relevante tenha sido violada, uma obrigação relevante que consista em um pré-requisito para nossa entrega de serviços e na qual você possa razoavelmente confiar, mas apenas na medida em que tais danos tenham sido causados diretamente pela violação e sejam previsíveis na data da conclusão deste Contrato e na medida em que sejam típicos no contexto deste Contrato.
              </Label>
            </CardText>
            <CardText className="pb-0">
              <Label className="text_central_ajuda_titulo ">
                Rescisão
              </Label>
            </CardText>
            <CardText className="mt-2">
              <Label className="text_central_ajuda mt-0">
                Qualquer uma das partes poderá rescindir este Contrato a qualquer momento sem aviso. Em caso de rescisão, você perde o direito de acessar e utilizar os Serviços.
              </Label>
            </CardText>
            <CardText className="pb-0">
              <Label className="text_central_ajuda_titulo ">
                Termos gerais
              </Label>
            </CardText>
            <CardText className="mt-2">
              <Label className="text_central_ajuda mt-0">
                Se um tribunal com autoridade sobre este Contrato considerar qualquer parte do presente instrumento inexequível, você e o Minhas Medalhas concordam que o tribunal deverá modificar os termos a fim de tornar essa parte exequível, sem deixar de alcançar sua finalidade. Caso o tribunal não possa fazer isso, nós concordamos em solicitar ao tribunal a remoção da parte inexequível e garantir a execução do restante deste Contrato.
              </Label>
              <Label className="text_central_ajuda">
                Este Contrato é o único acordo entre nós referente aos Serviços e substitui todos os acordos e contratos anteriores referentes aos Serviços.
              </Label>
              <Label className="text_central_ajuda">
                Caso não tenhamos tomado as providências devidas decorrentes de uma violação do presente Contrato, isso não significa que o Minhas Medalhas renunciou ao seu direito de executar o Contrato. Você não pode ceder ou transferir este Contrato para ninguém sem o nosso consentimento. Não há outros beneficiários neste Contrato.
              </Label>
            </CardText>
            <CardText className="pb-0">
              <Label className="text_central_ajuda_titulo ">
                O que é permitido ou proibido no Minhas Medalhas
              </Label>
            </CardText>
            <CardText className="mt-2">
              <Label className="text_central_ajuda mt-0">
                Esta lista do que é permitido ou proibido, limitam o que você pode e não pode fazer em relação aos nossos Serviços.
              </Label>
              <Label className="text_central_ajuda_titulo">
                O que é permitido! Você concorda que irá:
              </Label>
              <Label className="text_central_ajuda">
                - Cumprir todas as leis aplicáveis, incluindo, entre outros, leis de privacidade, leis de propriedade intelectual, leis antisspam, leis tributárias e exigências regulatórias;<br /><br />
                - Fornecer informações precisas para nós e as manterá atualizadas;<br /><br />
                - Utilizar seu nome verdadeiro em seu perfil; e<br /><br />
                - Utilizar os Serviços de maneira profissional.s
              </Label>
              <Label className="text_central_ajuda_titulo">
                O que é não permitido! Você concorda que não irá:
              </Label>
              <Label className="text_central_ajuda">
                - Criar uma identidade falsa no Minhas Medalhas, publicar informações incorretas no seu perfil, criar um perfil de Usuário para alguém que não seja você (uma pessoa física), ou utilizar ou tentar utilizar a conta de outra pessoa;<br />     <br />
                - Desenvolver, dar suporte ou utilizar software, dispositivos, scripts, robôs ou quaisquer outros meios ou processos (incluindo plugins e add-ons para navegadores ou quaisquer outras tecnologias) para fazer varredura nos Serviços ou copiar de outra forma perfis e outros dados dos Serviços;<br />     <br />
                - Copiar, utilizar, divulgar ou distribuir quaisquer informações obtidas a partir dos Serviços, diretamente ou por meio de terceiros, sem o consentimento do Minhas Medalhas;<br /><br />
                - Divulgar informações que você não tenha autorização para divulgar;<br /><br />
                - Violar direitos de propriedade intelectual de terceiros, incluindo direitos autorais, patentes, marcas, segredos industriais ou outros direitos protegidos. Exemplo: não copie nem distribua (exceto por meio da funcionalidade de compartilhamento disponível) publicações ou outros conteúdos de terceiros sem permissão;<br /><br />
                - Violar direitos de propriedade intelectual ou outros direitos do Minhas Medalhas, incluindo, entre outros: (i) copiar ou distribuir nossos vídeos de aprendizagem ou outros materiais; (ii) copiar ou distribuir nossa tecnologia, salvo se licenciada mediante licenças de código aberto; ou (iii) utilizar a palavra “Minhas Medalhas” ou nossas logomarcas em qualquer nome comercial, e-mail ou URL;<br /><br />
                - Publicar conteúdo que contenha vírus de software, worms ou qualquer outro código prejudicial;<br /><br />
                - Fazer a engenharia reversa, descompilar, desmontar, decifrar ou tentar extrair de outro modo o código-fonte dos Serviços ou de qualquer tecnologia relacionada que não seja de código aberto;<br /><br />
                - declarar que você é afiliado ou endossado pelo Minhas Medalhas sem o nosso consentimento expresso (por exemplo, apresentar-se como um suporte credenciado do Minhas Medalhas);<br /><br />
                - Criar um deep link para nossos Serviços para quaisquer fins, exceto para promover seu perfil, sem o consentimento do Minhas Medalhas;<br /><br />
                - Utilizar bots ou outros métodos automatizados para acessar os Serviços, adicionar ou baixar contatos, enviar ou redirecionar mensagens;<br /><br />
                - Monitorar a disponibilidade, o desempenho ou a funcionalidade dos Serviços para quaisquer fins competitivos;<br /><br />
                - Envolver-se em atividades de “enquadramento”, “espelhamento” ou de outro modo simular a aparência ou a função dos Serviços;<br /><br />
                - Sobrepor ou modificar de outro modo os Serviços ou sua aparência (por exemplo, inserindo elementos nos Serviços ou removendo, cobrindo ou obscurecendo um anúncio incluído nos Serviços);<br /><br />
                - Interferir no funcionamento ou colocar uma carga não razoável nos Serviços (por exemplo, spam, ataque de negação de serviço, vírus, algoritmos de jogos); e/ou<br /><br />
                - Violar quaisquer termos adicionais referentes a um Serviço específico que seja prestado quando você se cadastra ou começa a utilizar tal Serviço.
              </Label>
            </CardText>
            <CardText className="pb-0">
              <Label className="text_central_ajuda_titulo ">
                Como entrar em contato conosco
              </Label>
            </CardText>
            <CardText className="mt-2">
              <Label className="text_central_ajuda mt-0">
                Nossa Central de Ajuda também fornece informações sobre os nossos Serviços. Para consultas gerais, entre em contato conosco on-line.
              </Label>
            </CardText>
          </Col>
        </Row>
      </Card>

    </Container>
  );
}

export default TermosDeUso;
