import React, { useEffect, useState } from "react";
import { busca } from "../../api/api_event";
import { Link } from "react-router-dom";
import Countdown from "react-countdown";
import {
  CardBody,
  CardImg,
  CardText,
  Card,
  Alert,
  Button,
  CardGroup,
  CardFooter,
  Label
} from "reactstrap";
import ClipLoader from "react-spinners/ClipLoader";
import foto from "../../assets/img/no-photo.png"

const ProximosEventos = ({ url }) => {
  const [quatroEventos, setQuatroEventos] = useState([]);
  let [loading, setLoading] = useState(true);
  const [showResult, setShowResult] = useState(false);
  const [showNoResult, setShowNoResult] = useState(false);

  useEffect(() => {
    busca(url, setQuatroEventos, setLoading, setShowResult, setShowNoResult);
  }, [url]);

  const Completionist = () => <span>É hora de Correr!</span>;

  const Result = () => (
    <CardGroup className="pl-3 pr-3">
      {quatroEventos.map((evento) => (
        <Card className="p-0 m-1">
          <CardText className="p-1">
            <Link to={"/corridas/" + evento.id}>
              <CardImg
                top
                alt="Imagem do evento"
                src={evento.foto === null ? foto : evento.foto}
                className="img-fluid rounded-lg m-0 p-0 "
              />
            </Link>
          </CardText>
          <CardBody className="p-0 text-center" >
            <CardText className="p-0 m-0">
              <Label className="text_medium">{evento.nome}</Label>
            </CardText>
            <CardText className="p-0 m-0">
              <Label className="text_small">{evento.cidade}</Label>
            </CardText>
          </CardBody>
          <CardFooter >
          {evento.dataInicio}
          </CardFooter>
          <CardFooter className="m-0 p-0">
            <Alert color="warning " className="text_alert m-0 p-1">
           {evento.dataMilisegundos > 0 ? "Faltam" : null} <Countdown date={Date.now() + evento.dataMilisegundos}>
                <Completionist />
              </Countdown>
            </Alert>
          </CardFooter>
        </Card>
      ))}
    </CardGroup>

  );
  const NoResult = () => (
    <CardText className="text_medium m-3">
      Que pena! Não temos nenhum evento cadatrado para os próximos dias! Aproveite agora
      mesmo e cadastre sua próxima corrida!
      <br />
      <Button color="success" className="m-3">
        Planejar uma corrida!
      </Button>
    </CardText>
  );


  return (
    <Card className="p-0 m-0 mt-2 text-center">
      <Alert color="dark" className="text_big_center m-1 p-0" >
        Próximos eventos
      </Alert>
      <div className="text_medium">
        <ClipLoader color="gray" loading={loading} size={60} />
      </div>
      {showResult ? <Result /> : null}
      {showNoResult ? <NoResult /> : null}
    </Card>
  );
};

export default ProximosEventos;
