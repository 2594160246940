import axios from 'axios';
import { getToken, getUrlApi } from "./auth";

export const api = axios.create({
   baseURL: getUrlApi()+'/admin/time/'
})

api.interceptors.request.use(async config => {
   const token = getToken();
   if (token) {
     config.headers.Authorization = `Bearer ${token}`;
   }
   return config;
 });

export const busca = async (url, setDado, setLoading, setShowResult, setShowNoResult) => {
   try {
      const resposta = await api.get(url)
      setDado(resposta.data)
      setLoading(false)
      setShowNoResult(false)
      setShowResult(true)
      if (resposta.data.length === 0){
         setShowResult(false)
         setShowNoResult(true)
      }
   }catch (resposta){
      setLoading(false)
      setShowResult(false)
      setShowNoResult(true)
   }
};