import React, { useEffect, useState } from "react";
import { busca } from "../../api/api_adm_organizadora";
import { Link, useParams } from "react-router-dom";
import foto from "../../assets/img/no-photo.png";
import { getToken, getUrlApi } from "../../api/auth";
import {
  CardTitle,
  CardSubtitle,
  CardBody,
  CardImg,
  CardText,
  Card,
  Alert,
  CardHeader,
  Row,
  Col,
  Button,
  CardGroup,
  Form,
  FormGroup,
  Input,
  Container,
} from "reactstrap";
import ClipLoader from "react-spinners/ClipLoader";

const ListaEventos = ({ url }) => {
  let { id } = useParams();
  const [todosEventos, setTodosEventos] = useState([]);
  let [loading, setLoading] = useState(true);
  const [showResult, setShowResult] = useState(false);
  const [showNoResult, setShowNoResult] = useState(false);
  const [searchEvento, setSearchEvento] = useState("");
  const [messageAPI, setMessageAPI] = useState("");
  const [resultColor, setResultColor] = useState("");
  const [atualiza, setAtualiza] = useState(false);

  useEffect(() => {
    setLoading(true);
    setShowResult(false);
    setShowNoResult(false);
    busca(url, setTodosEventos, setLoading, setShowResult, setShowNoResult);
  }, [atualiza, url]);

  function AprovarEvento(id) {
    const token = getToken();
    const requestAtleta = {
      method: "GET",
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };
    fetch(
      getUrlApi() + "/admin/organizadora/aprovarEvento/" + id,
      requestAtleta
    ).then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          setAtualiza(!atualiza);
        } else {
          if (response.status === 202) {
            setMessageAPI(data.message);
          } else {
            if (response.status === 400) {
              setMessageAPI(data[0].message);
            }
          }
          setResultColor("warning");
        }
      });
    });
  }

  function buscaEvento(nomeEvento) {
    const token = getToken();
    const requestAtleta = {
      method: "POST",
      body: JSON.stringify({
        nome: `${nomeEvento}`,
      }),
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };
    fetch(getUrlApi(), requestAtleta).then((response) => {
      response.json().then((data) => {
        setTodosEventos(data);
        if (data.length === 0) {
          setShowNoResult(true);
        } else {
          setShowNoResult(false);
        }
      });
    });
  }

  const Result = () => (
    <CardGroup className="pl-3 pr-3">
      {todosEventos.map((evento) => (
        <Col sm="12" md="3" className="p-1">
          <Card className="m-1 text-center h-100">
            <CardText className="p-1">
              <Link to={"/corridas/" + evento.id}>
                <CardImg
                  top
                  alt="Imagem do evento"
                  src={evento.foto ? evento.foto : foto}
                  className="img-fluid rounded-lg m-0 p-0 "
                />
              </Link>
            </CardText>
            <Alert color={evento.statusCor} className="text-center m-0 p-0">
              {evento.statusEvento}
            </Alert>
            <CardBody className="p-1 text-center">
              <CardTitle tag="h6" className="p-0">
                {evento.nome}
              </CardTitle>
              <CardSubtitle tag="h6" className="mb-0 text-muted">
                {evento.cidade}
              </CardSubtitle>
              <CardText className="m-0">
                <small>{evento.dataInicio}</small>
              </CardText>
            </CardBody>
            {id === "pendenteAprovacao" ? (
              <Button
                block
                color="success"
                onClick={() => AprovarEvento(evento.id)}
              >
                Aprovar
              </Button>
            ) : id !== "pendenteAdministracao" ? (
              <Link to={"/adminOrganizadora/editarEvento/" + evento.id}>
                <Button block color="primary">
                  Editar
                </Button>
              </Link>
            ) : null}
          </Card>
        </Col>
      ))}
    </CardGroup>
  );

  const NoResult = () => (
    <CardText className="text-center m-2">
      Não encontramos corridas programadas pela organizadora!
      <br />
      <Link to="/novoEvento">
        <Button color="success" className="mr-2" block>
          Programe uma nova corrida!
        </Button>
      </Link>
    </CardText>
  );

  return (
    <Container>
      <Row>
        <Col sm="12" className="p-2 pt-2">
          <Card className="mt-2">
            <CardHeader className="text-center p-1" tag="h3">
              <Row>
                <Col sm="8" className="m-0">
                  Eventos
                </Col>
                <Col sm="4" className="m-0 text-end">
                  <Link to="/novoEvento">
                    <Button color="success" className="m-0" block>
                      Cadastre uma nova corrida!
                    </Button>
                  </Link>
                </Col>
              </Row>
            </CardHeader>
            <CardText>
              <Form>
                <Row>
                  <Col sm="12">
                    <FormGroup className="p-2 m-0">
                      <Input
                        type="text"
                        name="assessoria"
                        id="assessoria"
                        placeholder="Informe o nome de uma Corrida para filtrar"
                        onChange={(event) => {
                          setSearchEvento(event.target.value);
                          buscaEvento(event.target.value);
                        }}
                        value={searchEvento}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </CardText>
          </Card>

          <Card className="p-0 m-0 mt-2 text-center">
            <CardHeader className="text-left p-1">
              <Row>
                <Col className="m-0 pt-1 " tag="h6">
                  Eventos{" "}
                  <span>
                    {id === "semDataDefinida"
                      ? "sem data definida"
                      : id === "pendenteAprovacao"
                      ? "aguardando aprovação da Organizadora"
                      : id === "pendenteAdministracao"
                      ? "em analise pelo time Minhas Medalhas"
                      : id}
                  </span>
                </Col>
              </Row>
            </CardHeader>{" "}
            <div className="text-center">
              <ClipLoader color="gray" loading={loading} size={60} />
            </div>
            {showResult ? <Result /> : null}
            {showNoResult ? <NoResult /> : null}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ListaEventos;
